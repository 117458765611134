import React, { useState } from 'react'
import { Button } from '@mui/material'
import SideNavGroups from '../SideNavGroups/SideNavGroups'
import SideNavContentTypes from '../SideNavContentTypes/SideNavContentTypes'
import SideNavModal from '../SideNavModal/SideNavModal'
import { checkDupes } from './SideNavHelpers'

// Export side nav button for capas.
export default function SideNavButtonCAPAs ({ findingID, QIs, setSidePreviews, CAPACRSAC, CAPAESP, CAPALOC, CAPADL, audit }) {
  let auditTitle = ''
  let auditID = ''
  let qiString = ''
  const qiIDs = []
  const CRSACs = []
  const ESPs = []
  const LOCs = []
  const DLs = []
  // Set state to show modal.
  const [shouldShowModal, setShouldShowModal] = useState(false)
  // Set the content type.
  const [contentType, setContentType] = useState()
  // Set the content type title.
  const [contentTypeTitle, setContentTypeTitle] = useState()
  // Set the data to render.
  const [data, setData] = useState()
  // Check non-duplicate node and push it.
  const pushElement = (dataItem, type) => {
    dataItem.forEach(element => {
      if (element && checkDupes(element, type)) {
        type.push(element)
      }
    })
  }

  if (QIs) {
    // Loop through all qis data.
    QIs.filter(n => n).forEach(item => {
      // Push data for qis CRSAC.
      if (item.entity?.CRSAC) {
        pushElement(item.entity?.CRSAC, CRSACs)
      }
      // Push data for qis ESP.
      if (item.entity?.ESP) {
        pushElement(item.entity?.ESP, ESPs)
      }
      // Push data for qis LOC.
      if (item.entity?.LOC) {
        pushElement(item.entity?.LOC, LOCs)
      }
      // Push data for qis DL.
      if (item.entity?.DL) {
        pushElement(item.entity?.DL, DLs)
      }
      qiIDs.push(item.entity?.nid)
    })
  }
  qiString = qiIDs.filter(n => n).join(',')
  // Push data for capa CRSAC.
  if (CAPACRSAC) {
    pushElement(CAPACRSAC, CRSACs)
  }
  // Push data for capa ESP.
  if (CAPAESP) {
    pushElement(CAPAESP, ESPs)
  }
  // Push data for capa LOC.
  if (CAPALOC) {
    pushElement(CAPALOC, LOCs)
  }
  // Push data for capa DL.
  if (CAPADL) {
    pushElement(CAPADL, DLs)
  }

  if (audit) {
    auditID = audit?.nid
    auditTitle = audit?.title
    // Push data for audit CRSAC.
    if (audit?.CRSAC) {
      pushElement(audit?.CRSAC, CRSACs)
    }
    // Push data for audit ESP.
    if (audit?.ESP) {
      pushElement(audit?.ESP, ESPs)
    }
    // Push data for audit LOC.
    if (audit?.LOC) {
      pushElement(audit?.LOC, LOCs)
    }
    // Push data for audit DL.
    if (audit?.DL) {
      pushElement(audit?.DL, DLs)
    }
  }
  // Return the side nav button for capas.
  return (
    <div className='side-nav-button'>
      <Button
        onClick={() => {
          setSidePreviews(
            <div className='side-wrapper--right-column'>
              <div className='sidebar-wrapper--right-column'>
                <SideNavContentTypes
                  contentType='Audit'
                  number={auditID ? 1 : 0}
                  title={auditTitle}
                  link={auditID ? `/content/audits?id=${auditID}` : ''}
                />
                <SideNavContentTypes
                  contentType='Findings'
                  number={findingID ? 1 : 0}
                  link={findingID ? `/content/findings?id=${findingID}` : ''}
                />
                <SideNavContentTypes
                  contentType='Quality Investigations'
                  number={QIs.length}
                  link={QIs.length > 0 ? `/content/qis?id=${qiString}` : ''}
                />
                <SideNavGroups
                  contentType='CRSAC(s)'
                  data={CRSACs}
                  openModalCallback={() => {
                    setContentType('CRSAC')
                    setData(CRSACs)
                    setShouldShowModal(true)
                    setContentTypeTitle('CRSAC(s)')
                  }}
                />
                <SideNavGroups
                  contentType='ESP(s)'
                  data={ESPs}
                  openModalCallback={() => {
                    setContentType('ESP')
                    setData(ESPs)
                    setShouldShowModal(true)
                    setContentTypeTitle('ESP(s)')
                  }}
                />
                <SideNavGroups
                  contentType='LOC(s)'
                  data={LOCs}
                  openModalCallback={() => {
                    setContentType('LOC')
                    setData(LOCs)
                    setShouldShowModal(true)
                    setContentTypeTitle('LOC(s)')
                  }}
                />
                <SideNavGroups
                  contentType='DL(s)'
                  data={DLs}
                  openModalCallback={() => {
                    setContentType('DL')
                    setData(DLs)
                    setShouldShowModal(true)
                    setContentTypeTitle('DL(s)')
                  }}
                />
              </div>
            </div>
          )
        }}
      >
        Side Nav
      </Button>
      <SideNavModal
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
        data={data}
        contentType={contentType}
        contentTypeTitle={contentTypeTitle}
      />
    </div>

  )
}
