import React, { useState, useContext } from 'react'
// Import necessary components.
import { Button, Modal, Fade } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import './edit-member.scss'
import { constants } from '../../../../../lib'
import memberIconRed from '../../../../../images/red-icon-people.svg'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../../context/UserContext'

// Add css for member add modal.
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'grid',
    alignItems: 'baseline',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    margin: 'auto',
    borderRadius: '5px',
    [theme.breakpoints.up('sm')]: {
      width: '500px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw'
    }
  },
  checkboxLabel: {
    fontSize: '15px'
  }
}))
export default function RemoveMember ({ gid, visible, onCancel, person, setFeedbackMessage, setShouldReload, groupName }) {
  // Set css for modal.
  const classes = useStyles()
  // Fetch data from UserContext.
  const { otherDetails } = useContext(UserContext)
  // Set is loading.
  const [isLoading, setIsLoading] = useState(false)
  // Set is disable buttons
  const [disabled, setDisabled] = useState(false)
  // Set shouldReload.
  const [hasError, setHasError] = useState(false)
  // Set object from useNavigate hook.
  const navigate = useNavigate()
  // Handle submit.
  const handleSubmit = () => {
    setDisabled(true)
    // Set loading before submit.
    setIsLoading(true)
    // Set the api url.
    const url = `${process.env.REACT_APP_TARGET_URL}/api/remove-group-member`
    // Set the payload data.
    const data = {
      gid: parseInt(gid),
      uid: parseInt(person.userId)
    }
    // To track if logged out.
    let haveResponse = false
    // Call the api.
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(res => {
        haveResponse = true
        return res.json()
      })
      .then(res => {
        setDisabled(false)
        const isUserName = otherDetails.name === person.username
        if (!res.error && isUserName) {
          // Set the success message in localStorage.
          localStorage.setItem('feedbackMessage', JSON.stringify({ type: 'error', icon: <img src={memberIconRed} alt='Member Icon' />, section: 'Removed Group', message: res.response.msg }))
          // Redirect to page given in response.
          navigate(res.response.groupPath)
        } else {
          // Set message in page top.
          setFeedbackMessage({ message: res.response.msg, type: 'error', icon: <img src={memberIconRed} alt='Member Icon' />, section: 'editMember' })
          // Load the group silently.
          setShouldReload(true)
          // Close the modal.
          onCancel()
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err.message)
        setHasError(true)
      })
  }

  return (
    <div className='edit-member'>
      <Modal
        aria-labelledby='Remove member modal'
        aria-describedby='Remove group members'
        className={classes.modal}
        open={visible}
        onClose={onCancel}
        closeAfterTransition
      >
        <Fade in={visible}>
          <div className={`${classes.paper} modal-wrapper`}>
            <span className='close-modal'>
              <Close fontSize='medium' onClick={onCancel} />
            </span>
            <div className='edit-member-modal-content'>
              <div className='heading'>Remove Group Member</div>
              {hasError && <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: constants.errors.server }} /></Alert>}
              {!hasError && <div>
                <div className='sub-heading'>Are you sure you want remove {(person.displayName !== 'false') ? person.displayName : person.username} from {groupName} ?</div>
                <div className='btn-wrapper-edit'>
                  <Button disabled={disabled} variant='outlined' className='cancel-edit' onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button disabled={disabled} id='save-edit' variant='contained' className='save-edit' onClick={handleSubmit}>
                    {isLoading ? 'Removing...' : 'Remove'}
                  </Button>
                </div>
              </div>}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
