import React, { useState, useEffect } from 'react'
// Include necessary assets.
import { AppBar, Avatar, Button, Checkbox } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import '../sass/component/team-modal.scss'
import ProfileImage from '../../../ProfileImage'
import { userAbbreviation } from '../../../ProfileImage/utility'
import { constants } from '../../../../lib'
import JoinGroupButton from './JoinGroupButton'
import { memberSearchFilter } from './MemberSearch'
import HighLightText from './HighlightText'

// Export the component of modal.
export default function TeamModal ({ businessTeam, groupName, profilePictures, closeModal, id, isMember }) {
  // State initial tab content.
  const [reducedData, setReducedData] = useState(businessTeam.list)
  // Get the team data object from config.
  const groupTeamData = constants.misc.groupTeamMemberData
  // Set searchValue.
  const [searchValue, setSearchValue] = useState('')
  // Set selectedEmails.
  const [selectedEmails, setSelectedEmails] = useState([])
  // Set uniqueEmails.
  const uniqueEmails = []
  // Set selectedRole.
  const [roleFilter, setRoleFilter] = useState([])
  // Set windowLaunched.
  const [windowLaunched, setWindowLaunched] = useState(false)

  useEffect(() => {
    setSelectedEmails([])
    let roleFirst = false
    if (searchValue.trim().length === 0 && roleFilter.length) {
      roleFirst = true
    }
    setReducedData(memberSearchFilter(businessTeam.list, roleFilter, searchValue, roleFirst))
  }, [businessTeam, roleFilter, searchValue])

  // Generating unique emails.
  businessTeam.list.forEach(element => {
    if (!uniqueEmails.some(email => email === element.userEmail)) {
      uniqueEmails.push(element.userEmail)
    }
  })

  // Joining selectedEmails separated by ';'
  const mailString = selectedEmails.join(';')

  // Function to check if given email is selected or not.
  const checkIfSelected = email => selectedEmails.some(mail => mail === email)

  // Function to add or Remove email from selectedEmail list.
  const addOrRemoveEmail = email => {
    if (checkIfSelected(email)) {
      setSelectedEmails(selectedEmails.filter(mail => mail !== email))
    } else {
      setSelectedEmails([...selectedEmails, email])
    }
  }

  // Function to check if all emails are selected.
  const checkIfAllSelected = () => selectedEmails.length === uniqueEmails.length

  // Function to handle Select All and Deselect All.
  const handleSelectAndDeselectAll = () => {
    if (checkIfAllSelected()) {
      setSelectedEmails([])
    } else {
      setSelectedEmails(uniqueEmails)
    }
  }

  // Set roles to generate filter button.
  const roles = constants.misc.groupTeamMemberData.businessTeamRoles

  // Function to handle change in search value.
  const handleSearch = e => {
    setSearchValue(e.target.value)
  }

  // Open email client.
  const handleSendMessage = emails => {
    setWindowLaunched(true)
    window.location.href = `mailto:${emails}?body=${window.location.href}`
    setSelectedEmails([])
  }

  // Function to add or remove roll filter
  const handleRole = value => {
    if (roleFilter.some(role => role === value)) {
      setRoleFilter(roleFilter.filter(role => role !== value))
    } else {
      setRoleFilter([...roleFilter, value])
    }
  }

  // Return the modal output.
  // Loop through different tab and prepare data.
  // Display no member message if no member.
  // Mapping over each person in the team list.
  // Getting profile picture.
  return (
    <div className='business-wrapper'>
      <AppBar position="static" color="default">
        <div className="group-name">{groupName}</div>
        <CloseIcon className="close_popup" onClick={closeModal} />
      </AppBar>
      <>
        <div>
          <div className="content_team_wrapper">
            <div className="team_wrapper-left">
              <div className="content_heading">{groupTeamData.businessTeam.title}{`(${businessTeam.list?.length})`}</div>
              <div className="content_subheading">{groupTeamData.businessTeam.subTitle}</div>
            </div>
            <div className="team_wrapper_right join">
              {(!isMember && reducedData?.length > 0) && <JoinGroupButton gid={id} />}
            </div>
          </div>
          <div className="filter-raci">
            <div className='search-box'>
              <input type='search' placeholder='Search by name or keyword' value={searchValue} onChange={handleSearch} />
            </div>
            <div>
              <div className="role-filter-heading">Select to filter by role: </div>
              <div>{roles.map(role => <span onClick={() => handleRole(role)} className={roleFilter.some(filter => filter === role) ? 'selected' : 'default'} key={role}>{role}</span>)}
              </div>
            </div>
          </div>
        </div>
        <div><p className='results'>Results ({reducedData.length})</p></div>
        <div className="tabs_wrapper">
          {
            reducedData?.length === 0 && <div className="content_subheading">{groupTeamData.businessTeam.noMemberMessage}</div>
          }
          {reducedData?.length !== 0 && reducedData?.map((person, idx) => {
            const appUserPicture = person?.userPicture ? process.env.REACT_APP_TARGET_URL + '/' + person.userPicture : null
            return (
              person && (
                <ul className="tabs_section" key={idx}>
                  <li className="tabs_item">
                    <div className="team_details">
                      <div className="team_icon_left">
                        <Avatar>
                          <ProfileImage
                            alt={userAbbreviation(person.username)}
                            userPicture={appUserPicture}
                            profileImage={profilePictures[person.username]} />
                        </Avatar>
                      </div>
                      <div className="team_details_right">
                        <div className="user_name"><HighLightText content={(person.displayName !== 'false') ? person.displayName : person.username} quote={searchValue} /></div>
                        <div className="user_info"><HighLightText content={person.role} quote={searchValue} /></div>
                      </div>
                    </div>
                  </li>
                  <li className="tabs_item"><HighLightText content={person.userDescription} quote={searchValue} /></li>
                  <li className="tabs_item"><div className='user_emailwrapper'><HighLightText content={person.userEmail} quote={searchValue} /></div></li>
                  <li className="tabs_item right_align"><Checkbox checked={checkIfSelected(person.userEmail)} onChange={() => addOrRemoveEmail(person.userEmail)} /></li>
                </ul>
              ))
          })
          }
        </div>
      </>
      {windowLaunched && <p>Launching a new window to create your message</p>}
      <div className="btn_wrapper">
        <div className="left_btn">
          <Button variant='contained' disabled={selectedEmails.length === 0} onClick={() => handleSendMessage(mailString)}>Create Message</Button>
          <Button variant='outlined' onClick={handleSelectAndDeselectAll}>{checkIfAllSelected() ? 'Deselect All' : 'Select All'}</Button>
        </div>
        <div className="right_btn"><Button variant="contained" onClick={closeModal}>Close</Button></div>
      </div>
    </div>
  )
}
