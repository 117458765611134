import React, { useState, useEffect, useContext } from 'react'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../../../lib'
import Loader from '../../PageLoader'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Checkbox, Chip } from '@mui/material'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { getFormattedDate, getUsername } from './RevisionHelpers'
import RestoreLinkAndModal from './RestoreLinkAndModal'
import GenericErrorMessage from '../Assets/component/GenericErrorMessage'
import './revision-history.scss'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

const RevisionList = ({ setBreadCrumb, setIsHeaderLoading, setFeedbackMessage }) => {
  // Set check box check.
  const [checked, setChecked] = useState([])
  // Set is loading.
  const [isLoading, setIsLoading] = useState(true)
  // Set all revision result.
  const [allRevisions, setAllRevisions] = useState([])
  // Set currentRevision.
  const [currentRevision, setCurrentRevision] = useState({})
  // Set isRespOrAccountable.
  const [isRespOrAccountable, setIsRespOrAccountable] = useState(false)
  // Set errorMessage.
  const [errorMessage, setErrorMessage] = useState()
  // Get gid, nid from path parameter.
  const { gid, nid } = useParams()
  // Set url history.
  const navigate = useNavigate()
  // Set shouldReload.
  const [shouldReload, setShouldReload] = useState(true)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    if (!shouldReload) {
      return
    }
    setIsLoading(true)

    // Variables object. Make certain that the param string passed is really a
    // numeric string by converting from a string -> number -> string. With the
    // latest PHP and graphql updates id/nid and gid must ultimately be string
    // parameters for the graphql.
    let revisionHistoryVariables = {
      id: parseInt(nid).toString(),
      gid: parseInt(gid).toString()
    }
    // Stringify the variable object.
    revisionHistoryVariables = JSON.stringify(revisionHistoryVariables)

    // Prepare announcement query.
    const revisionHistoryQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.revisionListQuery}&variables=${revisionHistoryVariables}`
    queryContent(revisionHistoryQuery)
      .then(response => {
        // Assigning fetched data.
        setAllRevisions(response.data.allRevisions?.entities.filter(revision => !!revision).sort((a, b) => (b?.vid - a?.vid)))
        // Getting groups where user is responsible or accountable.
        const eligibleGroups = response.data.groupUserGroupsByUidRA.results.map(result => result.gid)
        // Setting isResponsibleOrAccountable if owning group found in eligibleGroups.
        setIsRespOrAccountable(eligibleGroups.includes(response.data.nodeQuery.entities[0]?.fieldOwningGroup?.entity?.entityId || 0))
        // Set accountable/responsible data based on entity id.
        const nodeQueryData = response?.data?.nodeQuery?.entities[0]
        // Setting the breadCrumbs.
        setBreadCrumb(nodeQueryData?.entityBreadCrumb)
        // Setting the current version.
        setCurrentRevision(response.data.nodeQuery?.entities[0])
        if (!response.data.allRevisions.count) {
          setErrorMessage(constants.errors.nullValue)
        }
        // Sending page data for tracking.
        setPageData({
          type: 'revision-history-listing',
          breadCrumbs: [...nodeQueryData?.entityBreadCrumb, { text: 'Revision-History-List' }],
          page_id: nid
        })
      })
      .catch(() => setErrorMessage(constants.errors.server))
      .finally(() => {
        setIsLoading(false)
        setIsHeaderLoading(false)
        setShouldReload(false)
      })
  }, [nid, gid, shouldReload])

  const checkChange = value => {
    // Set the checkbox empty.
    if (checked.indexOf(value) !== -1) {
      setChecked(checked.filter(check => check !== value))
    } else {
      // setChecked(previousCheck => ([...previousCheck, value]))
      setChecked(previousCheck => {
        const checkedValues = [...previousCheck, value]
        checkedValues.sort((a, b) => (a - b))
        return checkedValues
      })
    }
  }
  // Go to revision-history page.
  const CompareSelectedRevisions = () => {
    navigate(`/revision-history/${gid}/application/${nid}/compare/${checked[0]}/${checked[1]}`)
  }
  // Function to reload the data on restore modal submission.
  const reloadPage = () => {
    setShouldReload(true)
  }

  // Show loader when is loading is true.
  if (isLoading) {
    return <Loader />
  }
  return (
    <>
      {errorMessage
        ? <GenericErrorMessage errorMessage={errorMessage} />
        : <div className='revision_history_wrapper'>
          <div className='revision_history_table_wrapper'>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell size='small'>Revision</TableCell>
                    <TableCell align='center' size='small'>
                      Select two to compare
                    </TableCell>
                    <TableCell align='center' size='small'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRevisions.map(item => (
                    item && <TableRow key={item.vid}>
                      <TableCell size='small'>
                        <Link className='date' to={`/revision-history/${gid}/application/${nid}/view/${item?.vid}`}>{item.entityChanged ? getFormattedDate(item.entityChanged) : constants.filter.none}</Link> by {getUsername(item)}
                      </TableCell>
                      <TableCell align='center' size='small'>
                        <Checkbox
                          key={item?.vid}
                          onChange={() => checkChange(item?.vid)}
                          checked={checked.includes(item?.vid)}
                          disabled={!checked.includes(item?.vid) && checked.length > 1}
                        />
                      </TableCell>
                      <TableCell align='right' size='small'>
                        {currentRevision?.vid === item?.vid && <Chip
                          label='Current Version'
                          variant='outlined'
                          size='small'
                        />}
                        {currentRevision?.vid !== item?.vid && isRespOrAccountable && <RestoreLinkAndModal selectedVersion={item} currentVersion={currentRevision} postSuccessAction={reloadPage} nid={nid} setFeedbackMessage={setFeedbackMessage} />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className='compare_revision_button'>
            <Button variant='contained' color='primary' disabled={checked && checked.length !== 2} onClick={CompareSelectedRevisions}>
              Compare Selected Revisions
            </Button>
          </div>
        </div>
      }
    </>
  )
}
// Return the component for use.
export default RevisionList
