import React, { useContext, useEffect, useState } from 'react'
import FavoriteGroups from './FavoriteGroups'
import FavoriteApplications from './FavoriteApplications'
import '../Assets/sass/component/page-header.scss'
import './my-favorites.scss'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { UserContext } from '../../context/UserContext'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../../../lib'
import { deleteEntities } from './helpers'
import { useFavorites } from '../../../hooks'
import Loader from '../../PageLoader'
import { Alert } from '@mui/lab'
import starEmpty from '../../../images/green-star-empty.svg'
import starRedFilled from '../../../images/red-star-filled.svg'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'
import GenericErrorMessage from '../Assets/component/GenericErrorMessage'

const MyFavorites = () => {
  // Fetch the user context.
  const { userId, favorites, isFavoriteLoading, setIsFavoriteLoading } = useContext(UserContext)
  // Set the businessTeam Roles.
  const [allUserRoles, setAllUserRoles] = useState()
  // Set the groupData.
  const [groupData, setGroupData] = useState([])
  // Set the nodeData.
  const [nodeData, setNodeData] = useState([])
  // Set loading state.
  const [isLoading, setIsLoading] = useState(true)
  // Get the function that updates the timeStamps.
  const updateFavorite = useFavorites()[4]
  // Set feedback message.
  const [feedbackMessage, setFeedbackMessage] = useState()
  // Set isFavoriteLoaded.
  const [isFavoriteLoaded, setIsFavoriteLoaded] = useState(false)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)
  // Set errorOccured.
  const [errorOccured, setErrorOccured] = useState(false)

  useEffect(() => {
    // Check if ready for graphql call to pull data from drupal.
    if (!isFavoriteLoading) {
      setIsFavoriteLoaded(true)
      setIsFavoriteLoading(true)
    }
  }, [isFavoriteLoading])

  useEffect(() => {
    // Go for API call if latest favorite data already loaded.
    if (isFavoriteLoaded) {
      // Preparing the parameters for the query to get the group and node data.
      const uid = userId
      let gids = ''
      const gidsArr = ['']
      const nidsArr = ['']
      const linkedGids = ['']

      if (favorites?.group?.data && favorites?.node?.data) {
        // Prepare group ids.
        favorites.group.data.forEach(group => {
          if (gidsArr[0] === '') {
            gidsArr[0] = group.assetId.toString()
          } else {
            gidsArr.push(group.assetId.toString())
          }
        })
        // Prepare node ids.
        favorites.node.data.forEach(node => {
          if (nidsArr[0] === '') {
            nidsArr[0] = node.assetId.toString()
            linkedGids[0] = node.groupId.toString()
          } else {
            nidsArr.push(node.assetId.toString())
            linkedGids.push(node.groupId.toString())
          }
        })
        // Create comma separated string.
        if (gidsArr.length) {
          gids = gidsArr.join(',')
        }
      }

      // Combining all the prepared parameters.
      const groupDataVariables = JSON.stringify({ uid, gids, gidsArr, nidsArr, linkedGids })
      // Set the query url.
      const groupDataQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.myFavoriteQuery}&variables=${groupDataVariables}`
      // Firing the query to get group and node data.
      queryContent(groupDataQuery)
        .then(response => {
          // Preparing groupData from the data received from query.
          const groupsData = response.data.favoriteGroups.groups.filter(x => !!x).map(entity => {
            const groupFromFavorite = favorites.group.data.filter(group => parseInt(group?.assetId) === parseInt(entity?.gid))[0]
            return {
              ...entity,
              opened: groupFromFavorite.opened,
              createdAt: groupFromFavorite.createdAt,
              updatedAt: groupFromFavorite.updatedAt
            }
          })
          // Sending page data for tracking.
          setPageData({
            type: 'my-favorite',
            breadCrumbs: [{ text: 'Home', url: { path: '/' } }, { text: 'My Favorites' }]
          })

          // Preparing nodeData from the data received from query.
          const goToGroups = response.data.goToGroups.groups
          const nodesData = response.data.favoriteNodes.entities.filter(x => !!x).map(entity => {
            const nodeFromFavorite = favorites.node.data.filter(node => parseInt(node?.assetId) === parseInt(entity?.nid))[0]
            const groupFromGoToGroup = goToGroups.filter(x => !!x).filter(group => parseInt(group?.gid) === nodeFromFavorite?.groupId)[0]
            return ({
              ...entity,
              goToGroupUrl: groupFromGoToGroup?.url?.path || entity.owningGroupUrl?.entity?.url?.path || '/',
              gid: nodeFromFavorite.groupId,
              opened: nodeFromFavorite.opened,
              createdAt: nodeFromFavorite.createdAt,
              updatedAt: nodeFromFavorite.updatedAt
            })
          })
          setNodeData(nodesData)
          setGroupData(groupsData)
          setAllUserRoles(response.data.groupUserGroupsByUidRoleList?.results)

          // Delete entities if data not coming from drupal
          // Only if difference between count of groups coming from node backend and drupal backend.
          if (groupsData.length !== favorites.group.data.length) {
            deleteEntities(userId, favorites.group.data, groupsData, 'group')
          }

          // Only if difference between count of groups coming from node backend and drupal backend.
          if (nodesData.length !== favorites.node.data.length) {
            deleteEntities(userId, favorites.node.data, nodesData, 'node')
          }
          setIsLoading(false)
        })
        .catch(err => {
          setErrorOccured(true)
          console.error(err.message)
        })
    }
  }, [userId, favorites, isFavoriteLoaded])

  // Set feedbackMessage.
  useEffect(() => {
    if (feedbackMessage?.message) {
      const timer = setTimeout(() => setFeedbackMessage({}), constants.misc.favoritesLimit.feedbackMessageTimeout * 1000)
      return () => clearTimeout(timer)
    }
  }, [feedbackMessage])

  // Return the output.
  return (
    <div className="min_content_height bg-f7">
      <div className="page_header_wrapper">
        {feedbackMessage?.message && <div className="fab_details_alert">
          <Alert severity={feedbackMessage.type} iconMapping={{ success: <img src={starEmpty} alt="Favorite icon" />, error: <img src={starRedFilled} alt="Favorite icon" /> }}>
            {feedbackMessage?.message}  &nbsp;
            <Link to='/my-favorites'>My Favorites</Link>
          </Alert>
        </div>}
        <div className="breadcrumb_section_wrapper">
          <ul className="breadcrumb_section">
            <li className="breadcrumb_item"><Link to='/'>Home</Link><span className="breadcrumb_icon"><ChevronRightIcon /></span></li>
            <li className="breadcrumb_item">My Favorites</li>
          </ul>
        </div>
        <div className="page_header_inner">
          <div className="page_header_heading">My Favorites</div>
        </div>
      </div>
      {!errorOccured
        ? <div>
          {(!isLoading)
            ? <>
              <FavoriteApplications nodeData={nodeData} setNodeData={setNodeData} updateFavorite={updateFavorite} setFeedbackMessage={setFeedbackMessage} />
              <FavoriteGroups allUserRoles={allUserRoles} groupData={groupData} setGroupData={setGroupData} updateFavorite={updateFavorite} setFeedbackMessage={setFeedbackMessage} />
            </>
            : <Loader />}
        </div>
        : <GenericErrorMessage errorMessage={constants.errors.server} />}
    </div>
  )
}

// Exporting the component.
export default MyFavorites
