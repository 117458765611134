import React, { useContext, useEffect, useState } from 'react'
import './ContentType.scss'
import SelectFacet from '../../SearchFacets/SelectFacet'
import { useNavigate } from 'react-router-dom'
import ResultPreview from './Results/ResultPreview/ResultPreview'
import { KeyboardBackspace } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { constants } from '../../../lib/constants'
import ResultModal from './Results/ResultModal/ResultModal'
import { ContentSearchBar } from './ContentSearchBar/ContentSearchBar'
import itemImage from '../../../images/capaDef.png'
import SideNavButtonCAPAs from './SideNavigation/SideNavButton/SideNavButtonCAPAs'
import { obtainGroups } from './CTHelpers/GroupHelper'
import { UserContext } from '../../context/UserContext'
import { checkAccess, queryContent } from './CTHelpers/CheckAccess'
import { CTErrorPage, makeNoAccessRows } from './CTHelpers/CTErrorPage'
import Loader from '../../PageLoader'
import ContentManageButton from '../contentManageButton'
import EmbedShoppingCart from '../../ShoppingCart/embedShoppingCart'

// Export the CAPA details data.
export const CAPAs = ({ ids }) => {
  const CTName = 'CAPA'
  // Set all required states.
  // Set filter data.
  const [filter, setFilter] = useState({
    group: constants.filter.all,
    text: ''
  })
  // Set group data.
  const [groups, setGroups] = useState()
  // Set data to be displayed.
  const [dataToDisplay, setDataToDisplay] = useState()
  // Set all data.
  const [allData, setAllData] = useState()
  // Set if modal need to be shown.
  const [shouldShowModal, setShouldShowModal] = useState(false)
  // Set the selected item.
  const [selectedItem, setSelectedItem] = useState({})
  // Set loading state.
  const [isLoading, setIsLoading] = useState(true)
  // Set site preview data.
  const [sidePreviews, setSidePreviews] = useState()
  // Set if have availability error.
  const [notAvailable, setNotAvailable] = useState()
  // Set if have loading error.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set if have null value error.
  const [hasNullValue, setHasNullValue] = useState(false)
  // Set if has access or not.
  const [onlyNoAccess, setOnlyNoAccess] = useState(false)
  // Fetch the user context.
  const { userRoles, userName, otherDetails } = useContext(UserContext)
  const history = useNavigate()

  // Check node access when ids are available.
  useEffect(() => {
    if (!ids) {
      setHasNullValue(true)
      setIsLoading(false)
    } else {
      const uniqueIds = [...new Set(ids.split(','))]
      // Check for node access.
      const promises = uniqueIds.map(i => checkAccess(i))
      const show = []
      const hide = []
      Promise.all(promises).then(accessObjs => {
        accessObjs.forEach(obj => {
          // Show data if have access.
          if (obj.access) {
            show.push(obj.item)
          } else {
            // Hide data or generate error base on code received.
            if (obj.error === 403) {
              hide.push(obj.item)
            }
            if (obj.error === 404) {
              setHasLoadingError(true)
              setIsLoading(false)
            }
          }
        })
      }).then(() => {
        // Generate proper user information.
        const userInfo = userName || otherDetails.name
        // If user have access.
        if (show && show.length > 0) {
          // Build the payload for api.
          const capaVariables = JSON.stringify({
            ids: show
          })
          // Build the query URL string & call to fetch data.
          const capaQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.capaQuery}&variables=${capaVariables}`
          queryContent(capaQuery)
            .then(response => {
              // Set data or error base on response.
              if (!response?.data?.nodeQuery?.count) {
                setHasLoadingError(true)
              } else {
                const entities = response.data.nodeQuery.entities
                setAllData(entities)
                setDataToDisplay(entities)
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
          // Set different states based on retrieve data.
          const groupsData = obtainGroups(show)
          setGroups(groupsData)
          // Hide the necessary data.
          if (hide && hide.length > 0) {
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setNotAvailable(noAccessRows)
          }
        } else {
          if (!hide || hide.length === 0) {
            setHasLoadingError(true)
            setIsLoading(false)
          } else {
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setOnlyNoAccess(true)
            setNotAvailable(noAccessRows)
            setIsLoading(false)
          }
        }
      })
    }
  }, [ids, userName, otherDetails.name])

  // Filter the data based on allData and filter.
  useEffect(() => {
    // Filter for groups.
    switch (filter.group) {
      case constants.filter.all:
        setDataToDisplay(allData)
        break
      case constants.filter.none:
        setDataToDisplay(allData.filter(item => !item.group))
        break
      default:
        setDataToDisplay(allData.filter(item => item.group === filter.group))
        break
    }
    // Filter for text.
    if (filter.text.length > 0) {
      setDataToDisplay(data => (data.filter(item => JSON.stringify(item).toLowerCase().indexOf(filter.text.toLowerCase()) > -1)))
    }
  }, [allData, filter])

  // Filter all the types & data.
  const filterType1 = [
    constants.filter.all,
    constants.filter.none,
    ...(new Set(allData?.reduce((acc, entity) => {
      if (entity.group) {
        acc.push(entity.group)
      }
      return acc
    }, [])))
  ]

  const filterData = category => value => {
    setFilter(data => ({
      ...data,
      [category]: value
    }))
  }

  // Create pages for modal.
  const createPages = item => {
    return [
      {
        topics: [{
          title: 'Base Information',
          fields: [
            { title: 'Group', input: item?.groups.join(', ') },
            { title: 'Finding ID', input: item.findingID?.entity?.nid },
            { title: 'QI ID', input: item.qi?.map(({ entity }) => entity?.nid).join(' ') },
            { title: 'Group', input: item?.groupAssociation },
            { title: 'Source Reference Number', input: item.capaSourceReferenceNumber?.entity?.title },
            { title: 'Therapeutic Area', input: item.therapeuticArea?.entity?.title },
            { title: 'Original Due Date', input: item.capaOriginalDueDate?.value },
            { title: 'Approved By', input: item.capaApprovedBy?.entity?.title },
            { title: 'Action Type', input: item.capaActionType?.entity?.title },
            { title: 'Root Causes', input: item.capaRootCauses?.map(({ entity }) => entity?.title).join(', ') }
          ]
        }]
      },
      {
        topics: [
          {
            title: 'Extension Information',
            fields: [
              { title: 'Number of Extensions', input: item?.capaExtensions },
              { title: 'Extension Due Date Status', input: item?.capaExtDueDateStatus?.entity?.title }
            ]
          },
          {
            title: 'Interim Information',
            fields: [
              { title: 'Control Details', input: item.capaInterimControlDetail?.value != null ? <div dangerouslySetInnerHTML={{ __html: item.capaInterimControlDetail?.value }} /> : constants.filter.none },
              { title: 'Control Update Summary', input: item.capaInterimUpdateSummary?.value != null ? <div dangerouslySetInnerHTML={{ __html: item.capaInterimUpdateSummary?.value }} /> : constants.filter.none }
            ]
          },
          {
            title: 'Update Information',
            fields: [
              { title: 'Approval Date', input: item.capaUpdateApprovalDate?.value },
              { title: 'Approved By', input: item.capaApprovedBy?.entity?.title }
            ]
          }
        ]
      },
      {
        topics: [
          {
            title: 'Owning Information',
            fields: [
              { title: 'Business Unit', input: item.capaOwningBusinessUnit?.entity?.value },
              { title: 'Segment', input: item.capaOwningSegment?.entity?.value },
              { title: 'Site Region', input: item.capaOwningSiteRegion?.entity?.title }
            ]
          },
          {
            title: 'Summaries',
            fields: [
              { title: 'Cancellation', input: item.capaCancellationSummary?.value != null ? <div dangerouslySetInnerHTML={{ __html: item.capaCancellationSummary?.value }} /> : constants.filter.none },
              { title: 'Closure', input: item.capaClosureSummary?.value != null ? <div dangerouslySetInnerHTML={{ __html: item.capaClosureSummary?.value }} /> : constants.filter.none },
              { title: 'Execution', input: item.capaExecutionSummary?.value != null ? <div dangerouslySetInnerHTML={{ __html: item.capaExecutionSummary?.value }} /> : constants.filter.none }
            ]
          }
        ]
      }
    ]
  }

  // Create preview data for multiple capas.
  const createPreviews = data => {
    // Loop through all capa data.
    data.forEach(capa => {
      const currentGroups = []
      // Loop through the group.
      groups.forEach(group => {
        if (group.id === capa.nid) {
          currentGroups.push(group.group)
        }
      })
      capa.groups = currentGroups
    })
    // Return data for preview.
    // Components used:
    // - EmbedShoppingCart.
    // - ResultPreview.
    // - SideNavButtonCAPAs.
    // Display manage button based on user role.
    return data && data.map((item, i) =>
      <div key={i} className='result-row'>
        <EmbedShoppingCart item={item} image={itemImage} bundle="capas" />
        <ResultPreview
          key={i}
          title={item.title}
          nid={item.nid}
          naturalKey={item.naturalKey}
          group={item.groups}
          itemImage={itemImage}
          openModalCallback={() => {
            setSelectedItem(item)
            setShouldShowModal(true)
          }}
        />
        <div className='button-wrappers'>
          <SideNavButtonCAPAs
            findingID={item.findingID?.entity?.nid}
            QIs={item?.qi}
            setSidePreviews={setSidePreviews}
            CAPACRSAC={item?.CRSAC}
            CAPAESP={item?.ESP}
            CAPALOC={item?.LOC}
            CAPADL={item?.discoveryLocation}
            audit={item.findingID?.entity?.Audit?.entity}
          />
          {
            userRoles.administrator ||
              userRoles.contentAuthor ||
              userRoles.feedEngineer ||
              userRoles.groupAdmin
              ? <ContentManageButton nid={item.nid} />
              : <div />
          }
        </div>
      </div>
    )
  }

  // Display error message if null value received.
  if (hasNullValue && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.nullValue }} align='center' />
    // Return the error page.
    return (
      <CTErrorPage ct={`${CTName}s`} message={message} />
    )
  }

  // Display error message if don't have access.
  if (onlyNoAccess && !isLoading && notAvailable) {
    // Return the error page.
    return (
      <CTErrorPage ct={`${CTName}s`} message={notAvailable} />
    )
  }

  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.invalidContent }} align='center' />
    // Return the error page.
    return (
      <CTErrorPage ct={`${CTName}s`} message={message} />
    )
  }

  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Return entire data for display purpose.
  return !isLoading && allData && (
    <div className='content-wrapper'>
      <div className='header-wrapper'>
        <div className='back-button__clickable' onClick={() => { history(-1) }}>
          <KeyboardBackspace /> <Typography>Back</Typography>
        </div>
        <div className='content-title'>
          <Typography variant='h3'>
            CAPAs
          </Typography>
        </div>
      </div>
      <div className='search-bar-wrapper'>
        <ContentSearchBar
          setFilter={value => setFilter(data => ({
            ...data,
            text: value
          }))}
        />
      </div>
      <div className='result-wrapper'>
        <div className='filter-wrapper--left-column'>
          <SelectFacet
            onChange={value => {
              filterData('group')(value)
            }}
            selectOptions={filterType1}
            title='Group'
          />
        </div>
        <div className='table-wrapper'>
          {createPreviews(dataToDisplay)}
          {notAvailable}
        </div>
        <div className='sidebar-wrapper--right-column'>
          {sidePreviews}
        </div>
      </div>
      {Object.keys(selectedItem).length > 0 &&
        <ResultModal
          isOpen={shouldShowModal}
          onClose={() => setShouldShowModal(false)}
          nid={selectedItem.nid}
          naturalKey={selectedItem.naturalKey}
          title={selectedItem.title}
          pages={createPages(selectedItem)}
        />}
    </div>
  )
}
