// Create dummy element to get the actual size (width & height).
// Mainly used during various checkbox facet option creation.
function createDummyElement (text, className) {
  const element = document.createElement('div')
  const textNode = document.createTextNode(text)
  if (className) {
    element.classList.add(className)
  }
  element.appendChild(textNode)
  document.body.appendChild(element)
  return element
}
// Destroy the element once calculation completed.
function destroyElement (element) {
  element.parentNode.removeChild(element)
}
// Initiate cache.
const cache = {}
Object.defineProperty(exports, '__esModule', { value: true })
// Export the component with  width and height.
exports.default = function (text, options) {
  // create cache and return if exist in cache.
  const cacheKey = JSON.stringify({ text, options: options?.key })
  if (cache[cacheKey]) {
    return cache[cacheKey]
  }
  // Otherwise create dummy element.
  const element = createDummyElement(text, options?.className)
  const size = { width: element.offsetWidth, height: element.offsetHeight }
  destroyElement(element)
  cache[cacheKey] = size
  return size
}
