import React, { useEffect, useState, useRef, useContext } from 'react'
import RecordCard from './RecordCard'
import { Grid } from '@mui/material'
import MyRecordDetails from './MyRecordDetails'
import MyComments from '../Approvals/MyComments'
import Loader from '../../../PageLoader'
import Pagination from '../../../Pagination'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router-dom'
import { constants } from '../../../../lib'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

const Common = ({ setFeedbackMessage, setErrorMessage, urlPart, paramId, setShouldNotificationReload, isLoading, setIsLoading }) => {
  // Set result.
  const [results, setResults] = useState([])
  // Set url history.
  const history = useNavigate()
  // Set location.
  const location = useLocation()
  // Set url param.
  const url = location.search
  // Set query param.
  const queryStringObj = queryString.parse(url.replace('?', ''))
  // Set query string object with limit and offset if not exist.
  const newQueryStringObj = { ...queryStringObj, offset: queryStringObj?.offset || 0, limit: queryStringObj?.limit || constants.misc.defaultRecordCount }
  // Set params.
  const [params, setParams] = useState(newQueryStringObj)
  // Set result count.
  const [totalCount, setTotalCount] = useState(1)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)
  // Set is request page.
  const isReqPage = (urlPart === 'group-request-list')
  // Set is request page.
  const isApprovalOrReq = isReqPage ? 'requests' : 'approvals'
  const urlElements = useRef()
  // Re-initiate param based on url change
  useEffect(() => {
    setParams({ offset: 0, limit: constants.misc.defaultRecordCount })
  }, [paramId, urlPart])
  // Call specific query.
  useEffect(() => {
    // Set loading before submit.
    setIsLoading(true)
    if (urlElements.current?.urlPart !== urlPart || urlElements.current?.paramId !== paramId) {
      urlElements.current = { urlPart, paramId }
      return
    }
    // Generate the api endpoint.
    const apiUrl = `${process.env.REACT_APP_TARGET_URL}/api/${urlPart}/${paramId}`
    // Set the api url and initial values.
    const limit = params?.limit || constants.misc.defaultRecordCount
    const offset = params?.offset || 0
    // Call the api.
    fetch(`${apiUrl}?offset=${offset}&limit=${limit}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          // Send error response if API failed.
          setErrorMessage(res.errorMsg)
        } else {
          // Count off rows.
          const count = parseInt(res.response.count)
          // Assigning fetching data.
          const documents = res.response.data
          // Mapping the fetching data.
          const getData = documents.map(document => {
            return { sid: document.sid, owner: document.owner, requestedAt: document.requestedAt, groupData: document.groupData }
          })
          // Set total record count.
          setTotalCount(count)
          // Assigning fetched data.
          setResults(getData)
        }
        const isRequests = isApprovalOrReq === 'requests'
        if (paramId === 'all') {
          // Sending page data for tracking.
          setPageData({
            type: `inbox-${isApprovalOrReq}`,
            breadCrumbs: [{ text: 'Home', url: { path: '/' } }, { text: isRequests ? 'Requests' : 'Approvals' }]
          })
        } else {
          // Sending page data for tracking.
          setPageData({
            type: `inbox-${isRequests ? 'request' : 'approval'}`,
            breadCrumbs: [{ text: 'Home', url: { path: '/' } }, { text: isRequests ? 'Requests' : 'Approvals' }],
            page_id: paramId
          })
        }
      }).catch(err => {
        console.log(err)
        // set error message if API failed
        setErrorMessage(constants.errors.server)
      }).finally(() => {
        // Stop the loader.
        setIsLoading(false)
      })
  }, [paramId, urlPart, params])

  if (isLoading) {
    // Return the loader.
    return (<div className='inbox_details_wrapper'>
      <div className='inbox_details_item'></div><Loader />
    </div>)
  }

  // Show form if status pending or show the status.
  const approvalComment = item => {
    if (item.groupData.actionStatus === 'Denied' || item.groupData.actionStatus === 'Approved' || (isReqPage && item.groupData.actionStatus === 'Pending')) {
      return <RecordCard approvalData={item.groupData} isReqPage={isReqPage} />
    } else {
      return <MyComments sid={item.sid} setFeedbackMessage={setFeedbackMessage} setShouldNotificationReload={setShouldNotificationReload} />
    }
  }

  // Handle any search filter change.
  const handleChangeFor = category => value => {
    let resetOffset = false
    let newParams
    // Opt for reset offset if value changed
    if (category !== 'offset' && typeof params[category] !== 'undefined' && params[category] !== value) {
      resetOffset = true
    }
    // Remove parameter if blank else add/update.
    if (typeof params[category] !== 'undefined' && typeof value === 'string' && value.trim() === '') {
      newParams = params
      delete newParams[category]
    } else {
      newParams = { ...params, [category]: value }
    }
    // Reset of set if it is opt for or limit changed.
    if (category === 'limit' || (category !== 'offset' && (typeof params[category] === 'undefined' || resetOffset))) {
      newParams = { ...newParams, offset: 0 }
    }
    const redirectParam = newParams
    const returnURL = `/inbox/${isApprovalOrReq}?${queryString.stringify(redirectParam)}`
    setParams(newParams)
    history(returnURL)
  }

  return (
    <div className='inbox_details_wrapper'>
      {results && results.map((item, index) => (
        <div className='inbox_details_item' key={index}>
          <Grid container>
            <MyRecordDetails requestedBy={item.owner} requestedOn={item.requestedAt} groupData={item.groupData} isReqPage={isReqPage} />
          </Grid>
          <div className='mycomments_status'>{approvalComment(item)}</div>
        </div>
      ))}
      {params && paramId === 'all' && (
        <Pagination handleChangeFor={handleChangeFor} params={params} totalCount={totalCount} key={isApprovalOrReq} />
      )}
    </div>
  )
}

// Export the component for use.
export default Common
