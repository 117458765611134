import React from 'react'
import { Typography, Button } from '@mui/material'
import './ResultPreview.scss'
import { constants } from '../../../../../lib'
import defaultImage from '../../../../../images/green.jpg'

// Export the result preview.
export default function ResultPreview ({ title, nid, group, itemImage, openModalCallback }) {
  // Initialize the group display.
  let groupDisplay = ''
  // Generate group display if group exist.
  if (group) {
    if (group.length !== 0) {
      if (group.length === 1) {
        // Print the group.
        groupDisplay =
          <div className='title-row'>
            Group: {group[0]}
          </div>
      } else {
        // Print only 1st group and indicate more.
        groupDisplay =
          <div className='title-row'>
            Groups: {group[0]} ...
          </div>
      }
    } else {
      // Display N/A
      groupDisplay =
        <div className='title-row'>
          Group: {constants.filter.none}
        </div>
    }
  }

  // Return the result preview.
  return (
    <div className='result-item-wrapper'>
      <div className='button-wrapper'>
        <Button
          className='result-button'
          onClick={() => { openModalCallback() }}
        >
          <img className='result-image' src={itemImage || defaultImage} alt={title} />
        </Button>
      </div>
      <div className='field-wrapper'>
        <div className='title-row'>
          {title || constants.filter.none}
        </div>
        <div className='title-row'>
          {groupDisplay}
        </div>
        <div className='details-row'>
          <Typography variant='h6'>
            {nid}
          </Typography>
        </div>
      </div>
    </div>
  )
}
