import React from 'react'
import { Modal, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import ResultPreview from '../../Results/ResultPreview/ResultPreview'
import { useNavigate } from 'react-router-dom'
import dlImage from '../../../../../images/discoveryDef.png'
import locImage from '.././../../../../images/loc_icon.png'
import espImage from '../../../../../images/esp_icon.png'
import crsacImage from '../../../../../images/crsacDef.png'
// Export SideNavModal Data.
export default function SideNavModal ({ isOpen, onClose, contentType, data, contentTypeTitle }) {
  // Set history.
  const history = useNavigate()
  // Create preview data.
  const createPreviews = () => {
    // Set ctURL.
    let ctURL = ''
    if (contentType) {
      // Update ctURL value based on contentType.
      ctURL = contentType.toLowerCase()
    }
    // Set itemImage.
    let itemImage = ''
    // Update itemImage value based on contentType.
    switch (contentType) {
      case ('CRSAC'):
        itemImage = crsacImage
        break
      case ('ESP'):
        itemImage = espImage
        break
      case ('LOC'):
        itemImage = locImage
        break
      case ('DL'):
        itemImage = dlImage
        break
      default:
        itemImage = ''
        break
    }
    // Return preview data.
    // Component used:
    // - ResultPreview.
    return data && data.map((item, i) =>
      <div key={i}>
        <ResultPreview
          title={item.entity.title}
          nid={item.entity.nid}
          itemImage={itemImage}
          openModalCallback={() => {
            let url = `/content/${ctURL}?id=${item.entity?.nid}`
            if (item.entity?.url.path) {
              url = item.entity?.url.path
            }
            history(url)
            onClose()
          }}
        />
      </div>
    )
  }
  // Return SideNavModal data.
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className='result-modal'
    >
      <div className='result-modal-container__center-contents'>
        <div className='result-modal-contents'>
          <div className='result-modal-header'>
            <div className='title'>
              <Typography variant='h4'>
                {contentTypeTitle}
              </Typography>
              <Close className='modal-close-button' onClick={onClose} />
            </div>
          </div>
          <div className='result-modal--main-content'>
            {createPreviews()}
          </div>
        </div>
      </div>
    </Modal>
  )
}
