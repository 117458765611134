import React from 'react'
import './sideNavGroups.scss'
import { Button, Typography } from '@mui/material'
// Export SideNavGroups Data.
export default function SideNavGroups ({ contentType, openModalCallback, data }) {
  // Return SideNavGroups Data.
  return (
    <div className='content-type-item-wrapper'>
      <Typography variant='h6'>
        {contentType}
      </Typography>
      <Button
        className='redirect-button'
        onClick={() => { openModalCallback() }}
      >
        <Typography variant='h5'>{data.length}</Typography>
      </Button>
    </div>
  )
}
