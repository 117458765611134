import React, { useState } from 'react'
// Import necessary packages.
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { getFormattedDate, getUsername } from './RevisionHelpers'
import checkIconGreen from '../../../images/icon-checkmark.svg'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// Build the component.
const RestoreLinkAndModal = ({ selectedVersion, currentVersion, nid, setFeedbackMessage, postSuccessAction }) => {
  // Set modalOpen.
  const [modalOpen, setIsModalOpen] = useState(false)
  // Set isLoading.
  const [isInProgress, setIsInProgress] = useState(false)

  // Function to handle restore given version.
  const handleRestore = () => {
    // Set progress to disable buttons.
    setIsInProgress(true)
    // To track if logged out.
    let haveResponse = false
    // Call api to revert the revision.
    fetch(`${process.env.REACT_APP_TARGET_URL}/api/revert-node-revision`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        revision_id: `${selectedVersion.vid}`,
        nid
      })
    })
      .then(res => {
        haveResponse = true
        return res.json()
      })
      .then(res => {
        if (res.error) {
          throw new Error(res.errorMsg)
        }
        // Set the feedback message.
        setFeedbackMessage({ type: 'success', message: res.response.msg, icon: <img src={checkIconGreen} alt='Check Icon' /> })
        postSuccessAction()
      })
      .catch(error => {
        if (!haveResponse) {
          window.location.reload()
        }
        const errorMessage = error?.name === 'Error' ? error.message : 'Some Error Occurred'
        setFeedbackMessage({ type: 'error', message: errorMessage, icon: <ErrorOutlineIcon /> })
      })
      .finally(() => {
        setIsInProgress(false)
        handleClose()
      })
  }

  // Function to handle modal close.
  const handleClose = () => {
    setIsModalOpen(false)
    setIsInProgress(false)
  }

  // Return the output.
  return (
    <div>
      <span className='restore restore_ver' onClick={() => setIsModalOpen(true)}>
        Restore
      </span>
      {modalOpen && <Dialog
        open={modalOpen} onClose={handleClose} maxWidth={'xs'}
        aria-labelledby="revert-revision-modal" aria-describedby="revert-older-revision">
        <div className='restore_modal'>
          <DialogTitle>
            <Typography id="revert-modal-title">
              Are you sure you want to restore this application?
              <CloseIcon className="close-icon" onClick={handleClose} />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography className="modal-description top">
              {`${getFormattedDate(selectedVersion?.entityChanged)} by ${getUsername(selectedVersion)}`}
            </Typography>
            <Typography className="modal-description">
              Note: Restoring this application will replace the current version of the application:
            </Typography>
            <Typography className="modal-description">
              {`${getFormattedDate(currentVersion?.entityChanged)} by ${getUsername(currentVersion)}`}
            </Typography>
            <Typography className="modal-description">
              The selected version of the application will be duplicated and become the current version.
            </Typography>
            <div className='btn_restore'>
              <Button variant='outlined' disabled={isInProgress} onClick={handleClose}>Cancel</Button>
              <Button variant='contained' data-tracking='Restore' disabled={isInProgress} onClick={handleRestore}>{isInProgress ? 'Restoring...' : 'Restore'}</Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>}
    </div>
  )
}

// Export component for further use.
export default RestoreLinkAndModal
