import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import { constants } from '../../../../lib'
import { useNavigate } from 'react-router-dom'
import memberIconRed from '../../../../images/red-icon-people.svg'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

const DeleteApplication = ({ id, gid, manageThroughGroup, manageThroughOwningGroup, applicationName, setFeedbackMessage, crumbs }) => {
  // Set inProgress.
  const [inProgress, setInProgress] = useState(false)
  // Set object from useNavigate hook.
  const navigate = useNavigate()
  // Set hasError.
  const [hasError, setHasError] = useState(false)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    // Sending page data for tracking.
    setPageData({
      type: 'delete-application',
      breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: 'Delete Application' }],
      page_id: id
    })
  }, [])
  // Function to fire query for deleting selected application.
  const deleteApplication = () => {
    // Setting in progress as true.
    setInProgress(true)
    // Set API URL for notificationCount query.
    const query = `${process.env.REACT_APP_TARGET_URL}/api/delete-application`
    // To track if logged out.
    let haveResponse = false
    // Firing the query.
    fetch(query, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        nid: id,
        gid
      })
    })
      .then(response => {
        haveResponse = true
        return response.json()
      })
      .then(res => {
        if (!res.error) {
          // Set the success message in localStorage.
          localStorage.setItem('feedbackMessage', JSON.stringify({ type: 'error', section: 'manage-application', message: res.msg }))
          // Redirect to page given in response.
          navigate(res.response.redirectPath)
        } else {
          setFeedbackMessage({ icon: <img src={memberIconRed} alt='Member Icon' />, type: 'error', message: res.errorMsg })
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err)
        setHasError(true)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  return (
    <div>
      <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={inProgress} invisible={true}></Backdrop>
      <div className="manageapp_section_heading">Delete Application</div>
      {hasError && <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: constants.errors.server }} /></Alert>}
      <div>
        <p>Are you sure you'd like to delete {applicationName}.
          {manageThroughGroup && <> Deleting the application will remove this application from your group. </>}
          {manageThroughOwningGroup && <> Deleting the application will <strong>permanently remove</strong> this application from Voyager and ALL of the groups it is shared with.</>}
        </p>
      </div>
      {!hasError && <div className="btn-wrapper">
        {!inProgress
          ? <Button variant="contained" onClick={deleteApplication} >
            Delete Application
          </Button>
          : <Button variant="contained" disabled>
            Deleting...
          </Button>}
      </div>}
    </div >
  )
}

// Exporting the component.
export default DeleteApplication
