import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import './PageLoader.scss'

// Generate and export the loader component for use.
export default function Loader ({ type, wrapperClass }) {
  if (type === 'Linear') {
    // For Linear loader while page loading.
    return <LinearProgress />
  } else {
    // For circular loader while page loading.
    return (
      <div className={wrapperClass || 'content-wrapper loader'}>
        <CircularProgress />
      </div>
    )
  }
}
