import React, { useState } from 'react'
// Include necessary assets.
import '../sass/component/teamcard.scss'
import ProfileImage from '../../../ProfileImage'
// Utility function to get Username Abbreviation.
import { userAbbreviation } from '../../../ProfileImage/utility'
import { Avatar, Dialog } from '@mui/material'
import TeamModal from './TeamModal'
import JoinGroupButton from './JoinGroupButton'
import { constants } from '../../../../lib'

// Defining the TeamCard for the nextGen.
// This have list of users with image, name and role.
const TeamCard = ({ teamData, businessTeam, groupName, isMember, profileImageData, id }) => {
  // Keep track if the modal is opened or closed.
  const [isOpen, setIsOpen] = useState(false)
  // Get the team data object from config.
  const groupTeamData = constants.misc.groupTeamMemberData

  // Set the card content.
  const reducedData = businessTeam
  // Return the output.
  // Display no member message or see all link depending on member count.
  // Display team members as per limit specified.
  // Display Modal if isOpen Property is true.
  return (
    <div className="business_team_wrapper">
      <div className="business_heading">{teamData.title}</div>
      <div className="business_text">{teamData.subTitle}</div>
      <div className="team_wrapper">
        {
          reducedData?.count > 0 && reducedData?.list?.slice(0, teamData.limit).map((person, index) => {
            // Preparing profile picture.
            const appUserPicture = person?.userPicture ? process.env.REACT_APP_TARGET_URL + '/' + person.userPicture : null
            return (
              person && <div className="team_details" key={index}>
                <div className="team_icon_left">
                  <Avatar>
                    <ProfileImage
                      alt={userAbbreviation(person.username)}
                      userPicture={appUserPicture}
                      profileImage={profileImageData[person.username]} />
                  </Avatar>
                </div>
                <div className="team_details_right">
                  <div className="user_name"><a href={`mailto:${person.userEmail}`}>{(person.displayName !== 'false') ? person.displayName : person.username} </a></div>
                  <div className="user_info">{person.role}</div>
                </div>
              </div>
            )
          })
        }
        {(Object.keys(reducedData).length === 0 || reducedData?.count === 0)
          ? <div className="business_text">{groupTeamData.businessTeam.noMemberMessage}</div>
          : <div className="see_all" data-tracking='See All' onClick={() => setIsOpen(true)}>See all ({reducedData.count})</div>
        }
        {isOpen && <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <TeamModal businessTeam={businessTeam} groupName={groupName}
            profilePictures={profileImageData} closeModal={() => setIsOpen(false)}
            id={id} isMember={isMember} />
        </Dialog>
        }
      </div>
      {(!isMember && reducedData?.count > 0) && <div className="join btn btn_button">
        <JoinGroupButton gid={id} />
      </div>
      }
    </div>
  )
}

// Return the component for use.
export default TeamCard
