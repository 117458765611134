import { constants } from '../../../../lib'
export const memberSearchFilter = (businessTeam, roleFilter, searchValue, isRoleFirst) => {
  // If no roles are selected then by default all roles are selected.
  const roles = roleFilter.length ? roleFilter : constants.misc.groupTeamMemberData.businessTeamRoles
  // For the case when role filter is already active.
  if (isRoleFirst) {
    const roleFilteredData = businessTeam.filter(member => roles.some(filter => filter === member.role))
    if (searchValue.trim().length) {
      return textFilter(roleFilteredData, searchValue)
    } else {
      return roleFilteredData
    }
  } else {
    const textFilteredData = textFilter(businessTeam, searchValue)
    if (roleFilter.length) {
      return textFilteredData.filter(member => roles.some(filter => filter === member.role))
    } else {
      return textFilteredData
    }
  }
}

// Function to filter members based on the search keyword given.
const textFilter = (members, searchValue) => {
  return members.filter(member => {
    const keyword = searchValue.toLowerCase().trim()
    const username = member.displayName !== 'false' ? member.displayName : member.username
    // Preparing the dataString by joining all the data.
    const memberDataString = `${username}|||${member.userDescription}|||${member.userEmail}|||${member.role}`
    return memberDataString.toLowerCase().includes(keyword)
  })
}
