import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './discoveryLocation.scss'
import { Typography, Tabs, Tab } from '@mui/material'
import { withStyles } from '@mui/styles'
import queryString from 'query-string'
import StarRating from '../../../StarRating'
import itemImage from '../../../../images/discoveryDef.png'
import { Audits, Findings, QualityInvestigations, CAPAs } from '../index'
import { constants } from '../../../../lib'
import { UserContext } from '../../../context/UserContext'
import { CTErrorPage, makeNoAccessRows } from '../CTHelpers/CTErrorPage'
import { checkAccess, queryContent } from '../CTHelpers/CheckAccess'
import Loader from '../../../PageLoader'
import EmbedExternalUrl from '../../../EmbedExternalUrl'
import ContentManageButton from '../../contentManageButton'
import EmbedShoppingCart from '../../../ShoppingCart/embedShoppingCart'
import EntityUsageTabs from '../../entityUsageTabs'

// Add css for StyledTab.
const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    borderRight: '1px solid grey',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)
// Add css for LastTab.
const LastTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: '.7rem'
  }
}))(props => <Tab disableRipple {...props} />)
// Add css for contentTopStyle.
const contentTopStyle = {
  paddingTop: '20px'
}
// Add css for contentBottomStyle.
const contentBottomStyle = {
  paddingBottom: '20px'
}
// Export the DiscoveryLocation data.
export const DiscoveryLocation = ({ id }) => {
  // Set CTName value.
  const CTName = 'Discovery Location'
  // Set pageTabs data.
  const pageTabs = { home: 0, audits: 1, findings: 2, qis: 3, capas: 4, associations: 5 }
  // Set data.
  const [data, setData] = useState()
  // Set reference entity usage/association data.
  const [usageData, setUsageData] = useState()
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set selectedTab.
  const [selectedTab, setSelectedTab] = React.useState(0)
  // Set if has access or not.
  const [onlyNoAccess, setOnlyNoAccess] = useState(false)
  // Set if have null value error.
  const [hasNullValue, setHasNullValue] = useState(false)
  // Set if have availability error.
  const [notAvailable, setNotAvailable] = useState()
  // Fetch userRoles from UserContext.
  const { userRoles, userName, otherDetails } = useContext(UserContext)
  // Set location.
  const location = useLocation()
  // Set history.
  const history = useNavigate()
  // Get the hash for tab.
  const parsedHash = Object.keys(queryString.parse(location.hash))
  // Set initialTab value.
  let initialTab = 0
  if (parsedHash.length && typeof (pageTabs[parsedHash[0]]) !== 'undefined') {
    // Update initialTab value.
    initialTab = pageTabs[parsedHash[0]]
  }
  // Function for change operation.
  const handleChange = (event, newValue) => {
    if (initialTab !== newValue) {
      initialTab = newValue
      location.hash = Object.keys(pageTabs).find(key => pageTabs[key] === newValue)
      history(location)
    }
  }
  // Set selected tab based on hash.
  useEffect(() => {
    setSelectedTab(initialTab)
  }, [initialTab])
  // Check node access when ids are available.
  useEffect(() => {
    if (!id) {
      setHasNullValue(true)
      setIsLoading(false)
    } else {
      const promise = checkAccess(id)
      const show = []
      const hide = []
      promise.then(obj => {
        // Show data if have access.
        if (obj.access) {
          show.push(obj.item)
        } else {
          // Hide data or generate error base on code received.
          if (obj.error === 403) {
            hide.push(obj.item)
          }
          if (obj.error === 404) {
            setHasLoadingError(true)
            setIsLoading(false)
          }
        }
      }).then(() => {
        // If user have access.
        if (show && show.length > 0) {
          // Build the payload for api.
          const discLocVariable = JSON.stringify({
            id: show[0]
          })
          // Call graphql for discLocQuery.
          const discLocQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.discLocQuery}&variables=${discLocVariable}`
          // Query Call
          queryContent(discLocQuery)
            .then(response => {
              if (!response?.data) {
                setHasLoadingError(true)
              } else {
                // Set data based on nodeQuery.
                setData(response.data)
                // Set usage/association data based on response.
                setUsageData(response.data.entityUsageContent.results[0])
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
        } else {
          if (!hide || hide.length === 0) {
            setHasLoadingError(true)
            setIsLoading(false)
          } else {
            // Generate proper user information.
            const userInfo = userName || otherDetails.name
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setOnlyNoAccess(true)
            setNotAvailable(noAccessRows)
            setIsLoading(false)
          }
        }
      })
    }
  }, [id, userName, otherDetails.name])

  // Display error message if null value received.
  if (hasNullValue && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.nullValue }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }
  // Display error message if don't have access.
  if (onlyNoAccess && !isLoading && notAvailable) {
    return (
      <CTErrorPage ct={`${CTName}`} message={notAvailable} />
    )
  }
  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.invalidContent }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }
  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }
  // Return entire data for display purpose.
  // Tabs used:
  // - Home.
  // - Audits.
  // - Findings.
  // - QualityInvestigations.
  // - CAPAs.
  // - Associations.
  // Display manage button based on user role.
  return !isLoading && data && (
    <div className='discovery-location-wrapper tab-content-wrapper'>
      <div className='discovery-location-company-name'>
        <img className='content-image' src={itemImage} alt={data?.nodeById.entityLabel || 'DL'} />
        <div display='flex' flex-direction='column'>
          <div className='discovery-location-title'>
            {data?.nodeById.entityLabel}
          </div>
          {data?.nodeById.starRating !== null && (<div className='discovery-location-star-rating'>
            <StarRating rating={data?.nodeById.starRating / 2} />
            <div className='star-rating-text'>
              {data?.nodeById.starRating / 2}/5
            </div>
          </div>)}
        </div>
        {
          userRoles.administrator ||
            userRoles.contentAuthor ||
            userRoles.feedEngineer ||
            userRoles.groupAdmin
            ? <ContentManageButton nid={id} />
            : <div />
        }
        <EmbedShoppingCart item={data.nodeById} image={itemImage} bundle="discovery_location" />
      </div>
      <>
        <Tabs variant='fullWidth' value={selectedTab} onChange={handleChange} indicatorColor="primary">
          <StyledTab label="Home" />
          <StyledTab label="Audits" />
          <StyledTab label="Findings" />
          <StyledTab label="Quality Investigations" />
          <StyledTab label="CAPAs" />
          <LastTab label="Associations" />
        </Tabs>
        {selectedTab === 0 &&
          <div className='content-home-wrapper'>
            <div className="content-home-info--full-column">
              <div className='content-home-info__space-apart' style={contentTopStyle}>
                <Typography className="body-embed-url" color='primary'>
                  {data.nodeById.embedExternalUrl?.uri != null ? <EmbedExternalUrl url={data.nodeById.embedExternalUrl.uri} /> : ''}
                  {data.nodeById.body?.value != null ? <div dangerouslySetInnerHTML={{ __html: data.nodeById.body?.value }} /> : ''}
                </Typography>
                <Typography variant='h5' color="primary">
                  <strong>Contacts</strong>
                </Typography>
                <Typography>
                  <strong>Business Lead:</strong>
                </Typography>
                <Typography color="primary">
                  {data.nodeById.businessLead?.entity.title}
                </Typography>
                <Typography>
                  <strong>Quality Lead:</strong>
                </Typography>
                <Typography color="primary">
                  {data.nodeById.qualityLead?.entity.title}
                </Typography>
              </div>
              <div className='content-home-info__space-apart'>
                <Typography variant='h5' color="primary">
                  <strong>Location Information</strong>
                </Typography>
                <Typography>
                  <strong>Address 1:</strong>
                  <Typography color="primary">
                    {data?.nodeById.address1}</Typography>
                </Typography>
                <Typography>
                  <strong>Address 2:</strong> <Typography color="primary">{data?.nodeById.address2}</Typography>
                </Typography>
                <Typography>
                  <strong>City:</strong> <Typography color="primary">{data.nodeById.city?.entity.title}</Typography>
                </Typography>
                <Typography>
                  <strong>State:</strong> <Typography color="primary">{data.nodeById.state?.entity.title}</Typography>
                </Typography>
                <Typography>
                  <strong>Country:</strong> <Typography color="primary">{data.nodeById.country?.map(({ entity }) => entity.title).join(', ')}</Typography>
                </Typography>
                <Typography>
                  <strong>Country Address:</strong> <Typography color="primary">{data.nodeById.countryAddress?.entity.title}</Typography>
                </Typography>
                <Typography>
                  <strong>Latitude:</strong> <Typography color="primary">{data?.nodeById.latitude}</Typography>
                </Typography>
                <Typography>
                  <strong>Longitude:</strong> <Typography color="primary">{data?.nodeById.longitude}</Typography>
                </Typography>
                <Typography>
                  <strong>Region:</strong> <Typography color="primary">{data.nodeById.region?.map(({ entity }) => entity?.title).join(', ')}</Typography>
                </Typography>
              </div>
              <div className='content-home-info__space-apart' style={contentBottomStyle}>
                <Typography variant='h5' color="primary">
                  <strong>Additional Information</strong>
                </Typography>
                <Typography>
                  <strong>Star Rating Model:</strong> <Typography color="primary">{data.nodeById.starRatingModel?.entity.title}</Typography>
                </Typography>
                <Typography>
                  <strong>Natural Key:</strong> <Typography color="primary">{data?.nodeById.naturalKey}</Typography>
                </Typography>
                <Typography>
                  <strong>Therapeutic Area:</strong> <Typography color="primary">{data.nodeById.therapeuticArea?.entity.title}</Typography>
                </Typography>
                <Typography>
                  <strong>Functional Area:</strong> <Typography color="primary">{data.nodeById.functionalArea?.entity.title}</Typography>
                </Typography>
                <Typography>
                  <strong>Sector:</strong> <Typography color="primary">{data.nodeById?.sector?.map(({ entity }) => entity.title).join(', ')}</Typography>
                </Typography>
              </div>
            </div>
          </div>
        }
        {selectedTab === 1 &&
          <Audits ids={data.Audits.entities?.filter(n => n).map(({ nid }) => nid).join(',')} />
        }
        {selectedTab === 2 &&
          <Findings ids={data.Audits?.entities?.filter(n => n).map(props => props.Findings?.entities?.filter(n => n).map(({ nid }) => nid).join(',')).join(',')} />
        }
        {selectedTab === 3 &&
          <QualityInvestigations ids={data.QualityInvestigations.entities?.filter(n => n).map(({ nid }) => nid).join(',')} />
        }
        {selectedTab === 4 &&
          <CAPAs ids={data.CAPAs.entities?.filter(n => n).map(({ nid }) => nid).join(',')} />
        }
        {selectedTab === 5 &&
          <EntityUsageTabs groupUsage={data.nodeById.groups} contentUsage={usageData} />
        }
      </>
    </div>
  )
}
