import React, { useContext, useEffect } from 'react'
import './NotFound.scss'
import { EventTrackingContext } from '../../contexts/EventTrackingContext'

// Generate the not found page.
const NotFound = () => {
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    setPageData({
      type: '404'
    })
  }, [])

  return (
    <div className='not-found--wrapper min_content_height'>
      <div>Sorry, the page you are looking for could not be found.</div>
    </div>
  )
}

// Return the component for use.
export default NotFound
