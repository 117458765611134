import { constants } from '../../lib'
import capaImage from '../../images/capaDef.png'
import auditsImage from '../../images/auditsDef.png'
import crsacImage from '../../images/crsacDef.png'
import discoveryImage from '../../images/discoveryDef.png'
import findingImage from '../../images/findingDef.png'
import processImage from '../../images/processDef.png'
import qiImage from '../../images/QIDef.png'
import articleImage from '../../images/article_icon.png'
import groupLocationImage from '../../images/location.png'
import groupBusinessProcessImage from '../../images/business_process.jpg'
import groupProductTeamImage from '../../images/product_team.jpg'
import groupFunctionalImage from '../../images/functional.jpg'
// Export cardImageFor component.
export const cardImageFor = contentType => {
  switch (contentType) {
    case constants.drupalTypename.groupBusinessProcess:
      return groupBusinessProcessImage
    case constants.drupalTypename.groupFunctional:
      return groupFunctionalImage
    case constants.drupalTypename.groupLocation:
      return groupLocationImage
    case constants.drupalTypename.groupProductTeam:
      return groupProductTeamImage
    case constants.drupalTypename.process:
      return processImage
    case constants.drupalTypename.loc:
      return crsacImage
    case constants.drupalTypename.qi:
      return qiImage
    case constants.drupalTypename.esp:
      return crsacImage
    case constants.drupalTypename.crsac:
      return crsacImage
    case constants.drupalTypename.findings:
      return findingImage
    case constants.drupalTypename.dl:
      return discoveryImage
    case constants.drupalTypename.capas:
      return capaImage
    case constants.drupalTypename.audits:
      return auditsImage
    case constants.drupalTypename.article:
      return articleImage
    default:
      return ''
  }
}
// Export cardRedirectFor component.
export const cardRedirectFor = (contentType, id, url) => {
  if (url) {
    return url
  } else {
    switch (contentType) {
      case constants.drupalTypename.groupBusinessProcess:
      case constants.drupalTypename.groupFunctional:
      case constants.drupalTypename.groupLocation:
      case constants.drupalTypename.groupProductTeam:
        // URL for group content.
        return `/group/${id}`
      case constants.drupalTypename.process:
        // URL for process content.
        return `/content/process?id=${id}`
      case constants.drupalTypename.loc:
        // URL for LOC content.
        return `/content/loc?id=${id}`
      case constants.drupalTypename.qi:
        // URL for QI content.
        return `/content/qis?id=${id}`
      case constants.drupalTypename.esp:
        // URL for ESP content.
        return `/content/esp?id=${id}`
      case constants.drupalTypename.crsac:
        // URL for CRSAC content.
        return `/content/crsac?id=${id}`
      case constants.drupalTypename.findings:
        // URL for Findings content.
        return `/content/findings?id=${id}`
      case constants.drupalTypename.dl:
        // URL for DL content.
        return `/content/dl?id=${id}`
      case constants.drupalTypename.capas:
        // URL for CAPAS content.
        return `/content/capas?id=${id}`
      case constants.drupalTypename.audits:
        // URL for audits content.
        return `/content/audits?id=${id}`
      case constants.drupalTypename.article:
        // URL for article content.
        return `/content/article?id=${id}`
      default:
        return ''
    }
  }
}
