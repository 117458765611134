import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { constants } from '../../lib'

const ShoppingCartError = ({ setValue, remainingItemsCount, setRemainingItemsCount }) => {
  // Set the error state if has any.
  const [hasError, setHasError] = useState(true)

  // Handle the error dismissal.
  const dismissError = () => {
    setHasError(false)
    setValue(false)
    if (remainingItemsCount > 0) {
      setRemainingItemsCount(0)
    }
  }
  // Set singular or plural item.
  const itemOrItems = remainingItemsCount > 1 ? 'items' : 'item'

  // Return the dialog with cart error.
  // Along with remaining count which not added.
  return (
    <Dialog
      open={hasError}
      onClose={dismissError}
      aria-labelledby="Shopping Cart Error"
      aria-describedby="Max shopping cart capacity reached"
    >
      <DialogTitle id="alert-dialog-title">Reached maximum cart capacity of {constants.misc.maxCartItem}</DialogTitle>
      <DialogContent>
        <DialogContentText>You have reached maximum capacity of your cart - please proceed to check out to remove or process these records.</DialogContentText>
        {remainingItemsCount > 0 ? <DialogContentText>Note - {remainingItemsCount} {itemOrItems} not added to your cart from displayed list.</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={dismissError} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
// Export the shopping cart error.
export default ShoppingCartError
