import { constants } from '../../lib'
import { convertTimeToDate } from './SearchUtil'
import { facetNames, miscConfig, constructValue } from './SearchConfig'

export default function GenerateSearchPayloadVariable (queryStringObj, otherDetails, currentEntityType, renderEntityType, setIsLoading) {
  // Set render entity type
  const entityTypeToRender = renderEntityType || queryStringObj?.entityType || 'all'
  // Include the entity type in query string.
  queryStringObj = { ...queryStringObj, entityType: entityTypeToRender }
  // Set original entity type as current.
  const originalEntityType = currentEntityType || queryStringObj?.entityType || 'all'
  const params = queryStringObj
  const filters = {}
  let filterConditions = {}
  // The search keyword.
  const searchForKeyword = params?.keyword ? encodeURIComponent(params.keyword) : ''
  // Fetch the actual field name depending on entity type.
  const getActualFieldName = (fieldName, typeOfEntity) => {
    let actualFieldName
    // Generate actual field name for different fields and different entity types.
    if (typeOfEntity === 'content' || typeOfEntity === 'group') {
      switch (fieldName) {
        case 'title':
        case 'created':
        case 'changed':
          actualFieldName = typeOfEntity + '__' + fieldName
          // Node title have different field id hence handle that separately.
          if (fieldName === 'title' && typeOfEntity === 'content') {
            actualFieldName = typeOfEntity + '__' + typeOfEntity + '_' + fieldName
          }
          break
        case 'relevance':
          actualFieldName = 'search_api_' + fieldName
          break
        default:
          actualFieldName = ''
          break
      }
    }
    return actualFieldName
  }
  // Generate sort parameter for query.
  const setSortQueryParam = typeOfEntity => {
    // Set sort by & order.
    const sortParts = params?.sort ? params.sort.split(miscConfig.multiValueSeparator) : []
    // Set the default field sort depending on search keyword existence.
    const defaultSortField = searchForKeyword ? miscConfig.sorting[0].default2 : miscConfig.sorting[0].default
    // If not part of url then use default one.
    let fieldName = sortParts[0] || defaultSortField
    const sortOrder = sortParts[1] || miscConfig.sorting[1].default
    const sortArrObject = []
    // Set default field if invalid field name supplied.
    if (!Object.keys(miscConfig.sorting[0].options).includes(fieldName)) {
      fieldName = defaultSortField
    }
    // Get the actual field name.
    const actualFieldName = getActualFieldName(fieldName, typeOfEntity)
    if (actualFieldName) {
      sortArrObject.push({ field: actualFieldName, value: sortOrder })
      if (actualFieldName === 'search_api_relevance') {
        // Fetch secondary sort criteria in case of relevance.
        const secondarySortField = miscConfig.sorting[0].default2 === 'relevance' ? miscConfig.sorting[0].default : miscConfig.sorting[0].default2
        if (secondarySortField !== 'relevance') {
          // Add secondary sort criteria with relevance.
          const defaultFieldName = getActualFieldName(secondarySortField, typeOfEntity)
          sortArrObject.push({ field: defaultFieldName, value: sortOrder })
        }
      }
    } else {
      // For all, sort with relevance only, as there are mixed entities.
      sortArrObject.push({ field: 'search_api_relevance', value: 'desc' })
    }
    // Return the sorting parameters to sort the result set.
    return sortArrObject
  }
  // Get the entity type value, default all.
  let baseEntityType = params?.entityType || 'all'
  if (typeof facetNames[baseEntityType] !== 'undefined') {
    let customParamValue
    // Filter out based on valid facet keys.
    filterConditions = Object.entries(facetNames[baseEntityType]).filter(([key]) => {
      if (typeof params[key] === 'undefined' && typeof facetNames[baseEntityType][key].combine !== 'undefined') {
        customParamValue = ''
        // If combine exist for facet key.
        const paramKeys = Object.keys(params)
        const combineKeys = facetNames[baseEntityType][key].combine
        const paramValues = []
        // Prepare and set the combine field value based om search config.
        // Check if all combine field value exist in param.
        if (!combineKeys.some(val => !paramKeys.includes(val))) {
          for (const combineKey of combineKeys) {
            paramValues.push(params[combineKey].split(miscConfig.multiValueSeparator))
          }
          // Generate the values.
          customParamValue = paramValues.reduce((a, b) => a.flatMap(x => b.map(y => x ? x + '_' + y : y)), ['']).join(miscConfig.multiValueSeparator)
        }
        return !!customParamValue
      } else if (!otherDetails.bypassAccess && typeof params[key] === 'undefined' && typeof facetNames[baseEntityType][key].generateValue !== 'undefined' && facetNames[baseEntityType][key].generateValue === true) {
        customParamValue = ''
        // If generateValue exist for facet key.
        // If relateWith key exist.
        if (typeof constructValue[key].relateWith !== 'undefined') {
          // If have user key inside which will give user value from userContext.
          if (typeof constructValue[key].relateWith.user !== 'undefined') {
            customParamValue = otherDetails[constructValue[key].relateWith.user]
          }
          // Accumulate all keys except user.
          const allRelateWithKeys = Object.keys(constructValue[key].relateWith).filter(item => item !== 'user')
          // Generate the value string from supplied array.
          const generateValString = objKey => {
            // Split the param value, as multiple values can exist.
            const allValArr = params[objKey].split(miscConfig.multiValueSeparator)
            return allValArr.map(item => {
              let currentItem = item
              if (typeof constructValue[key].relateWith[objKey].splitWith !== 'undefined') {
                // Split the value if splitWith key exist
                currentItem = item.split(constructValue[key].relateWith[objKey].splitWith)
                if (typeof constructValue[key].relateWith[objKey].index !== 'undefined' && typeof currentItem[constructValue[key].relateWith[objKey].index] !== 'undefined') {
                  // Take the index as specified.
                  currentItem = currentItem[constructValue[key].relateWith[objKey].index]
                } else {
                  // Else take the 0th index
                  currentItem = currentItem[0]
                }
              }
              // Return the constructed value.
              return customParamValue + '__' + currentItem
            }).join(miscConfig.multiValueSeparator)
          }
          // Loop through the array.
          for (const objKey of allRelateWithKeys) {
            // Check if same key exist in facet list, param and param have value.
            if (typeof facetNames[baseEntityType][objKey] !== 'undefined' && typeof params[objKey] !== 'undefined' && params[objKey]) {
              // If have user value.
              if (customParamValue) {
                // Construct/generate the value.
                customParamValue = generateValString(objKey)
              } else {
                // Set the param value as nothing to concatenate.
                customParamValue = params[objKey]
              }
            }
          }
          // If some default value need to pass then concat that.
          if (typeof constructValue[key].defaultAccessVal !== 'undefined') {
            if (customParamValue) {
              customParamValue += miscConfig.multiValueSeparator + constructValue[key].defaultAccessVal
            } else {
              customParamValue = constructValue[key].defaultAccessVal
            }
          }
        }
        return !!customParamValue
      } else if (typeof params[key] !== 'undefined' && params[key] !== null && params[key] !== '') {
        return true
      } else {
        return false
      }
    }).map(([key]) => {
      // Set the filter object for facet search.
      return {
        name: key,
        operator: facetNames[baseEntityType][key].type === 'checkboxes' ? 'eq' : 'notEq',
        value: params[key] ? params[key] : customParamValue
      }
    })
  }

  // Initialize the query parameter.
  // GraphQL is expecting an integer instead of integer in a string.
  if (typeof params.offset === 'string') {
    params.offset = parseInt(params.offset)
  }
  let variables = {
    offset: params.offset || 0,
    limit: params.limit || constants.misc.defaultRecordCount,
    key: searchForKeyword,
    filters,
    sort: []
  }

  // Set facet specific filter for each different entities.
  switch (params.entityType) {
    // For all content types.
    case 'process':
    case 'local_operating_company':
    case 'quality_investigations':
    case 'clinical_research_site_activity':
    case 'external_service_provider':
    case 'findings':
    case 'audits':
    case 'discovery_location':
    case 'capas':
    case 'article':
      baseEntityType = 'content'
      variables.filters = {
        conjunction: 'AND',
        groups: [
          {
            conjunction: 'AND',
            conditions: [
              { operator: '=', name: 'content__content_type', value: params.entityType },
              { operator: '<>', name: 'content__field_life_cycle__entity__name', value: constants.misc.retiredLifeCycle },
              { operator: '=', name: 'content__field_is_application', value: 'yes' }
            ]
          }
        ]
      }
      break
    // For group.
    case 'group':
      variables.filters = {
        conjunction: 'AND',
        groups: [
          {
            conjunction: 'AND',
            conditions: [
              { operator: '>', name: 'group__id', value: '0' },
              { operator: '<>', name: 'group__field_life_cycle__entity__title', value: constants.misc.retiredLifeCycle },
              { operator: '<>', name: 'group__field_is_live', value: '0' }
            ]
          }
        ]
      }
      break
    // For any content.
    case 'content':
      variables.filters = {
        conjunction: 'AND',
        groups: [
          {
            conjunction: 'OR',
            conditions: [
              { operator: '=', name: 'content__content_type', value: 'audits' },
              { operator: '=', name: 'content__content_type', value: 'quality_investigations' },
              { operator: '=', name: 'content__content_type', value: 'capas' },
              { operator: '=', name: 'content__content_type', value: 'findings' },
              { operator: '=', name: 'content__content_type', value: 'clinical_research_site_activity' },
              { operator: '=', name: 'content__content_type', value: 'external_service_provider' },
              { operator: '=', name: 'content__content_type', value: 'local_operating_company' },
              { operator: '=', name: 'content__content_type', value: 'discovery_location' },
              { operator: '=', name: 'content__content_type', value: 'article' },
              { operator: '=', name: 'content__content_type', value: 'process' }
            ]
          },
          {
            conjunction: 'AND',
            conditions: [
              { operator: '<>', name: 'content__field_life_cycle__entity__name', value: constants.misc.retiredLifeCycle }
            ]
          }
        ]
      }
      break
    // For all i.e. any content or group.
    case 'all':
    default:
      // Need to set it for default.
      variables.filters = {
        conjunction: 'AND',
        groups: [
          {
            conjunction: 'OR',
            conditions: [
              { operator: '=', name: 'content__content_type', value: 'audits' },
              { operator: '=', name: 'content__content_type', value: 'quality_investigations' },
              { operator: '=', name: 'content__content_type', value: 'capas' },
              { operator: '=', name: 'content__content_type', value: 'findings' },
              { operator: '=', name: 'content__content_type', value: 'clinical_research_site_activity' },
              { operator: '=', name: 'content__content_type', value: 'external_service_provider' },
              { operator: '=', name: 'content__content_type', value: 'local_operating_company' },
              { operator: '=', name: 'content__content_type', value: 'discovery_location' },
              { operator: '=', name: 'content__content_type', value: 'process' },
              { operator: '=', name: 'content__content_type', value: 'article' },
              { operator: '>', name: 'group__id', value: '0' }
            ]
          },
          {
            conjunction: 'AND',
            conditions: [
              { operator: '<>', name: 'group__field_life_cycle__entity__title', value: constants.misc.retiredLifeCycle },
              { operator: '<>', name: 'group__field_is_live', value: '0' },
              { operator: '<>', name: 'content__field_life_cycle__entity__name', value: constants.misc.retiredLifeCycle }
            ]
          }
        ]
      }
      break
  }
  // Merge the condition with existing condition.
  if (filterConditions.length > 0) {
    let groupCondition
    // Generate filter condition for multiple values.
    for (const filterCondition of filterConditions) {
      const values = filterCondition.value.split(miscConfig.multiValueSeparator)
      const fieldCondition = []
      let op = ''
      let indx = 0
      for (const value of values) {
        if (value !== null && value !== '') {
          let queryVal = encodeURIComponent(value)
          if (filterCondition.operator === 'eq') {
            // For checkbox facet component.
            op = '='
          } else {
            // For date facet component.
            queryVal = encodeURIComponent(convertTimeToDate(value))
            if (indx === 0 && value !== '' && value !== null) {
              // For from date.
              op = '>='
            }
            if (indx === 1 && value !== '' && value !== null) {
              // For to date.
              op = '<='
            }
          }
          // Combine all conditions.
          fieldCondition.push({
            name: filterCondition.name,
            operator: op,
            value: queryVal
          })
        }
        indx++
      }
      // Append to overall condition for conjunction and conditions.
      groupCondition = {
        conjunction: filterCondition.operator === 'eq' ? 'OR' : 'AND',
        conditions: fieldCondition
      }
      variables.filters.groups.push(groupCondition)
    }
  }
  // Generate the sort param.
  variables.sort = setSortQueryParam(baseEntityType)
  // Start the loading indication.
  setIsLoading(true)
  // if have multiple entity types to render i.e. content, group etc.
  if (entityTypeToRender !== originalEntityType) {
    variables = { ...variables, offset: 0, limit: miscConfig.multipleEntitiesRecordLimit }
  }

  return variables
}
