import React, { useState } from 'react'
import { Button, Menu, MenuItem, ListItemText } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { withStyles } from '@mui/styles'
import GroupMemberAddModal from './GroupMemberAddModal'
import { Link } from 'react-router-dom'
import ModalForms from '../Assets/component/ModalForms'
import { constants } from '../../../lib'

// Add CSS for dropdown menu.
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))

// Add CSS for each menu item.
const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '0',
    '& div.MuiListItemText-root': {
      margin: '0px'
    },
    '& span.MuiTypography-root': {
      fontFamily: 'Commissioner-Regular',
      fontSize: '15px',
      color: '#333',
      padding: '10px 50px 10px 25px',
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: 'inherit'
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#1C75BC'
      }
    }
  }
}))(MenuItem)

const ActionsMenu = ({ id, setGroupShouldReload, isContentLoading, isRespOrAccount, setFeedbackMessage }) => {
  // To set the anchor element for dropdown menu.
  const [anchorEl, setAnchorEl] = useState(null)
  // Set webFormOpen.
  const [webFormOpen, setWebFormOpen] = useState(false)
  // Set webFormUrl.
  const [webFormUrl, setWebFormUrl] = useState('')
  // Generate iframe Url.
  const generateIframeUrl = iframeUrl => {
    let url = iframeUrl.replace('{GID}', id)
    url = url.replace('{permission}', +isRespOrAccount)
    setWebFormUrl(`${process.env.REACT_APP_TARGET_URL}${url}`)
    setWebFormOpen(true)
    handleClose()
  }
  // Handle to open dropdown.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  // Handle to close dropdown.
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Set open flag for Add member modal.
  const [open, setOpen] = useState(false)
  // Handle to open.
  const handleAddOpen = () => {
    setOpen(true)
    handleClose()
  }
  // Handle to close.
  const handleAddClose = () => {
    setOpen(false)
  }
  // Conditionally Render the Options Based on Access Privileges.
  // - Edit Group
  // - Add members
  // - Move Group
  return (
    <div className="action-menu-wrapper">
      <Button
        aria-controls="customized-menu" aria-haspopup="true" variant="contained" color="primary"
        onClick={handleClick} disabled={isContentLoading} endIcon={<ExpandMoreIcon />}>
        Actions
      </Button>
      <StyledMenu id="action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className="action_menu_item">
        {isRespOrAccount && <StyledMenuItem>
          <ListItemText children={<Link to={`/manage-group/${id}/edit`}>Manage Group</Link>} />
        </StyledMenuItem>}
        {isRespOrAccount
          ? <StyledMenuItem>
            <ListItemText primaryTypographyProps={{ 'data-tracking': 'Add Group' }} primary="Add Group" onClick={() => generateIframeUrl(constants.misc.webFormUrls.addAndRequestGroup)} />
          </StyledMenuItem>
          : <StyledMenuItem>
            <ListItemText primaryTypographyProps={{ 'data-tracking': 'Request New Group' }} primary="Request New Group" onClick={() => generateIframeUrl(constants.misc.webFormUrls.addAndRequestGroup)} />
          </StyledMenuItem>}
        {isRespOrAccount && <StyledMenuItem>
          <ListItemText primaryTypographyProps={{ 'data-tracking': 'Add New Application' }} primary="Add New Application" onClick={() => generateIframeUrl(constants.misc.webFormUrls.addApplication)} />
        </StyledMenuItem>}
        {isRespOrAccount && <StyledMenuItem>
          <ListItemText primaryTypographyProps={{ 'data-tracking': 'Add Existing Application' }} primary="Add Existing Application" onClick={() => generateIframeUrl(constants.misc.webFormUrls.addExistingApplication)} />
        </StyledMenuItem>}
        {isRespOrAccount && <StyledMenuItem>
          <ListItemText primaryTypographyProps={{ 'data-tracking': 'Add Members' }} primary="Add Members" onClick={handleAddOpen} />
        </StyledMenuItem>}
      </StyledMenu>
      <GroupMemberAddModal visible={open} onCancel={handleAddClose} gid={id} setGroupShouldReload={setGroupShouldReload} setFeedbackMessage={setFeedbackMessage} />
      {webFormOpen && <ModalForms open={webFormOpen} setOpen={setWebFormOpen} url={webFormUrl} setShouldReload={setGroupShouldReload} setMessage={setFeedbackMessage} />}
    </div>
  )
}

// Exporting the component.
export default ActionsMenu
