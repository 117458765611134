import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
// Import the required assets and images.
import '../sass/component/footer.scss'
import siteLogo from '../../../../images/logo-voyager.svg'

// Defining the footer for the nextGen.
const Footer = () => {
  // Set menuData.
  const [menuData, setMenuData] = useState([])
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Get current year.
  const currentYear = new Date().getFullYear()

  // Footer Data fetching
  useEffect(() => {
    // Prepare the footerVariable.
    const footerVariable = JSON.stringify({
      name: 'footer-menu'
    })
    // Call graphql for Footer.
    const FooterQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.navbarQuery}&variables=${footerVariable}`
    // Query Call
    queryContent(FooterQuery)
      .then(response => {
        if (response.data.menuByName.level0) {
          // Set menu data.
          setMenuData(response?.data?.menuByName?.level0)
        } else {
          // Set error.
          setHasLoadingError(true)
        }
      })
      .catch(() => setHasLoadingError(true))
  }, [])

  const generateLinks = menu => {
    let label = menu?.label
    const target = menu?.target ? menu.target : '_self'
    const path = menu.url?.path
    // Replace the [CURRENT_YEAR] token with actual value.
    label = label.replace('[CURRENT_YEAR]', currentYear)
    // Return only label if there is no label.
    // If it is internal link use react NavLink.
    // If not use normal a tag.
    if (!path) {
      return label
    } else if (path.toLowerCase().includes('mailto:')) {
      return <a href={path} target={target}>{label}</a>
    } else {
      return <NavLink to={path} target={target}>{label}</NavLink>
    }
  }
  // Return the output.
  return (
    <div className="footer-outer-wrapper">
      <div className="footer-inner-wrapper">
        <div className="footer-logo"><Link to='/'><img src={siteLogo} alt="Logo" /></Link></div>
        <div className="footer-menu">
          <ul>
            {menuData && menuData?.map((menu, index) => (
              <li key={index}>
                {generateLinks(menu)}
              </li>
            ))
            }
          </ul>
        </div>
      </div>
      {hasLoadingError && <div>Data Loading Error...</div>}
    </div>
  )
}

// Return the component for use.
export default Footer
