import React, { useState } from 'react'
// Import the necessary packages.
import { Button, Menu, MenuItem, ListItemText } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { withStyles } from '@mui/styles'
import { Link } from 'react-router-dom'

// Add CSS for dropdown menu.
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))

// Add CSS for each menu item.
const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '0',
    '& div.MuiListItemText-root': {
      margin: '0px'
    },
    '& span.MuiTypography-root': {
      fontFamily: 'Commissioner-Regular',
      fontSize: '15px',
      color: '#333',
      padding: '10px 50px 10px 25px',
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: 'inherit'
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#1C75BC'
      }
    }
  }
}))(MenuItem)

// Build the component.
const ContentActionMenu = ({ id, gid, ownerGroupId, isContentLoading, isRespOrAccount }) => {
  // To set the anchor element for dropdown menu.
  const [anchorEl, setAnchorEl] = useState(null)
  // Handle to open dropdown.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  // Handle to close dropdown.
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Conditionally Render the Options Based on Access Privileges.
  // - Manage Application
  return (
    <div className="action-menu-wrapper">
      <Button aria-controls="customized-menu" aria-haspopup="true" variant="contained" color="primary"
        onClick={handleClick} disabled={isContentLoading} endIcon={<ExpandMoreIcon />}>
        Actions
      </Button>
      <StyledMenu id="action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className="action_menu_item">
        {isRespOrAccount && <StyledMenuItem>
          <ListItemText children={<Link to={`/manage-application/${id}/edit/${gid || 0}`}>Manage Application</Link>} />
        </StyledMenuItem>}
      </StyledMenu>
    </div>
  )
}

// Exporting the component.
export default ContentActionMenu
