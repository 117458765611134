import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DialogContent, DialogContentText, Typography } from '@mui/material'
import IframeResizer from 'iframe-resizer-react'
import { constants } from '../../../../lib'
import Loader from '../../../PageLoader'
import { usePostMessage } from '../../../../hooks'

// Build the component.
const AddAnnouncement = ({ setMessage }) => {
  // Set formLoading.
  const [formLoading, setFormLoading] = useState(true)
  // Get navigate.
  const navigate = useNavigate()
  // Set the url.
  const url = `${process.env.REACT_APP_TARGET_URL}${constants.misc.webFormUrls.addAnnouncement}`
  // Just a dummy function to pass in post message hook.
  const dummyFnc = () => true
  // Trigger when cancel will be clicked.
  const cancelRedirect = () => navigate('/inbox/announcements/all')

  // Call the post message hook.
  usePostMessage(dummyFnc, cancelRedirect, setMessage)

  // Determine form loaded.
  const formLoaded = () => {
    setFormLoading(false)
  }
  // Return the output.
  return (
    <div className='dialog-modal'>
      <DialogContent>
        {formLoading ? <Loader /> : null}
        <DialogContentText>
          <Typography className='field'>
            <IframeResizer
              src={url}
              style={{ width: '1px', minWidth: '100%' }}
              className="webform-share-iframe"
              frameBorder="0"
              allow="geolocation; microphone; camera"
              allowtransparency="true"
              onLoad={formLoaded}
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </div>
  )
}
// Export the component for use.
export default AddAnnouncement
