import { useEffect, useState } from 'react'
import { sortAlphabetically, sortByDateAdded } from '../components/NextGen/Assets/component/ItemSortHelpers'

// Build and export the hook for use.
export const useActiveSearch = contentType => {
  // Set group data.
  const [groupData, setGroupData] = useState({})
  // Set searchData.
  const [searchData, setSearchData] = useState([])
  // Set searchValue.
  const [searchValue, setSearchValue] = useState('')
  // Set tags.
  const [tags, setTags] = useState([])
  // Set tagsFilteredData.
  const [tagFilteredData, setTagFilteredData] = useState([])
  // Set tagFilterData.
  const [tagFilterActive, setTagFilterActive] = useState(false)
  // Set textFilteredData.
  const [textFilterData, setTextFilterData] = useState([])
  // Set num of tags selected.
  const [numOfTagsSelected, setNumOfTagsSelected] = useState(0)
  // Set selected tags.
  const [selected, setSelected] = useState([])
  // Set sort criteria.
  const [sortCriteria, setSortCriteria] = useState('1')

  // Generate tags based on the received data.
  useEffect(() => {
    if (contentType === 'applications' && !tagFilterActive) {
      let resultData
      if (searchValue) {
        resultData = textFilterData || []
      } else {
        resultData = groupData?.groupContentByGidList?.results || []
      }
      const tagsArr = generateTags(resultData)
      setTags([...new Set(tagsArr)].map(title => {
        if (selected.includes(title)) {
          return ({ label: title, selected: true })
        } else {
          return ({ label: title, selected: false })
        }
      }))
    }
  }, [textFilterData, searchValue, groupData, contentType, tagFilterActive])

  // Function to generateTags from result data.
  const generateTags = resultData => {
    const tagsArr = []
    resultData.forEach(element => {
      const relationalTagsData = element.relationalTags?.entities[0]
      if (relationalTagsData?.useTags.includes('yes')) {
        relationalTagsData.fieldTags.forEach(tag => tag.entity?.entityLabel && tagsArr.push(tag.entity?.entityLabel))
      } else {
        element.fieldTags.forEach(tag => tag.entity?.entityLabel && tagsArr.push(tag.entity?.entityLabel))
      }
    })
    return tagsArr
  }

  // Update the selected tags based on tags received.
  useEffect(() => {
    const tagsArr = []
    tags.forEach(tag => {
      if (tag.selected) {
        tagsArr.push(tag.label)
      }
    })
    setSelected(tagsArr)
  }, [tags])

  // Function to filter data by the tags selected.
  const filterByTags = (selectedTags, data) => {
    return data.filter(item => {
      const fieldTags = item.fieldTags
      const useRelationalTags = item.relationalTags?.entities[0].useTags[0] === 'yes'
      const relationalTags = item.relationalTags?.entities[0].fieldTags
      if (useRelationalTags) {
        return relationalTags.some(tag => selectedTags.includes(tag.entity?.entityLabel))
      } else {
        return fieldTags.some(tag => selectedTags.includes(tag.entity?.entityLabel))
      }
    })
  }

  // Filtering data based on combination of tags and search keyword.
  useEffect(() => {
    if (contentType === 'applications' && tags.length) {
      const selectedTags = tags.filter(tag => tag.selected).map(selTag => selTag.label)
      if (selectedTags.length > 0) {
        let filteredData = []
        // Enable tagFilterActive
        setTagFilterActive(true)
        if (searchValue) {
          const textData = groupData?.groupContentByGidList?.results.filter(entity => entity?.entityLabel.toLowerCase().includes(searchValue.toLowerCase()))
          filteredData = filterByTags(selectedTags, textData)
        } else {
          filteredData = filterByTags(selectedTags, groupData?.groupContentByGidList?.results)
        }
        setTagFilteredData(filteredData || [])
        setSearchData(filteredData || [])
      } else {
        setTagFilterActive(false)
      }
      // setNumOfTagsSelected based on the number of selectedTags.
      let count = 0
      tags.forEach(tag => {
        if (tag.selected) {
          count++
        }
      })
      setNumOfTagsSelected(count)
    }
  }, [tags, groupData, contentType, searchValue])

  // Clear searchValue if tagFilter is not active.
  useEffect(() => {
    if (!tagFilterActive) {
      searchHandler({
        target: {
          value: searchValue || ''
        }
      })
      setTagFilteredData([])
    }
  }, [tagFilterActive])

  // Changes the cardData based on changes in sortCriteria, groupData or searchTerm.
  useEffect(() => {
    let result
    if (searchData) {
      switch (sortCriteria) {
        case '1': {
          result = searchData
          break
        }
        case '2': {
          result = sortAlphabetically(searchData, 'asc', contentType)
          break
        }
        case '3': {
          result = sortAlphabetically(searchData, 'desc', contentType)
          break
        }
        case '4': {
          result = sortByDateAdded(searchData, 'asc')
          break
        }
        case '5':
          result = sortByDateAdded(searchData, 'desc')
          break
        default: {
          result = searchData
        }
      }
      setSearchData(result)
    }
  }, [searchValue, groupData, searchData, sortCriteria])

  // Set searchData based on the contentType received.
  useEffect(() => {
    if (contentType === 'groups') {
      setSearchData(groupData?.groupById?.childGroups?.entities || [])
    }
    if (contentType === 'applications') {
      setSearchData(groupData?.groupContentByGidList?.results || [])
      setTextFilterData(groupData?.groupContentByGidList?.results || [])
    }
  }, [groupData, contentType])

  // Group search handler.
  const searchHandler = e => {
    const enteredValue = e.target.value
    let filteredData = []
    setSearchValue(enteredValue)
    if (!tagFilterActive) {
      if (contentType === 'groups') {
        filteredData = groupData?.groupById?.childGroups?.entities.filter(entity => entity?.title.toLowerCase().includes(enteredValue.toLowerCase()))
      }
      if (contentType === 'applications') {
        filteredData = groupData?.groupContentByGidList?.results.filter(entity => entity?.entityLabel.toLowerCase().includes(enteredValue.toLowerCase()))
      }
    } else {
      if (contentType === 'groups') {
        filteredData = tagFilteredData.filter(entity => entity?.title.toLowerCase().includes(enteredValue.toLowerCase()))
      }
      if (contentType === 'applications') {
        filteredData = tagFilteredData.filter(entity => entity?.entityLabel.toLowerCase().includes(enteredValue.toLowerCase()))
      }
    }
    setSearchData(filteredData)
    if (enteredValue) {
      setTextFilterData(filteredData || [])
    } else {
      setTextFilterData(groupData?.groupContentByGidList?.results || [])
    }
  }

  // expose the data and method from hooks.
  return [
    searchData,
    setSearchData,
    searchValue,
    searchHandler,
    setGroupData,
    sortCriteria,
    setSortCriteria,
    tags,
    setTags,
    numOfTagsSelected
  ]
}
