import React, { useState, useEffect } from 'react'
import { constants } from '../../lib'
import { queryContent } from '../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { Typography, Box, FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material'
import Loader from '../PageLoader'
import { CTErrorPage } from '../ContentDetails/ContentPages/CTHelpers/CTErrorPage'
import StarRating from '../StarRating'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import logoDefault from '../../images/office.jpg'
import queryString from 'query-string'
import Pagination from '../Pagination'
import { handleChangeFor, formatDate, latestDate, earliestDate, earliestDateYear } from './utility'
import './assetInsights.scss'
let locationChange = true

// The component.
const Insights = ({ details }) => {
  const history = useNavigate()
  const location = useLocation()
  const url = location.search
  // Set CTName value.
  const CTName = `My ${details.contentType} Insights`
  // Set defaultAlt value.
  const defaultAlt = 'company-logo'
  // Set data.
  const [data, setData] = useState()
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set result count.
  const [totalCount, setTotalCount] = useState(0)
  // Set url params.
  const [params, setParams] = useState(queryString.parse(url.replace('?', '')))
  // Set filterYear.
  const [selectedFilterYear, setSelectedFilterYear] = useState(params.showing || 'any')
  // Set Search.
  const [searchValue, setSearchValue] = useState(params.search_value || '')
  // Set checkBox values.
  const [checkboxValuesObj, setCheckboxValuesObj] = useState({ audit: params.audit === '1', finding: params.finding === '1', qi: params.qi === '1', capa: params.capa === '1' })
  // Set if zero insights error.
  const [hasZeroInsights, setHasZeroInsights] = useState(false)

  // Calculate findings and auditDate data.
  const calculateFindingsAndAuditData = entity => {
    // Findings and auditDate calculation variables.
    let totalFindings = 0
    let totalCriticalFindings = 0
    let totalMajorFindings = 0
    let totalMinorFindings = 0
    let auditDate
    const auditDateValues = [0]
    if (entity !== null) {
      entity.Audits.entities.forEach(auditEntity => {
        if (auditEntity !== null) {
          // Findings calculation.
          totalFindings += auditEntity.Findings?.count
          totalCriticalFindings += auditEntity.criticalFindings?.count
          totalMajorFindings += auditEntity.majorFindings?.count
          totalMinorFindings += auditEntity.minorFindings?.count
          // Audit Date calculation.
          if (auditEntity.auditPublishedDate !== null) {
            auditDateValues.push(new Date(auditEntity.auditPublishedDate.date).getTime())
          }
          if (auditEntity.auditPlannedEndDate !== null) {
            auditDateValues.push(new Date(auditEntity.auditPlannedEndDate.date).getTime())
          }
        }
      })
      const auditDateSortedValues = [...[...auditDateValues]
        .sort((prev, next) => (prev - next))].reverse()
      auditDate = auditDateSortedValues[0]
    }
    return ({
      totalFindings,
      totalCriticalFindings,
      totalMajorFindings,
      totalMinorFindings,
      auditDate
    })
  }

  // Handle change of url parameter.
  const handleChangeForUrl = category => value => {
    locationChange = true
    const handleChangeValue = handleChangeFor(category, params, location, queryString)(value)
    setParams(handleChangeValue.newParams)
    history(handleChangeValue.returnURL)
  }

  // Search conditioning for free text and checkboxes.
  const searchCheck = insightsVariable => {
    if (searchValue) {
      insightsVariable.search_keyword = `%${searchValue}%`
      for (const [checkboxNames, checkboxValues] of Object.entries(checkboxValuesObj)) {
        if (checkboxValues) {
          insightsVariable[`${checkboxNames}_keyword`] = `%${searchValue}%`
        }
      }
    }
  }

  // Insights Data fetching if params changes.
  useEffect(() => {
    // Set loading state.
    setIsLoading(true)
    // current date
    const currentDate = new Date()
    // Set the object to use further.
    const insightsVariable = {
      current_date: formatDate(currentDate, 'yyyy-mm-dd')
    }
    // Set the limit & offset.
    if (params) {
      insightsVariable.limit = params.limit
      insightsVariable.offset = params.offset
    }
    // Set the date filter.
    if (selectedFilterYear !== 'any') {
      if (selectedFilterYear === 'earliest') {
        insightsVariable.filter_date = formatDate(earliestDate, 'yyyy-mm-dd')
      } else {
        insightsVariable.filter_date = formatDate(latestDate, 'yyyy-mm-dd')
      }
    }
    // Adding search variable parameters.
    searchCheck(insightsVariable)
    // Prepare the query variable.
    const InsightsQueryVariable = encodeURIComponent(JSON.stringify(insightsVariable))
    // Call graphql for InsightQuery.
    const InsightQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${details.insightQuery}&variables=${InsightsQueryVariable}`
    // Query Call
    queryContent(InsightQuery)
      .then(response => {
        if (!response?.data) {
          setHasLoadingError(true)
        } else {
          // Check zero insights condition.
          if (response.data.nodeQuery?.count === 0) {
            setHasZeroInsights(true)
          } else {
            setHasZeroInsights(false)
          }
          // Set data.
          setData(response.data)
          // Set Total count.
          setTotalCount(response.data.nodeQuery.count)
        }
      })
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))
  }, [params, selectedFilterYear, details.insightQuery])

  // Detect the location change.
  useEffect(() => {
    const paramString = queryString.parse(location.search.replace('?', ''))
    if (!locationChange && JSON.stringify(params) !== JSON.stringify(paramString)) {
      setParams(paramString)
      setSearchValue(paramString.search_value)
    } else {
      locationChange = false
    }
  }, [location, params])

  // Select year dropdown function.
  const selectYearOptions = () => {
    const selectYearTypes = constants.misc.selectYearLabels
    // Initialize selectYearOption data.
    const selectYearOptionList = []
    // Loop through all selectYearLabels and set selectYearOption data.
    for (const [, keyValue] of Object.entries(selectYearTypes)) {
      keyValue.value === 'earliest'
        ? selectYearOptionList.push({ label: `${earliestDateYear} - Present`, value: `${keyValue.value}` })
        : selectYearOptionList.push({ label: `${keyValue.label}`, value: `${keyValue.value}` })
    }

    // The menu handler.
    const menuHandler = value => {
      // Set selectedYear Value.
      setSelectedFilterYear(value)
      handleChangeForUrl('showing')(value)
    }

    // Return the output.
    return (
      <Box className="dropdown-wrapper">
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Showing</InputLabel>
          <Select
            id="select-menu"
            value={selectedFilterYear}
            label="FilterYear"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {selectYearOptionList.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => {
                  menuHandler(option.value)
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  // Audit Click handler.
  const auditClicked = () => {
    setCheckboxValuesObj(prevCheckboxValues => ({ ...prevCheckboxValues, audit: !prevCheckboxValues.audit }))
  }
  // Findings Click handler.
  const findingsClicked = () => {
    setCheckboxValuesObj(prevCheckboxValues => ({ ...prevCheckboxValues, finding: !prevCheckboxValues.finding }))
  }
  // QIs Click handler.
  const QIsClicked = () => {
    setCheckboxValuesObj(prevCheckboxValues => ({ ...prevCheckboxValues, qi: !prevCheckboxValues.qi }))
  }
  // CAPAs Click handler.
  const CAPAsClicked = () => {
    setCheckboxValuesObj(prevCheckboxValues => ({ ...prevCheckboxValues, capa: !prevCheckboxValues.capa }))
  }

  // Search button click.
  const SearchClicked = () => {
    const selectedSearchObject = {}
    let currentCheckboxValues = checkboxValuesObj
    selectedSearchObject.search_value = searchValue.trim()
    setSearchValue(selectedSearchObject.search_value)
    if (selectedSearchObject.search_value.length === 0) {
      currentCheckboxValues = { audit: false, finding: false, qi: false, capa: false }
      setCheckboxValuesObj(currentCheckboxValues)
    }
    checkCheckboxCondition(selectedSearchObject, currentCheckboxValues)
    handleChangeForUrl(selectedSearchObject)()
  }

  // Checking checkbox status.
  const checkCheckboxCondition = (selectedSearchObject, currentCheckboxValues) => {
    for (const [checkboxNames, checkboxValues] of Object.entries(currentCheckboxValues)) {
      if (checkboxValues) {
        selectedSearchObject[checkboxNames] = '1'
      } else {
        selectedSearchObject[checkboxNames] = ''
      }
    }
  }

  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    // Set message.
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.server }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }

  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Record title block code.
  const recordTitleBlock = entity => {
    return (
      <div className="recordtitle">
        {details.logo && <img className='recordimage' src={entity?.fieldImage?.logoUrl || logoDefault} alt={defaultAlt} />}
        <div className={details.logo ? 'recordinfo withlogo' : 'recordinfo'}>
          <div className="recordname">{entity?.title || constants.filter.none}</div> <em>&bull;</em>
          {entity?.starRating && (
            <div className='recordStarRating' display='flex' flex-direction='row'>
              <StarRating rating={entity?.starRating / 2} />
            </div>
          )}
          {entity?.starRating && <em>&bull;</em>}Updated:
          <span>{entity?.lastUpdatedDate ? formatDate(new Date(entity?.lastUpdatedDate)) : '00-00-0000'}</span>
          <Link to={entity?.url?.path || '#'} className="view">
            view
          </Link>
        </div>
      </div>
    )
  }

  // Record body block code.
  const recordBodyBlock = (entity, findingsAndAuditDataObj) => {
    return (
      <div className="recordbody">
        <div className="recordtiles list01">
          <h3>Audits</h3>
          <ul className="vchart">
            <li>
              Audits<span>
                <Link to={`${entity?.url?.path}#audits`} className="totalCount">{entity?.Audits?.count || 0}</Link>
              </span>
            </li>
            <li>
              Last Audit
              <span className="vdate">
                {findingsAndAuditDataObj?.auditDate ? formatDate(new Date(findingsAndAuditDataObj?.auditDate)) : constants.filter.none}
              </span>
            </li>
          </ul>
        </div>
        <div className="recordtiles list02">
          <h3>Findings</h3>
          <ul className="vchart">
            <li>
              Total<span>
                <Link to={`${entity?.url?.path}#findings`} className="totalCount">{findingsAndAuditDataObj?.totalFindings || 0}</Link>
              </span>
            </li>
            <li>
              Critical<span>{findingsAndAuditDataObj?.totalCriticalFindings || 0}</span>
            </li>
            <li>
              Minor<span>{findingsAndAuditDataObj?.totalMinorFindings || 0}</span>
            </li>
            <li>
              Major<span>{findingsAndAuditDataObj?.totalMajorFindings || 0}</span>
            </li>
          </ul>
        </div>
        <div className="recordtiles list03">
          <h3>QIs</h3>
          <ul className="vchart">
            <li>
              Total<span><Link to={`${entity?.url?.path}#qis`} className="totalCount">{entity?.QIs?.count || 0}</Link></span>
            </li>
            <li>
              Open<span>{entity?.openQIs?.count || 0}</span>
            </li>
          </ul>
        </div>
        <div className="recordtiles list04">
          <h3>CAPAs</h3>
          <ul className="vchart">
            <li>
              Total<span><Link to={`${entity?.url?.path}#capas`} className="totalCount">{entity?.CAPAs?.count || 0}</Link></span>
            </li>
            <li>
              Open<span>{entity?.openCAPAs?.count || 0}</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  // Return the output with pagination.
  return !isLoading && data && (
    <>
      <header className="headerarea">
        <h1>{CTName}</h1>
        <nav>
          <ul className="navigation">
            <li>
              <span>Total {data?.nodeQuery?.count} {details.contentType}(s) in your communities</span>
            </li>
          </ul>
        </nav>
      </header>

      <section className="record-top">
        <div className="section-search">
          <div className="searchbox">
            <input type="text" placeholder="Search.." name="search" onChange={event => setSearchValue(event.target.value)} value={searchValue} onKeyDown={e => (e.key === 'Enter') ? SearchClicked() : ''} />
            <button type="button" onClick={SearchClicked}>Go</button>
            <ul className="srcfilter">
              <li>
                <strong>Filter:</strong>
              </li>
              <li>
                <Checkbox color='primary' onClick={auditClicked} checked={checkboxValuesObj.audit} />
                <label htmlFor="audits">Audits</label>
              </li>
              <li>
                <Checkbox color='primary' onClick={findingsClicked} checked={checkboxValuesObj.finding} />
                <label htmlFor="findings">Findings</label>
              </li>
              <li>
                <Checkbox color='primary' onClick={QIsClicked} checked={checkboxValuesObj.qi} />
                <label htmlFor="ois">QIs</label>
              </li>
              <li>
                <Checkbox color='primary' onClick={CAPAsClicked} checked={checkboxValuesObj.capa} />
                <label htmlFor="capas">CAPAs</label>
              </li>
            </ul>
          </div>
        </div>
        <div className="section-filtering">
          {selectYearOptions()}
        </div>
      </section>

      {hasZeroInsights
        ? <div className="zero-insight-wrapper">
          <Typography>{constants.errors.noInsightFound.replace('#TYPE#', `${details.contentType}`)}</Typography>
        </div>
        : data.nodeQuery?.entities?.map((entity, key) => {
          const findingsAndAuditDataObj = calculateFindingsAndAuditData(entity)
          return (
            <section className={entity !== null ? 'record-block' : 'record-block access-denied'} key={key}>
              <span className="denied">Access Denied</span>
              {recordTitleBlock(entity)}
              {recordBodyBlock(entity, findingsAndAuditDataObj)}
            </section>
          )
        })}
      {
        !hasZeroInsights && <Pagination handleChangeFor={handleChangeForUrl} params={params} totalCount={totalCount} />
      }
    </>
  )
}
// Export the component.
export default Insights
