import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Grid, Box, Paper, Typography, Hidden, Button, CircularProgress } from '@mui/material'
import { Delete, Send, PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material'
import CartItem from '../CartItem'
import EmailSentMessage from '../EmailSentMessage'
import SendAllMessage from '../SendAllMessage'
import GroupAssociation from '../GroupAssociation'

import { ShoppingCartContext } from '../../contexts'
import { UserContext } from '../context/UserContext'
import './ShoppingCart.scss'
import { constants } from '../../lib'

function ShoppingCartBodyContents ({ setPopupRecipient, setPopupItem, setPopupVisibility, setErrorText, errorText, setEmailStatusText, setEventInProgress }) {
  // Set processes.
  const [processes, setProcesses] = useState()
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set selectedProcess.
  const [selectedProcess, setSelectedProcess] = useState('')
  // Fetch data from ShoppingCartContext.
  const { shoppingCartItems } = useContext(ShoppingCartContext)

  useEffect(() => {
    // Prepare payload data.
    const parameters = JSON.stringify({
      limit: '500'
    })
    // Call graphql for processQuery.
    const processQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.processQuery}&variables=${parameters}`
    fetch(processQuery, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(response => setProcesses(response.data.nodeQuery))
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))
  }, [])

  const handleChange = value => {
    setSelectedProcess(value)
  }

  if (isLoading) {
    // Return loader.
    return (
      <div className='loading-wrapper__center'>
        <CircularProgress />
      </div>
    )
  }

  return hasLoadingError
    ? (<Box>
      <Typography align='center' dangerouslySetInnerHTML={{ __html: constants.errors.server }} />
    </Box>)
    : (<Box>
      <div className='error__center' dangerouslySetInnerHTML={{ __html: errorText }} />
      <Grid container>
        <Hidden smDown>
          <Grid item md={4}>
            <Box className='section-header'><Typography variant='h6'>Item</Typography> </Box>
          </Grid>
          <Grid item md={4}>
            <Box className='section-header'><Typography variant='h6'>Process</Typography> </Box>
          </Grid>
          <Grid item md={4}>
            <Box className='section-header'><Typography variant='h6'>Send/Delete</Typography> </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Grid>
        {Array.from(shoppingCartItems).map(([mapKey, value]) => (
          <CartItem
            setPopupRecipient={recipient => setPopupRecipient(recipient)}
            setPopupItem={item => setPopupItem(item)}
            setPopupVisibility={status => setPopupVisibility(status)}
            processes={processes}
            item={value}
            mapKey={mapKey}
            key={`Item=${mapKey}`}
            setErrorText={setErrorText}
            setEmailStatusText={setEmailStatusText}
            changeHandler={handleChange}
            defaultSelectedProcess={selectedProcess}
            emailNotifyHandler={sendEmailForAssetMove}
            setEventInProgress={setEventInProgress}
          />
        ))}
      </Grid>
    </Box>)
}

// Return no content output.
function ShoppingCartNoContent () {
  return (
    <Box className='no-content'>
      <Typography variant='h5'>There doesn't seem to be anything here...</Typography>
      <Typography variant='h6'>Try searching for assets and clicking on the cart icon to add them to your cart.</Typography>
    </Box>
  )
}

// Associate asset & process with group and send email.
export const sendEmailForAssetMove = (assetProcessIds, assetType, setEmailStatusText, isCheckedNotify) => {
  setEmailStatusText('')
  if (isCheckedNotify.includes(true)) {
    setEmailStatusText('<div class="sending-email">Sending Email...</div>')
  }

  // Set URL for API.
  const url = `${process.env.REACT_APP_TARGET_URL}/api/perform-asset-process-transition`
  fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({
      assetProcessIds: assetProcessIds,
      assetType: assetType,
      notify_user: isCheckedNotify
    })
  })
    .then(result => {
      if (result.ok) {
        return result.json()
      } else {
        throw new Error(`${result.status}`)
      }
    })
    .then(result => {
      if (!result.error) {
        if (isCheckedNotify) {
          setEmailStatusText(`<div class="success-email">${result.response.msg}</div>`)
        }
      } else {
        throw new Error('Unexpected Error')
      }
    })
    .catch(() => {
      if (isCheckedNotify) {
        setEmailStatusText('<div class="request-failed">Failed to send email!!</div>')
      } else {
        setEmailStatusText('<div class="request-failed">Failed to complete the workflow!!</div>')
      }
    })
}

// Handle send and delete all.
function SendDeleteAll ({ setSendAllVisibility, setShoppingCartSize, setErrorText, setPopupItems, setPopupRecipients, setEmailStatusText, setEventInProgress, groups }) {
  const { clearCart, shoppingCartItems, emailRecipients } = useContext(ShoppingCartContext)

  // Format content Id array.
  const contentIds = useMemo(() => [...shoppingCartItems.keys()]
    .filter(key => shoppingCartItems.get(key).resultType !== 'group')
    .map(key => shoppingCartItems.get(key).id), [shoppingCartItems])

  // Function to send all cart items.
  const HandleSendAll = () => {
    setErrorText('')
    if (shoppingCartItems.size > 0 && emailRecipients.size > 0 && [...shoppingCartItems.keys()].every(val => [...emailRecipients.keys()].includes(val))) {
      setEventInProgress(true)
      // Prepare payload data.
      const payload = [...shoppingCartItems.keys()].map(key => ({
        assetId: shoppingCartItems.get(key).id,
        processId: emailRecipients.get(key).processId,
        assetType: shoppingCartItems.get(key).resultType
      }))
      const assetProcessIds = [...emailRecipients.values()]
        .map(assetProcess => `${assetProcess.assetId}_${assetProcess.processId}`)
      const isNotify = [...emailRecipients.values()]
        .map(item => item.sendNotify)
      const assetType = [...shoppingCartItems.keys()].map(key => shoppingCartItems.get(key).resultType)
      fetch('/api/assetProcesses', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }).then(res => {
        if (res.status < 400) {
          setPopupItems(new Map(shoppingCartItems))
          setPopupRecipients(new Map(emailRecipients))
          setSendAllVisibility(true)
          setShoppingCartSize(shoppingCartItems.size)
          clearCart()
          if (assetProcessIds.length > 0) {
            sendEmailForAssetMove(assetProcessIds, assetType, setEmailStatusText, isNotify)
          }
        } else {
          return res.json()
        }
      })
        .then(res => {
          if (res) {
            setErrorText(res.error)
          }
        })
        .catch(() => {
          setErrorText(constants.errors.server)
        })
        .finally(() => setEventInProgress(false))
    } else {
      window.setTimeout(() => setErrorText('One or more assets are missing processes. Please verify all of them are added and try again.'), 500)
    }
  }
  // Function to delete all item.
  const HandleDeleteAll = () => {
    clearCart()
  }
  // Set open Group Association.
  const [openGroupAssociation, setOpenGroupAssociation] = useState(false)
  // Function to handle open.
  const handleOpenGroupAssociation = () => {
    setOpenGroupAssociation(true)
  }
  // Function to handle close.
  const handleCloseGroupAssociation = () => {
    setOpenGroupAssociation(false)
  }

  return (
    <>
      <Box className='floating-btn-group'>
        <Paper>
          {groups.length > 0 && contentIds.length > 0 &&
            <Button
              endIcon={<PlaylistAddIcon />}
              color='primary'
              variant='contained'
              className='actionButton'
              onClick={handleOpenGroupAssociation}
            >
              Associate Group
            </Button>
          }
          <Button
            endIcon={<Send />}
            color='primary'
            variant='contained'
            className='actionButton'
            onClick={HandleSendAll}
          >
            Send All
          </Button>
          <Button
            endIcon={<Delete />}
            color='secondary'
            variant='contained'
            className='actionButton'
            onClick={HandleDeleteAll}
          >
            Delete All
          </Button>
        </Paper>
      </Box>
      {openGroupAssociation &&
        <GroupAssociation visible={openGroupAssociation} contentIds={contentIds} onCancel={handleCloseGroupAssociation} groups={groups} />
      }
    </>
  )
}
// Export ShoppingCart component.
export default function ShoppingCart () {
  // Fetch data from ShoppingCartContext.
  const { shoppingCartItems } = useContext(ShoppingCartContext)
  // Set errorText.
  const [errorText, setErrorText] = useState('')
  // Set emailStatusText.
  const [emailStatusText, setEmailStatusText] = useState('')
  // Set eventInProgress flag.
  const [eventInProgress, setEventInProgress] = useState(false)

  // States are updated in child components by passing down functions to set alter their values
  // Set popupRecipient.
  const [popupRecipient, setPopupRecipient] = useState(null)
  // Set popupItem.
  const [popupItem, setPopupItem] = useState(null)
  // Set popupVisibility flag.
  const [popupVisibility, setPopupVisibility] = useState(false)
  // Set sendAllVisibility flag.
  const [sendAllVisibility, setSendAllVisibility] = useState(false)
  // Set shoppingCartSize.
  const [shoppingCartSize, setShoppingCartSize] = useState(0)
  // Set sendAllItems.
  const [sendAllItems, setSendAllItems] = useState(null)
  // Set sendAllRecipients.
  const [sendAllRecipients, setSendAllRecipients] = useState(null)
  // Set currentCart.
  const [currentCart, setCurrentCart] = useState(null)
  // Set Groups.
  const [groups, setGroups] = useState([])
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Fetch the user context.
  const { userId } = useContext(UserContext)

  // Set currentCart data.
  useEffect(() => {
    if (shoppingCartItems.size === 0) {
      setCurrentCart(null)
    } else if (!currentCart || currentCart.size === 0) {
      setCurrentCart(shoppingCartItems)
    }
  }, [currentCart, shoppingCartItems])

  useEffect(() => {
    if (errorText) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [errorText])

  useEffect(() => {
    // Get Groups details
    // Prepare the payload.
    const groupVariable = JSON.stringify({
      uid: userId
    })
    // Call graphql for processQuery.
    const processQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.allGroupsByUserQuery}&variables=${groupVariable}`
    fetch(processQuery, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(response => {
        const groupsList = response.data.groupUserGroupsByUidList?.results?.length ? response.data.groupUserGroupsByUidList.results.filter(entity => entity !== null) : []
        return setGroups(groupsList)
      })
      .catch(() => setErrorText(constants.errors.server))
      .finally(() => setIsLoading(false))
  }, [userId])

  // Return ShoppingCart data.
  // Components used:
  // - EmailSentMessage.
  // - SendAllMessage.
  // - ShoppingCartBodyContents.
  // - SendDeleteAll.
  return (
    <Paper className='cart-background'>
      <EmailSentMessage
        popupRecipient={popupRecipient}
        popupItem={popupItem}
        isVisible={popupVisibility}
        setPopupItem={item => setPopupItem(item)}
        setPopupRecipient={recipient => setPopupItem(recipient)}
        setVisibility={status => setPopupVisibility(status)}
        emailStatusText={emailStatusText}
      />
      <SendAllMessage
        setVisibility={status => setSendAllVisibility(status)}
        isVisible={sendAllVisibility}
        shoppingCartSize={shoppingCartSize}
        setShoppingCartSize={size => setShoppingCartSize(size)}
        shoppingCartItems={sendAllItems}
        emailRecipients={sendAllRecipients}
        emailStatusText={emailStatusText}
      />
      <Typography variant='h4' className='cart-title'>Your Cart</Typography>
      {eventInProgress
        ? <div className='loading-wrapper__center inprogress'>
          <div className='loader-wrapper-eventprogress'><CircularProgress /></div>
        </div>
        : null}
      {currentCart && currentCart.size > 0
        ? (<ShoppingCartBodyContents
          setPopupRecipient={recipient => setPopupRecipient(recipient)}
          setPopupItem={item => setPopupItem(item)}
          setPopupVisibility={status => setPopupVisibility(status)}
          setErrorText={setErrorText}
          errorText={errorText}
          setEmailStatusText={setEmailStatusText}
          setEventInProgress={setEventInProgress}
        />)
        : <ShoppingCartNoContent />}
      {currentCart && currentCart.size > 0 && !isLoading
        ? (<SendDeleteAll
          setSendAllVisibility={status => setSendAllVisibility(status)}
          setShoppingCartSize={size => setShoppingCartSize(size)}
          setErrorText={setErrorText}
          setPopupItems={setSendAllItems}
          setPopupRecipients={setSendAllRecipients}
          setEmailStatusText={setEmailStatusText}
          setEventInProgress={setEventInProgress}
          groups={groups}
        />)
        : null}
    </Paper>
  )
}
