import React from 'react'
// Import various components.
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Chip from '@mui/material/Chip'

// Setting the empty icon.
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
// Setting the checked icon.
const checkedIcon = <CheckBoxIcon fontSize="small" />

// Generate the component.
// - Receive all eligible groups for user.
// - The groups to be selected
// - Set the newly selected group.
const GroupSelectDropdown = ({ groupOptions, selectedGroups, setSelectedGroups }) => (
  <div className="group_selection_wrapper">
    <Autocomplete
      multiple
      id="my-groups-checkboxes"
      options={groupOptions}
      value={selectedGroups}
      disableCloseOnSelect
      onChange={(event, value) => setSelectedGroups(value)}
      getOptionLabel={option => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.gid}>
          {option.title}
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginLeft: 8 }}
            checked={selected}
          />
        </li>
      )}
      style={{ width: '100%' }}
      renderInput={params => (
        <TextField {...params} placeholder={selectedGroups.length ? '' : `All Groups (${groupOptions.length})`} />
      )}
      noOptionsText='No Groups Available'
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="primary"
            key={option.gid}
            label={option.title}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  </div>
)

// Exporting the component.
export default GroupSelectDropdown
