import React, { useState, useEffect } from 'react'
// Import necessary components.
import { Button, Modal, Fade, TextareaAutosize, FormLabel } from '@mui/material'
import Alert from '@mui/material/Alert'
import memberIcon from '../../../../../images/green-icon-people.svg'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import './edit-member.scss'
import { constants } from '../../../../../lib'

// Add css for member add modal.
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'grid',
    alignItems: 'baseline',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    margin: 'auto',
    borderRadius: '5px',
    [theme.breakpoints.up('sm')]: {
      width: '500px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw'
    }
  },
  checkboxLabel: {
    fontSize: '15px'
  }
}))
export default function EditMember ({ gid, visible, person, onCancel, setFeedbackMessage, setShouldReload }) {
  const classes = useStyles()
  // Set RACI roles array.
  const raciRolesArr = constants.misc.groupTeamMemberData.businessTeamRoles
  // Set is loading.
  const [isLoading, setIsLoading] = useState(false)
  // Set disabled buttons
  const [disabled, setDisabled] = useState(false)
  // Set shouldReload.
  const [hasError, setHasError] = useState(false)
  // Set role value.
  const [isRole, setIsRole] = useState()
  // Set description value.
  const [isDeScription, setIsDeScription] = useState()
  // Set description error message value.
  const [isErrorDecMes, setIsErrorDecMes] = useState()
  // Set description error flag.
  const [isDesError, setIsDesError] = useState(false)

  // Set description and role default values.
  useEffect(() => {
    setIsRole(person.role.toLocaleLowerCase())
    setIsDeScription(person.userDescription)
    setIsDesError(false)
  }, [person])

  const closeModal = () => {
    onCancel()
    setIsDesError(false)
  }

  // Set form validate
  const validateForm = () => {
    // Initiate the value.
    let isError = false
    setIsDesError(false)
    // No description placed.
    if (!isDeScription) {
      isError = true
      setIsDesError(true)
      setIsErrorDecMes('Please enter description.')
    }
    return !isError
  }
  // Set data when role, description change.
  const changeData = (e, field) => {
    const value = e.target.value.trim()
    if (field === 'description') {
      setIsDeScription(value)
      if (value) {
        setIsDesError(false)
      }
    }
    if (field === 'role') {
      setIsRole(value)
    }
  }

  // Handle submit.
  const handleSubmit = () => {
    if (!validateForm()) {
      return
    }
    setDisabled(true)
    // Set loading before submit.
    setIsLoading(true)
    // Set the api url.
    const url = `${process.env.REACT_APP_TARGET_URL}/api/update-group-member`
    // Set the payload data.
    const data = {
      gid: parseInt(gid),
      uid: parseInt(person.userId),
      role: isRole,
      description: isDeScription
    }
    // To track if logged out.
    let haveResponse = false
    // Call the api.
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(res => {
        haveResponse = true
        return res.json()
      })
      .then(res => {
        setIsLoading(false)
        setHasError(false)
        setDisabled(false)
        if (res.error) {
          setIsDesError(true)
          setIsErrorDecMes(res.errorMsg)
        } else {
          // Set message in page top.
          setFeedbackMessage({ message: res.response.msg, type: 'success', icon: <img src={memberIcon} alt='Member Icon' />, section: 'editMember' })
          // Load the group silently.
          setShouldReload(true)
          // Close the modal.
          closeModal()
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err.message)
        setHasError(true)
      })
  }
  return (
    <div className='edit-member'>
      <Modal
        aria-labelledby='Edit member modal'
        aria-describedby='Edit group members'
        className={classes.modal}
        open={visible}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={visible}>
          <div className={`${classes.paper} modal-wrapper`}>
            <span className='close-modal'>
              <Close fontSize='medium' onClick={closeModal} />
            </span>
            <div className='edit-member-modal-content'>
              <div className='heading'>Edit Member</div>
              {hasError && <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: constants.errors.server }} /></Alert>}
              {!hasError && <div>
                <div className='sub-heading'>Edit member details</div>
                <div id='edit-member-form' className='edit-member-form-wrapper'>
                  <div className='edit-member-details-wrapper'>
                    <div className='member-info'>
                      <div className='member-item'>
                        <FormLabel component='legend' className='fieldHeading name'>
                          Name
                        </FormLabel>
                        <div className='member-name'>{person.username}</div>
                      </div>
                      <div className='member-item'>
                        <FormLabel component='legend' className='fieldHeading email'>
                          Email
                        </FormLabel>
                        <div className='member-email'>{person.userEmail}</div>
                      </div>
                    </div>
                  </div>
                  <div className='edit-member-role-wrapper'>
                    <FormLabel component='legend' className={isRole ? 'fieldHeading role error-input fieldError' : 'fieldHeading role error-input'}>Role</FormLabel>
                    <select name='role' onChange={e => changeData(e, 'role')} defaultValue={person.role.toLocaleLowerCase()}>
                      {raciRolesArr.map(raciRole => (
                        <option value={raciRole.toLocaleLowerCase()} key={raciRole}>
                          {raciRole}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='description-wrapper'>
                    <FormLabel component='legend' className='fieldHeading description'>
                      Description
                    </FormLabel>
                    <TextareaAutosize
                      id='description'
                      className={isDesError ? 'description-textarea fieldError' : 'description-textarea'}
                      aria-label='minimum height'
                      defaultValue={person.userDescription}
                      minRows={5}
                      placeholder='Description'
                      required
                      maxRows={5}
                      onChange={e => changeData(e, 'description')}
                    />
                    {isDesError && <span className="error-desc">{isErrorDecMes}</span>}
                  </div>
                  <div className='btn-wrapper-edit'>
                    <Button variant='outlined' className='cancel-edit' disabled={disabled} onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button id='save-edit' data-tracking='save' variant='contained' className='save-edit' onClick={handleSubmit} disabled={disabled}>
                      {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
