import React, { createContext, useState, useEffect } from 'react'
import { useTimer } from 'use-timer'
import { constants } from '../../lib'
import Loader from '../PageLoader'
import { downloadPhotoIndividual } from '../ProfileImage/utility'
// Export UserContext component.
export const UserContext = createContext()
// Export UserProvider component.
export const UserProvider = ({ children }) => {
  const endTime = 60 * 15
  const queryId = constants.drupalQueryIds.profileQuery
  const ROUTE_TO_VERIFY_ACCESS = `${process.env.REACT_APP_TARGET_URL}/graphql`
  // Set isAuthenticated flag.
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  // Set userId.
  const [userId, setUserId] = useState()
  // Set userRoles.
  const [userRoles, setUserRoles] = useState()
  // Set uuid.
  const [uuid, setUuid] = useState()
  // Set display name.
  const [userName, setUserName] = useState()
  // Set otherDetails.
  const [otherDetails, setOtherDetails] = useState({ name: '', userPicture: '' })
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set isLogoutLoading flag.
  const [isLogoutLoading, setIsLogoutLoading] = useState(false)
  // Set favorites.
  const [favorites, setFavorites] = useState()
  // Set isFavoriteLoading.
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(true)
  // Set SearchHistory.
  const [searchHistory, setSearchHistory] = useState()

  // Method for perform login.
  const performLogin = () => {
    if (!isAuthenticated && window.location.pathname !== '/logout') {
      // Set the authentication state in local storage.
      localStorage.setItem('isAuthenticated', '1')
      // Set user is active.
      localStorage.setItem('userActive', '1')
      // Encode the entire frontend URL before navigate to login.
      const uriWithFragment = encodeURIComponent(window.location)
      window.location = `${process.env.REACT_APP_TARGET_URL}/saml_login?redirect=${uriWithFragment}`
    }
  }

  // Method for perform logout.
  const performLogout = () => {
    setIsAuthenticated(false)
    setIsLogoutLoading(true)
    // Set the authentication state in local storage.
    localStorage.setItem('isAuthenticated', '0')
    // Set user active state in local storage.
    localStorage.setItem('userActive', '0')
    // There's a chance that the user is already logged out.
    // If they are, a call to /user/logout will fail, so we need to send them straight to the logout message.
    callIfAuthenticated(() => {
      window.location = `${process.env.REACT_APP_TARGET_URL}/user/logout?goto=frontend`
    }, () => {
      window.location = `${process.env.REACT_APP_TARGET_URL}/logout?goto=frontend`
    })()
  }

  // Method for perform periodically when time update.
  const performTimeUpdateEvent = () => {
    // Get authenticated flag from local storage.
    const authenticatedUser = parseInt(localStorage.getItem('isAuthenticated'))
    // Get user is active or not.
    const activeUser = parseInt(localStorage.getItem('userActive'))
    // Logout due to inactivity.
    if (!isLoading && !authenticatedUser && !isLogoutLoading) {
      performLogout()
    }
    // Reset timer on user activity if user perform any click or scroll activity in any of the opened tab.
    if (authenticatedUser && activeUser) {
      reset()
      start()
      setTimeout(() => localStorage.setItem('userActive', '0'), 1000)
    }
  }

  /**
   * Applies either the successful or failed callback depending on whether the user is authenticated properly.
   *
   * @param {function} success Callback performed on success.
   * @param {function} failure Callback performed if authentication failed.
   *
   * @returns {function} A function that takes in an array of parameters called when the callback is invoked.
   */
  const callIfAuthenticated = (success, failure) => params => {
    fetch(ROUTE_TO_VERIFY_ACCESS, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ queryId })
    })
      .then(result => {
        if (result.ok) {
          return result.json()
        } else {
          throw new Error('Call failed.')
        }
      })
      .then(result => {
        // If success supplied as undefined, then no need to set the local storage.
        // As success only contains function if logout called.
        if (!success) {
          localStorage.setItem('isAuthenticated', '1')
        }
        setIsAuthenticated(true)
        localStorage.setItem('userActive', '1')
        const userInfoJson = result?.data?.brqcCurrentUserContext?.results[0]
        setUserId(userInfoJson?.uid)
        setUuid(userInfoJson?.uuid)
        setUserRoles(translateRoles(userInfoJson?.roles))
        setUserName(userInfoJson?.displayName)
        // Set more user related details.
        setOtherDetails({
          userPicture: userInfoJson?.userPicture,
          jobTitle: userInfoJson?.jobTitle ? userInfoJson.jobTitle : constants.filter.none,
          timezone: userInfoJson?.timezone,
          elevatorSpeech: userInfoJson?.elevatorSpeech ? userInfoJson.elevatorSpeech : constants.filter.none,
          roles: userInfoJson?.roles,
          mail: userInfoJson?.mail,
          name: userInfoJson?.name,
          profilePicture: '',
          bypassAccess: userInfoJson?.roles.includes(constants.drupalRoles.administrator) || userInfoJson?.permission.includes(constants.drupalUserPermissions.bypassContentAccessControl)
        })
        const functionArgs = params || []
        return success && success.apply(undefined, functionArgs)
      })
      .catch(() => {
        const functionArgs = params || []
        setIsAuthenticated(false)
        // set the authentication state in local storage
        localStorage.setItem('isAuthenticated', '0')
        failure && failure.apply(undefined, functionArgs)
      })
  }
  // Count down for logout.
  const countDownUntilLogout = () => {
    isAuthenticated && start()
  }
  // Verify if authenticated.
  const verifyIsAuthenticated = () => {
    if (!isAuthenticated && !isLogoutLoading) {
      callIfAuthenticated(undefined, performLogin)()
    }
  }
  // Set refresh timer.
  const setRefreshTimers = () => {
    window.onclick = userActivityEvent()
    window.onscroll = userActivityEvent()
  }

  const userActivityEvent = () => {
    if (isAuthenticated) {
      return () => {
        reset()
        start()
        localStorage.setItem('userActive', '1')
      }
    } else {
      return undefined
    }
  }

  // Calculate timer.
  const { start, reset } = useTimer({
    endTime,
    onTimeUpdate: performTimeUpdateEvent,
    onTimeOver: performLogout
  })

  // Translating from Drupal Strings to JS Camel Case
  const translateRoles = roles => {
    roles = roles.split(', ')
    const specialRoles = {
      administrator: false,
      webformDesigner: false,
      jrAdmin: false,
      groupAdmin: false,
      feedEngineer: false,
      contentAuthor: false,
      scenariosAuto: false
    }
    roles.forEach(role => {
      switch (role) {
        case (constants.drupalRoles.administrator):
          specialRoles.administrator = true
          break
        case (constants.drupalRoles.webformDesigner):
          specialRoles.webformDesigner = true
          break
        case (constants.drupalRoles.jrAdmin):
          specialRoles.jrAdmin = true
          break
        case (constants.drupalRoles.groupAdmin):
          specialRoles.groupAdmin = true
          break
        case (constants.drupalRoles.feedEngineer):
          specialRoles.feedEngineer = true
          break
        case (constants.drupalRoles.contentAuthor):
          specialRoles.contentAuthor = true
          break
        case (constants.drupalRoles.scenariosAuto):
          specialRoles.scenariosAuto = true
          break
        default:
          break
      }
    })
    return specialRoles
  }

  // Append j&J profile image if exist
  const setProfilePicture = profilePicture => {
    if (profilePicture) {
      const otherDataWithProfileImg = { ...otherDetails, profilePicture }
      setOtherDetails(otherDataWithProfileImg)
    }
  }
  // Logout countdown.
  useEffect(countDownUntilLogout, [isAuthenticated])
  // Refresh timer.
  useEffect(setRefreshTimers, [isAuthenticated])
  // Verify if user is authenticated.
  useEffect(verifyIsAuthenticated, [isAuthenticated])
  // Pull the user profile picture.
  useEffect(() => {
    if (otherDetails.name && !otherDetails.userPicture) {
      downloadPhotoIndividual(otherDetails.name, '48x48')
        .then(res => setProfilePicture(res.profileImage))
        .finally(() => setIsLoading(false))
    } else if (otherDetails.name) {
      setIsLoading(false)
    }
  }, [otherDetails.name, otherDetails.userPicture])

  const fetchSearchHistory = () => {
    fetch(`/api/userSearches/${userId}`)
      .then(res => res.json())
      .then(res => {
        setSearchHistory(res.data)
      })
      .catch(err => console.log(err))
  }

  // Get the favorites of the user & recent searches.
  useEffect(() => {
    if (userId) {
      // Fetch the favorite data against logged in user.
      fetch(`/api/favorites/${userId}`,
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'include'
        })
        .then(result => {
          if (result.ok) {
            return result.json()
          } else if (result.status === 403) {
            window.location.reload()
          } else {
            throw new Error('Call failed unexpectedly.')
          }
        })
        .then(result => setFavorites(result.data))
        .catch(err => console.error(err))
        .finally(() => {
          if (window.location.pathname === constants.misc.myFavoriteUrl) {
            setIsFavoriteLoading(false)
          }
        })
      fetchSearchHistory()
    }
  }, [userId, window.location.pathname])

  // Return either the loader or the output.
  if (isLoading || !isAuthenticated || isLogoutLoading) {
    return <Loader type="Linear" />
  } else {
    return (
      <UserContext.Provider
        value={{
          isAuthenticated,
          performLogin,
          performLogout,
          userId,
          uuid,
          userRoles,
          userName,
          otherDetails,
          favorites,
          isFavoriteLoading,
          setIsFavoriteLoading,
          searchHistory,
          fetchSearchHistory
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}
