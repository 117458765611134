import { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { UserContext } from '../components/context/UserContext'
import { constants } from '../lib'
import { queryContent } from '../components/ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { miscConfig } from '../components/Search/SearchConfig'
import GenerateSearchPayloadVariable from '../components/Search/GenerateSearchPayloadVariable'

// Build and export useNextGenSearch custom hook.
export const useNextGenSearch = searchBoxId => {
  // Set history.
  const history = useNavigate()
  // Set location.
  const location = useLocation()
  // Set searchValue.
  const [searchValue, setSearchValue] = useState((queryString.parse(location.search.replace('?', ''))?.keyword || ''))
  // Fetch required values from user context.
  const { userId, searchHistory, fetchSearchHistory, otherDetails } = useContext(UserContext)
  // Set searchOptions.
  const [searchOptions, setSearchOptions] = useState(searchHistory)
  // Set searchOptionCount.
  const [searchOptionCount, setSearchOptionCount] = useState(0)
  // Set Loading.
  const [loading, setIsLoading] = useState(false)
  // Get url from location.
  const url = location.search

  useEffect(() => {
    // Clear the search box if navigated to other page than search.
    if (location.pathname !== '/search') {
      document.getElementById(searchBoxId).value = ''
      setSearchValue('')
    }
  }, [location, searchBoxId])

  // Query for getting predictions.
  useEffect(() => {
    // If search value exist call the predictive search.
    // Else take all options from recent search history.
    if (searchValue && searchValue.trim().length) {
      setSearchOptions([])
      setSearchOptionCount(0)
      setIsLoading(true)
      const delayDebounceFn = setTimeout(() => {
        // Send Axios request here
        const queryVariable = {
          key: encodeURIComponent(searchValue),
          filters: {
            conjunction: 'AND',
            groups: []
          }
        }
        // Prepare queryString object.
        const queryStringObj = queryString.parse(url.replace('?', ''))
        // Set entity type.
        const entityType = queryStringObj?.entityType || 'all'
        // Filters to be added if entityType is 'all' or blank
        if (entityType === 'all') {
          queryVariable.filters.groups.push({
            conjunction: 'OR',
            conditions: [
              {
                operator: '=',
                name: 'content__field_is_application',
                value: 'yes'
              },
              {
                operator: '>',
                name: 'group__id',
                value: '0'
              }
            ]
          })
          queryVariable.filters.groups.push({
            conjunction: 'AND',
            conditions: [
              {
                operator: '<>',
                name: 'group__field_life_cycle__entity__title',
                value: constants.misc.retiredLifeCycle
              },
              {
                operator: '<>',
                name: 'group__field_is_live',
                value: '0'
              },
              {
                operator: '<>',
                name: 'content__field_life_cycle__entity__name',
                value: constants.misc.retiredLifeCycle
              }
            ]
          })
          // Add extra payload for those user who don't have bypass access permission.
          if (!otherDetails.bypassAccess) {
            queryVariable.filters.groups.push({
              conjunction: 'OR',
              conditions: [
                {
                  name: 'content__access_by_users',
                  operator: '=',
                  value: otherDetails.name
                },
                {
                  name: 'content__access_by_users',
                  operator: '=',
                  value: 'access_not_restricted'
                }
              ]
            })
          }
          // Root sector field name.
          const rootSectorFieldName = 'content__field_root_sector'
          // Set segment type.
          const rootSegment = queryStringObj[rootSectorFieldName] || ''
          // Filters to be added if any segment type is selected.
          if (rootSegment !== '') {
            // Prepare selectedType Array.
            const selectedTypes = rootSegment.split(miscConfig.multiValueSeparator)
            const conditions = []
            selectedTypes.forEach(type => {
              conditions.push({
                operator: '=',
                name: 'content__field_root_sector',
                value: type
              })
              conditions.push({
                operator: '=',
                name: 'group__field_root_sector',
                value: type
              })
            })
            queryVariable.filters.groups.push({
              conjunction: 'OR',
              conditions
            })
          }
        } else {
          // Call generateSearchPayloadVariable function.
          const payloadVariable = GenerateSearchPayloadVariable(queryStringObj, otherDetails, entityType, entityType, setIsLoading)
          queryVariable.filters = payloadVariable?.filters
        }

        // Build the query endpoint.
        const predictionQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.predictiveSearch}&variables=${JSON.stringify(queryVariable)}`
        // Call the query.
        queryContent(predictionQuery)
          .then(res => {
            setSearchOptions(res.data.searchAPISearch.documents.map(option => ({ searchTerm: option.group__title || option.content__content_title })))
            setSearchOptionCount(res.data.searchAPISearch.count)
          })
          .catch(err => console.error(err))
          .finally(() => {
            setIsLoading(false)
          })
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    } else {
      setIsLoading(false)
      setSearchOptions(searchHistory)
      setSearchOptionCount(0)
    }
  }, [searchValue, location, otherDetails, searchHistory, url])

  // Add current search url to recent searches.
  const addToRecentSearch = value => {
    fetch('/api/userSearches', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        searchTerm: value || searchValue.trim()
      })
    })
      .then(res => {
        res.json()
        fetchSearchHistory()
      })
      .catch(err => console.log(err))
  }

  // Function for URl redirect.
  const urlForRedirect = (id, value) => {
    setIsLoading(false)
    // Set the search.
    setSearchValue(value)
    // Close the auto suggestion when search.
    document.getElementById(id).blur()
    // Set params.
    const params = queryString.parse(location.search.replace('?', ''))
    // Set newParams.
    const newParams = { ...params, keyword: value || searchValue, offset: 0 }
    const newUrl = `/search?${queryString.stringify(newParams)}`
    // Check if new url then log that and redirect to that url.
    if ((window.location.pathname + window.location.search) !== newUrl) {
      if (searchValue.trim().length || value) {
        addToRecentSearch(value)
      }
      history(newUrl, { replace: true })
    }
  }

  // Expose the value from hook.
  return [
    searchValue,
    setSearchValue,
    urlForRedirect,
    loading,
    searchOptions,
    searchOptionCount
  ]
}
