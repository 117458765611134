import { useContext, useEffect, useState } from 'react'
import memberIconGreen from '../images/green-icon-people.svg'
import checkIconGreen from '../images/icon-checkmark.svg'
import { constants } from '../lib'
import { AnnouncementContext } from '../contexts/AnnouncementContext'
import { useNavigate } from 'react-router-dom'

export const usePostMessage = (setShouldReload, handleClose, setMessage) => {
  // Set feedbackMessage.
  const [feedbackMessage, setFeedbackMessage] = useState({})
  // Get announcementReload from context.
  const { setAnnouncementReload } = useContext(AnnouncementContext)
  // Get navigate.
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('message', handleEvent, false)
    // cleanup this component
    return () => {
      window.removeEventListener('message', handleEvent, false)
    }
  }, [])

  // Set the success or error message.
  useEffect(() => {
    const timer = setTimeout(() => {
      setFeedbackMessage({})
    }, constants.misc.favoritesLimit.feedbackMessageTimeout * 1000)
    return () => clearTimeout(timer)
  }, [feedbackMessage])

  // Handle the event, triggered from popup.
  const handleEvent = event => {
    // Check origin before executing the event.
    if (event.origin !== process.env.REACT_APP_TARGET_URL) {
      return
    }
    let eventData = ''
    try {
      eventData = JSON.parse(event.data)
    } catch {
      eventData = false
    }
    // Set the message received from Drupal form.
    if (eventData?.action === 'addSubmissionSuccess') {
      setMessage({ type: 'success', message: eventData?.message, icon: <img src={memberIconGreen} alt='Member Icon' /> })
      // Group should reload automatically after successful submission.
      setShouldReload(true)
      // Close the popup once received.
      handleClose()
    }
    if (eventData?.action === 'addAnnouncementSubmissionSuccess') {
      setMessage({ type: 'success', message: eventData?.message, icon: <img src={checkIconGreen} alt='Check Icon' /> })
      setAnnouncementReload(true)
      if (eventData?.target) {
        navigate(`/inbox/announcements/${eventData.target}`)
      } else {
        setShouldReload(true)
        handleClose()
      }
    }
    if (eventData?.action === 'editSubmissionSuccess') {
      setFeedbackMessage({ type: 'success', message: eventData?.message, icon: <img src={memberIconGreen} alt='Member Icon' /> })
      setShouldReload(true)
    }
    if (eventData?.action === 'editApplicationSubmissionSuccess') {
      setFeedbackMessage({ type: 'success', message: eventData?.message, icon: <img src={checkIconGreen} alt='Data saved' /> })
      setShouldReload(true)
    }
    if (eventData?.eventName === 'displayPreview' && eventData?.embededUrl) {
      const percentage = 0.8
      const popupWidth = Math.floor(window.innerWidth * percentage)
      const popupHeight = Math.floor(window.innerHeight * percentage)
      window.open(`/view-application?url=${eventData.embededUrl}`, '_blank', `height=${popupHeight},width=${popupWidth},toolbar=no,location=no,status=no,menubar=no`)
    }
    // Close popup.
    if (event?.data === 'closeModal') {
      handleClose()
    }
  }

  // Expose the value from hook.
  return [
    feedbackMessage,
    setFeedbackMessage,
    handleEvent
  ]
}
