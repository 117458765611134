import React, { createContext, useState } from 'react'
// Creating the context.
export const NavBarContext = createContext()

// export the component for use.
export const NavBarProvider = ({ children }) => {
  // Set the active url.
  const [urlPath, setUrlPath] = useState()

  // Return the output of context provider.
  return (
    <NavBarContext.Provider value={{
      urlPath,
      setUrlPath
    }}>
      {children}
    </NavBarContext.Provider>
  )
}
