import React, { useState, useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import FieldSwitch from '../../NextGen/Assets/component/FieldSwitch'

// Export and build the switch component.
export default function SwitchFacetField ({ fieldName, fieldLabel, options, onChange, defaultChecked }) {
  // Set checked or not.
  const [checked, setChecked] = useState(Boolean(defaultChecked.length))
  // Fetch data from UserContext.
  const { userName, otherDetails } = useContext(UserContext)
  // Option of switch.
  let switchOption

  // Generate switch option value.
  switch (fieldName) {
    case 'group__members':
      // This field value depends on display name exist or not.
      switchOption = userName ? `${userName} (${otherDetails.name})` : otherDetails.name
      break
    case 'content__group_users':
      // This field value depends on display name exist or not.
      switchOption = otherDetails.name
      break
    default:
      switchOption = options[0]
      break
  }

  // Handle the switch change.
  const handleSwitchChange = e => {
    const isChecked = e.target.checked
    setChecked(isChecked)
    // Send for search.
    onChange(fieldName)(isChecked ? switchOption : '')
  }

  // Return the output of start and end date along with clear selection.
  return (
    <div className="facet-box">
      <div className='switch-wrapper'>{fieldLabel}<FieldSwitch checked={checked} onChange={handleSwitchChange} name={fieldName} /></div>
    </div>
  )
}
