import React, { useContext } from 'react'
import { Card, CardMedia, CardContent, IconButton, CardHeader } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ShoppingCart, CheckOutlined } from '@mui/icons-material'
import StarRating from '../StarRating'
import { ShoppingCartContext } from '../../contexts'
import { Link } from 'react-router-dom'
import { constants } from '../../lib'
import groupLocationImage from '../../images/location.png'
import groupBusinessProcessImage from '../../images/business_process.jpg'
import groupProductTeamImage from '../../images/product_team.jpg'
import groupFunctionalImage from '../../images/functional.jpg'
import capaImage from '../../images/capaDef.png'
import auditsImage from '../../images/auditsDef.png'
import crsacImage from '../../images/crsacDef.png'
import espImage from '../../images/esp_icon.png'
import locImage from '../../images/loc_icon.png'
import discoveryImage from '../../images/discoveryDef.png'
import findingImage from '../../images/findingDef.png'
import processImage from '../../images/processDef.png'
import qiImage from '../../images/QIDef.png'
import articleImage from '../../images/article_icon.png'
import './auditItem.scss'

// Add or modify css elements.
const useStyles = makeStyles({
  root: {
    minWidth: 280,
    maxWidth: 280
  },
  headerTitle: {
    fontSize: 18,
    maxHeight: 20
  }
})

// Build and export Audit Item.
export default function AuditItem ({ item, setHasCartError }) {
  // Fetching the shopping cart item.
  const { addItemToCart, removeItem, shoppingCartItems } = useContext(ShoppingCartContext)
  const classes = useStyles()

  // Add title property if not exist.
  if (!item?.title) {
    item = { ...item, title: item?.entityLabel }
  }
  // Add id property if not exist.
  if (!item?.id) {
    item = { ...item, id: item?.nid }
  }
  // Add rating property if not exist with null.
  if (!item?.rating) {
    item = { ...item, rating: null }
  }
  // Overwrite url property with url path if exist.
  if (item?.url?.path) {
    item.url = item?.url?.path
  }

  // Toggle cart item if added or removed.
  const toggleItemInCart = clickedItem => {
    if (shoppingCartItems && shoppingCartItems.has(`${clickedItem.resultType}_${clickedItem.id}`)) {
      removeItem(`${clickedItem.resultType}_${clickedItem.id}`)
    } else {
      if (shoppingCartItems.size < 100) {
        addItemToCart(clickedItem)
      } else {
        setHasCartError(true)
      }
    }
  }

  // Generate the redirect url for any item.
  const urlForRedirect = () => {
    if (item?.url) {
      return item?.url
    } else {
      switch (item.bundle) {
        case constants.drupalTypename.groupFunctional:
        case constants.drupalTypename.groupBusinessProcess:
        case constants.drupalTypename.groupLocation:
        case constants.drupalTypename.groupProductTeam:
          // Redirect url for group.
          return `/group/${item.id}`
        case constants.drupalDataType.contentType.process:
          // Redirect url for process content.
          return `/content/process?id=${item.id}`
        case constants.drupalDataType.contentType.loc:
          // Redirect url for loc content.
          return `/content/loc?id=${item.id}`
        case constants.drupalDataType.contentType.qi:
          // Redirect url for qi content.
          return `/content/qis?id=${item.id}`
        case constants.drupalDataType.contentType.esp:
          // Redirect url for esp content.
          return `/content/esp?id=${item.id}`
        case constants.drupalDataType.contentType.crsac:
          // Redirect url for crsac content.
          return `/content/crsac?id=${item.id}`
        case constants.drupalDataType.contentType.findings:
          // Redirect url for findings content.
          return `/content/findings?id=${item.id}`
        case constants.drupalDataType.contentType.dl:
          // Redirect url for dl content.
          return `/content/dl?id=${item.id}`
        case constants.drupalDataType.contentType.capas:
          // Redirect url for capas content.
          return `/content/capas?id=${item.id}`
        case constants.drupalDataType.contentType.audits:
          // Redirect url for audits content.
          return `/content/audits?id=${item.id}`
        case constants.drupalDataType.contentType.article:
          // Redirect url for article content.
          return `/content/article?id=${item.id}`
        default:
          return ''
      }
    }
  }

  // Map item images.
  const mapImageToTile = () => {
    switch (item.bundle) {
      case constants.drupalTypename.groupFunctional:
        // Image for functional group.
        return groupFunctionalImage
      case constants.drupalTypename.groupBusinessProcess:
        // Image for businessProcess group.
        return groupBusinessProcessImage
      case constants.drupalTypename.groupLocation:
        // Image for location group.
        return groupLocationImage
      case constants.drupalTypename.groupProductTeam:
        // Image for productTeam group.
        return groupProductTeamImage
      case constants.drupalDataType.contentType.process:
        // Image for process content.
        return processImage
      case constants.drupalDataType.contentType.loc:
        // Image for loc content.
        return locImage
      case constants.drupalDataType.contentType.qi:
        // Image for qi content.
        return qiImage
      case constants.drupalDataType.contentType.esp:
        // Image for esp content.
        return espImage
      case constants.drupalDataType.contentType.crsac:
        // Image for crsac content.
        return crsacImage
      case constants.drupalDataType.contentType.findings:
        // Image for findings content.
        return findingImage
      case constants.drupalDataType.contentType.dl:
        // Image for dl content.
        return discoveryImage
      case constants.drupalDataType.contentType.capas:
        // Image for capas content.
        return capaImage
      case constants.drupalDataType.contentType.audits:
        // Image for audits content.
        return auditsImage
      case constants.drupalDataType.contentType.article:
        // Image for article content.
        return articleImage
      default:
        return item.cardImage
    }
  }

  // Return the item card.
  return (
    <Card elevation={4} className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label='add to cart' component='span' onClick={() => toggleItemInCart(item)}>
            {shoppingCartItems && shoppingCartItems.has(`${item.resultType}_${item.id}`) ? <CheckOutlined /> : <ShoppingCart />}
          </IconButton>
        }
        title={item.title}
        classes={{
          title: classes.headerTitle
        }}
      />
      <Link className='no-text-decoration' to={urlForRedirect}>
        <CardMedia
          className='card-img__resize'
          component='img'
          alt='audit item'
          image={mapImageToTile()}
          style={{ height: '150px' }}
        />
      </Link>
      <CardContent>
        {item.rating !== null ? <StarRating rating={item.rating / 2} /> : <div style={{ height: '28px' }}>&nbsp;</div>}
      </CardContent>
    </Card>
  )
}
