import React, { useState, useContext, useEffect, useCallback } from 'react'
import NavigationCard from '../NavigationCard'
import ourGroups from '../../images/our_groups_navcard.jpg'
import production from '../../images/production.jpg'
import { UserContext } from '../context/UserContext'
import ProfileImage from '../ProfileImage'
import { downloadPhotoIndividual, userAbbreviation } from '../ProfileImage/utility'
import logo from '../../images/logo-big.png'
import './index.scss'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { AppBar, Tabs, Tab, Typography, Box, Paper, Grid, Button, Avatar, IconButton } from '@mui/material'
import { constants } from '../../lib'
import articleImage from '../../images/article_icon.png'
import auditsImage from '../../images/auditsDef.png'
import capaImage from '../../images/capaDef.png'
import crsacImage from '../../images/crsacDef.png'
import dlImage from '../../images/discoveryDef.png'
import espImage from '../../images/esp_icon.png'
import findingImage from '../../images/findingDef.png'
import locImage from '../../images/loc_icon.png'
import qiImage from '../../images/QIDef.png'
import trainingResources from '../../images/training-resources.png'
import groupsImage from '../../images/groups_icon.png'
import EditIcon from '@mui/icons-material/Edit'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import MyItems from './MyItems'
import ToDo from './ToDo'

let homeLocationChange = true
// TabPanel function.
function TabPanel (props) {
  const { children, value, index, ...other } = props
  // Return TabPanel data.
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

// TabProps function.
function tabProps (index) {
  // Return tabProps data.
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
// Add css to Home component.
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '30px',
    borderRadius: '10px'
  },
  gridroot: {
    flexGrow: 1,
    marginTop: '30px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  avatarStyle: {
    width: '150px',
    height: '150px'
  }
}))
// Home component.
export default function Home () {
  // Set classes.
  const classes = useStyles()
  // Set history.
  const history = useNavigate()
  // Set location.
  const location = useLocation()
  // Set pageTabs data.
  const pageTabs = { userinfo: 0, initiated: 1, todo: 2, moved: 3, completed: 4, rejected: 5, quicklinks: 6 }
  // Fetch data from UserContext.
  const { userId, userName, otherDetails } = useContext(UserContext)
  // Prepare my group params
  const urlParams = userName ? `${userName} (${otherDetails.name})` : otherDetails.name
  // Prepare my group Url
  const queryString = `group&group__members=${encodeURIComponent(urlParams).replace('(', '%28').replace(')', '%29')}`
  // Prepare my group Url
  const myGroupUrl = `/search?entityType=${queryString}`

  // Get all icons against content types
  const getIcon = contentType => {
    switch (contentType) {
      case 'article':
        return articleImage
      case 'audits':
        return auditsImage
      case 'capas':
        return capaImage
      case 'clinical_research_site_activity':
        return crsacImage
      case 'discovery_location':
        return dlImage
      case 'external_service_provider':
        return espImage
      case 'findings':
        return findingImage
      case 'local_operating_company':
        return locImage
      case 'quality_investigations':
        return qiImage
      default:
        return ''
    }
  }
  // All content types with label.
  const contentTypes = constants.drupalDataType.contentTypeLabels
  // Set quickLinkObj data.
  const quickLinkObj = []
  for (const [key, value] of Object.entries(contentTypes)) {
    // Eliminate process as it is already there in the top.
    if (key !== 'process') {
      quickLinkObj.push({ label: `${value.short} Search`, icon: getIcon(key), url: key })
    }
  }
  // Set insightLinksObj data.
  const insightLinkObj = []
  // Filter the object based on insight page exist from configuration.
  const allInsights = Object.keys(contentTypes)
    .filter(key => contentTypes[key].insightPage !== undefined)
    .reduce((obj, key) => ({ ...obj, [key]: contentTypes[key] }), {})
  for (const [key, value] of Object.entries(allInsights)) {
    insightLinkObj.push({ label: `${value.short} Insight`, icon: getIcon(key), url: `${value.insightPage}` })
  }
  // Set extraLinkObj data.
  const extraLinkObj = [
    { label: 'Training Resources', icon: trainingResources, url: '/cia', pageUrl: true },
    { label: 'My Groups', icon: groupsImage, url: queryString }
  ]

  // Set message.
  const [message, setMessage] = useState('Welcome to CIA Apps')
  // Set user application profile photo.
  const [myAppProfilePhoto, setMyAppProfilePhoto] = useState('')
  // Set user jnj profile photo.
  const [myProfilePhoto, setMyProfilePhoto] = useState('')
  // Set params.
  const params = useParams()
  // Set currentTab.
  const [currentTab, setCurrentTab] = useState(typeof (pageTabs[params?.tab]) !== 'undefined' ? pageTabs[params?.tab] : 0)
  // Function for change tab.
  const changeTab = (newValue, changeLocation) => {
    homeLocationChange = changeLocation
    setCurrentTab(newValue)
    setTimeout(() => {
      homeLocationChange = true
      return homeLocationChange
    }, 500)
  }
  // Set welcome message.
  useEffect(() => {
    if (userName && userName !== '') {
      setMessage(`Welcome ${userName} to CIA Apps`)
    }
  }, [userName])
  // Set tabUrl.
  useEffect(() => {
    let tabUrl = '/home/'
    if (currentTab > 0) {
      tabUrl += Object.keys(pageTabs).find(key => pageTabs[key] === currentTab)
    } else {
      tabUrl = '/home/userinfo'
    }
    history(tabUrl)
  }, [currentTab])
  // Set changeTab data.
  useEffect(() => {
    if (homeLocationChange) {
      const pathParts = location.pathname.split('/home/')
      const tabNo = typeof (pageTabs[pathParts[1]]) !== 'undefined' ? pageTabs[pathParts[1]] : 0
      changeTab(tabNo, false)
    }
  }, [location])
  // Loading profile image.
  useEffect(() => {
    (async () => {
      if (otherDetails.userPicture) {
        // Load if profile photo exist in application.
        setMyAppProfilePhoto(`${process.env.REACT_APP_TARGET_URL}/${otherDetails.userPicture}`)
      } else if (otherDetails.profilePicture) {
        // Load j&j account profile photo.
        setMyProfilePhoto(otherDetails.profilePicture)
        const response = await downloadPhotoIndividual(otherDetails.name, '240x240')
        setMyProfilePhoto(response.profileImage)
      }
    })()
  }, [otherDetails.name, otherDetails.userPicture, otherDetails.profilePicture])

  const MyGroupIcon = useCallback(() => {
    return (
      <IconButton onClick={() => history(myGroupUrl)} title="My Groups">
        <SupervisedUserCircleIcon fontSize="large" color="primary" />
      </IconButton>
    )
  }, [history, myGroupUrl])

  // Return Home data.
  // Components used:
  // - AppBar.
  // - Tabs.
  // - Tab.
  // - TabPanel.
  // - MyItems.
  // - NavigationCard.
  return (
    <div className='home-container'>
      <div className='message-container'>
        <div className='home-message--top'>{message}</div>
      </div>
      <div className={classes.root}>
        <AppBar position="static" className="tabs-main" key="AppBar">
          <Tabs value={currentTab} onChange={(e, newVal) => changeTab(newVal, false)} className="tabs-wrapper">
            <Tab className="tabs-title" label="User Info" {...tabProps(0)} />
            <Tab className="tabs-title" label="Initiated by me" {...tabProps(1)} />
            <Tab className="tabs-title" label="To-Do" {...tabProps(2)} />
            <Tab className="tabs-title" label="Moved by me" {...tabProps(3)} />
            <Tab className="tabs-title" label="Completed by me" {...tabProps(4)} />
            <Tab className="tabs-title" label="Rejected by me" {...tabProps(5)} />
            <Tab className="tabs-title" label="Quick Links" {...tabProps(6)} />
          </Tabs>
        </AppBar>
        <TabPanel value={currentTab} index={0} key="tab_0">
          <div className="user-info-wrapper">
            <div className="profile-card">
              <div className="profile-card__img">
                {(myAppProfilePhoto || myProfilePhoto)
                  ? (
                    <Avatar className={classes.avatarStyle}>
                      <ProfileImage
                        alt={userAbbreviation(otherDetails.name)}
                        userPicture={myAppProfilePhoto}
                        profileImage={myProfilePhoto}
                      />
                    </Avatar>)
                  : <img src={trainingResources} alt="Default Profile Avatar" />}
              </div>
              <div className="profile-card__cnt">
                <div className="name-group-wrapper">
                  {userName ? <div className="profile-card__name">{userName}</div> : null}
                  <MyGroupIcon myGroupUrl={myGroupUrl} />
                </div>
                <div className="profile-card__txt"><strong>Job Title:</strong> {otherDetails.jobTitle}</div>
                <div className="profile-card__txt"><strong>Timezone:</strong> {otherDetails.timezone}</div>
                <div className="profile-card-loc__txt"><strong>Elevator speech:</strong> <div dangerouslySetInnerHTML={{ __html: otherDetails.elevatorSpeech }} /></div>
                <div className="profile-card-loc__txt"><strong>Role(s) in the system:</strong> {otherDetails.roles}</div>
                <div className="profile-card-loc__txt">&nbsp;</div>
                <Button
                  variant="contained"
                  color="primary"
                  href={`${process.env.REACT_APP_TARGET_URL}/user/${userId}/edit`}
                  target="__blank"
                  startIcon={<EditIcon />}
                >
                  Edit My Profile
                </Button>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={currentTab} index={1} key="tab_1">
          <MyItems activityType="INITIATED" key="myItem_1" />
        </TabPanel>
        <TabPanel value={currentTab} index={2} key="tab_2">
          <ToDo />
        </TabPanel>
        <TabPanel value={currentTab} index={3} key="tab_3">
          <MyItems activityType="MOVED" key="myItem_3" />
        </TabPanel>
        <TabPanel value={currentTab} index={4} key="tab_4">
          <MyItems activityType="COMPLETED" key="myItem_4" />
        </TabPanel>
        <TabPanel value={currentTab} index={5} key="tab_5">
          <MyItems activityType="DELETED" key="myItem_5" />
        </TabPanel>
        <TabPanel value={currentTab} index={6} className="tab-details" key="tab_6">
          <div className='card-row'>
            <NavigationCard
              title='Group Search'
              description="Use faceted search to find the groups you're looking for."
              cardImage={ourGroups}
              redirectLink='?entityType=group'
            />
            <div>
              <img src={logo} alt='logo' className='logo-image' />
            </div>
            <NavigationCard
              title='Capabilities Search'
              description='Find processes relevant to you, add them to your cart and send them to your groups.'
              cardImage={production}
              redirectLink='?entityType=process'
            />
          </div>
          <div className="separator separator-transparent"></div>
          <div className={classes.gridroot}>
            <Grid container className="gridbox">
              {/* Block for search quick link in left side. */}
              <Grid item xs={8} className="gridcontent">
                <Typography variant="h2">Quick Searches</Typography>
                <div className="gridcontent-area">
                  {quickLinkObj.map((item, index) =>
                    <Grid item xs={6} key={`grid_item_${index}`} className="gridtable">
                      <Paper className={classes.paper} onClick={() => {
                        const redirectUrl = item?.pageUrl ? item.url : `/search?entityType=${item.url}`
                        history(redirectUrl)
                      }}>
                        <div className="grid-img">
                          <img src={item.icon} alt={item.label} />
                        </div>
                        <div className="grid-text">
                          {item.label}
                        </div>
                      </Paper>
                    </Grid>
                  )}
                </div>
              </Grid>
              {/* Block for insight page link in right top. */}
              <Grid item xs={4} className="gridsidebar">
                <Grid item xs={12} className="sidegrid gridside-area1">
                  <Typography variant="h2">Insights</Typography>
                  <div className="sidegrid-padd">
                    {insightLinkObj.map((item, index) =>
                      <Grid item xs={12} key={`grid_item_${index}`} className="gridtable">
                        <Paper className={classes.paper} onClick={() => {
                          const redirectUrl = `/insights/${item.url}`
                          history(redirectUrl)
                        }}>
                          <div className="grid-img">
                            <img src={item.icon} alt={item.label} />
                          </div>
                          <div className="grid-text">
                            {item.label}
                          </div>
                        </Paper>
                      </Grid>
                    )}
                  </div>
                </Grid>
                {/* Block for other page link in right bottom. */}
                <Grid item xs={12} className="sidegrid gridside-area2">
                  <Typography variant="h2">Others</Typography>
                  <div className="sidegrid-padd">
                    {extraLinkObj.map((item, index) =>
                      <Grid item xs={12} key={`grid_item_${index}`} className="gridtable">
                        <Paper className={classes.paper} onClick={() => {
                          const redirectUrl = item?.pageUrl ? item.url : `/search?entityType=${item.url}`
                          history(redirectUrl)
                        }}>
                          <div className="grid-img">
                            <img src={item.icon} alt={item.label} />
                          </div>
                          <div className="grid-text">
                            {item.label}
                          </div>
                        </Paper>
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </div>
    </div>
  )
}
