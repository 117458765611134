import React, { useState, useEffect, useContext } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import '../sass/component/navbar.scss'
import siteLogo from '../../../../images/logo-voyager.svg'
import { ReactComponent as MenuIcon } from '../../../../images/icon-hamburger-menu.svg'
import { ReactComponent as CloseIcon } from '../../../../images/icon-close.svg'
import { useNextGenSearch } from '../../../../hooks'
import MyProfile from './MyProfile'
import Inbox from './Inbox'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import SearchInput from './SearchInput'

// Defining the NavBar for the nextGen.
const NavBar = () => {
  // Set menuData.
  const [menuData, setMenuData] = useState()
  // Use useNextGenSearch hook.
  const [searchValue, setSearchValue, urlForRedirect, loading, searchOptions, searchOptionCount] = useNextGenSearch('navbar_search_box')
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set mobile menu open flag.
  const [mobMenuOpen, setMobMenuOpen] = useState(false)
  // Location.
  const location = useLocation()
  // Location pathname.
  const { pathname } = location
  // Use NavBarContext.
  const { urlPath, setUrlPath } = useContext(NavBarContext)

  // NavBar Data fetching.
  useEffect(() => {
    // Prepare the navbar variable.
    const navbarVariable = JSON.stringify({
      name: 'header-menu'
    })
    // Call graphql for Navbar.
    const NavBarQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.navbarQuery}&variables=${navbarVariable}`
    // Query Call
    queryContent(NavBarQuery)
      .then(response => {
        if (response.data.menuByName.level0) {
          // Set menu data.
          setMenuData(response?.data?.menuByName?.level0)
        } else {
          setHasLoadingError(true)
        }
      })
      .catch(() => setHasLoadingError(true))
  }, [])

  // Set search keyword.
  useEffect(() => {
    const queryStringObj = queryString.parse(location.search.replace('?', ''))
    if (queryStringObj?.keyword) {
      setSearchValue(queryStringObj?.keyword)
    } else {
      setSearchValue('')
    }
  }, [location])

  // Set urlPath based on location change.
  useEffect(() => {
    setUrlPath('')
  }, [pathname, setUrlPath])

  return (
    <div className="navbar-wrapper">
      <div className="navbar">
        <div className="navbar-left">
          <div className="site-logo"><Link to='/'><img src={siteLogo} alt="logo" /></Link></div>
          <ul className={mobMenuOpen ? 'nav-items-wrapper open' : 'nav-items-wrapper'}>
            {menuData && menuData.map((menu, index) => (
              <li className="nav-item" key={index}>
                <NavLink to={menu.url?.path} className={urlPath === menu.url?.path ? 'active' : ''} onClick={() => setMobMenuOpen(false)}>{menu.label}</NavLink>
              </li>
            ))
            }
          </ul>
        </div>
        {hasLoadingError && <div>Data Loading Error...</div>}
        <div className="navbar-right">
          <ul>
            <li className="nav-autocomplete-wrapper">
              <SearchInput id="navbar_search_box" searchValue={searchValue} setSearchValue={setSearchValue}
                searchOptions={searchOptions} urlForRedirect={urlForRedirect} loading={loading} placeholder="Search" count={searchOptionCount} />
            </li>
            <li className="Notification-icon"><Inbox /></li>
            <li className="nav_person">
              <div className="person_icon"><MyProfile /></div>
            </li>
            <li className={mobMenuOpen ? 'phone_menuicon' : 'phone_menuicon close'} onClick={() => setMobMenuOpen(!mobMenuOpen)}>{mobMenuOpen ? <CloseIcon /> : <MenuIcon />}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

// Return the component for use.
export default NavBar
