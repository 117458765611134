import React, { memo } from 'react'
import { Button } from '@mui/material'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import { facetNames, excludeItems, miscConfig, customLabel } from '../../Search/SearchConfig'
import CheckboxFacetField from './CheckboxFacetField'
import SwitchFacetField from './SwitchFacetField'
import { DateRangeFacetField } from './DateFacetField'

// Build and export the component.
export function FacetFields ({ entityType, facetsData, hideFacets, onChange, params }) {
  // Preparing facet fields
  if (Object.prototype.hasOwnProperty.call(facetNames, entityType)) {
    const facetFiltersConfig = facetNames[entityType]
    const facetFiltersKey = Object.keys(facetFiltersConfig).filter(el => !hideFacets.includes(el))

    // Clear all filter applied.
    const ClearAllFilters = () => {
      // Fetch all default params.
      const defaultParams = new Set(miscConfig.facetPersistentParams)
      // Look for extra param apart from default.
      const extraParams = Object.keys(params).filter(param => !defaultParams.has(param))
      // Return clear filter button if there are some extra params.
      return extraParams.length > 0
        ? (<Button
          variant="outlined"
          size="small"
          color="secondary"
          style={{ width: '100%', marginBottom: '10px' }}
          onClick={() => onChange('clearAllFilters')('yes')}
          startIcon={<ClearAllIcon />}
          className="hidden"
        >
          {miscConfig.facetClearAll}
        </Button>)
        : null
    }
    // Return the output.
    // Loop through all facet filter keys.
    return (
      <div className={`facetdetails ${entityType}`}>
        <ClearAllFilters />
        {
          facetFiltersKey.map(key => {
            const fieldConfig = facetFiltersConfig[key]
            let currentFacetData = ''
            let labelValues = []
            // If current key exist in object.
            if (Object.prototype.hasOwnProperty.call(facetsData, key)) {
              currentFacetData = facetsData[key].options
              if (Object.prototype.hasOwnProperty.call(excludeItems, key)) {
                currentFacetData = currentFacetData.filter(item => !excludeItems[key].includes(item))
              }
              // Do numeric sort if data type set as number or normal sort.
              if (Object.prototype.hasOwnProperty.call(facetFiltersConfig[key], 'dataType') && facetFiltersConfig[key].dataType === 'number') {
                currentFacetData.sort((a, b) => a - b)
              } else {
                currentFacetData.sort()
              }
              // Search in label instead of value if searchLabel key exist.
              if (Object.prototype.hasOwnProperty.call(facetFiltersConfig[key], 'searchLabel') && facetFiltersConfig[key].searchLabel === true && typeof (customLabel[key]) !== 'undefined') {
                labelValues = customLabel[key]
              }
            }
            let inputVal = []
            let facetComponent
            // Split the input val from url param.
            if (Object.prototype.hasOwnProperty.call(params, key) && typeof (params[key]) !== 'undefined') {
              inputVal = params[key].split(miscConfig.multiValueSeparator)
            }
            // Invoke different sub components based on type.
            switch (fieldConfig.type) {
              case 'checkboxes':
                if (fieldConfig?.displayType === 'switch') {
                  if (!currentFacetData.length) {
                    currentFacetData = [fieldConfig?.defaultVal || 'yes']
                  }
                  facetComponent = <SwitchFacetField key={key} fieldName={key} fieldLabel={fieldConfig.label} options={currentFacetData} onChange={onChange} defaultChecked={inputVal} />
                } else {
                  facetComponent = <CheckboxFacetField key={key} fieldName={key} fieldLabel={fieldConfig.label} options={currentFacetData} onChange={onChange} defaultChecked={inputVal} labelValues={labelValues} entityType={entityType} />
                }
                break
              case 'dateField':
                facetComponent = <DateRangeFacetField key={key} fieldName={key} fieldLabel={fieldConfig.label} onChange={onChange} defaultValue={inputVal} />
                break
              default:
                facetComponent = ''
                break
            }
            return facetComponent
          })}
      </div>
    )
  } else {
    return <div />
  }
}

// Export field by Memo to render multiple times.
export default memo(FacetFields, (prev, nextP) => {
  const prevObjArr = []
  const nextObjArr = []
  const chkPropTypeVals = ['object', 'array', 'string', 'number']
  Object.keys(prev).forEach(p => {
    chkPropTypeVals.indexOf(typeof prev[p]) > -1 && prevObjArr.push(prev[p])
  })
  Object.keys(nextP).forEach(p => {
    chkPropTypeVals.indexOf(typeof nextP[p]) > -1 && nextObjArr.push(nextP[p])
  })
  return JSON.stringify(prevObjArr) === JSON.stringify(nextObjArr)
})
