import React from 'react'
import { Card, CardMedia, CardContent, CardActionArea, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

// Overwrite some styles and add some new as required.
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
    marginTop: 14
  },
  media: {
    height: 160
  }
})

// Generate and export the component.
export default function NavigationCard ({ title, description, redirectLink, cardImage }) {
  const classes = useStyles()
  const history = useNavigate()

  // Return the output.
  // Check for card image exist.
  return (
    <Card className={classes.root} id='navCard'>
      <CardActionArea onClick={() => {
        // Generate and redirect to search page.
        const redirectURL = `/search${redirectLink}`
        history(redirectURL)
      }}
      >
        {
          cardImage &&
          <CardMedia
            component='img'
            alt='navigational card image'
            image={cardImage}
            className={classes.media}
          />
        }
        <CardContent>
          <Typography gutterBottom variant='h5' component='h3'>
            {title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
