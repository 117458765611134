import { useRef, useMemo, memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
// Set cursor point.
const style = {
  cursor: 'move'
}
// Set drag item css.
const draggingStyle = {
  borderColor: 'transparent',
  color: 'transparent',
  backgroundColor: '#6B6A771A'
}
export const Card = memo(function Card ({ id, text, index, moveCard }) {
  // Set type.
  const ItemTypes = {
    CARD: 'card'
  }
  const ref = useRef(null)
  // Set drop item.
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover (item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
  // Set dragging item.
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })
  // Set dropped item.
  drag(drop(ref))
  // Set dragging css.
  const backgroundColor = isDragging ? draggingStyle : null
  const containerStyle = useMemo(() => ({ ...style, ...backgroundColor }), [backgroundColor])
  // Return the component for use.
  return (
    <div className='group_items' ref={ref} style={containerStyle} data-handler-id={handlerId} >
      {text} <DragIndicatorIcon /></div >
  )
})
