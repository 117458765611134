import React from 'react'
import DefaultItems from './DefaultItems'
import './set-group-view.scss'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const SetGroupItemsOrder = ({ isApplication, gid, groupContent, setFeedbackMessage, crumbs }) => {
  // Set the application page flag.
  const isApplicationPage = isApplication === 'application'
  // Return the component for use.
  return (
    <div className='set-group-view-wrapper'>
      <div className="setgroup-content_wrapper">
        <div className="content_heading">Set {isApplicationPage ? 'Application' : 'Group'} View</div>
        <div className="content_subheading">Drag and drop to edit the order of your {isApplicationPage ? 'applications' : 'groups'}.</div>
      </div>
      <div className="group_order_wrapper">
        <DndProvider backend={HTML5Backend}>
          <DefaultItems isApplicationPage={isApplicationPage} gid={gid} groupContent={groupContent} setFeedbackMessage={setFeedbackMessage} crumbs={crumbs} />
        </DndProvider>
      </div>
    </div>
  )
}
// Export the component for use.
export default SetGroupItemsOrder
