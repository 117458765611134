import React, { useContext, useState, useEffect } from 'react'
// Import the required assets and images.
import personMultiIcon from '../../../../images/icon-people.svg'
import appStatistics from '../../../../images/icon-application.svg'
import '../sass/component/page-header.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Alert } from '@mui/material'
import { constants } from '../../../../lib'
import ActionsMenu from '../../Group/ActionsMenu'
import ContentActionMenu from '../../Application/ContentActionMenu'
import { Link } from 'react-router-dom'
import { NavBarContext } from '../../../../contexts/NavBarContext'
import { FavoriteButton } from './FavoriteButton'
import DeleteIcon from '@mui/icons-material/Delete'

// Defining the group header for the nextGen.
// this component have
// - Breadcrumb.
// - Page info - Title, app count, user count.
const PageHeader = ({ title, appCount, ownerGroupId, memberCount, lifeCycle, groupIsLive, id, setGroupShouldReload, isContentLoading, isRespOrAccount, entity, breadCrumb, userFavorites, addOrRemoveFavorite, feedback, gid }) => {
  // NavBarContext method.
  const { setUrlPath } = useContext(NavBarContext)
  // Set feedback message.
  const [feedbackMessage, setFeedbackMessage] = useState()

  // Set feedbackMessage from local storage if it exists.
  useEffect(() => {
    if (localStorage.getItem('feedbackMessage')) {
      setFeedbackMessage({ ...JSON.parse(localStorage.getItem('feedbackMessage')), icon: <DeleteIcon /> })
      // Remove the feedbackMessage from localStorage after it is already retireved.
      localStorage.removeItem('feedbackMessage')
    }
  }, [])

  // Set url path for navbar.
  useEffect(() => {
    if (breadCrumb && breadCrumb?.length > 1) {
      setUrlPath(breadCrumb[1]?.url?.path)
    }
  }, [breadCrumb, setUrlPath])

  // Set feedbackMessage
  useEffect(() => {
    if (feedback?.message) {
      setFeedbackMessage(feedback)
    }
  }, [feedback])

  useEffect(() => {
    const timer = setTimeout(() => setFeedbackMessage({}), constants.misc.favoritesLimit.feedbackMessageTimeout * 1000)
    return () => clearTimeout(timer)
  }, [feedbackMessage])

  return (
    <div className="page_header_wrapper">
      {feedbackMessage?.message && <div className="fab_details_alert">
        <Alert severity={feedbackMessage.type} icon={feedbackMessage?.icon} >
          {feedbackMessage?.message} &nbsp;
          {feedbackMessage.section === 'favorite' && ((feedbackMessage.type !== 'error') ? <Link to='/my-favorites'>My Favorites</Link> : (<span><Link to='/my-favorites'>Manage My Favorites</Link> to add more.</span>))}
        </Alert>
      </div>}
      <div className="breadcrumb_section_wrapper">
        <ul className="breadcrumb_section">
          {breadCrumb && breadCrumb?.map((item, index) => (
            <li key={index} className="breadcrumb_item">{index < breadCrumb.length - 1 ? <><Link to={item?.url?.path}>{item?.text}</Link><span className="breadcrumb_icon"><ChevronRightIcon /></span></> : item?.text}</li>
          ))}
        </ul>
      </div>
      <div className="page_header_inner">
        <div className="page_header_heading">{title}</div>
        <div className="count_action_wrapper">
          <div className="page_header_count data_count_innerleft">
            {lifeCycle !== constants.misc.retiredLifeCycle && groupIsLive && entity === 'Group' && <>
              {(memberCount || memberCount === 0 || memberCount === null) && <div className="data_count"><img src={personMultiIcon} alt={constants.misc.uniqueUserAssociatedAlt} title={constants.misc.uniqueUserAssociatedAlt} />{memberCount || 0}</div>}
              {(appCount || appCount === 0 || appCount === null) && <div className="data_statics"><img src={appStatistics} alt={constants.misc.uniqueAppAssociatedAlt} title={constants.misc.uniqueAppAssociatedAlt} />{appCount || 0}</div>}
            </>}
            {entity !== 'Group' && <>
              {(memberCount || memberCount === 0 || memberCount === null) && <div className="data_count"><img src={personMultiIcon} alt={constants.misc.uniqueUserAssociatedAlt} title={constants.misc.uniqueUserAssociatedAlt} />{memberCount || 0}</div>}
            </>}
          </div>
          <FavoriteButton userFavorites={userFavorites} addOrRemoveFavorite={addOrRemoveFavorite} id={id} title={title} assetType={entity === 'Group' ? 'group' : 'node'} gid={gid} />
          {(entity === 'Group' && lifeCycle !== constants.misc.retiredLifeCycle && groupIsLive) && <ActionsMenu id={id} setGroupShouldReload={setGroupShouldReload} isContentLoading={isContentLoading}
            isRespOrAccount={isRespOrAccount} setFeedbackMessage={setFeedbackMessage} />
          }
          {(isRespOrAccount && entity !== 'Group') && <ContentActionMenu ownerGroupId={ownerGroupId} id={id} gid={gid} breadCrumb={breadCrumb} isContentLoading={isContentLoading} isRespOrAccount={isRespOrAccount} />}
        </div>
      </div>
    </div>
  )
}

// Return the component for use.
export default PageHeader
