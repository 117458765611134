import React from 'react'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// Export SideNav Data.
export default function SideNavContentTypes ({ contentType, number, link }) {
  // Set history.
  const history = useNavigate()
  // Return SideNav Data.
  return (
    <div className='content-type-item-wrapper'>
      <Typography variant='h6'>
        {contentType}
      </Typography>
      <Button
        className='redirect-button'
        onClick={() => {
          history(link)
          window.location.reload()
        }}
      >
        <Typography variant='h5'>{number}</Typography>
      </Button>
    </div>
  )
}
