// Import various components.
import React, { useEffect, useState } from 'react'
import { constants } from '../../../lib'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import Pagination from '@mui/material/Pagination'
import { Link, useLocation } from 'react-router-dom'
import { Alert } from '@mui/material'

const HomeBanner = () => {
  // Set max count of pagination.
  const maxCount = 5
  // Set location.
  const location = useLocation()
  // Set announcements.
  const [announcements, setAnnouncements] = useState([])
  // Set total count.
  const [totalCount, setTotalCount] = useState(0)
  // Set initial page.
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    // set clean the component.
    let initialLoad = false
    // Variables object.
    let announcementVariables = {
      homeBanner: 'yes',
      groupOrGlobal: 'global',
      status: 'active',
      pageSize: maxCount,
      page: 0
    }
    // Stringify the variable object.
    announcementVariables = JSON.stringify(announcementVariables)
    // Prepare announcement query.
    const announcementQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.announcementListQuery}&variables=${announcementVariables}`
    // Fire the query.
    queryContent(announcementQuery)
      .then(response => {
        if (initialLoad) return
        setAnnouncements(response.data.announcementsList.results)
        setTotalCount(response?.data?.announcementsList?.results?.length || 0)
      })
      .catch(error => console.log(error))
    // clean component.
    return () => { initialLoad = true }
  }, [location.pathname])

  // Function to handle change of page number.
  const handleChange = (_, value) => {
    setPageNumber(value)
  }
  // Return JSX.
  if (totalCount) {
    return (
      <Alert className="home_alert_warning" severity="warning">
        <div className='home_banner'>
          <div className='announcements_list'>
            <span>{announcements[pageNumber - 1].title} </span><Link className='viewfull' to={`/inbox/announcements/global/${announcements[pageNumber - 1].nid}`}> View Full Announcement </Link>
          </div>
          <div className='Pagination'>
            <div className='total'>{pageNumber} / {totalCount}</div>
            <Pagination count={totalCount} page={pageNumber} onChange={handleChange} />
          </div>
        </div>
      </Alert>
    )
  } else {
    return null
  }
}
// Export the component for use.
export default HomeBanner
