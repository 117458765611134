import React, { useState, useEffect, useContext } from 'react'
import { constants } from '../../lib'
import { Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import ContentDetails from '../ContentDetails'
import Loader from '../PageLoader'
import { CTErrorPage, emailNoAccess } from '../ContentDetails/ContentPages/CTHelpers/CTErrorPage'
import { UserContext } from '../context/UserContext'
import hljs from 'highlight.js'
import 'highlight.js/styles/base16/atelier-forest-light.css'
import './ckeditor_style.scss'
import Group from '../NextGen/Group'
import NotFound from '../NotFound'

const ContentOrGroupDetails = () => {
  // Set location.
  const location = useLocation()
  // Set history.
  const history = useNavigate()
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set all data.
  const [data, setData] = useState({})
  // Set dataType.
  const [dataType, setDataType] = useState()
  // Set routeAccess.
  const [routeAccess, setRouteAccess] = useState(200)
  // Set code block count.
  const [codeBlockFound, setCodeBlockFound] = useState(0)
  // Fetch userRoles from UserContext.
  const { userName, otherDetails } = useContext(UserContext)

  useEffect(() => {
    if (document.querySelectorAll('pre code').length && codeBlockFound === 0) {
      document.querySelectorAll('pre code').forEach(el => {
        el.innerHTML = el.textContent
        hljs.highlightElement(el)
      })
      setCodeBlockFound(document.querySelectorAll('pre code').length)
    }
  })

  useEffect(() => {
    const getResolvedId = async path => {
      try {
        setIsLoading(true)
        // Check if unknown URL is a content or group.
        let resolvedId = await fetch(`${process.env.REACT_APP_TARGET_URL}/graphql`,
          {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({
              queryId: constants.drupalQueryIds.groupOrContentIdResolverQuery,
              variables: JSON.stringify({ path })
            })
          })
        resolvedId = await resolvedId.json()
        // Allow only group and node.
        const allowedEntities = ['group', 'node']
        if (!resolvedId?.data?.route || !allowedEntities.includes(resolvedId?.data?.route?.entity?.entityType)) {
          // Check if route is 403 or 404 for this user.
          fetch(`${process.env.REACT_APP_TARGET_URL}${path}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
          }).then(res => {
            if (res.status === 403) {
              setRouteAccess(res.status)
            } else {
              setRouteAccess(404)
            }
          }).catch(() => {
            setRouteAccess(404)
          }).finally(() => setIsLoading(false))
        } else {
          setRouteAccess(200)
          if (resolvedId?.data?.route?.entity?.entityType === 'group') {
            setData({
              id: resolvedId?.data?.route?.entity?.entityId
            })
            setDataType('group')
          } else if (resolvedId?.data?.route?.entity?.entityType === 'node') {
            setData({
              id: resolvedId?.data?.route?.entity?.entityId,
              type: resolvedId?.data?.route?.entity?.entityBundle
            })
            setDataType('content')
          } else {
            setRouteAccess(404)
          }
          setIsLoading(false)
        }
      } catch (err) {
        console.error(err.message)
      }
    }
    getResolvedId(location.pathname)
    setCodeBlockFound(0)
  }, [history, location.pathname])
  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Generate no access content if 403.
  if (routeAccess === 403) {
    // Generate proper user information.
    const userInfo = userName || otherDetails.name
    // Current page url.
    const pageURL = window.location.href
    // Generate no access html.
    const message = (
      <div className='error-page-messages'>
        <Typography dangerouslySetInnerHTML={{ __html: constants.errors.noAccess }} align='center' onClick={() => emailNoAccess(pageURL, userInfo)} />
      </div>
    )
    return !isLoading && (
      <CTErrorPage ct="Access Forbidden" message={message} />
    )
  }

  // Generate not found content if 404.
  if (routeAccess === 404) {
    return !isLoading && <NotFound />
  }

  // Return ContentOrGroupDetails.
  return !isLoading && (
    <>
      {
        (dataType === 'group'
          ? <Group match={data} />
          : <ContentDetails match={data} />
        )
      }
    </>
  )
}

export default ContentOrGroupDetails
