import React from 'react'
import { Link } from 'react-router-dom'
// Import the required assets and images.
import '../sass/component/home-connectIntelligence.scss'
import personMultiIcon from '../../../../images/icon-people.svg'
import appStatistics from '../../../../images/icon-application.svg'
import { constants } from '../../../../lib'

// Defining the root sector cards for the nextGen.
// This component have sector color, title, description app and user count fetched from backend.
const ConnectIntelligenceCard = ({ data }) => {
  return (
    <div className="col-4">
      <Link to={data?.url?.path} >
        <div className="vew-section-wrapper" >
          <div className="bg_view_data" style={data?.rootSectorColor && { backgroundColor: data.rootSectorColor }}></div>
          <div className="data_heading">{data?.title}</div>
          <div className="data_text" dangerouslySetInnerHTML={{ __html: data?.description?.value ? data?.description?.value : constants.filter.none }}></div>
          <div className="data_count-wrapper">
            <div className="data_count"><img src={personMultiIcon} alt={constants.misc.uniqueUserAssociatedAlt} title={constants.misc.uniqueUserAssociatedAlt} />{data?.userAssociated || 0}</div>
            <div className="data_statics"><img src={appStatistics} alt={constants.misc.uniqueAppAssociatedAlt} title={constants.misc.uniqueAppAssociatedAlt} />{data?.appAssociated || 0}</div>
          </div>
        </div>
      </Link>
    </div>
  )
}

// Return the component for use.
export default ConnectIntelligenceCard
