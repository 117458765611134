import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Tab, Tabs, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import itemImage from '../../images/training-resources.png'
import { constants } from '../../lib'
import { UserContext } from '../context/UserContext'
import Loader from '../PageLoader'
import EmbedExternalUrl from '../EmbedExternalUrl'

// Configure the tab style except last one.
const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    borderRight: '1px solid grey',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)

// Configure the tab style of last one.
const LastTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)

// Build the component.
const Dashboard = () => {
  const CTName = 'My Dashboard'
  // Define the tabs.
  const pageTabs = { global: 0, business: 1, functional: 2, location: 3, product: 4 }
  const location = useLocation()
  const history = useNavigate()
  // Get the location hash.
  const parsedHash = Object.keys(queryString.parse(location.hash))
  let initialTab = 0
  if (parsedHash.length && typeof (pageTabs[parsedHash[0]]) !== 'undefined') {
    initialTab = pageTabs[parsedHash[0]]
  }
  // Set the loading state.
  const [isLoading, setIsLoading] = useState(true)
  // Set the selected tab state.
  const [selectedTab, setSelectedTab] = useState(initialTab)
  // Set the global dashboard existence state.
  const [hasGlobal, setHasGlobal] = useState(false)
  // Set the business process dashboard existence state.
  const [hasBusinessProcess, setHasBusinessProcess] = useState(false)
  // Set the functional dashboard existence state.
  const [hasFunctional, setHasFunctional] = useState(false)
  // Set the location dashboard existence state.
  const [hasLocation, setHasLocation] = useState(false)
  // Set the product team dashboard existence state.
  const [hasProductTeam, setHasProductTeam] = useState(false)
  // Set the product team dashboard existence state.
  const [dashboardExist, setDashboardExist] = useState(false)

  // Handle the tab change.
  const handleChange = (event, newValue) => {
    if (initialTab !== newValue) {
      initialTab = newValue
      location.hash = Object.keys(pageTabs).find(key => pageTabs[key] === newValue)
      history(location)
    }
  }

  // Set the tab based on location hash.
  useEffect(() => {
    setSelectedTab(initialTab)
  }, [initialTab])

  // Fetch the required data from user context.
  const { userName, otherDetails } = useContext(UserContext)
  // Prepare the backend url.
  const cfUrl = `${constants.misc.cloudFrontUrl}${process.env.REACT_APP_ENV}/users/${otherDetails.name.toLowerCase()}/`
  // Prepare all dashboard urls.
  const dashboardUrls = {
    global: `${cfUrl}global.groups.html`,
    business: `${cfUrl}business.groups.html`,
    functional: `${cfUrl}functional.groups.html`,
    location: `${cfUrl}location.groups.html`,
    product: `${cfUrl}product.groups.html`
  }

  // Email setup for non existence user dashboard.
  const emailUserDashboardNotExist = () => {
    // Prepare the user info
    const userInfo = userName || otherDetails.name
    // Prepare the email subject.
    const subject = 'Inquiry into CIA Personal Dashboards'
    // Prepare the email body.
    const body = `Hello CIA Support Team!\n\nI am interested in getting a dashboard for my activity. When viewing I don't see a designated dashboard. Can you help?\n\nThanks!\n${userInfo}`
    // Set the entire uri.
    let uri = `mailto:${constants.contactEmail}?subject=`
    uri += encodeURIComponent(subject)
    uri += '&body='
    uri += encodeURIComponent(body)
    // Send to email client.
    window.location.href = uri
  }

  // Prepare the dashboard not exist output.
  const DashBoardNotExist = useCallback(() => (
    <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: constants.errors.userDashboardNotExist }} onClick={emailUserDashboardNotExist} />
  ), [])

  // Set each dashboard existence status.
  useEffect(() => {
    if (otherDetails.name) {
      let pageLoadCount = 0
      let firstTab = 5
      // Generate the time stamp to invalid cloudfront cache.
      const dt = new Date()
      const tm = dt.getTime()
      // Set dashboard existence of each dashboard.
      const setDashboardExistence = (type, status) => {
        switch (type) {
          case 'global':
            setHasGlobal(status)
            break
          case 'business':
            setHasBusinessProcess(status)
            break
          case 'functional':
            setHasFunctional(status)
            break
          case 'location':
            setHasLocation(status)
            break
          case 'product':
            setHasProductTeam(status)
            break
          default:
            break
        }
      }

      // Loop through all dashboard url to get the existence status.
      Object.keys(dashboardUrls).forEach(key => (
        fetch(dashboardUrls[key] + '?tm=' + tm, {
          method: 'GET',
          mode: 'cors'
        })
          .then(res => {
            if (parseInt(res.status) === 200) {
              setDashboardExistence(key, true)
              setDashboardExist(true)
              if (firstTab > pageTabs[key]) {
                firstTab = pageTabs[key]
              }
            } else {
              setDashboardExistence(key, false)
            }
          })
          .catch(() => setDashboardExistence(key, false))
          .finally(() => {
            pageLoadCount++
            // Stop loader based on page load count 5 as all loaded.
            if (pageLoadCount === 5) {
              if (firstTab < 5) {
                handleChange('', firstTab)
              }
              setIsLoading(false)
            }
          })
      ))
    }
  }, [otherDetails.name])

  // If page in loading state.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Return the entire output once out put loaded.
  return !isLoading && (
    <div className='content-home'>
      <div className='content-company-name'>
        <img className='content-image' src={itemImage} alt={CTName} />
        <div display='flex' flex-direction='column'>
          <div className='main-title'>
            {CTName}
          </div>
        </div>
      </div>
      {dashboardExist
        ? (<>
          <Tabs variant='fullWidth' value={selectedTab} onChange={handleChange} indicatorColor='primary' className="tab-bottom-style">
            {hasGlobal ? <StyledTab label='Global' /> : <StyledTab label='Global' className="tab-hide" />}
            {hasBusinessProcess ? <StyledTab label='Business Process' /> : <StyledTab label='Business Process' className="tab-hide" />}
            {hasFunctional ? <StyledTab label='Functional' /> : <StyledTab label='Functional' className="tab-hide" />}
            {hasLocation ? <StyledTab label='Location' /> : <StyledTab label='Location' className="tab-hide" />}
            {hasProductTeam ? <LastTab label='Product Team' /> : <StyledTab label='Product Team' className="tab-hide" />}
          </Tabs>
          {selectedTab === 0 &&
            <div className='content-home-wrapper content-data'>
              <div className='content-home-info--full-column'>
                <Typography className="body-embed-url global-dashboard">
                  {hasGlobal ? <EmbedExternalUrl url={dashboardUrls.global} /> : <DashBoardNotExist />}
                </Typography>
              </div>
            </div>
          }
          {selectedTab === 1 &&
            <div className='content-home-wrapper content-data'>
              <div className='content-home-info--full-column'>
                <Typography className="body-embed-url business-process-dashboard">
                  {hasBusinessProcess ? <EmbedExternalUrl url={dashboardUrls.business} /> : <DashBoardNotExist />}
                </Typography>
              </div>
            </div>
          }
          {selectedTab === 2 &&
            <div className='content-home-wrapper content-data'>
              <div className='content-home-info--full-column'>
                <Typography className="body-embed-url functional-dashboard">
                  {hasFunctional ? <EmbedExternalUrl url={dashboardUrls.functional} /> : <DashBoardNotExist />}
                </Typography>
              </div>
            </div>
          }
          {selectedTab === 3 &&
            <div className='content-home-wrapper content-data'>
              <div className='content-home-info--full-column'>
                <Typography className="body-embed-url location-dashboard">
                  {hasLocation ? <EmbedExternalUrl url={dashboardUrls.location} /> : <DashBoardNotExist />}
                </Typography>
              </div>
            </div>
          }
          {selectedTab === 4 &&
            <div className='content-home-wrapper content-data'>
              <div className='content-home-info--full-column'>
                <Typography className="body-embed-url product-team-dashboard">
                  {hasProductTeam ? <EmbedExternalUrl url={dashboardUrls.product} /> : <DashBoardNotExist />}
                </Typography>
              </div>
            </div>
          }
        </>)
        : (
          <div className='content-home-wrapper content-data'>
            <div className='content-home-info--full-column'>
              <Typography className="body-embed-url product-team-dashboard">
                <DashBoardNotExist />
              </Typography>
            </div>
          </div>)
      }
    </div >
  )
}

// Export the component for use.
export default Dashboard
