import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LaunchIcon from '@mui/icons-material/Launch'
import Loader from '../../../PageLoader'
import { constants } from '../../../../lib'
import { queryContent } from '../../../../components/ContentDetails/ContentPages/CTHelpers/CheckAccess'
import '../sass/component/announcement-card.scss'
import ModalForms from './ModalForms'

const GlobalGroupAnnouncement = ({ globalOrGroupId, setFeedbackMessage, setAnnouncementsCount, announcementsCount, isMember, isRespOrAccount }) => {
  // Set params.
  const pageType = globalOrGroupId === 'global' ? 'global' : 'group'
  // Set query string for group
  let qString = ''
  // Set url part.
  let pageUrlPart = globalOrGroupId
  if (globalOrGroupId !== 'global') {
    qString = `?gids=${globalOrGroupId}`
    pageUrlPart = 'my-groups'
  }
  // Set infinite scrolling stop.
  const [hasMoreAnnouncements, setHasMoreAnnouncements] = useState(true)
  // Set announcements.
  const [announcements, setAnnouncements] = useState([])
  // Set pageNumber.
  const [pageNumber, setPageNumber] = useState(0)
  // set data count from fetch data.
  const [countOfAnnouncements, setCountOfAnnouncements] = useState(0)
  // Set shouldReload.
  const [shouldReload, setShouldReload] = useState(false)
  // Set webFormOpen.
  const [webFormOpen, setWebFormOpen] = useState(false)
  // Set fetch announcements data.
  const fetchAnnouncementsData = () => {
    // Set offset initial value.
    if (announcements.length >= countOfAnnouncements) {
      // Set infinite scrolling stop.
      setHasMoreAnnouncements(false)
    } else {
      // Set offset value updating.
      setPageNumber(pageNumber + 1)
    }
  }

  useEffect(() => {
    // Initiated.
    let isMounted = true
    if (hasMoreAnnouncements && globalOrGroupId) {
      // Set initial payload.
      const globalGroup = globalOrGroupId === 'global' ? 'groupOrGlobal' : 'groupIds'
      // Variables object.
      let announcementVariables = {
        [globalGroup]: globalOrGroupId,
        status: 'active',
        pageSize: constants.misc.defaultInboxRecordCount,
        page: pageNumber
      }
      // Stringify the variable object.
      announcementVariables = JSON.stringify(announcementVariables)
      // Prepare announcement query.
      const announcementQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.announcementListQuery}&variables=${announcementVariables}`
      // Call the query.
      queryContent(announcementQuery)
        .then(response => {
          if (isMounted) {
            const totalCount = parseInt(response.data.announcementsList.count)
            setCountOfAnnouncements(totalCount)
            if (pageNumber > 0) {
              setAnnouncements(prevAnnouncements => [...prevAnnouncements, ...response.data.announcementsList.results])
            } else {
              setAnnouncements(response.data.announcementsList.results)
            }
            setAnnouncementsCount(totalCount)
          }
        })
        .catch(e => console.error(e.message))
        .finally(() => setShouldReload(false))
    }
    // Return to avoid memory leak.
    return () => { isMounted = false }
  }, [pageNumber, hasMoreAnnouncements, globalOrGroupId, shouldReload])

  // Return blank output if no announcements.
  if (!announcementsCount && (pageType === 'global' || (pageType === 'group' && !isRespOrAccount))) {
    return null
  }

  // Return output.
  return (
    <div className={`${pageType}_announcement announcement_wrapper`}>
      {webFormOpen && <ModalForms open={webFormOpen} setOpen={setWebFormOpen}
        url={`${process.env.REACT_APP_TARGET_URL}${constants.misc.webFormUrls.addAnnouncement}?group_id=${globalOrGroupId}`}
        setShouldReload={setShouldReload} setMessage={setFeedbackMessage}
      />}
      <div className={`announcement_title count_${announcementsCount}`}>{pageType} announcements</div>
      {announcementsCount > 0 &&
        <div>
          <div className="announcement_list_wrapper" id="scrollableAnnSection">
            <InfiniteScroll dataLength={announcements.length} next={fetchAnnouncementsData} hasMore={hasMoreAnnouncements} loader={<Loader type={countOfAnnouncements ? 'Linear' : ''} />} scrollableTarget='scrollableAnnSection'>
              {announcements.map(item => {
                return (
                  <div className="announcement_list" key={item.nid}>
                    <div className='date'>{item.entityChanged}</div>
                    {item.fieldPriority && item.fieldPriority[0] && <div className='priority'>
                      <ErrorOutlineIcon /> High Priority
                    </div>}
                    <div className='title'>{item.title}</div>
                    <div className="announcement_message" dangerouslySetInnerHTML={{ __html: item.body && item.body.value }} />
                    <Link className='viewfull' to={`/inbox/announcements/${pageUrlPart}/${item.nid}`}>View Full Announcement </Link>
                  </div>
                )
              })
              }
            </InfiniteScroll>
          </div>
        </div>
      }
      {announcementsCount > 0 && (pageType === 'global' || isMember) &&
        <div className="announcement_viewall"><Link to={`/inbox/announcements/${pageUrlPart}${qString}`}>View All</Link></div>}
      {pageType === 'group' && isRespOrAccount &&
        <div className='create_announcement'>
          <Button aria-haspopup='true' variant='outlined' startIcon={<LaunchIcon />} onClick={() => setWebFormOpen(true)}>
            New Group Announcement
          </Button>
        </div>}
    </div>
  )
}

// Export component for further use.
export default GlobalGroupAnnouncement
