import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Card, CardMedia, CardContent, CardHeader, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import StarRating from '../StarRating'
import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import './MyItemCard.scss'
// Add css to MyItemCard component.
const useStyles = makeStyles({
  myitemcard: {
    width: '23%',
    margin: '1%'
  }
})
// MyItemCard component.
const MyItemCard = ({ CardData }) => {
  // Set history.
  const history = useNavigate()
  // Set classes.
  const classes = useStyles()
  // Return MyItemCard data.
  // Components used:
  // - Card.
  // - CardContent.
  return (
    <Card elevation={4} className={classes.myitemcard}>
      <div title={CardData.title}>
        <CardHeader
          className='inbox-item-title__center'
          title={CardData.title}
        />
      </div>
      {
        CardData.cardImage &&
        <CardMedia
          className='card-img__resize card-img__clickable'
          component='img'
          alt={CardData.title}
          image={CardData.cardImage}
          onClick={() => {
            history(CardData.cardRedirect)
          }}
        />
      }
      <CardContent>
        {CardData.rating && <div className='star-rating-header'>
          <StarRating rating={CardData.rating / 2} />
          <div>{CardData.rating / 2} / 5</div>
        </div>}
        <div className='inbox-title-wrapper'>
          <Typography align='center' className='inbox-title__bold'>
            Process tied to
          </Typography>
          <Typography align='center'>
            {CardData?.processTiedToLabel && CardData?.processTiedToUrl
              ? <Link className='inbox-data__blue' to={CardData?.processTiedToUrl} title={CardData?.processTiedToLabel}>
                {CardData?.processTiedToLabel}
              </Link>
              : '--'}
          </Typography>
          <Typography align='center' className='inbox-title__bold'>
            Group tied to
          </Typography>
          <Typography align='center'>
            {CardData?.groupTiedToLabel && CardData?.groupTiedToUrl
              ? <Link className='inbox-data__blue' to={CardData?.groupTiedToUrl} title={CardData?.groupTiedToLabel}>
                {CardData?.groupTiedToLabel}
              </Link>
              : '--'}
          </Typography>
        </div>
        {CardData.userGroupRole && <Typography align='center'>Role(s): {CardData.userGroupRole}</Typography>}
        {CardData.createdAt && <Typography align='center'>Added: {
          format(zonedTimeToUtc(CardData?.createdAt, Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York'), 'MM/dd/yy hh:mm a')
        }</Typography>}
      </CardContent>
    </Card>
  )
}
// Export MyItemCard component.
export default MyItemCard
