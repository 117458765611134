import React from 'react'
// Import required component.
import '../sass/component/tags-card.scss'

// Build the component.
const TagsCard = ({ tags }) => {
  let haveTag = false
  // Check at least one tag have title.
  for (const currentTag of tags) {
    if (currentTag.entity?.title) {
      haveTag = true
      break
    }
  }
  // Return out put based on active tag present
  return (
    haveTag
      ? <div className='tags_wrapper'>
        <div className="tags_inner">
          <div className="tags_heading">Tags</div>
          <div className="tag_inner_wrapper">{tags.map(tag => tag.entity?.title && <span className="selected-tags" key={tag.entity?.tid}>{tag.entity?.title}</span>)}</div>
        </div>
      </div>
      : null
  )
}

// Export the component to be used.
export default TagsCard
