import { Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { KeyboardBackspace } from '@mui/icons-material'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import './CTErrorPage.scss'
import { constants } from '../../../../lib'
import { queryContent } from './CheckAccess'
import Loader from '../../../PageLoader'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

// Generate error page.
// Sections available
// - Header
// - Body
export const CTErrorPage = ({ ct, message }) => {
  // Set errorMessage.
  const [errorMessage, setErrorMessage] = useState(message)
  // Set sharedWith.
  const [sharedWith, setSharedWith] = useState([])
  // Set ownedBy.
  const [ownedBy, setOwnedBy] = useState()
  // Set isLoading.
  const [isLoading, setIsLoading] = useState(false)
  // Set location.
  const location = useLocation()
  const history = useNavigate()
  const params = new URLSearchParams(location.search)
  // Get gid from params.
  const gid = params.get('gid')
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    setIsLoading(true)
    queryContent(`${process.env.REACT_APP_TARGET_URL}/api/content-group-info?url_path=${location.pathname}`)
      .then(res => {
        const owningGroupId = res?.response?.owningGroup
        const associatedGroups = []
        if (res?.response?.associatedGroups) {
          Object.keys(res.response.associatedGroups).forEach(group => {
            if (parseInt(group) === owningGroupId) {
              setOwnedBy({ ...res.response.associatedGroups[owningGroupId], gid: group })
            } else {
              associatedGroups.push({ ...res.response.associatedGroups[group], gid: group })
            }
          })
          setSharedWith(associatedGroups.sort((a, b) => {
            if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
              return 1
            } else {
              return -1
            }
          }))
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsLoading(false)
        // Sending page data for tracking.
        setPageData({
          type: '403'
        })
      })
  }, [location.pathname])

  useEffect(() => {
    if (sharedWith.length || ownedBy) {
      const accessMessage = (
        <div>
          <div className='error-page-messages'>
            <Typography dangerouslySetInnerHTML={{ __html: constants.errors.contentAccessError }} align='center' />
          </div>
          <div>
            {ownedBy && <div className='link-wrapper'>
              <h4 className='link-title'>Owned By</h4>
              <div><Link to={ownedBy.groupUrl} className={(gid === null || gid === ownedBy.gid) ? 'group-link bold' : 'group-link'}>{ownedBy?.groupName}</Link></div>
            </div>
            }
            {
              sharedWith.length
                ? <div className='link-wrapper'>
                  <h4 className='link-title'>Shared With</h4>
                  {
                    sharedWith.map(group => <div key={group.gid}><Link to={group.groupUrl} className={gid === group.gid ? 'group-link bold' : 'group-link'}>{group.groupName}</Link></div>)
                  }
                </div>
                : null
            }
          </div>
        </div>
      )
      setErrorMessage(accessMessage)
    }
  }, [sharedWith, ownedBy, gid])

  // Show loader if api is loading.
  if (isLoading) {
    return <Loader />
  }

  // Return error html
  return (
    <div className='min_content_height error-page-bg'>
      <div className='header-wrapper'>
        {history.length >= 2 && <div className='back-button__clickable' onClick={() => { history(-1) }}>
          <KeyboardBackspace /> <p>Back</p>
        </div>}
        <div className='content-title error-page-title'>
          <h1>{ct}</h1>
        </div>
      </div>
      <div className='result-wrapper'>
        <div className='table-wrapper'>
          {errorMessage}
        </div>
      </div>
    </div>
  )
}

// Generate no access page.
export function makeNoAccessRows (ct, hide, userInfo) {
  return hide.map((item, index) => {
    // Return no access html
    return (
      <div className='error-page-messages' key={index}>
        <Typography align='center' dangerouslySetInnerHTML={{ __html: constants.errors.noAccess }} onClick={() => emailNoAccess(`${ct} # ${item}`, userInfo)} />
      </div>
    )
  })
}

// Build and trigger the email template for no access.
export function emailNoAccess (page, userInfo) {
  // Generate the email subject.
  const subject = 'Access Denied - Would Like to Gain Access'
  // Generate the email body.
  const body = `Hello CIA Support Team!\n\nI'm trying to access this ${page} but states I'm not able - can you help me?\n\nThanks!\n${userInfo}`
  // Generate the total uri.
  let uri = `mailto:${constants.contactEmail}?subject=`
  uri += encodeURIComponent(subject)
  uri += '&body='
  uri += encodeURIComponent(body)
  // Send to email client.
  window.location.href = uri
}
