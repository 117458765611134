import React, { useState, useCallback } from 'react'
import { Button } from '@mui/material'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import Loader from '../PageLoader'
import FullscreenImg from './../../images/icon-expand.svg'

// Configure some styles.
const hideElement = {
  display: 'none'
}
const showElement = {
  display: 'inline-flex'
}

// Embed any external url as received with full screen button.
const EmbedExternalUrl = ({ url, buttonTitle, frameHeight }) => {
  const handle = useFullScreenHandle()
  // Set element style.
  const [elementStyle, setElementStyle] = useState(hideElement)
  // Set page loading state.
  const [pageLoading, setPageLoading] = useState(true)
  // Set iframe height.
  const iframeHeight = frameHeight || 493
  // Set iframe initial height.
  const iframeInitialHeight = window.innerHeight > iframeHeight ? iframeHeight : window.innerHeight
  // Set iframe actual height.
  const [iframeActualHeight, setIframeActualHeight] = useState(iframeInitialHeight)

  // Executed on page load.
  const pageLoaded = () => {
    setPageLoading(false)
    setElementStyle(showElement)
  }

  // Execute the on full screen change.
  const handleChange = useCallback(isFullScreen => {
    if (isFullScreen) {
      setIframeActualHeight(window.innerHeight)
    } else {
      setIframeActualHeight(iframeInitialHeight)
    }
  }, [setIframeActualHeight, iframeInitialHeight])

  if (url) {
    // If url exist.
    return (
      <>
        {pageLoading ? <Loader type="Linear" /> : null}
        <FullScreen handle={handle} onChange={handleChange}>
          <iframe
            id="embed-iframe"
            src={url}
            title="embed-iframe"
            className="embed-iframe"
            width="100%"
            height={iframeActualHeight}
            allow="geolocation; microphone; camera"
            allowtransparency="true"
            onLoad={pageLoaded}
          ></iframe>
        </FullScreen>
        {buttonTitle !== false && <Button
          style={elementStyle}
          onClick={handle.enter}
          startIcon={<img src={FullscreenImg} alt="Fullscreen Icon" />}
        >
          {buttonTitle || 'Go Fullscreen'}
        </Button>}
      </>
    )
  } else {
    // Else return blank div.
    return <div />
  }
}

// Export the component for use.
export default EmbedExternalUrl
