import React, { useContext, useEffect, useState } from 'react'
import './ContentType.scss'
import { useNavigate } from 'react-router-dom'
import ResultPreview from './Results/ResultPreview/ResultPreview'
import { KeyboardBackspace } from '@mui/icons-material'
import { Typography } from '@mui/material'
import ResultModal from './Results/ResultModal/ResultModal'
import { constants } from '../../../lib'
import { ContentSearchBar } from './ContentSearchBar/ContentSearchBar'
import SelectFacet from '../../SearchFacets/SelectFacet'
import itemImage from '../../../images/auditsDef.png'
import SideNavButtonAudits from './SideNavigation/SideNavButton/SideNavButtonAudits'
import { obtainGroups } from './CTHelpers/GroupHelper'
import { UserContext } from '../../context/UserContext'
import { checkAccess, queryContent } from './CTHelpers/CheckAccess'
import { CTErrorPage, makeNoAccessRows } from './CTHelpers/CTErrorPage'
import Loader from '../../PageLoader'
import ContentManageButton from '../contentManageButton'
import EmbedShoppingCart from '../../ShoppingCart/embedShoppingCart'

export const Audits = ({ ids }) => {
  // Set CTName value.
  const CTName = 'Audit'
  // Set history.
  const history = useNavigate()
  // Set filter.
  const [filter, setFilter] = useState({
    group: constants.filter.all,
    text: ''
  })
  // Set groups.
  const [groups, setGroups] = useState()
  // Set dataToDisplay.
  const [dataToDisplay, setDataToDisplay] = useState()
  // Set allData.
  const [allData, setAllData] = useState()
  // Set sidePreviews.
  const [sidePreviews, setSidePreviews] = useState()
  // Set shouldShowModal.
  const [shouldShowModal, setShouldShowModal] = useState(false)
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set if have availability error.
  const [notAvailable, setNotAvailable] = useState()
  // Set if has access or not.
  const [onlyNoAccess, setOnlyNoAccess] = useState(false)
  // Set if have null value error.
  const [hasNullValue, setHasNullValue] = useState(false)
  // Set selectedItem.
  const [selectedItem, setSelectedItem] = useState({})
  // Fetch userRoles from UserContext.
  const { userRoles, userName, otherDetails } = useContext(UserContext)

  // Query Calls
  useEffect(() => {
    if (!ids) {
      setHasNullValue(true)
      setIsLoading(false)
    } else {
      const uniqueIds = [...new Set(ids.split(','))]
      // Check for Access
      const promises = uniqueIds.map(i => checkAccess(i))
      const show = []
      const hide = []
      Promise.all(promises).then(accessObjs => {
        accessObjs.forEach(obj => {
          // Show data if have access.
          if (obj.access) {
            show.push(obj.item)
          } else {
            // Hide data or generate error base on code received.
            if (obj.error === 403) {
              hide.push(obj.item)
            }
            if (obj.error === 404) {
              setHasLoadingError(true)
              setIsLoading(false)
            }
          }
        })
      }).then(() => {
        // Generate proper user information.
        const userInfo = userName || otherDetails.name
        // If user have access.
        if (show && show.length > 0) {
          // Build the payload for api.
          const auditVariables = JSON.stringify({
            ids: show
          })
          // Call graphql for auditQuery.
          const auditQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.auditsQuery}&variables=${auditVariables}`
          // Query Call.
          queryContent(auditQuery)
            .then(json => {
              if (!json?.data?.nodeQuery?.count) {
                setHasLoadingError(true)
              } else {
                const entities = json.data.nodeQuery.auditEntities
                // Set DataToDisplay.
                setDataToDisplay(entities)
                // Set AllData.
                setAllData(entities)
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
          const groupsData = obtainGroups(show)
          setGroups(groupsData)
          if (hide && hide.length > 0) {
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setNotAvailable(noAccessRows)
          }
        } else {
          if (!hide || hide.length === 0) {
            setHasLoadingError(true)
            setIsLoading(false)
          } else {
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setOnlyNoAccess(true)
            setNotAvailable(noAccessRows)
            setIsLoading(false)
          }
        }
      })
    }
  }, [ids, userName, otherDetails.name])
  // End Query Calls.
  useEffect(() => {
    // Filter for groups.
    switch (filter.group) {
      case constants.filter.all:
        setDataToDisplay(allData)
        break
      case constants.filter.none:
        setDataToDisplay(allData.filter(item => !item.group))
        break
      default:
        setDataToDisplay(allData.filter(item => item.group === filter.group))
        break
    }
    // Filter for text.
    if (filter.text.length > 0) {
      setDataToDisplay(data => (data.filter(item => JSON.stringify(item).toLowerCase().indexOf(filter.text.toLowerCase()) > -1)))
    }
  }, [allData, filter])

  const filterType1 = [
    constants.filter.all,
    constants.filter.none,
    ...(new Set(allData?.reduce((acc, entity) => {
      if (entity.group) {
        acc.push(entity.group)
      }
      return acc
    }, [])))
  ]

  const filterData = category => value => {
    setFilter(data => ({
      ...data,
      [category]: value
    }))
  }

  // Pages used for Audit Modal.
  const createPages = item => {
    return [
      {
        topics: [{
          title: 'Base Information',
          fields: [
            { title: 'Published', input: item?.auditPublishedDate?.date },
            { title: 'Group', input: item?.groups.join(', ') },
            { title: 'Phase', input: item?.phase?.entity?.title },
            { title: 'Scope', input: item?.auditAnticipatedScope },
            { title: 'Type', input: item?.auditType?.entity?.title },
            { title: 'Reason', input: item?.auditReason?.entity?.title },
            { title: 'Universe', input: item?.auditUniverse?.map(({ entity }) => entity?.title).join(', ') },
            { title: 'Sector', input: item?.auditTargetSector?.entity?.title },
            { title: 'Therapeutic Area', input: item?.therapeuticArea?.entity?.title },
            { title: 'Status', input: item.capaActionType?.entity?.title },
            { title: 'Activity ID', input: item.activityID?.map(({ entity }) => entity?.title).join(', ') },
            { title: 'Compound Name', input: item.compoundName?.map(({ entity }) => entity?.title).join(', ') }
          ]
        }]
      },
      {
        topics: [{
          title: 'System Information',
          fields: [
            { title: 'Source System', input: item?.sourceSystem?.entity?.title },
            {
              title: 'System Name / Description',
              input: item?.auditSystemNameDescription?.map(({ entity }) => entity?.title).join(', ')
            },
            { title: 'System Low Level', input: item?.systemLowLevel?.entity?.title },
            { title: 'System Mid Level', input: item?.systemMidLevel?.entity?.title },
            { title: 'System Top Level', input: item?.systemTopLevel?.entity?.title }
          ]
        },
        {
          title: 'ESP Information',
          fields: [
            { title: 'ESP Type', input: item?.espType?.map(({ entity }) => entity?.title).join(', ') },
            { title: 'ESP Activity Type', input: item?.espActivityService?.map(({ entity }) => entity?.title).join(', ') }
          ]
        }]
      },
      {
        topics: [
          {
            title: 'People Involved',
            fields: [
              { title: 'Study Sponsor', input: item?.studySponsor?.entity?.title },
              { title: 'Study Director', input: item?.studyDirector?.entity?.title },
              { title: 'Principal Investigator', input: item?.principalInvestigator?.entity?.title },
              { title: 'Lead Auditor:', input: item?.leadAuditor?.entity?.title },
              { title: 'Global Executing Company', input: item?.globalExecutingCompany?.entity?.title }
            ]
          },
          {
            title: 'Location Information',
            fields: [
              { title: 'Location Name', input: item?.auditLocationName?.map(({ entity }) => entity?.title).join(', ') },
              { title: 'Location Region', input: item?.region?.map(({ entity }) => entity?.title).join(', ') },
              { title: 'City', input: item?.city?.entity?.title },
              { title: 'State', input: item?.state?.entity?.title },
              { title: 'Country', input: item?.country?.entity?.title }
            ]
          }]
      }
    ]
  }
  // Display error message if null value received.
  if (hasNullValue && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.nullValue }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}s`} message={message} />
    )
  }
  // Display error message if don't have access.
  if (onlyNoAccess && !isLoading && notAvailable) {
    return (
      <CTErrorPage ct={`${CTName}s`} message={notAvailable} />
    )
  }
  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.invalidContent }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}s`} message={message} />
    )
  }
  // Create preview data for multiple audits.
  const createPreviews = data => {
    data.forEach(audit => {
      const currentGroups = []
      groups.forEach(group => {
        if (group.id === audit.nid) {
          currentGroups.push(group.group)
        }
      })
      audit.groups = currentGroups
    })
    // Return data for preview.
    // Components used:
    // - EmbedShoppingCart.
    // - ResultPreview.
    return data && data.map((item, i) =>
      <div key={i} className='result-row'>
        <EmbedShoppingCart item={item} image={itemImage} bundle="audits" />
        <ResultPreview
          title={item.title}
          nid={item.nid}
          group={item.groups}
          naturalKey={item.naturalKey}
          itemImage={itemImage}
          openModalCallback={() => {
            setSelectedItem(item)
            setShouldShowModal(true)
          }}
          bundle="audits"
        />
        <div className='button-wrappers'>
          <SideNavButtonAudits
            auditData={item}
            setSidePreviews={setSidePreviews}
          />
          {
            userRoles.administrator ||
              userRoles.contentAuthor ||
              userRoles.feedEngineer ||
              userRoles.groupAdmin
              ? <ContentManageButton nid={item.nid} />
              : <div />
          }
        </div>
      </div>

    )
  }
  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }
  // Return entire data for display purpose.
  return !isLoading && allData && (
    <div className='content-wrapper'>
      <div className='header-wrapper'>
        <div className='back-button__clickable' onClick={() => { history(-1) }}>
          <KeyboardBackspace /> <Typography>Back</Typography>
        </div>
        <div className='content-title'>
          <Typography variant='h3'>
            Audits
          </Typography>
        </div>
      </div>
      <div className='search-bar-wrapper'>
        <ContentSearchBar
          setFilter={value => setFilter(data => ({
            ...data,
            text: value
          }))}
        />
      </div>
      <div className='result-wrapper'>
        <div className='filter-wrapper--left-column'>
          <SelectFacet
            onChange={value => {
              filterData('group')(value)
            }}
            selectOptions={filterType1}
            title='Group'
          />
        </div>
        <div className='table-wrapper'>
          {createPreviews(dataToDisplay)}
          {notAvailable}
        </div>
        {/* Side Navs */}
        <div className='sidebar-wrapper--right-column'>
          {sidePreviews}
        </div>
      </div>
      {/* Modals Here */}
      {Object.keys(selectedItem).length > 0 &&
        <ResultModal
          isOpen={shouldShowModal}
          onClose={() => setShouldShowModal(false)}
          nid={selectedItem.nid}
          naturalKey={selectedItem.naturalKey}
          title={selectedItem.title}
          pages={createPages(selectedItem)}
        />}
    </div>
  )
}
