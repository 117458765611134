import React, { useState, useEffect } from 'react'
// Import necessary packages.
import { Search, ArrowDropDown } from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { withStyles } from '@mui/styles'
import { sortByLastUsed, searchEntityData } from './helpers'
import { sortAlphabetically, sortByDateAdded } from '../Assets/component/ItemSortHelpers'

// Add CSS for dropdown menu
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #F0F0F0',
    '& ul.MuiList-padding': {
      paddingTop: '12px',
      paddingBottom: '22px'
    }
  }
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))

// Add CSS for each menu item.
const StyledMenuItem = withStyles(theme => ({
  root: {
    fontFamily: 'Commissioner-Regular',
    fontSize: '14px',
    paddingLeft: '20px',
    paddingRight: '55px',
    color: '#333333',
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1C75BC'
    }
  }
}))(MenuItem)

// Build the component.
const FavoriteEntityHeader = ({ title, data, sortCriteria, handleSortCriteria, setCardData }) => {
  // Set the anchor.
  const [anchorEl, setAnchorEl] = useState(null)
  // Set searchTerm.
  const [searchTerm, setSearchTerm] = useState('')
  // Set the sort criteria.
  const selectedSortCriteria = () => {
    switch (sortCriteria) {
      case '2':
        return 'Alphabetical Z to A'
      case '3':
        return 'Date Added to My Favorites - Ascending'
      case '4':
        return 'Date Added to My Favorites - Descending'
      case '5':
        return 'Date last Used - Most recent to least'
      case '1':
      default:
        return 'Alphabetical A to Z'
    }
  }

  // Changes the cardData based on changes in sortCriteria, groupData or searchTerm.
  useEffect(() => {
    let result
    if (data) {
      switch (parseInt(sortCriteria)) {
        case 2:
          result = sortAlphabetically(data, 'desc', 'favorite')
          break
        case 3:
          result = sortByDateAdded(data, 'asc', 'favorite')
          break
        case 4:
          result = sortByDateAdded(data, 'desc', 'favorite')
          break
        case 5:
          result = sortByLastUsed(data)
          break
        case 1:
        default:
          result = sortAlphabetically(data, 'asc', 'favorite')
      }
      // Set card data as per sorted order.
      setCardData(searchEntityData(result, searchTerm))
    }
  }, [sortCriteria, data, searchTerm])

  // Handle click on menu.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  // Handle close menu item.
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Handle click on menu item.
  const handleMenuClick = value => {
    handleSortCriteria(value)
    // Close the sort menu.
    handleClose()
  }

  // Handles change in value of search input field.
  const searchHandler = e => {
    setSearchTerm(e.target.value)
  }
  // Return the output.
  return (
    <div className="fav-wrapper">
      <div className="fav_inner_left">
        {title} <span>({data?.length || 0})</span>
      </div>
      {data?.length > 0 && <div className="fav_inner_right">
        <div className="sort_by_section">
          Sort By : <span className="selected_value" onClick={handleClick}>{selectedSortCriteria()}<ArrowDropDown /></span>
          <StyledMenu id="sort-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <StyledMenuItem onClick={() => handleMenuClick('1')}>Alphabetical A to Z</StyledMenuItem >
            <StyledMenuItem onClick={() => handleMenuClick('2')}>Alphabetical Z to A</StyledMenuItem >
            <StyledMenuItem onClick={() => handleMenuClick('3')}>Date Added to My Favorites - Ascending</StyledMenuItem >
            <StyledMenuItem onClick={() => handleMenuClick('4')}>Date Added to My Favorites - Descending</StyledMenuItem>
            <StyledMenuItem onClick={() => handleMenuClick('5')}>Date last Used - Most recent to least</StyledMenuItem >
          </StyledMenu>
        </div>
        <div className="group_search">
          <div className="group_inner_search">
            <Search />
            <input type="text" placeholder={`Search ${title.toLowerCase() === 'favorite groups' ? 'Groups' : 'Applications'}`} onChange={e => searchHandler(e)}></input>
          </div>
        </div>
      </div>}
    </div>
  )
}

// Export component for further use.
export default FavoriteEntityHeader
