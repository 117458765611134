// Function to sort the data items based on the updatedAt field.
export const sortByLastUsed = data => {
  const result = data
  result.sort((a, b) => {
    if (a.updatedAt > b.updatedAt) {
      return -1
    } else {
      return 1
    }
  })
  return result
}

// Function to return data items based of the searchTerm provided.
export const searchEntityData = (data, searchTerm) => data.filter(entity => entity.title.toLowerCase().includes(searchTerm.toLowerCase()))

// Function to delete entries from node backend based on various parameters.
export const deleteEntities = async (userId, fromNode, fromDrupal, assetType, assetId) => {
  const assetIds = []
  let apiStatus = false
  // Accumulate the assetIds.
  if (assetId) {
    assetIds.push(assetId)
  } else {
    // Generate the node ids.
    if (assetType === 'node') {
      fromNode.forEach(entity => {
        if (!fromDrupal.some(item => parseInt(item.nid) === entity.assetId)) {
          assetIds.push(entity.assetId)
        }
      })
    }
    // Generate the group ids.
    if (assetType === 'group') {
      fromNode.forEach(entity => {
        if (!fromDrupal.some(item => parseInt(item.gid) === entity.assetId)) {
          assetIds.push(entity.assetId)
        }
      })
    }
  }
  // Delete favorites from db through api.
  await fetch('/api/favorites',
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        drupalUserId: userId,
        assetIds,
        assetType
      })
    })
    .then(result => result.json())
    .then(result => {
      if (result.error || result.errorMessage) {
        throw new Error()
      }
      apiStatus = true
    })
    .catch(err => {
      console.error(err)
      apiStatus = false
    })
  // Return the api status.
  return apiStatus
}
