import { UserContext } from '../components/context/UserContext'
import React, { useState, useContext, useEffect } from 'react'
import { constants } from '../lib'
import starEmpty from '../images/green-star-empty.svg'
import starRedFilled from '../images/red-star-filled.svg'
import starFilled from '../images/green-star-filled.svg'

export const useFavorites = () => {
  // Set UserFavorites.
  const [userFavorites, setUserFavorites] = useState()
  // Set toBeUndone.
  const [toBeUndone, setToBeUndone] = useState()
  // Set Feedback Message.
  const [feedbackMessage, setFeedbackMessage] = useState()
  // Fetch the user context.
  const { userId, favorites } = useContext(UserContext)
  // Function to generate favorite Message icons.
  const favoriteMessageIcon = iconSrc => <img src={iconSrc} alt="Favorite icon" />

  // Set all favorites for user.
  useEffect(() => {
    if (favorites) {
      setUserFavorites({
        node: favorites.node.data.map(item => item.assetId),
        group: favorites.group.data.map(item => item.assetId)
      })
    }
  }, [favorites])

  // Function to add or remove favorite.
  const addOrRemoveFavorite = (e, assetId, assetType, assetName, gid, setInProgress) => {
    e.preventDefault()
    let messageType = ''
    const items = userFavorites[assetType]
    if (items.includes(assetId)) {
      // Item already there, so remove it.
      messageType = 'remove'
      setUserFavorites(() => ({ ...userFavorites, [assetType]: items.filter(item => item !== assetId) }))
    } else {
      // Item not there, so add it.
      messageType = 'add'
      if (userFavorites[assetType].length >= constants.misc.favoritesLimit[assetType]) {
        setFeedbackMessage({ message: 'Favorite not added.', icon: favoriteMessageIcon(starRedFilled), type: 'error', section: 'favorite' })
        setInProgress(false)
        return
      }
      setUserFavorites(() => ({ ...userFavorites, [assetType]: [...items, assetId] }))
    }

    // Calling the API to add or remove favorite
    fetch('/api/favorites',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          drupalUserId: userId,
          assetId,
          assetType,
          groupId: gid
        })
      })
      .then(result => result.json())
      .then(result => {
        if (result.error) {
          throw new Error('Favorite not added/removed.')
        }
        if (result.data === 0) {
          throw new Error('Favorite not added.')
        }
        // Set message based on the type of operation.
        if (messageType === 'add') {
          setFeedbackMessage({ message: `Added ${assetName} to `, icon: favoriteMessageIcon(starFilled), type: 'success', operationType: 'add', section: 'favorite' })
        } else {
          setFeedbackMessage({ message: `Removed ${assetName} from `, icon: favoriteMessageIcon(starEmpty), type: 'success', operationType: 'remove', section: 'favorite' })
        }
      })
      .catch(err => {
        // If API fails or maximum limit reached then revert back the changes.
        setToBeUndone({ assetId, assetType })
        setFeedbackMessage({ message: err.message, icon: favoriteMessageIcon(starRedFilled), type: 'error', section: 'favorite' })
      })
      .finally(() => setInProgress(false))
  }

  // Function to update the favorite
  const updateFavorite = (assetId, type, code) => {
    fetch('/api/favorites',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          drupalUserId: userId,
          assetId,
          assetType: type,
          opened: code
        })
      })
      .then(res => res.json())
      .catch(err => console.error(err))
  }

  // Remove entity ids from userFavorites if it present in toBeUndone
  useEffect(() => {
    if (userFavorites && toBeUndone) {
      // Get the group and node items from favorite.
      const { group, node } = userFavorites
      // Get undone item id and type.
      const { assetType, assetId } = toBeUndone
      if (assetType === 'group') {
        if (group.includes(assetId)) {
          setUserFavorites(() => ({ ...userFavorites, group: group.filter(g => g !== assetId) }))
        } else {
          setUserFavorites(() => ({ ...userFavorites, group: [...userFavorites.group, assetId] }))
        }
      }
      if (assetType === 'node') {
        if (node.includes(assetId)) {
          setUserFavorites(() => ({ ...userFavorites, node: node.filter(n => n !== assetId) }))
        } else {
          setUserFavorites(() => ({ ...userFavorites, node: [...userFavorites.node, assetId] }))
        }
      }
    }
  }, [toBeUndone])

  // Exposing various functionality.
  return [
    userFavorites,
    addOrRemoveFavorite,
    feedbackMessage,
    setFeedbackMessage,
    updateFavorite
  ]
}
