import React, { useContext, useEffect } from 'react'
import { EventTrackingContext } from '../contexts/EventTrackingContext'

const EventTracker = () => {
  // Set page data for tracking.
  const { pageTracking, searchTracking, clickTracking, fileTracking } = useContext(EventTrackingContext)

  useEffect(() => {
    if (pageTracking) {
      // Pushing page load tracking data.
      const dataLayer = window.dataLayer || []
      dataLayer.push({ page_data: null })
      dataLayer.push(pageTracking)
    }
  }, [pageTracking])

  useEffect(() => {
    if (searchTracking) {
      // Pushing search tracking data.
      const dataLayer = window.dataLayer || []
      dataLayer.push({ event_data: null })
      dataLayer.push(searchTracking)
    }
  }, [searchTracking])

  useEffect(() => {
    if (clickTracking) {
      // Pushing click tracking data.
      const dataLayer = window.dataLayer || []
      dataLayer.push({ event_data: null })
      dataLayer.push(clickTracking)
    }
  }, [clickTracking])

  useEffect(() => {
    if (fileTracking) {
      // Pushing click tracking data.
      const dataLayer = window.dataLayer || []
      dataLayer.push({ event_data: null })
      dataLayer.push(fileTracking)
    }
  }, [fileTracking])

  return (
    <div></div>
  )
}

// Exporting the component.
export default EventTracker
