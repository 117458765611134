import React, { useState, useContext, memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Backdrop, Fade, Typography, FormControl, TextField } from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Autocomplete } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { ShoppingCartContext } from '../../contexts'
import './GroupAssociation.scss'

// Add css for the modal.
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      width: '600px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
    border: '2px solid #000099'
  },
  textbox: {
    borderRadius: '10px'
  },
  textboxwidth: {
    width: '80%'
  }
}))

const GroupAssociation = ({ visible, onCancel, groups, contentIds }) => {
  // Set Selected Group.
  const [selectedGroup, setSelectedGroup] = useState('')
  // Set error text
  const [errorText, setErrorText] = useState('')
  // Set confirmation message
  const [confirmationMsg, setConfirmationMsg] = useState('')
  // Set eventInProgress flag.
  const [eventInProgress, setEventInProgress] = useState(false)
  // Constant for Group alert note
  const groupAssociationNote = 'Note: Groups can not be associated.'
  // Fetch data from ShoppingCartContext.
  const { shoppingCartItems } = useContext(ShoppingCartContext)
  // Const  group association notes
  const groupAssociationNoteAlert = [...shoppingCartItems.keys()].length !== contentIds.length

  // Function to handle change operation.
  const handleChange = (_, value) => {
    setSelectedGroup(value)
  }
  // Function to handle Group Association
  const handleGroupAssociation = () => {
    if (!selectedGroup) {
      setErrorText('Select at least one Group for association')
    } else {
      setErrorText('')
      setEventInProgress(true)
      // Prepare payload data.
      const payload = {
        contentIds,
        gid: selectedGroup.gid
      }
      // Set URL for API.
      const url = `${process.env.REACT_APP_TARGET_URL}/api/associate-content-to-group`
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(payload)
      })
        .then(result => {
          if (result.ok) {
            return result.json()
          } else {
            throw new Error(`${result.status}`)
          }
        })
        .then(result => {
          if (!result.error) {
            setConfirmationMsg(result.response.msg)
          } else {
            throw new Error('Unexpected Error')
          }
        })
        .catch(() => setErrorText('Failed to associate the group'))
        .finally(() => setEventInProgress(false))
    }
  }
  // Get the styles.
  const classes = useStyles()

  // Prepare option data.
  const options = groups
    .reduce((acc, { gid, title, type }) => [...acc, { label: `${type.entity.title} : ${title}`, gid }], [])
    .filter((item, i, grps) => i === grps.findIndex(element => element.gid === item.gid))

  // Return the component output.
  // This modal consist of all groups as dropdown options where the user member of.
  return (
    <div>
      <Modal
        aria-labelledby="Group Association"
        aria-describedby="Group Association form"
        className={classes.modal}
        open={visible}
        onClose={onCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={visible}>
          <div className={classes.paper}>
            <div className="modal-wrapper">
              <span className="close-modal">
                <CancelRoundedIcon
                  className="cancel-icon"
                  fontSize="large"
                  onClick={onCancel}
                />
              </span>
              <div className="modal-content">
                {confirmationMsg
                  ? <Typography className="response-message" align='center' dangerouslySetInnerHTML={{ __html: confirmationMsg }} />
                  : (
                    <>
                      {errorText && <Typography className='error__center'>{errorText}</Typography>}
                      <FormControl className='formControl-wrapper'>
                        <Autocomplete
                          options={options}
                          getOptionLabel={option => option.label || ''}
                          renderInput={params => <TextField {...params} label="Select Group" variant="outlined" />}
                          onChange={handleChange}
                          value={selectedGroup}
                        />

                        <Button
                          endIcon={<PlaylistAddIcon />}
                          color='primary'
                          variant='contained'
                          className='actionButton'
                          onClick={handleGroupAssociation}
                          disabled={eventInProgress}
                        >
                          Associate
                        </Button>
                        {groupAssociationNoteAlert && <Typography align='center' className="group-footnote">{groupAssociationNote}</Typography>}
                      </FormControl>
                    </>)
                }
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
// Prototype to follow.
GroupAssociation.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  contentIds: PropTypes.array.isRequired
}
// Export the memorize component.
export default memo(GroupAssociation)
