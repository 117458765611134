import React, { useState } from 'react'
// Import the necessary packages.
import { Avatar } from '@mui/material'
import ProfileImage from '../../../../ProfileImage'
import { userAbbreviation } from '../../../../ProfileImage/utility'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

// Build the component.
export default function Member ({ person, appUserPicture, membersProfileImageData, handleAction }) {
  // Set edit member MoreVertIcon.
  const [anchorEl, setAnchorEl] = useState(null)
  // Set modal options.
  const options = ['Edit', 'Remove']
  // Set menu height.
  const ITEM_HEIGHT = 48
  // Set menu open.
  const open = Boolean(anchorEl)
  // Set menu able to click.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  // Set menu action.
  const menuAction = option => {
    setAnchorEl(null)
    handleAction(option, person)
  }

  // Return the output.
  return (
    <ul className="tabs_section tabs_rows">
      <li className="tabs_item">
        <div className="team_details">
          <div className="team_icon_left">
            <Avatar>
              <ProfileImage
                alt={userAbbreviation(person.username)}
                userPicture={appUserPicture}
                profileImage={membersProfileImageData[person.username]} />
            </Avatar>
          </div>
          <div className="team_details_right">
            <div className="user_name">{(person.displayName !== 'false') ? person.displayName : person.username}</div>
            <div className="user_info">{person.userEmail}</div>
          </div>
        </div>
      </li>
      <li className="tabs_item">{person.role}</li>
      <li className="tabs_item" dangerouslySetInnerHTML={{ __html: person.userDescription }} />
      <li className="tabs_item">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          className="businessTeamMenu"
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={menuAction}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5
            }
          }}
        >
          {options.map(option => (
            <MenuItem key={option} onClick={() => menuAction(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </li>
    </ul>
  )
}
