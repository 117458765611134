import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Typography } from '@mui/material'
import { ArrowLeft, ArrowRight, Close } from '@mui/icons-material'
import './ResultModal.scss'
import { constants } from '../../../../../lib'
import { UserContext } from '../../../../context/UserContext'

// Export the result modal.
export default function ResultModal ({ isOpen, onClose, nid, naturalKey, title, pages }) {
  // Set current body state.
  const [currentBody, setCurrentBody] = useState(pages[0])
  // Set result idx.
  const [resultIdx, setResultIdx] = useState(0)
  // Fetch the user context.
  const { userRoles } = useContext(UserContext)
  // To reset and close.
  const resetAndClose = () => {
    setCurrentBody(pages[0])
    setResultIdx(0)
    onClose()
  }

  // Set current body based on resultIdx and pages.
  useEffect(() => {
    setCurrentBody(pages[resultIdx])
  }, [resultIdx, pages])

  // Populate relevant fields.
  const populateFields = fields => {
    // Loop through all fields.
    return fields.map((field, key) =>
      <div key={key}>
        <Typography variant='div' className='field'>{field.title}</Typography>
        <Typography variant='div' className='field-item'> {field.input || constants.filter.none}</Typography>
      </div>
    )
  }

  // Populate relevant page.
  const populatePage = page => {
    // Loop through all pages.
    return page.map((topic, key) =>
      <div className='topic-wrapper' key={key}>
        <Typography variant='div' className='topic-title'>
          {topic.title}
        </Typography>
        {populateFields(topic.fields)}
      </div>
    )
  }

  // Return the modal.
  // Display manage button based on user role.
  return (
    <Modal
      open={isOpen}
      onClose={resetAndClose}
      className='result-modal'
    >
      <div className='result-modal-container__center-contents'>
        <ArrowLeft
          className={`result-navigation-arrow result-navigation-arrow__resize ${resultIdx <= 0 ? 'hide_arrow' : ''}`}
          onClick={() => { resultIdx > 0 && setResultIdx(resultIdx - 1) }}
        />
        <div className='result-modal-contents'>
          <div className='result-modal-header'>
            <div className='title'>
              <Typography variant='h4'>
                {title}
              </Typography>
              {
                userRoles.administrator ||
                  userRoles.contentAuthor ||
                  userRoles.feedEngineer ||
                  userRoles.groupAdmin
                  ? <Button className='manage-button' href={`${process.env.REACT_APP_TARGET_URL}/node/${nid}`}>
                    Manage
                  </Button>
                  : <div />
              }
              <Close className='modal-close-button' onClick={resetAndClose} />
            </div>
            <div className='id-info'>
              <div>
                <Typography variant='div' className='field'>ID</Typography>
                <Typography variant='div' className='field-item'> {nid}</Typography>
              </div>
              <div>
                <Typography variant='div' className='field'>Natural Key</Typography>
                <Typography variant='div' className='field-item'> {naturalKey}</Typography>
              </div>

            </div>
          </div>
          <div className='result-modal--main-content'>
            {populatePage(currentBody.topics)}
          </div>
        </div>
        <ArrowRight
          className={`result-navigation-arrow result-navigation-arrow__resize ${resultIdx >= pages.length - 1 ? 'hide_arrow' : ''}`}
          onClick={() => {
            resultIdx < pages.length - 1 && setResultIdx(resultIdx + 1)
          }}
        />
      </div>
    </Modal>
  )
}
