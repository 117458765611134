import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import './ContentSearchBar.scss'

// Generate content search bar.
export const ContentSearchBar = ({ setFilter }) => {
  const [query, setQuery] = useState('')
  const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 500), []
  )
  // Set the filter.
  const sendQuery = q => {
    setFilter(q)
  }
  // Search during search
  const typeAhead = value => {
    setQuery(value)
    delayedQuery(value)
  }

  // Generate the search bar html.
  return (
    <div className='toolBarContainer'>
      <div className='searchContainer'>
        <form>
          <input
            type='text'
            placeholder='Search'
            value={query}
            onChange={event => {
              typeAhead(event.target.value)
            }}
          />
        </form>
      </div>
    </div>
  )
}
