import React, { useEffect, useState } from 'react'
// Import necessary components.
import { makeStyles } from '@mui/styles'
import { constants } from '../../../lib'
import AddMemberModalStep1 from './AddMemberModalStep1'
import AddMemberModalStep2 from './AddMemberModalStep2'

// Add css for member add modal.
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'grid',
    alignItems: 'baseline',
    justifyContent: 'center',
    overflowY: 'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    margin: 'auto',
    borderRadius: '5px',
    [theme.breakpoints.up('md')]: {
      width: '800px'
    },
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  },
  checkboxLabel: {
    fontSize: '15px'
  }
}))

// Export GroupMemberAddModal component.
const GroupMemberAddModal = ({ visible, onCancel, gid, setGroupShouldReload, setFeedbackMessage }) => {
  const classes = useStyles()
  // Set current step.
  const [step, setStep] = useState(1)
  // Initiate data.
  const initialData = {
    email: '',
    role: '',
    description: '',
    sameRaciRole: false
  }
  // Set Data.
  const [data, setData] = useState(initialData)
  // Set email array.
  const [emailArray, setEmailArray] = useState([])
  // Set RACI roles array.
  const raciRolesArr = constants.misc.groupTeamMemberData.businessTeamRoles

  // Proceed to next step
  const continues = (email, role, description, sameRaciRole) => {
    setData({ email, role, description, sameRaciRole })
    setStep(() => step + 1)
  }

  // Set emails from data.
  useEffect(() => {
    let allEmails = data.email.trim().split(';').map(item => item.trim())
    // Take unique emails only.
    allEmails = [...new Set(allEmails)]
    setEmailArray(allEmails.map(item => ({
      email: item,
      role: data.role,
      description: data.description
    })))
  }, [data])

  // Go back to prev step.
  const stepBack = email => {
    setData({
      ...data,
      email: email
    })
    setStep(() => step - 1)
  }

  // Reset all when modal closed.
  const onClose = () => {
    setData(initialData)
    setStep(1)
    onCancel()
  }

  // Configure all steps.
  switch (step) {
    case 2:
      // Step 2.
      return <AddMemberModalStep2 visible={visible}
        gid={gid}
        raciRolesArr={raciRolesArr}
        onCancel={onClose}
        stepBack={stepBack}
        emailArray={emailArray}
        setGroupShouldReload={setGroupShouldReload}
        setFeedbackMessage={setFeedbackMessage}
        classes={classes} />
    case 1:
    default:
      // Step 1 or Default.
      return <AddMemberModalStep1 visible={visible}
        raciRolesArr={raciRolesArr}
        onCancel={onClose}
        continues={continues}
        data={data}
        classes={classes}
      />
  }
}

// Export component for use.
export default GroupMemberAddModal
