import React, { useState } from 'react'
// Importing components.
import { ArrowDropDown, Search } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// Add CSS for dropdown menu
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #F0F0F0',
    '& ul.MuiList-padding': {
      paddingTop: '12px',
      paddingBottom: '22px'
    }
  }
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))

// Add CSS for each menu item.
const StyledMenuItem = withStyles(theme => ({
  root: {
    fontFamily: 'Commissioner-Regular',
    fontSize: '14px',
    paddingLeft: '20px',
    paddingRight: '55px',
    color: '#333333',
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.common.white
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1C75BC'
    }
  }
}))(MenuItem)

// Build the component.
const EntityHeader = ({ title, sortCriteria, handleSortCriteria, searchHandler, searchTerm, tagsFilter, handleFilterOpen, numOfTagsSelected }) => {
  // Set the anchor.
  const [anchorEl, setAnchorEl] = useState(null)

  // Get the selected sort criteria.
  // Based on value of sortCriteria prop.
  const selectedSortCriteria = () => {
    switch (sortCriteria) {
      case '2':
        return 'Alphabetical A to Z'
      case '3':
        return 'Alphabetical Z to A'
      case '4':
        return 'Date Added to Voyager - Ascending'
      case '5':
        return 'Date Added to Voyager - Descending'
      case '1':
      default:
        return 'Default Order'
    }
  }

  // Handle click on menu.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  // Handle close menu item.
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Handle click on menu item.
  const handleMenuClick = value => {
    handleSortCriteria(value)
    setAnchorEl(null)
  }

  // Returning JSX for the component.
  return (
    <div className="group_heading_wrapper">
      <div className="group_heading">{title}</div>
      <div className="filter_wrapper">
        <div className="sort_by_section">
          Sort By : <span className="selected_value" onClick={handleClick}>{selectedSortCriteria()}<ArrowDropDown /></span>
          <StyledMenu id="sort-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <StyledMenuItem onClick={() => handleMenuClick('1')}>Default Order</StyledMenuItem >
            <StyledMenuItem onClick={() => handleMenuClick('2')}>Alphabetical A to Z</StyledMenuItem>
            <StyledMenuItem onClick={() => handleMenuClick('3')}>Alphabetical Z to A</StyledMenuItem >
            <StyledMenuItem onClick={() => handleMenuClick('4')}>Date Added to Voyager - Ascending</StyledMenuItem >
            <StyledMenuItem onClick={() => handleMenuClick('5')}>Date Added to Voyager - Descending</StyledMenuItem >
          </StyledMenu>
        </div>
        {title === 'Applications' && tagsFilter > 0 && <div className="filter_section">
          <div onClick={handleFilterOpen} className="filter_by">Filter By Tags: <span className="selected_item">{numOfTagsSelected ? `${numOfTagsSelected} selected` : 'All'}<ArrowDropDownIcon /></span></div>
        </div>}
        <div className="group_search">
          <div className="group_inner_search">
            <Search />
            <input type="text" placeholder={`Search ${title}`} onChange={searchHandler}></input>
          </div>
        </div>
      </div>
    </div>
  )
}

// Exporting the component.
export default EntityHeader
