import React from 'react'
import { Paper, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './SendAllMessage.scss'

/**
 *
 * @param {bool} isVisible - Boolean representing the visibility of the popup
 * @param {function} setVisibility - function callback for changing the value of isVisible once message is acknowledged
 * @param {number} shoppingCartSize - value representing the number of items in the shopping cart when "Send All" was pressed
 * @param {function} setShoppingCartSize - function callback for setting shoppingCartSize when message is acknowledged
 * @param {object} shoppingCartItems - All shopping cart item to send
 * @param {object} emailRecipients - All processes to send assets
 * @param {string} emailStatusText - Status text whether email sent or not
 *
 * @return {Object} The React wrapper component for the popup
 */
export default function SendAllMessage ({ isVisible, setVisibility, shoppingCartSize, setShoppingCartSize, shoppingCartItems, emailRecipients, emailStatusText }) {
  const handleClick = () => {
    setVisibility(false)
    setShoppingCartSize(0)
  }

  return (
    isVisible ? <MessageBody shoppingCartSize={shoppingCartSize} handleClick={handleClick} shoppingCartItems={shoppingCartItems} emailRecipients={emailRecipients} emailStatusText={emailStatusText} /> : null
  )
}

/**
 * This function creates a UI element for when a user sends a site to the next user in the SIPOC chain
 *
 * @param {function} handleClick - The callback function for after the user has acknowledged the popup
 * @param {number} shoppingCartSize - value representing the number of items in the shopping cart when "Send All" was pressed
 * @param {object} shoppingCartItems - All shopping cart item to send
 * @param {object} emailRecipients - All processes to send assets
 * @param {string} emailStatusText - Status text whether email sent or not
 *
 * @return {Object} The React element for the email sent popup
 */
function MessageBody ({ handleClick, shoppingCartSize, shoppingCartItems, emailRecipients, emailStatusText }) {
  return (
    <Paper
      className='popup'
      elevation={5}
    >
      <IconButton
        onClick={handleClick}
        className='popup-close-btn'
      >
        <CloseIcon />
      </IconButton>
      <Typography variant='h5' className='popup-title'>Message Sent</Typography>
      {shoppingCartSize > 1
        ? (<Typography variant='body1' className='popup-body text__color-janssen-blue'>
          You have sent the following {`${shoppingCartSize} items`}:
          <ul className='send-all__no-bullet'>
            {[...shoppingCartItems.keys()].map(key => <li>{`${shoppingCartItems.get(key)?.title} to ${emailRecipients.get(key)?.name}`}</li>)}
          </ul>
        </Typography>)
        : (<Typography variant='body1' className='popup-body text__color-janssen-blue'>
          You have sent {[...shoppingCartItems.keys()].map(key => (`${shoppingCartItems.get(key)?.title} to ${emailRecipients.get(key)?.name}`))}.
        </Typography>)}
      <Typography variant='body1' className='popup-body'>
        The accountable and responsible individuals will have access to the pending items in their Group's Inbox for review.
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: emailStatusText }} style={{ paddingBottom: '10px' }}></div>
    </Paper>
  )
}
