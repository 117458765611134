import React from 'react'
// Import necessary packages.
import { Link } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

// Export component for use.
export default function ResultBreadcrumbAndLink ({ breadcrumb, url, rowType }) {
  let breadcrumbObjArr
  // Parse breadcrumb json if valid otherwise specify as null.
  try {
    breadcrumbObjArr = JSON.parse(breadcrumb)
  } catch {
    breadcrumbObjArr = null
  }
  // Return the output.
  return <div className="search-result-row-footer">
    <div className="breadcrumb-wrapper">
      {breadcrumbObjArr && <Breadcrumbs maxItems={3} itemsBeforeCollapse={2} separator={<NavigateNextIcon fontSize="small" />} aria-label="search-row-breadcrumb">
        {breadcrumbObjArr.map((breadcrumbObj, index) => {
          let output
          // Generate breadcrumb.
          if (index < breadcrumbObjArr.length - 1) {
            // Generate breadcrumb with link.
            output = <Link key={breadcrumbObj.url} to={breadcrumbObj.url}>
              {breadcrumbObj.text}
            </Link>
          } else {
            // Create last item without link.
            output = <Typography key="last_item">{breadcrumbObj.text}</Typography>
          }
          // Return breadcrumb item.
          return output
        })}
      </Breadcrumbs>}
    </div>
    <div className="details-link"><Link to={url}>View {rowType}</Link></div>
  </div>
}
