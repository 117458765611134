import React, { useState, useEffect, useContext } from 'react'
// Import required packages.
import { Autocomplete } from '@mui/material'
import { Search } from '@mui/icons-material'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { constants } from '../../../../lib'
import { UserContext } from '../../../context/UserContext'

// Build the component.
// This component used in 3 places.
// - In header
// - In home body
// - In search page
const SearchInput = ({ id, searchValue, setSearchValue, count, searchOptions, urlForRedirect, placeholder, loading, className, showIcon, showButton }) => {
  // Set Results.
  const [results, setResults] = useState([])
  // Fetch search History from user context.
  const { searchHistory } = useContext(UserContext)

  // Set required result either from recent history or from predictive search.
  useEffect(() => {
    if (!searchValue && searchHistory?.length) {
      setResults([constants.misc.recentSearchLabel, ...searchHistory?.map(option => option.searchTerm)])
    } else {
      if (searchOptions?.length) {
        setResults([...searchOptions?.map(option => option.searchTerm), constants.misc.seeAllResultString.replace('COUNT', count)])
      } else {
        // Case where no results are found in predictive search.
        setResults([constants.misc.noResultFoundString, constants.misc.seeAllResultString.replace('COUNT', 0)])
      }
    }
  }, [searchValue, searchOptions, count, searchHistory])

  // Function to handle see all click.
  const handleSeeAllClick = value => {
    // Return if no results found in predictive search.
    if (!count) {
      return
    }
    // Redirect to search page with given keyword.
    urlForRedirect(id, value)
  }

  // Return the auto complete text box. setSearchValue(newInputValue)
  return (
    <Autocomplete
      id={id}
      onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
      freeSolo
      filterOptions={options => options}
      inputValue={searchValue}
      renderOption={(props, option, { inputValue }) => {
        // Render the see all option at the bottom.
        if (option === constants.misc.seeAllResultString.replace('COUNT', count)) {
          return <div {...props} onClick={() => handleSeeAllClick(inputValue)} className={`result_count count_${count}`}>
            <span>{option}</span>
          </div >
        }
        const matches = match(option, inputValue || (searchValue || ''), { insideWords: true, findAllOccurrences: true })
        const parts = parse(option, matches)
        // Update the key to avoid duplicate key error, as there is a chance of duplicate title.
        const updatedProps = { ...props, key: props.id }
        // Return the highlighted output.
        return (
          <div {...updatedProps}>
            {parts.map((part, index) => (
              <span key={index} style={{ fontFamily: part.highlight ? 'Commissioner-Bold' : 'Commissioner-Regular' }}>
                {part.text}
              </span>)
            )}
          </div>
        )
      }}
      options={!loading ? results : []}
      getOptionDisabled={(option, index) => {
        if (option === constants.misc.recentSearchLabel || option === constants.misc.noResultFoundString) {
          return true
        } else if (results) {
          return index === results.length - 1
        } else {
          return false
        }
      }
      }
      loading={loading}
      onChange={(event, value) => value ? urlForRedirect(id, value) : ''}
      renderInput={params => (
        <div className="search-wrapper" ref={params.InputProps.ref}>
          <input type="search" {...params.inputProps} className={className}
            name="search" placeholder={placeholder}
            onKeyDown={e => (e.key === 'Enter') ? urlForRedirect(id, searchValue) : ''} />
          {(id === 'navbar_search_box') && <Search />}
          {showButton && <button className="search-btn" onClick={() => urlForRedirect(id, searchValue)}>{showIcon && <Search />}Search</button>}
        </div>
      )}
    />
  )
}

// Export the component for use.
export default SearchInput
