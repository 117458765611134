import React, { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { UserContext } from '../components/context/UserContext'
import { constants } from '../lib'
// Create context.
export const EventTrackingContext = createContext()

// Build the context.
export const EventTrackingProvider = ({ children }) => {
  // Set pageData.
  const [pageData, setPageData] = useState({})
  // Get required user context data.
  const { userId, uuid, otherDetails } = useContext(UserContext)
  // Set userDate.
  const [userData, setUserData] = useState({})
  // Set searchData.
  const [searchData, setSearchData] = useState()
  // Set environment.
  const [environment, setEnvironment] = useState()
  // Set pageTracking.
  const [pageTracking, setPageTracking] = useState()
  // Set searchTracking.
  const [searchTracking, setSearchTracking] = useState()
  // Set clickTracking.
  const [clickTracking, setClickTracking] = useState()
  // Set fileTracking.
  const [fileTracking, setFileTracking] = useState()
  // Set currentPage.
  const [currentPage, setCurrentPage] = useState()

  // Generate the page info and user info.
  useEffect(() => {
    if (pageData?.type) {
      // Setting pageData.
      const pageInfo = {}
      pageInfo.system = 'frontend'
      let env = window.location.hostname.split('.')[0]
      if (env === 'jnjbrqc') {
        env = 'local'
      }
      if (env === 'iq') {
        env = 'prod'
      }
      pageInfo.env = env
      setEnvironment(env)
      pageInfo.page_referrer = null
      pageInfo.page_id = pageData?.page_id || null
      pageInfo.page_type = pageData?.type
      pageInfo.page_location = pageData?.externalURL || window.location.href
      setCurrentPage(pageInfo?.page_location)
      pageInfo.page_title = document.title

      // Setting breadCrumbs.
      const crumbs = pageData?.breadCrumbs
        ? pageData.breadCrumbs.map(item => ({
          location: item?.url?.path ? `${window.location.origin}${item.url.path}` : null,
          title: item?.text
        }))
        : null
      pageInfo.breadcrumbs = crumbs
      pageInfo.site_sectors = null

      if (pageData?.siteSector) {
        pageInfo.site_sectors = pageData?.siteSector
      }

      // Setting userData.
      const userInfo = {}
      userInfo.user_id = uuid
      userInfo.user_login_state = otherDetails?.roles
      if (parseInt(userId)) {
        if (!otherDetails?.roles) {
          userInfo.user_login_state = 'Authenticated'
        }
      } else {
        userInfo.user_login_state = constants.misc.anonymousUser
      }
      userInfo.user_language = 'en_US'
      userInfo.user_group_id = pageData?.group_id || null
      userInfo.user_group_title = pageData?.group_title || null
      userInfo.user_group_location = pageData?.group_location || null
      // Setting group_roles.
      const groupRole = pageData?.members ? pageData.members.filter(member => member?.userId === userId)[0]?.groupRoles : undefined
      userInfo.user_group_roles = groupRole ? [groupRole] : (pageData?.userRole ? [pageData.userRole] : null)

      setUserData(userInfo)
      // Create final data for dataLayer.
      const finalData = {
        event: 'page_view',
        page_data: pageInfo,
        user_data: userInfo
      }
      setPageTracking(finalData)
    }
  }, [otherDetails?.roles, pageData, userId, uuid])

  // Generate search data.
  useEffect(() => {
    if (searchData) {
      const eventInfo = {}
      eventInfo.system = 'frontend'
      eventInfo.env = environment
      eventInfo.search_term = searchData?.searchTerm
      eventInfo.search_order = []
      if (searchData?.searchTerm) {
        eventInfo.search_order.push({
          dimension: 'relevance',
          dir: 'desc'
        })
      } else {
        const applicationOrder = {
          dimension: 'content__changed',
          dir: 'desc'
        }
        const groupOrder = {
          dimension: 'group__changed',
          dir: 'desc'
        }
        if (searchData?.entityType === 'all') {
          eventInfo.search_order = [applicationOrder, groupOrder]
        } else if (searchData.entityType === 'entity') {
          eventInfo.search_order.push(applicationOrder)
        } else {
          eventInfo.search_order.push(groupOrder)
        }
      }
      eventInfo.search_filters = searchData?.filters
      // Create final data for dataLayer.
      const finalData = {
        event: 'search',
        event_data: eventInfo,
        user_data: userData
      }
      setSearchTracking(finalData)
    }
  }, [searchData])

  // Gives the link href type whether http, https or mail to.
  function linkHrefType (node) {
    if (node?.nodeName?.toLowerCase() !== 'a') {
      return null
    }
    if (node.nodeName?.toLowerCase() === 'a' && node.getAttribute('href').split(':').at(0) === 'mailto') {
      return 'mailto'
    } else {
      return window.location.protocol.split(':').at(0)
    }
  }

  // Gives inner html of an element.
  function elementText (event) {
    if (event?.target?.getAttribute('data-tracking')) {
      return event?.target?.getAttribute('data-tracking')
    }
    return event?.target?.innerText
  }

  // Gives class or id of an element.
  function getAttributes (attrType, event) {
    return event?.target?.getAttribute(attrType)
  }

  // Returns true if the URL domain is 3rd party.
  function isOutBound (elmUrl) {
    if (!elmUrl) {
      return false
    }
    try {
      return !(new URL(elmUrl).host.includes(window.location.host))
    } catch (error) {
      return false
    }
  }

  // Return href of a tag if available.
  function getLinkUrl (node) {
    if (node?.nodeName?.toLowerCase() === 'a') {
      if (node.getAttribute('link')) {
        return node.getAttribute('link')
      } else if (node.getAttribute('href').split(':').at(0) === 'mailto') {
        return node.getAttribute('href')
      } else {
        return `${window.location.origin}${node.getAttribute('href')}`
      }
    }
    return null
  }

  // Returns whether the node clicked is trackable or not.
  function isTrackable (nodeType, dataAttr, node = null) {
    return (['button', 'a'].includes(nodeType) || dataAttr || node)
  }

  // Check if parent node present or not.
  function isParentNodePresent (nodeElm, dataAttr) {
    if (['button', 'a'].includes(nodeElm?.node?.toLowerCase()) || dataAttr) {
      return nodeElm
    }
    return nodeElm.closest('a')
  }

  // Generate data for click.
  function clickHandler (event) {
    const nodeType = event?.target?.nodeName?.toLowerCase()
    const dataAttr = !!event?.target?.getAttribute('data-tracking')
    const clickedItem = event?.target
    const node = isParentNodePresent(clickedItem, dataAttr)
    if (!isTrackable(nodeType, dataAttr, clickedItem.closest('a'))) {
      return
    }
    // Setting the common data for both click and file download tracking.
    const commonData = {
      env: environment,
      system: 'frontend',
      page_location: currentPage,
      page_title: document.title
    }
    const linkData = {
      link_url: getLinkUrl(node),
      link_id: getAttributes('id'),
      link_classes: getAttributes('class'),
      link_text: elementText(event),
      link_href_type: linkHrefType(node),
      outbound: isOutBound(getLinkUrl(node))
    }
    // Case for if download button is clicked.
    if (elementText(event).toLowerCase() === 'download-file') {
      const fileName = event?.target?.getAttribute('download')
      const extension = fileName.split('.')[1]
      const link = event?.target?.getAttribute('link')
      const fileTrackingData = {
        event: 'file_download',
        event_data: {
          ...commonData,
          file_extension: extension,
          file_name: fileName,
          ...linkData,
          link_url: link
        },
        user_data: userData
      }
      setFileTracking(fileTrackingData)
      return
    }
    // Create final data for dataLayer.
    const finalData = {
      event: 'click',
      event_data: {
        ...commonData,
        ...linkData
      },
      user_data: userData
    }
    setClickTracking(finalData)
  }

  // Setting handler function to track onclick.
  document.onclick = clickHandler

  // Set context values.
  const contextValue = useMemo(() => ({ pageData, setPageData, userData, searchData, setSearchData, pageTracking, searchTracking, clickTracking, fileTracking }), [pageData, setPageData, userData, searchData, setSearchData, pageTracking, searchTracking, clickTracking, fileTracking])

  // Return EventTrackingContext.
  return (
    <EventTrackingContext.Provider value={contextValue}>
      {children}
    </EventTrackingContext.Provider>
  )
}
