import React, { useState } from 'react'
import { Button, Alert, Stack, AlertTitle } from '@mui/material'
import { CheckCircleSharp, CancelSharp } from '@mui/icons-material'
import awaitingIcon from '../../../../images/awaiting_icon.svg'

const RecordCard = ({ approvalData, isReqPage }) => {
  // Set visible comment section.
  const [isVisibleComment, setIsVisibleComment] = useState(false)
  // Check if data coming from comment.
  const { actionComment, actionStatus, actionTaker, actionDate } = approvalData
  // Set show approved or denied status.
  const statusMessage = (actionStatus === 'Pending' && isReqPage) ? 'Awaiting Approval' : `This request was ${actionStatus} by ${actionTaker} on ${actionDate}`
  // Set the icon for awaiting and approved, denied.
  let commentIcon
  // Set the message type.
  let severityStatus
  if (actionStatus === 'Pending' && isReqPage) {
    severityStatus = 'info'
    commentIcon = (<img src={awaitingIcon} alt='awaitingIcon' sx={{ fontSize: 18 }} />)
  } else if (actionStatus === 'Denied') {
    severityStatus = 'error'
    commentIcon = (<CancelSharp sx={{ fontSize: 18 }} />)
  } else if (actionStatus === 'Approved') {
    severityStatus = 'success'
    commentIcon = (<CheckCircleSharp sx={{ fontSize: 18 }} />)
  } else {
    severityStatus = 'error'
  }
  // Return the output.
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert
        severity={severityStatus}
        iconMapping={{ success: commentIcon, error: commentIcon, info: commentIcon }}
        action={actionStatus !== 'Pending' && (
          <Button color='inherit' size='small' onClick={() => setIsVisibleComment(!isVisibleComment)}>
            {isVisibleComment ? (<span>Hide comment</span>) : (<span>View comment</span>)}
          </Button>
        )}
      >
        <AlertTitle>{statusMessage}</AlertTitle>
        {isVisibleComment && actionComment && (
          <div className='alert_comment' dangerouslySetInnerHTML={{ __html: actionComment }} />
        )}
      </Alert>
    </Stack>
  )
}
// Export the component for use.
export default RecordCard
