import React, { useState, useEffect, useContext } from 'react'
import './group.scss'
import personMultiIcon from '../../../images/icon-people.svg'
import userStatistics from '../../../images/icon-application.svg'
import PageAboutDetails from '../Assets/component/PageAboutDetails'
import PageHeader from '../Assets/component/PageHeader'
import { Link, useLocation, useParams } from 'react-router-dom'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../../../lib'
import Loader from '../../PageLoader'
import ApplicationCard from '../Assets/component/ApplicationCard'
import TeamCard from '../Assets/component/TeamCard'
import NotFound from '../../NotFound'
import { useActiveSearch, useMemberList, useFavorites } from '../../../hooks'
import { FavoriteButton } from '../Assets/component/FavoriteButton'
import TagsFilterPage from '../Assets/component/TagsFilterPage'
import EntityHeader from '../Assets/component/EntityHeader'
import GlobalGroupAnnouncement from '../Assets/component/GlobalGroupAnnouncement'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

// Defining the Group for the nextGen.
const Group = ({ match }) => {
  // Set the location.
  const location = useLocation()
  // Set params.
  const params = useParams()
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)
  // Set the group id.
  const [id, setId] = useState()
  // Set loading state.
  const [isLoading, setIsLoading] = useState(true)
  // Set error state.
  const [hasError, setHasError] = useState(false)
  // Set group data.
  const [groupData, setGroupData] = useState({})
  // Get the team data object from config.
  const groupTeamData = constants.misc.groupTeamMemberData
  // Get member details from member list hook.
  const [businessTeam, membersProfileImageData, isMember, isRespOrAccount] = useMemberList(groupData?.groupMembersById?.results)
  // Set groupSearch.
  const [groupSearchData, setGroupSearchData, groupSearchValue, groupSearchHandler, setActiveSearchGroupData, groupSortCriteria, setGroupSortCriteria] = useActiveSearch('groups')
  // Set groupSearch.
  const [applicationSearchData, setApplicationSearchData, applicationSearchValue, applicationSearchHandler, setActiveSearchAppGroupData, applicationSortCriteria, setApplicationSortCriteria, tags, setTags, numOfTagsSelected] = useActiveSearch('applications')
  // Set the current page.
  const [currentPage, setCurrentPage] = useState(1)
  // Set group temp data.
  const [groupTempData, setGroupTempData] = useState({})
  // Set the page count.
  const [pageCount, setPageCount] = useState(0)
  // Set content loading state.
  const [isContentLoading, setIsContentLoading] = useState(false)
  // Set aboutHeading.
  const aboutHeading = 'About Group'
  // Set if group page need to be reload.
  const [groupShouldReload, setGroupShouldReload] = useState(false)
  // Fetch the favorites and favorite operations from hook.
  const [userFavorites, addOrRemoveFavorite, feedbackMessage, setFeedbackMessage, updateFavorite] = useFavorites()
  // Set tagFilterOpen.(Tag filter page is open or not)
  const [tagFilterOpen, setTagFilterOpen] = useState(false)
  // Set defaultGroupOrder.
  const [defaultGroupOrder, setDefaultGroupOrder] = useState([])
  // Set defaultApplicationOrder.
  const [defaultApplicationOrder, setDefaultApplicationOrder] = useState([])
  // Set announcementsCount.
  const [announcementsCount, setAnnouncementsCount] = useState(0)

  // Function to handle change in group sort criteria.
  const handleGroupSortCriteriaChange = value => {
    if (value === '1') {
      setGroupSearchData(defaultGroupOrder)
      groupSearchHandler({ target: { value: groupSearchValue } })
    }
    setGroupSortCriteria(value)
  }

  // Function to handle change in application sort criteria.
  const handleApplicationSortCriteriaChange = value => {
    if (value === '1') {
      setApplicationSearchData(defaultApplicationOrder)
      applicationSearchHandler({ target: { value: applicationSearchValue } })
    }
    setApplicationSortCriteria(value)
  }

  // Resolve the group id based on parameter received.
  useEffect(() => {
    const getResolvedId = () => {
      if (!match) {
        setId(params.id)
      } else {
        setId(match.id)
      }
    }
    getResolvedId()
  }, [location, params, match])

  // Update latest used date.
  useEffect(() => {
    if (userFavorites && userFavorites?.group.includes(parseInt(id))) {
      updateFavorite(id, 'group', 2)
    }
  }, [id])
  // Fetch the overall details for the group.
  useEffect(() => {
    // If id exists & reload requested.
    if (id || groupShouldReload) {
      // Set current page.
      setCurrentPage(1)
      setGroupTempData({})
      let groupContentList = null
      // Collect the content data if already loaded fully.
      if (groupData.groupContentByGidList?.results && !isContentLoading) {
        groupContentList = groupData.groupContentByGidList?.results
      }
      // Generate the payload.
      const groupVariable = JSON.stringify({
        id: id.toString()
      })
      // Generate the query url.
      const groupQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupOverviewQuery}&variables=${groupVariable}`
      // Query Call.
      queryContent(groupQuery)
        .then(response => {
          let totalPages = 0
          // Retrieve the number of pages & set it.
          if (response?.data.groupContentByGidList.count > 0) {
            totalPages = Math.floor(response?.data.groupContentByGidList.count / constants.misc.defaultGroupContentCount)
            setPageCount(totalPages)
          }
          // Set data in temp.
          const tempGroupData = response?.data
          // Setting the default group order.
          setDefaultGroupOrder(tempGroupData?.groupById?.childGroups?.entities)
          const siteSector = tempGroupData?.groupById?.owningSector?.entity
          // Sending page data for tracking.
          setPageData({
            type: 'group',
            breadCrumbs: tempGroupData?.groupById?.entityBreadCrumb,
            page_id: id,
            group_id: id,
            siteSector: [{
              id: siteSector?.id,
              location: `${window.location.origin}${siteSector?.url?.path}`,
              title: siteSector?.title
            }],
            group_title: tempGroupData?.groupById?.entityLabel,
            group_location: window.location.href,
            members: tempGroupData?.groupMembersById?.results
          })
          if (totalPages > 0) {
            // More than one page exist, hence set temp group data if not loaded already.
            if (!groupContentList) {
              setIsContentLoading(true)
              setGroupTempData(tempGroupData)
              setGroupData(tempGroupData)
              // Set groupData for active search.
              setActiveSearchGroupData(tempGroupData)
              setActiveSearchAppGroupData(tempGroupData)
              setDefaultApplicationOrder(tempGroupData?.groupContentByGidList?.results)
            } else {
              // Set content from loaded content list.
              tempGroupData.groupContentByGidList.results = groupContentList
              setGroupData({ ...tempGroupData })
              // Set groupData for active search.
              setActiveSearchGroupData({ ...tempGroupData })
              setActiveSearchAppGroupData({ ...tempGroupData })
              // Setting default application order.
              const incrementalApplicationOrder = tempGroupData?.groupContentByGidList?.results
              if (incrementalApplicationOrder) {
                setDefaultApplicationOrder([...incrementalApplicationOrder])
              }
            }
          } else {
            setGroupData(tempGroupData)
            // Set groupData for active search.
            setActiveSearchGroupData(tempGroupData)
            setActiveSearchAppGroupData(tempGroupData)
            // Setting default application order.
            setDefaultApplicationOrder(tempGroupData?.groupContentByGidList?.results)
          }
        })
        .catch(() => setHasError(true))
        .finally(() => {
          setIsLoading(false)
          setGroupShouldReload(false)
        })
    }
  }, [id, groupShouldReload])

  // Fetch group content list from 2nd page onwards.
  // If temp data for group has been set.
  useEffect(() => {
    if (id && groupTempData?.groupContentByGidList && currentPage <= pageCount && isContentLoading) {
      // Generate the payload.
      const groupVariable = JSON.stringify({
        id: id.toString(),
        page: currentPage
      })
      // Generate the query url.
      const groupQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupContentListQuery}&variables=${groupVariable}`
      // Query Call
      queryContent(groupQuery)
        .then(response => {
          // Combine all temp data.
          const clonedGroupTempData = groupTempData
          if (!groupTempData.groupContentByGidList?.results) {
            clonedGroupTempData.groupContentByGidList.results = response?.data.groupContentByGidList.results
          } else {
            clonedGroupTempData.groupContentByGidList.results = groupTempData.groupContentByGidList.results.concat(response?.data.groupContentByGidList.results)
          }
          // Set the temp data.
          setGroupTempData(clonedGroupTempData)
          if (currentPage === pageCount) {
            // All content loaded.
            setIsContentLoading(false)
          } else {
            // Incrementing current page.
            setCurrentPage(currentPage + 1)
          }
          setGroupData({ ...clonedGroupTempData })
          // Set groupData for active search.
          setActiveSearchGroupData({ ...clonedGroupTempData })
          setActiveSearchAppGroupData({ ...clonedGroupTempData })
        })
        .catch(() => setHasError(true))
    }
  }, [id, groupTempData, currentPage, pageCount, isContentLoading])

  // Return error page if have some error.
  if (hasError) {
    return (
      <div className="group_page_wrapper min_content_height bg-f7">
        <div className="group_wrapper_section">
          <div className="group_page_section">
            <div className="errorLoading" dangerouslySetInnerHTML={{ __html: constants.errors.server }} />
          </div>
        </div>
      </div>
    )
  }

  // Function to close filter page.
  const handleFilterClose = () => {
    setTagFilterOpen(false)
  }
  // Function to close filter page.
  const handleFilterOpen = () => {
    setTagFilterOpen(true)
  }

  // Show loader if data fetching in progress.
  if (isLoading) {
    // return the loader.
    return <Loader />
  }

  // Function to check if just added.
  const checkJustAdded = date => {
    const today = new Date()
    const created = new Date(date)
    const diffTime = Math.abs(today - created)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays < constants.misc.justAddedValidityDays
  }

  // Display actual data.
  return (
    <div className="min_content_height bg-f7">
      {isContentLoading ? <Loader type="Linear" /> : null}
      {groupData?.groupById?.entityLabel
        ? <PageHeader title={groupData?.groupById?.entityLabel} appCount={groupData?.groupById?.appAssociated} memberCount={groupData?.groupById?.userAssociated} lifeCycle={groupData?.groupById?.fieldLifeCycle?.entity?.title}
          groupIsLive={groupData?.groupById?.fieldIsLive} id={id} userFavorites={userFavorites} addOrRemoveFavorite={addOrRemoveFavorite} feedback={feedbackMessage} setGroupShouldReload={setGroupShouldReload} isRespOrAccount={isRespOrAccount}
          isContentLoading={isContentLoading} entity="Group" breadCrumb={groupData?.groupById?.entityBreadCrumb} />
        : <NotFound />
      }
      {groupData?.groupById?.entityLabel && groupData?.groupById?.fieldLifeCycle?.entity?.title !== constants.misc.retiredLifeCycle && groupData?.groupById?.fieldIsLive && <div className="group_page_wrapper">
        <div className="group_wrapper_section">
          <div className="group_page_section">
            <div className="group_pageleft col-9">
              {groupData?.groupById?.childGroups?.entities?.length !== 0 && <>
                <EntityHeader title="Groups" sortCriteria={groupSortCriteria} handleSortCriteria={handleGroupSortCriteriaChange}
                  searchTerm={groupSearchValue} searchHandler={groupSearchHandler} />
                <div className="row">
                  {
                    groupSearchData && groupSearchData?.map(entity => {
                      const wasJustAdded = checkJustAdded(entity?.entityCreated)
                      return (
                        <div className="col-4" key={entity.id}>
                          <Link to={entity?.url?.path}>
                            <div className="group_innersection">
                              <div className={wasJustAdded ? 'group_innerheading with_just_added' : 'group_innerheading'}>{entity?.title}{wasJustAdded && <div className="just-added">Just added</div>}</div>
                              <div className="data_count-wrapper">
                                <div className="data_count_innerleft">
                                  <div className="data_count"><img src={personMultiIcon} alt={constants.misc.uniqueUserAssociatedAlt} title={constants.misc.uniqueUserAssociatedAlt} />{entity?.userAssociated || 0}</div>
                                  <div className="data_statics"><img src={userStatistics} alt={constants.misc.uniqueAppAssociatedAlt} title={constants.misc.uniqueAppAssociatedAlt} />{entity?.appAssociated || 0}</div>
                                </div>
                                <div className="favorite_right"><FavoriteButton {...entity} userFavorites={userFavorites} addOrRemoveFavorite={addOrRemoveFavorite} assetType="group" /></div>
                              </div>
                            </div>
                          </Link>
                        </div>)
                    })
                  }
                  {
                    groupSearchData?.length === 0 && <div className="col-12">{constants.errors.noChildGroupsFound}</div>
                  }
                </div>
              </>}
              {groupData?.groupContentByGidList?.results?.length !== 0 && <>
                <EntityHeader title="Applications" sortCriteria={applicationSortCriteria} handleSortCriteria={handleApplicationSortCriteriaChange}
                  searchTerm={applicationSearchValue} tagsFilter={tags?.length}
                  searchHandler={applicationSearchHandler} handleFilterOpen={handleFilterOpen} numOfTagsSelected={numOfTagsSelected} />
                <div className="row home_application_wrapper group_wrapper">
                  {
                    applicationSearchData && applicationSearchData?.map(entity => {
                      const wasJustAdded = checkJustAdded(entity.entityCreated)
                      return <ApplicationCard key={entity.nid} appName={entity.entityLabel} image={entity.contentImage?.entity?.thumbnail} description={entity.vision?.value}
                        gxp={entity.gxp} lifeCycle={entity.lifeCycle?.entity?.entityLabel} wasJustAdded={wasJustAdded} memberCount={entity.userHavingAccess} url={entity.url?.path} gid={id} userFavorites={userFavorites} addOrRemoveFavorite={addOrRemoveFavorite} assetType="node" nid={entity.nid} />
                    })
                  }
                  {
                    applicationSearchData?.length === 0 && <div className="col-12">{constants.errors.noApplicationsFound}</div>
                  }
                  <TagsFilterPage tagFilterOpen={tagFilterOpen} handleFilterClose={handleFilterClose} tags={tags} setTags={setTags} />
                </div>
              </>}
              {groupData?.groupById?.childGroups?.entities?.length === 0 && groupData?.groupContentByGidList?.results?.length === 0 && <div className="message">{constants.errors.noContentFound}</div>}
            </div>
            <div className="group_pageright col-3">
              <PageAboutDetails vision={groupData?.groupById?.fieldVision?.value} date={groupData?.groupById?.fieldInceptionDate?.date} heading={aboutHeading} />
              <GlobalGroupAnnouncement globalOrGroupId={id} setAnnouncementsCount={setAnnouncementsCount}
                announcementsCount={announcementsCount} isMember={isMember}
                isRespOrAccount={isRespOrAccount} setFeedbackMessage={setFeedbackMessage} />
              <TeamCard teamData={groupTeamData.businessTeam}
                groupName={groupData?.groupById?.entityLabel}
                businessTeam={businessTeam} id={id} isMember={isMember}
                profileImageData={membersProfileImageData} />
            </div>
          </div>
        </div>
      </div>}
      {(groupData?.groupById?.entityLabel && (groupData?.groupById?.fieldLifeCycle?.entity?.title === constants.misc.retiredLifeCycle || !groupData?.groupById?.fieldIsLive)) && <div className="group_page_wrapper">
        <div className="group_wrapper_section">
          <div className="group_page_section">
            <div className="col-12 message">{constants.errors.retiredGroupMessage}</div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

// Return the component for use.
export default Group
