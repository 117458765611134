import React, { useState } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { miscConfig } from './SearchConfig'

// Export the apache solr search component.
export default function SearchSorting ({ onChange, params }) {
  // Set sorting criteria.
  const [sortCriteria, setSortCriteria] = useState(() => {
    // Set the criteria if exist in url.
    const sortParts = params?.sort ? params.sort.split(miscConfig.multiValueSeparator) : []
    // Set the default field sort depending on search keyword existence.
    const defaultSortField = params?.keyword ? miscConfig.sorting[0].default2 : miscConfig.sorting[0].default
    // Return the default criteria during load.
    return {
      sortBy: (sortParts[0] || defaultSortField),
      sortOrder: (sortParts[1] || miscConfig.sorting[1].default)
    }
  })

  // Change sort handler.
  const handleChange = event => {
    // Get the value.
    const value = event.target.value
    // Set the object.
    const newSortCriteria = {
      ...sortCriteria,
      [event.target.name]: value
    }
    // Set the state.
    setSortCriteria(newSortCriteria)
    // Change the url with updated sort criteria.
    onChange('sort')(newSortCriteria.sortBy + miscConfig.multiValueSeparator + newSortCriteria.sortOrder)
  }

  // Return the output.
  return (
    <>
      {miscConfig.sorting.map(item => (
        <Box sx={{ minWidth: 150, width: 'auto' }} key={item.title}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>{item.label}</InputLabel>
            <Select
              label="Select"
              value={sortCriteria[item.title]}
              name={item.title}
              onChange={handleChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
            >
              {Object.keys(item.options).map((val, key) =>
                <MenuItem
                  key={val}
                  value={val}
                >
                  {Object.values(item.options)[key]}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      ))}
    </>
  )
}
