import React, { useState, useEffect, useContext } from 'react'
import { constants } from '../../lib'
import { UserContext } from '../context/UserContext'
import Loader from '../PageLoader'
import MyItemCard from './MyItemCard'
import { cardImageFor, cardRedirectFor } from './CardFunc'
import Pagination from '../Pagination'
// Build the component.
const ToDo = () => {
  // Fetch userId from UserContext.
  const { userId } = useContext(UserContext)
  // Set allProcessIds.
  const [allProcessIds, setAllProcessIds] = useState([])
  // Set hasError flag.
  const [hasError, setHasError] = useState(false)
  // Set isLoadingUserGroups flag.
  const [isLoadingUserGroups, setIsLoadingUserGroups] = useState(true)
  // Set toDoData.
  const [toDoData, setToDoData] = useState({})
  // Set isLoadingToDoData flag.
  const [isLoadingToDoData, setIsLoadingToDoData] = useState(true)
  // Set requireAssetProcess data.
  const [requireAssetProcess, setRequireAssetProcess] = useState([])
  // Set isLoadingDetails flag.
  const [isLoadingDetails, setIsLoadingDetails] = useState(true)
  const [allUserRoles, setAllUserRoles] = useState({})
  const [pageParam, setPageParam] = useState({ limit: constants.misc.defaultHomeRecordCount, offset: 0 })
  const [validData, setValidData] = useState({})
  // Function for change operation.
  const handleChangeFor = category => value => {
    const currentParams = pageParam
    if (category === 'limit') {
      currentParams.limit = value
      currentParams.offset = 0
    }
    if (category === 'offset') {
      currentParams.offset = value
    }
    setPageParam(currentParams)
  }

  // Fetch the usr group & process data.
  useEffect(() => {
    if (userId != null) {
      // Prepare payload data.
      const variables = JSON.stringify({
        uid: userId
      })
      // Call graphql for cartQuery.
      const cartQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.userGroupsListRoleQuery}&variables=${variables}`
      // Query Call
      fetch(cartQuery, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(response => {
          const userGroupProcessData = response?.data?.groupUserGroupsByUidList?.results
          const userGroupRolesData = response?.data?.groupUserGroupsByUidRoleList?.results
          setAllUserRoles(userGroupRolesData)
          const processIds = []
          if (userGroupProcessData?.length > 0) {
            userGroupProcessData.map(userGroups =>
              userGroups?.reverseFieldProcessOwnerNode?.entities.map(item =>
                processIds.push(item?.nid)
              )
            )
          }
          setAllProcessIds(processIds)
          if (processIds.length === 0) {
            setIsLoadingToDoData(false)
            setIsLoadingDetails(false)
          }
        })
        .catch(() => setHasError(true))
        .finally(() => setIsLoadingUserGroups(false))
    }
  }, [userId])

  // Fetch asset & process items that belong to the group.
  useEffect(() => {
    if (allProcessIds.length > 0) {
      fetch('/api/assetProcesses/inbox', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: allProcessIds
        })
      })
        .then(res => {
          if (res.status < 400) {
            return res.json()
          } else {
            throw new Error()
          }
        })
        .then(res => {
          setToDoData(res)
          if (res.length === 0) {
            setIsLoadingDetails(false)
          }
        })
        .catch(() => setHasError(true))
        .finally(() => setIsLoadingToDoData(false))
    }
  }, [allProcessIds])

  // Fetch the contents of the inbox cards.
  useEffect(() => {
    if (toDoData && typeof (toDoData.data) !== 'undefined' && toDoData.data.length > 0) {
      const nodeAssetIds = toDoData.data.filter(item => item.Asset.type === 'node')
        .reduce((acc, item) => [...acc, item?.Asset?.drupalId], [])
      const groupAssetIds = toDoData.data.filter(item => item.Asset.type === 'group')
        .reduce((acc, item) => [...acc, item?.Asset?.drupalId], [])
      const processIds = toDoData.data.reduce((acc, item) => [...acc, item?.Process?.drupalId], [])
      const allIds = nodeAssetIds.concat(processIds)
      const ids = [...new Set(allIds)]
      // Prepare payload data.
      const variables = JSON.stringify({
        ids: ids.length ? ids : ['0'],
        gids: groupAssetIds.length ? groupAssetIds : ['0']
      })
      // Call graphql for cartQuery.
      const cartQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.shoppingCartQuery}&variables=${variables}`
      // Query Call
      fetch(cartQuery, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(response => {
          const nodeQueryData = response?.data?.nodeQuery?.assets
          const groupQueryData = response?.data?.groupQuery?.assets
          const assetProcessMasterData = nodeQueryData.concat(groupQueryData)
          setRequireAssetProcess(assetProcessMasterData)
          const validAssetIds = assetProcessMasterData.reduce((acc, item) => [...acc, item?.assetId], [])
          const filteredData = toDoData.data.filter(item => validAssetIds.includes(item?.Asset?.drupalId))
          setValidData({ data: filteredData, totalCount: filteredData.length })
        })
        .catch(() => setHasError(true))
        .finally(() => setIsLoadingDetails(false))
    } else {
      if (allProcessIds.length > 0) {
        setValidData({ data: [], totalCount: 0 })
        setIsLoadingDetails(false)
      }
    }
  }, [toDoData])

  if (hasError) {
    // Display error message.
    return <div className='details-wrapper'>
      <div align="center" style={{ width: '100%' }}>
        <strong>An error occurred while trying to load items. Please try again later.</strong>
      </div>
    </div>
  }

  // Return the loader or output data.
  if (isLoadingUserGroups || isLoadingToDoData || isLoadingDetails) {
    return <Loader />
  } else {
    return (
      validData && validData?.data?.length > 0
        ? (<div>
          {validData.data.slice(pageParam.offset, (pageParam.offset + pageParam.limit)).map((item, index) => {
            const assetData = requireAssetProcess.find(asset => (asset?.assetId === item?.Asset?.drupalId) && (asset?.assetType === item?.Asset.type))
            const processData = requireAssetProcess.find(process => process?.assetId === item?.Process?.drupalId)
            const userRoleData = allUserRoles.find(currentRole => currentRole?.gid === processData?.processOwner?.entity?.gid)
            const currentContentType = assetData.contentType?.entity?.entityLabel ? assetData.contentType?.entity?.entityLabel : assetData.contentType
            return assetData && processData && (
              <MyItemCard
                CardData={{
                  assetData,
                  processData,
                  id: assetData.assetId,
                  cardImage: cardImageFor(currentContentType),
                  cardRedirect: cardRedirectFor(currentContentType, assetData.assetId, assetData.assetUrl.path),
                  title: assetData.assetName,
                  rating: assetData.fieldStarRating,
                  createdAt: item.createdAt,
                  processTiedToLabel: processData.assetName,
                  processTiedToUrl: processData.assetUrl.path,
                  groupTiedToLabel: processData?.processOwner?.entity?.entityLabel,
                  groupTiedToUrl: `${processData?.processOwner?.entity?.url.path}#inbox`,
                  userGroupRole: userRoleData?.groupRoles
                }}
                key={`${pageParam.offset}_${index}`}
              />
            )
          }
          )}
          <Pagination handleChangeFor={handleChangeFor} params={pageParam} totalCount={validData.totalCount} />
        </div>)
        : <div align="center" style={{ display: 'block' }}><strong>There is no items found here.</strong></div>
    )
  }
}
// Export the component for use.
export default ToDo
