import React, { useState } from 'react'
import { Button } from '@mui/material'
import SideNavGroups from '../SideNavGroups/SideNavGroups'
import SideNavContentTypes from '../SideNavContentTypes/SideNavContentTypes'
import SideNavModal from '../SideNavModal/SideNavModal'
import { checkDupes } from './SideNavHelpers'

// Export side nav button for findings.
export default function SideNavButtonFindings ({ setSidePreviews, audit, capas, qis }) {
  let auditTitle = ''
  let auditID = ''
  let qiString = ''
  let capaString = ''
  const CRSACs = []
  const ESPs = []
  const LOCs = []
  const DLs = []
  // Set state to show modal.
  const [shouldShowModal, setShouldShowModal] = useState(false)
  // Set the content type.
  const [contentType, setContentType] = useState()
  // Set the content type title.
  const [contentTypeTitle, setContentTypeTitle] = useState()
  // Set the data to render.
  const [data, setData] = useState()
  // Check non-duplicate node and push it.
  const pushElement = (dataItem, type) => {
    dataItem.forEach(element => {
      if (element && checkDupes(element, type)) {
        type.push(element)
      }
    })
  }

  if (capas) {
    // Loop through all capas data.
    capas.filter(n => n).forEach(item => {
      // Push data for capas CRSAC.
      if (item?.CRSAC) {
        pushElement(item?.CRSAC, CRSACs)
      }
      // Push data for capas ESP.
      if (item?.ESP) {
        pushElement(item?.ESP, ESPs)
      }
      // Push data for capas LOC.
      if (item?.LOC) {
        pushElement(item?.LOC, LOCs)
      }
      // Push data for capas DL.
      if (item?.DL) {
        pushElement(item?.DL, DLs)
      }
    })
  }
  capaString = capas.filter(n => n).map(item => item?.nid).join(',')
  if (qis) {
    // Loop through all qis.
    qis.filter(n => n).forEach(item => {
      // Push data for qis CRSAC.
      if (item?.CRSAC) {
        pushElement(item?.CRSAC, CRSACs)
      }
      // Push data for qis ESP.
      if (item?.ESP) {
        pushElement(item?.ESP, ESPs)
      }
      // Push data for qis LOC.
      if (item?.LOC) {
        pushElement(item?.LOC, LOCs)
      }
      // Push data for qis DL.
      if (item?.DL) {
        pushElement(item?.DL, DLs)
      }
    })
  }
  qiString = qis.filter(n => n).map(item => item?.nid).join(',')
  if (audit) {
    auditID = audit?.nid
    auditTitle = audit?.title
    // Push data for audit CRSAC.
    if (audit?.CRSAC) {
      pushElement(audit?.CRSAC, CRSACs)
    }
    // Push data for audit ESP.
    if (audit?.ESP) {
      pushElement(audit?.ESP, ESPs)
    }
    // Push data for audit LOC.
    if (audit?.LOC) {
      pushElement(audit?.LOC, LOCs)
    }
    // Push data for audit DL.
    if (audit?.DL) {
      pushElement(audit?.DL, DLs)
    }
  }
  // Return the side nav button for findings.
  return (
    <div>
      <Button
        onClick={() => {
          setSidePreviews(
            <div className='side-wrapper--right-column'>
              <div className='sidebar-wrapper--right-column'>
                <SideNavContentTypes
                  contentType='Audit'
                  number={audit ? 1 : 0}
                  title={audit ? auditTitle : ''}
                  link={audit ? `/content/audits?id=${auditID}` : ''}
                />
                <SideNavContentTypes
                  contentType='Quality Investigations'
                  number={qis ? qis.length : 0}
                  link={qis ? `/content/qis?id=${qiString}` : ''}
                />
                <SideNavContentTypes
                  contentType='CAPAs'
                  number={capas ? capas.length : 0}
                  link={capas ? `/content/capas?id=${capaString}` : ''}
                />
                <SideNavGroups
                  contentType='CRSAC(s)'
                  data={CRSACs}
                  openModalCallback={() => {
                    setContentType('CRSAC')
                    setData(CRSACs)
                    setShouldShowModal(true)
                    setContentTypeTitle('CRSAC(s)')
                  }}
                />
                <SideNavGroups
                  contentType='ESP(s)'
                  data={ESPs}
                  openModalCallback={() => {
                    setContentType('ESP')
                    setData(ESPs)
                    setShouldShowModal(true)
                    setContentTypeTitle('ESP(s)')
                  }}
                />
                <SideNavGroups
                  contentType='LOC(s)'
                  data={LOCs}
                  openModalCallback={() => {
                    setContentType('LOC')
                    setData(LOCs)
                    setShouldShowModal(true)
                    setContentTypeTitle('LOC(s)')
                  }}
                />
                <SideNavGroups
                  contentType='DL(s)'
                  data={DLs}
                  openModalCallback={() => {
                    setContentType('DL')
                    setData(DLs)
                    setShouldShowModal(true)
                    setContentTypeTitle('DL(s)')
                  }}
                />
              </div>
            </div>
          )
        }}
      >
        Side Nav
      </Button>
      <SideNavModal
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
        data={data}
        contentType={contentType}
        contentTypeTitle={contentTypeTitle}
      />
    </div>

  )
}
