import 'date-fns'
import React, { useState } from 'react'
import { miscConfig } from '../../Search/SearchConfig'
import { convertTimeToDate, getTimestamp } from '../../Search/SearchUtil'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Button } from '@mui/material'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'

// Generate each facet field, i.e. start and end date.
function DateFacetField ({ fieldId, fieldName, onChange, defaultVal }) {
  // Set the selected date.
  const [selectedDate, setSelectedDate] = useState(defaultVal || null)
  // Set the date picker visibility state.
  const [openDatePicker, setOpenDatePicker] = useState(false)
  // Return the field output.
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        clearable
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        onClick={() => setOpenDatePicker(true)}
        InputProps={{ readOnly: true }}
        placeholder="mm/dd/yyyy"
        id={fieldId}
        name={fieldName}
        format="MM/dd/yyyy"
        value={selectedDate}
        className="datehandler"
        onChange={value => {
          setSelectedDate(value)
          onChange(value, fieldName)
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />
    </LocalizationProvider>
  )
}

// Export and build the component.
export function DateRangeFacetField ({ fieldName, fieldLabel, onChange, defaultValue }) {
  // Set the default form value.
  const defaultFromVal = (typeof (defaultValue[0]) !== 'undefined') ? convertTimeToDate(defaultValue[0]) : ''
  // Set the default to value.
  const defaultToVal = (typeof (defaultValue[1]) !== 'undefined') ? convertTimeToDate(defaultValue[1]) : ''
  // Set the form value in state.
  const [dateFromVal, setDateFromVal] = useState(defaultFromVal)
  // Set the to value in state.
  const [dateToVal, setDateToVal] = useState(defaultToVal)

  // Handle the date change.
  const handleDateChange = (value, thisFieldName) => {
    let fromDate = dateFromVal ? getTimestamp(dateFromVal) : ''
    let toDate = dateToVal ? getTimestamp(dateToVal) : ''
    let dateRange = ''
    // If to field received.
    if (thisFieldName === `${fieldName}_to`) {
      toDate = getTimestamp(value)
      setDateToVal(toDate)
    }
    // If to field received.
    if (thisFieldName === `${fieldName}_from`) {
      fromDate = getTimestamp(value)
      setDateFromVal(fromDate)
    }
    // Combine the dates to send for search.
    if (toDate || fromDate) {
      dateRange = `${fromDate}${miscConfig.multiValueSeparator}${toDate}`
    }
    // Send for search.
    onChange(fieldName)(dateRange)
  }

  // Clear the date selection.
  const ClearSelection = () => {
    return defaultValue.length > 0
      ? (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{ width: '100%' }}
          onClick={() => onChange(fieldName)('')}
          startIcon={<DeleteSweepIcon />}
        >
          {miscConfig.facetClearElement}
        </Button>)
      : null
  }

  // Return the output of start and end date along with clear selection.
  return (
    <div className="facet-box">
      <label className="facetid-heading">{fieldLabel}</label>
      <div className="date-wrapper">
        <label className="date-label">From</label>
        <DateFacetField fieldId={fieldName + '-from'} fieldName={fieldName + '_from'} onChange={handleDateChange} defaultVal={dateFromVal} />
      </div>
      <div className="date-wrapper">
        <label className="date-label">To</label>
        <DateFacetField fieldId={fieldName + '-to'} fieldName={fieldName + '_to'} onChange={handleDateChange} defaultVal={dateToVal} />
      </div>
      <ClearSelection />
    </div>
  )
}
