// The support contact email.
const contactEmail = 'DL-CONTACT-VOYAGER@its.jnj.com'
// Configure and export all constant value.
export const constants = {
  // The support contact email.
  contactEmail,
  // Define all sipoc related constants.
  sipoc: {
    suppliers: 0,
    inputs: 1,
    processes: 2,
    outputs: 3,
    customers: 4,
    get allCases () {
      return [this.suppliers, this.inputs, this.processes, this.outputs, this.customers]
    }
  },
  // Define all filter related constants.
  filter: {
    all: 'All',
    none: '-',
    get allCases () {
      return [this.all, this.none]
    }
  },
  // Define all error related constants.
  errors: {
    server: `An unexpected error has occurred. <a href="javascript:void(0)" onClick="window.location.reload()">Click here</a> to reload the page. If this persists, please contact <a href="mailto:${contactEmail}">${contactEmail}</a>.`,
    invalidContent: `There is an invalid content type, please try again. If this persists and you think this is an error,
                     please contact <a href="mailto:${contactEmail}">${contactEmail}</a>.`,
    noAccess: `You don't have access to this content - if you like to to gain access contact us by clicking this link <a href="javascript:void(0)">${contactEmail}</a>.`,
    nullValue: `The system has returned no records. This could be due to the fact you don't have access to the source data or there is indeed no records.
                If you think this is an error, please contact <a href="mailto:${contactEmail}">${contactEmail}</a>.`,
    userDashboardNotExist: `Under Construction - Please contact CIA team <a href="javascript:void(0)">${contactEmail}</a> if you have any questions.`,
    groupDashboardNotExist: `Under Construction - Please contact CIA Team <a href="javascript:void(0)">${contactEmail}</a> to get timelines.`,
    noInsightFound: 'Sorry, no results were found. You are not a member of an associated team or the search criteria is too strict. Either join a new team to start seeing what #TYPE#s they are following or loosen your search criteria.',
    noChildGroupsFound: 'No child groups found.',
    noApplicationsFound: 'No applications found.',
    noContentFound: 'Content is only visible to group members. Please request to join the group, or reach out to the Responsible/Accountable group member(s) for more information.',
    retiredGroupMessage: 'Sorry, either this group is not active yet or has been "Retired".',
    contentAccessError: 'You don\'t have access to this content - if you like to to gain access you must be a member of at least one of the following groups with an appropriate RACI role for the content. To get started, click on one of the group(s) below and then click on the "Request to Join Group" button to make an access request.The group\'s Responsible and Accountable members will be notified and are responsible for acting on your access request to their content.'
  },
  // Define all storage related constants.
  storage: {
    cartItems: 'CART_ITEMS'
  },
  // Define all drupal data types.
  drupalDataType: {
    // Define all groups.
    group: {
      functional: 'functional',
      businessProcess: 'business_process',
      location: 'location',
      productTeam: 'product_team'
    },
    // Define all content types.
    // For Quality investigations qi & qis both are used.
    contentType: {
      process: 'process',
      sipoc: 'sipoc',
      loc: 'local_operating_company',
      qi: 'quality_investigations',
      qis: 'quality_investigations',
      crsac: 'clinical_research_site_activity',
      esp: 'external_service_provider',
      findings: 'findings',
      audits: 'audits',
      dl: 'discovery_location',
      capas: 'capas',
      article: 'article'
    },
    // Define all content type labels against backend content type's machine name.
    // Property short used for entity dropdown in search page.
    // Property long used for quick links tab search & insights links in home page.
    // Property insightPage only exist if corresponding insights page exist.
    // The insightPage contains the partial url to build "Insights" section in home page.
    contentTypeLabels: {
      article: { short: 'Article', long: 'Article' },
      audits: { short: 'Audit', long: 'Audit' },
      capas: { short: 'CAPA', long: 'CAPA' },
      clinical_research_site_activity: { short: 'CRSA', long: 'Clinical Research Site Activity', insightPage: 'crsa' },
      discovery_location: { short: 'DL', long: 'Discovery Location' },
      external_service_provider: { short: 'ESP', long: 'External Service Provider', insightPage: 'esp' },
      findings: { short: 'Finding', long: 'Finding' },
      local_operating_company: { short: 'LOC', long: 'Local Operating Company', insightPage: 'loc' },
      process: { short: 'Process', long: 'Process' },
      quality_investigations: { short: 'QI', long: 'Quality Investigations' }
    }
  },
  // Define all drupal type names.
  drupalTypename: {
    groupFunctional: 'Functional',
    groupBusinessProcess: 'Business Process',
    groupLocation: 'Location',
    groupProductTeam: 'Product Team',
    process: 'NodeProcess',
    loc: 'NodeLocalOperatingCompany',
    qi: 'NodeQualityInvestigations',
    crsac: 'NodeClinicalResearchSiteActivity',
    esp: 'NodeExternalServiceProvider',
    findings: 'NodeFindings',
    audits: 'NodeAudits',
    dl: 'NodeDiscoveryLocation',
    capas: 'NodeCapas',
    article: 'NodeArticle'
  },
  // Define all drupal user roles.
  drupalRoles: {
    administrator: 'Administrator',
    webformDesigner: 'WebForm Designer',
    jrAdmin: 'Jr Admin',
    groupAdmin: 'Group Admin',
    feedEngineer: 'Feed Engineer',
    contentAuthor: 'Content Author',
    scenariosAuto: 'Scenarios Automation Engineer'
  },
  // Define all graphQL query id for different entities.
  // The :1 at the end is needed for versioning purposes
  drupalQueryIds: {
    auditsQuery: '5ccb5683a3afe28e7baef327590314571fc8ff04:1',
    capaQuery: '43addd428da62aac43128ee0e3f85cfca5a39f05:1',
    CRSACQuery: 'bf732964ef00087e3507da42e5139d1d3859dee1:1',
    CRSACInsightQuery: '7b17d1e452ccf9d5f8625c1a0c3357ee882c105f:1',
    discLocQuery: 'fd3052570fcf09a678382e8133c8d12c0dfea8b9:1',
    ESPQuery: 'b20da1a1c3f8e67d01043fbd9066749444083383:1',
    ESPInsightQuery: '98761976014c5282b36fb8e1acdefc3cdaa16174:1',
    findingsQuery: 'c1052ac896b528c26862242f42901e3d11383e02:1',
    generalSearchQuery: '37bcfa8700caaa657f9a2a200004a35518ead3f4:1',
    groupOverviewQuery: '623ed7d51c4b6ac89db474eab94f62205066420d:1',
    groupContentListQuery: '9ba2b216b78bf92d95ceb481194a72ca13e9f2de:1',
    groupQueryById: 'ab333889dbc29f63626aea1d33d59c2e244bafe5:1',
    groupsQueryByIds: '639737d01bb0e4db4f7e5ecf5563b6daf8627a43:1',
    LOCQuery: 'cf4be7b1b139c3a395ad6fa7574648980aae47a4:1',
    LOCInsightQuery: '1abc6d5a20263becffbbe3c9e6b7f7fecf105e32:1',
    menuQuery: 'cdc7e5ad20c0f5c888dfbc68ddca48f4365e767d:1',
    myFavoriteQuery: '6a525f32a99fb435a186d80d40e808940d89a709:1',
    navbarQuery: '6aea7ca20d76598bf89917a4ea1ad35ed36f713e:1',
    rootSectorQuery: 'b759c465f0830de1a8f228e4a1c4b94b0df0fb8d:1',
    processChainQuery: '4b18300121c7fab526950be13c460fb3cb08597f:1',
    processQuery: 'ef004cca96b1bcc6e3ceec84414d52f683ae2016:1',
    processQueryGroup: '38313d5b43c47d65dbb0e86da31820fdbb4a9f61:1',
    processQueryCT: '8b78b3356f3b6181e9b49c84f6b97f820044e50a:1',
    processQueryIds: '73f882d1cbd3c87beb65a4b2ae98ae4739f8e79a:1',
    profileQuery: '64d05c93709fcd0e43a88e6a9f50e4865f4141e6:1',
    predictiveSearch: '9dc9d71e3cfda6835f330bcae44c7a72e232ce37:1',
    QIQuery: '4f243c20e004ec9540d3c81025c7a4ab23183c46:1',
    RACIQuery: 'a8610de456ecf3b2057ab6e294dbc5c7e5cfa082:1',
    searchQuery: '93b06394d58e40bf7154c8d704d7218a85065815:1',
    shoppingCartQuery: '07af5fbb9b11bf68ff8260c8c364b32f4c489116:1',
    sipocQuery: 'b1ac266878c0081276263b2b5a7a163528be1a3e:1',
    groupOrContentIdResolverQuery: 'bb76be889ba48d6ebcbcc13065ba7505694e216e:1',
    articleQuery: '2fd18683bd37f2f7e8116ae8c264094fa1b22850:1',
    userGroupsListRoleQuery: '23738e987e78ba973a46939277c645fa8f2f93c9:1',
    allGroupsByUserQuery: '76374c95367518b75c89f64e97f779ace2220359:1',
    moveGroupDetailQuery: 'daff2c3cf96d378cc825a4d84aa41beb60ef1aed:1',
    groupUserGroupsByUidRA: '52df894bc7c7b70a5ff5b576a7d8caaaa9ff56d7:1',
    announcementListQuery: '12abbcbdb9dc6bb03796cd28f77dde2354322fdb:1',
    announcementQuery: '0fe10712fbb704b83b666fa415dc0ed6b239a590:1',
    revisionListQuery: '11cb85f82f55545366da56cefc722e7e06c3cef1:1',
    revisionQuery: 'c9fec9b315ba4eac1655bbbf3e532f8ac425d11f:1'
  },
  // Various user permission for drupal.
  drupalUserPermissions: {
    bypassContentAccessControl: 'Bypass content access control'
  },
  // Define Miscellaneous constants.
  misc: {
    idleTimeLimit: 5,
    defaultRecordCount: 50,
    defaultHomeRecordCount: 10,
    defaultInboxRecordCount: 10,
    defaultGroupContentCount: 1000,
    cloudFrontUrl: 'https://d2ejq6x9ved1pb.cloudfront.net/',
    maxCartItem: 100,
    maxAddMemberCount: 100,
    myFavoriteUrl: '/my-favorites',
    logoutUrl: '/webform/logout_survey/share/iframe-resizer/4.2.10',
    retiredLifeCycle: 'Retired',
    // Define year labels for insight pages.
    selectYearLabels: {
      any: { label: 'Any', value: 'any' },
      earliest: { label: 'Earliest Date (3 years from today)', value: 'earliest' },
      latest: { label: 'Latest Date (today)', value: 'latest' }
    },
    uniqueUserAssociatedAlt: 'number of associated users',
    uniqueAppAssociatedAlt: 'number of associated apps',
    gxpLabel: 'GxP',
    nonGxpLabel: 'Non-GxP',
    recentSearchLabel: 'Recent Searches',
    // Define business & content team member constants.
    groupTeamMemberData: {
      businessTeam: {
        title: 'Business Team',
        subTitle: 'Responsible for executing the vision',
        limit: 5,
        noMemberMessage: 'No member present'
      },
      businessTeamRoles: ['Responsible', 'Accountable', 'Consulted', 'Informed']
    },
    // Define favorite related constants.
    favoritesLimit: {
      group: 48,
      node: 48,
      feedbackMessageTimeout: 5,
      groupDisplayLimit: 12,
      nodeDisplayLimit: 12
    },
    // Define all webform urls.
    webFormUrls: {
      addAndRequestGroup: '/webform/add_group/share/iframe-resizer/4.2.10?parent_gid={GID}&group_add_permission={permission}',
      groupEditUrl: '/webform/manage_group/share/iframe-resizer/4.2.10?group_id={GID}',
      addApplication: '/webform/add_new_application/share/iframe-resizer/4.2.10?parent_gid={GID}',
      addExistingApplication: '/webform/add_existing_application/share/iframe-resizer/4.2.10?parent_gid={GID}',
      ManageApplication: '/webform/manage_application_details/share/iframe-resizer/4.2.10?app_id={NID}&parent_gid={GID}',
      ManageApplicationCoverImage: '/webform/manage_application_cover_image/share/iframe-resizer/4.2.10?app_id={NID}&parent_gid={GID}',
      ManageApplicationTags: '/webform/manage_application_tags/share/iframe-resizer/4.2.10?app_id={NID}&parent_gid={GID}',
      addAnnouncement: '/webform/add_announcement/share/iframe-resizer/4.2.10'
    },
    // After days just added will be disappeared.
    justAddedValidityDays: 7,
    seeAllResultString: 'See All Results (COUNT)',
    noResultFoundString: 'No Results',
    dateFormat: {
      timeZone: 'US/Eastern',
      dateTime: 'MM/DD/YYYY - HH:mm',
      dayDateTime: 'ddd, MM/DD/YYYY - HH:mm',
      date: 'MM/DD/YYYY',
      dateLong: 'MMM DD YYYY'
    },
    anonymousUser: 'Anonymous'
  }
}
