import { Typography } from '@mui/material'
import React from 'react'
import { constants } from '../../../../lib'

// Generate primary location for ESP.
// Fields use:
// - title
// - address1
// - address2
// - city
// - country
// - Lat, Lon
export function printPrimaryLocation (location) {
  return (
    <div>
      <Typography className='fieldTitle'>
        {location.title}
      </Typography>
      <Typography className='field'>
        {location.address1 || constants.filter.none}
      </Typography>
      <Typography className='field'>
        {location.address2 || constants.filter.none}
      </Typography>
      <Typography className='field'>
        {location.city || constants.filter.none}, {location.state || constants.filter.none}
      </Typography>
      <Typography className='field'>
        {location.country || constants.filter.none}
      </Typography>
      <Typography className='fieldTitle'>
        Lat, Lon :
      </Typography>
      <Typography className='field'>
        {location.lat || constants.filter.none} , {location.lon || constants.filter.none}
      </Typography>
    </div>
  )
}
