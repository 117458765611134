import moment from 'moment'
import 'moment-timezone'
import { constants } from '../../../lib'

// Function to generate diff data.
export const generateFieldData = (revision, hasNone = true) => {
  // None can be blank while doing comparison but indicate none string for view revision.
  const noneString = hasNone ? constants.filter.none : ''
  // Mapping fields.
  const diffData = {
    title: revision?.title || noneString,
    vision: revision?.fieldVision?.value || noneString,
    details: revision?.body?.value || noneString,
    applicationUrl: revision?.fieldEmbedExternalUrl?.uri || noneString,
    status: revision?.fieldIsPublished ? 'Public' : 'Private',
    lifecycleState: revision?.fieldLifeCycle?.entity?.entityLabel || noneString,
    accessInstructions: revision?.fieldAccessInstructions?.value || noneString,
    isGxp: revision?.fieldIsGxp[0] === 'yes' ? 'Yes' : 'No',
    isApplication: revision?.fieldIsApplication[0] === 'yes' ? 'Yes' : 'No',
    tags: revision?.fieldTags?.map(tag => tag.entity?.title).join('; ') || noneString,
    owningGroup: revision?.fieldOwningGroup?.entity?.title || noneString
  }
  // Owner or creator, as structure are different.
  const ownerOrCreator = revision?.owner === null ? revision?.creator : revision?.owner?.entity
  if (ownerOrCreator?.displayName) {
    diffData.owner = `${ownerOrCreator?.displayName} - (${ownerOrCreator?.username})`
  } else if (ownerOrCreator?.username) {
    diffData.owner = ownerOrCreator?.username
  } else {
    diffData.owner = 'Anonymous'
  }
  // Return the difference.
  return diffData
}

// Function to return formatted date.
export const getFormattedDate = (date, weekDay) => {
  const { dateFormat } = constants.misc
  let format = dateFormat.dateTime
  if (weekDay) {
    format = dateFormat.dayDateTime
  }
  return moment(date).tz(dateFormat.timeZone).format(format)
}

// Function to get username from revision.
export const getUsername = revision => {
  const userEntity = revision?.revisionUid?.entity
  if (userEntity?.displayName) {
    return `${userEntity?.displayName} (${userEntity?.username})`
  } else if (userEntity?.username) {
    return userEntity?.username
  } else {
    return 'Anonymous'
  }
}

// Mapping of attributes along with their labels.
export const revisionLabels = {
  title: 'Title',
  vision: 'Vision',
  details: 'Details',
  applicationUrl: 'Link to Application',
  status: 'Status',
  lifecycleState: 'Lifecycle State',
  accessInstructions: 'Access Instructions',
  isGxp: 'Is Gxp?',
  isApplication: 'Is Application?',
  tags: 'Tags',
  owningGroup: 'Owning Group',
  owner: 'Application Owner',
  revisionCreated: 'Last Update in Voyager'
}
