import React, { createContext, useEffect, useState } from 'react'
import { constants } from '../lib'

export const ShoppingCartContext = createContext()

export const ShoppingCartProvider = ({ children }) => {
  // Set Shopping Cart Items.
  const [shoppingCartItems, setShoppingCartItems] = useState(new Map())
  // Set Email Recipients.
  const [emailRecipients, setEmailRecipients] = useState(new Map())

  useEffect(() => {
    if (shoppingCartItems.size === 0) {
      const currentCart = new Map()
      let previousCart = localStorage.getItem(constants.storage.cartItems)

      if (previousCart === '[]') {
        previousCart = null
      }

      if (previousCart) {
        JSON.parse(previousCart).forEach(([key, value]) => {
          currentCart.set(key, value)
        })
        setShoppingCartItems(currentCart)
      }
    }
  }, [shoppingCartItems])
  // Function to add item to cart.
  const addItemToCart = item => {
    setShoppingCartItems(new Map(shoppingCartItems.set(`${item.resultType}_${item.id}`, item)))
    localStorage.setItem(constants.storage.cartItems, JSON.stringify([...shoppingCartItems]))
  }
  // Function to remove item from cart.
  const removeItem = itemId => {
    const clonedShoppingCartItems = new Map(shoppingCartItems)
    clonedShoppingCartItems.delete(itemId)

    localStorage.setItem(constants.storage.cartItems, JSON.stringify([...clonedShoppingCartItems]))
    setShoppingCartItems(clonedShoppingCartItems)
    removeRecipient(itemId)
  }
  // Function to clear cart items.
  const clearCart = () => {
    localStorage.clear(constants.storage.cartItems)
    setShoppingCartItems(new Map())
    setEmailRecipients(new Map())
  }
  // Function to add email recipients.
  const addEmailRecipient = item => {
    setEmailRecipients(new Map(emailRecipients.set(item.id, item)))
  }
  // Function to remove recipients.
  const removeRecipient = itemId => {
    const clonedEmailRecipients = new Map(emailRecipients)
    clonedEmailRecipients.delete(itemId)
    setEmailRecipients(clonedEmailRecipients)
  }
  // Return Shopping Cart Context.
  return (
    <ShoppingCartContext.Provider
      value={{
        addItemToCart,
        removeItem,
        clearCart,
        shoppingCartItems,

        addEmailRecipient,
        removeRecipient,
        emailRecipients
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}
