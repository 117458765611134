const generateRootSectorOrGlobal = (groups, isGlobal, dataOnly) => {
  // Return global tag if isGLobal is true.
  if (isGlobal && !dataOnly) {
    return <div className='tag global'>Global</div>
  }

  // Return null if no groups supplied.
  if (!groups) {
    return null
  }

  // Initialize uniqueTargetIds.
  const uniqueTargetIds = []

  // Initialize rootSectors.
  const rootSectors = []

  // Loop through all the groups received
  groups.forEach(element => {
    const targetId = element?.fieldOwningSector?.targetId
    if (!uniqueTargetIds.includes(targetId)) {
      uniqueTargetIds.push(targetId)
      if (element?.fieldOwningSector?.entity?.title) {
        rootSectors.push(element?.fieldOwningSector)
      }
    }
  })

  // Sort according to field rootSector.
  rootSectors.sort((a, b) => parseInt(a.entity?.fieldRootSector || 0) - parseInt(b.entity?.fieldRootSector || 0))

  if (!dataOnly) {
    // Map over to generate tags.
    return rootSectors.map(tag => (
      <div className='tag' key={tag?.targetId} style={{ backgroundColor: tag?.entity?.fieldRootSectorColor }}>{tag?.entity?.title}</div>
    ))
  } else {
    return rootSectors.map(tag => ({
      id: `${tag?.targetId}`,
      location: `${window.location.origin}${tag?.entity?.url?.path}`,
      title: tag?.entity?.title
    }))
  }
}
// Export the function.
export default generateRootSectorOrGlobal
