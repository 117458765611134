import React from 'react'
import { constants } from '../../../lib'

// Export the component for use.
// Each row contain image, title, vision, description, GxP/Non-GxP, sector.
// Sector color coming from backend and which is configurable.
export default function ArticleResults ({ item }) {
  // Return the output.
  return (
    <div className={`search_inner_section ${item.resultType} ${item.bundle}`}>
      <div className="outer_section_left">
        {item.cardImage && <img src={item.cardImage} alt={item.title} />}
      </div>
      <div className="outer_section_right">
        <div className="section_left">
          <div className="sections">
            <label className="heading">{item.title}</label>
            <span className="section_lbl application_lbl" style={{ backgroundColor: item.sectorColor }}>{item.sectorLabel}</span>
          </div>
          <div className="section_vision">
            <span className="bld">Vision:</span>
            {item.vision ? <span className="long_text" dangerouslySetInnerHTML={{ __html: item.vision }} /> : constants.filter.none}
          </div>
          <div className="section_details">
            <div className="section-inside">
              <span className="bld">Details:</span>
              {item.description ? <span className="long_text" dangerouslySetInnerHTML={{ __html: item.description }} /> : constants.filter.none}
            </div>
            <div className="lbl_gxp">{item.gxp['0'] === 'yes' ? constants.misc.gxpLabel : constants.misc.nonGxpLabel}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
