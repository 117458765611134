import React, { useContext, useEffect, useState } from 'react'
// Import the necessary component.
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Modal } from '@mui/material'
import moment from 'moment'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { UserContext } from '../../../context/UserContext'
import Loader from '../../../PageLoader'
import generateRootSectorOrGlobal from '../../Assets/component/GenerateRootSectorOrGlobal'
import ProfileImage from '../../../ProfileImage'
import { downloadPhotoIndividual, userAbbreviation } from '../../../ProfileImage/utility'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArchiveIcon from '../../../../images/archive_icon.svg'
import ScheduledIcon from '../../../../images/scheduled_icon.svg'
import { Link, useParams } from 'react-router-dom'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ArchiveButtonAndModal from './ArchiveButtonAndModal'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

// Define the style of the modal.
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24
}

// Build the component.
const AnnouncementDetails = ({ groupUserGroupsByUidRA, profileImageData, setErrorMessage, setProfileImageData, backClick, setFeedbackMessage }) => {
  // Set announcement data.
  const [data, setData] = useState()
  // Set isLoading.
  const [isLoading, setIsLoading] = useState(true)
  // Get id, nid from path parameter.
  const { nid } = useParams()
  // Set isAccessChecked.
  const [isAccessChecked, setIsAccessChecked] = useState(false)
  // Set imageModalUrl.
  const [imageModalUrl, setImageModalUrl] = useState('')
  // Set isEligible to be archived.
  const [isEligibleForArchive, setIsEligibleForArchive] = useState(false)
  // Getting userId and profilePic from context.
  const { userId, otherDetails } = useContext(UserContext)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  const grantAccess = info => {
    setIsAccessChecked(true)
    setData(info)
    return true
  }
  useEffect(() => {
    setIsLoading(true)
    // Prepare query variable.
    const groupsQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.announcementQuery}&variables=${JSON.stringify({ id: nid })}`
    // Fire the query.
    queryContent(groupsQuery)
      .then(async response => {
        // Initiate access flag as false.
        let hasAccess = false
        // If no announcement is found with the given nid.
        if (response.data.nodeQuery.count === 0) {
          setIsAccessChecked(hasAccess)
        } else {
          const info = response.data.nodeQuery?.entities[0]
          // If this is a scheduled/inactive announcement.
          if (info?.fieldStatus !== 'archive') {
            // If global announcement check if the user root group RA.
            if (info?.fieldIsGroupOrGlobal === 'global') {
              const queryResponse = await queryContent(`${process.env.REACT_APP_TARGET_URL}/api/is-root-group-ra`)
              // Check the access for schedule global announcement.
              if (info?.fieldStatus === 'inactive' && queryResponse.status) {
                hasAccess = true
              }
              // Eligible for global announcement archive.
              if (queryResponse.status) {
                setIsEligibleForArchive(true)
              }
            } else {
              // If user is RA of any of the groups associated with.
              const postedInGroups = info?.fieldGroups?.entities || []
              postedInGroups.some(group => {
                if (groupUserGroupsByUidRA.includes(parseInt(group.id))) {
                  // Eligible for group announcement archive.
                  setIsEligibleForArchive(true)
                  // Check the access for schedule group announcement.
                  if (info?.fieldStatus === 'inactive') {
                    hasAccess = true
                    return true
                  }
                  return false
                }
                return false
              })
            }
            if (info?.fieldStatus === 'active') {
              hasAccess = true
            }
          } else {
            hasAccess = true
          }
          if (hasAccess) {
            // Grant the access.
            grantAccess(info)
            const siteSectors = generateRootSectorOrGlobal(info?.fieldGroups?.entities, info?.fieldIsGroupOrGlobal === 'global', true)
            // Sending page data for tracking.
            setPageData({
              type: 'inbox-announcements-individual',
              breadCrumbs: [{ text: 'Home', url: { path: '/' } }, { text: 'Announcement-Details' }],
              siteSector: siteSectors,
              page_id: nid
            })
            // If profile pic already present in profile image data object.
            if (info.owner.userId === userId) {
              setProfileImageData(prevProfileImageData => ({ ...prevProfileImageData, [info.owner.userId]: otherDetails.profilePicture }))
            } else {
              // Fire the query to download the profile picture.
              downloadPhotoIndividual(info.owner.username, '48x48')
                .then(res => {
                  // Add the downloaded profile pic in the profile image data object.
                  setProfileImageData(prevProfileImageData => ({ ...prevProfileImageData, [info.owner.userId]: res.profileImage }))
                })
            }
          }
        }
      })
      .catch(() => setErrorMessage(constants.errors.server))
      .finally(() => setIsLoading(false))
  }, [userId, otherDetails, nid])

  // Preparing profile picture.
  const appUserPicture = data?.owner?.userPicture?.userEmail
  // Get image name.
  const imageName = imageModalUrl.split('/').slice(-1)

  // Function to generate group tags.
  const generateGroupTags = groups => {
    return groups && groups.map(group => (
      <Chip
        label={group.title}
        variant='outlined'
        size='small'
        key={group.id}
      />)
    )
  }

  // Function to handleClose of modal.
  const handleClose = () => {
    setImageModalUrl('')
  }

  // Function to download the image.
  const downloadImage = () => {
    fetch(imageModalUrl, {
      method: 'GET',
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          // Set download url and attribute.
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', decodeURI(imageName))
          link.setAttribute('data-tracking', 'download-file')
          link.setAttribute('link', imageModalUrl)
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Set loader if data not received yet.
  if (isLoading) {
    return <div className='announcement_fullview'><Loader /></div>
  }
  // Return the output.
  return (
    <>
      {isAccessChecked
        ? <div className='announcement_fullview'>
          <div className='fullview_title_wrapper'>
            <div className='title'><ArrowBackIcon onClick={backClick} />{data.title}</div>
            <div className='date'>
              {data?.fieldStatus === 'archive' && <img src={ArchiveIcon} alt='archived' />}
              {data?.fieldStatus === 'inactive' && <img src={ScheduledIcon} alt='scheduled' />}
              {data?.fieldStatus === 'inactive' ? moment(new Date(data.fieldPlannedStartDate?.value)).format(constants.misc.dateFormat.dateLong) : data.entityChanged}
            </div>
          </div>
          <div className='fullview_details_wrapper'>
            <div className='person_info'>
              <div>
                <Avatar>
                  <ProfileImage
                    alt={userAbbreviation(data.owner.username)}
                    userPicture={appUserPicture}
                    profileImage={profileImageData[data.owner.userId]}
                  />
                </Avatar>
              </div>
              <div className='name'>{data.owner.displayName || data.owner.username}</div>
              <div className='separator'>&bull;</div>
              <div className='email'>{data.owner.userEmail.toLowerCase()}</div>
            </div>
            {data.fieldPriority.length ? <div className='priority'><ErrorOutlineIcon /> High Priority</div> : null}
            <div className='description' dangerouslySetInnerHTML={{ __html: data.body.value }}></div>
            {data.fieldIsGroupOrGlobal !== 'global' && <div className='group_info'>
              This announcement was posted to the following group(s):
              <div className='chip_wrapper'>{generateGroupTags(data.fieldGroups?.entities)}</div>
            </div>
            }
            <div className='tag_wrapper'>
              This announcement is associated with the following sector(s):
              <div className='tag_inner_wrapper'>{generateRootSectorOrGlobal(data.fieldGroups?.entities, data.fieldIsGroupOrGlobal === 'global', false)}</div>
            </div>
          </div>
          {((data.fieldExistingContent?.entities.filter(x => !!x).length || 0) + (data.fieldUploadImages.length || 0))
            ? <div className='attachments_wrapper'>
              <div className='title'>Attachments:</div>
              <div className='attachment_items'>
                {
                  data.fieldExistingContent && data.fieldExistingContent?.entities.filter(x => !!x).map((app, index) => (
                    <Card key={app?.nid}>
                      <CardMedia
                        sx={{ height: 160, width: 275 }}
                        image={app?.contentImage?.entity?.thumbnail?.derivative?.url || 'no image'} alt={`${app?.title} ${index}`} />
                      <CardContent>
                        <div>{app?.title}</div>
                      </CardContent>
                      <CardActions>
                        <p><Link to={app?.url?.path}>Go to application</Link></p>
                      </CardActions>
                    </Card>
                  ))
                }
                {
                  data.fieldUploadImages.map((image, index) => (
                    <Card key={image?.targetId}>
                      <CardMedia
                        sx={{ height: 160, width: 275 }}
                        image={image?.derivative?.url || 'no image'} alt={`${data.title} ${index}`} />
                      <CardContent>
                        <div>{decodeURI(image.url.split('/').slice(-1))}</div>
                      </CardContent>
                      <CardActions>
                        <p data-tracking='Preview' onClick={() => setImageModalUrl(image.url)}>Preview</p>
                      </CardActions>
                    </Card>
                  ))
                }
              </div>
            </div>
            : null}
          <div className='archive_wrapper'>
            {isEligibleForArchive && <ArchiveButtonAndModal subject={data?.title} postedDate={data?.entityChanged} scheduleDate={moment(new Date(data.fieldPlannedStartDate?.value)).format(constants.misc.dateFormat.dateLong)} status={data?.fieldStatus} setFeedbackMessage={setFeedbackMessage} />}
          </div>
        </div>
        : <div className='announcement_fullview no-access'>You do not have access to view this announcement.</div>
      }
      {data?.title && <Modal
        open={imageModalUrl.length !== 0}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='preview_modal' sx={style}>
          <div className='preview_modal_user'>
            <div className='img'>
              <Avatar>
                <ProfileImage
                  alt={userAbbreviation(data?.owner?.username || '')}
                  userPicture={appUserPicture}
                  profileImage={profileImageData[data?.owner?.userId]}
                />
              </Avatar>
            </div>
            <div className='owner_name'>
              <p className='owner'>{data?.owner.displayName || data?.owner.username}</p>
              <p className='img_name'>{decodeURI(imageName)}</p>
            </div>
            <div className='close' onClick={handleClose}><CloseRoundedIcon fontSize='large' /></div>
          </div>
          <img src={imageModalUrl} height={800} width={800} alt={`${data?.title}`} />
          <div className='download_btn'>
            <Button variant='outlined' onClick={downloadImage}>Download</Button>
          </div>
        </Box>
      </Modal>}
    </>
  )
}

// Exporting the component.
export default AnnouncementDetails
