import React from 'react'
// Import necessary packages.
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Alert } from '@mui/material'

// Build the component.
// This header will be used for
// - Revision listing.
// - Individual revision view.
// - Revision comparison.
const RevisionHeader = ({ breadCrumb, feedbackMessage, gid }) => {
  // Generate the gid query string to append with application in breadcrumb.
  const gidString = gid ? `?gid=${gid}` : ''
  return (
    <div className='page_header_wrapper'>
      {feedbackMessage?.message && <div className="fab_details_alert">
        <Alert severity={feedbackMessage.type} icon={feedbackMessage?.icon} >
          {feedbackMessage?.message}
        </Alert>
      </div>}
      <div className="breadcrumb_section_wrapper">
        <ul className="breadcrumb_section">
          {breadCrumb && breadCrumb?.map((item, index) => {
            return <li key={index} className="breadcrumb_item">
              <>
                <Link to={breadCrumb.length - 1 > index ? item?.url?.path : `${item?.url?.path}${gidString}`}>{item?.text}</Link>
                <span className="breadcrumb_icon">
                  <ChevronRightIcon />
                </span>
              </>
            </li>
          })}
          <li key="last-item" className="breadcrumb_item">Revision History</li>
        </ul>
      </div>
      <div className='page_header_inner'>
        <div className='page_header_heading_wrapper'>
          <div className='page_header_heading'>Revision History</div>
        </div>
      </div>
    </div>
  )
}

// Export the component for further use.
export default RevisionHeader
