import { DialogContent, DialogContentText, Typography } from '@mui/material'
import IframeResizer from 'iframe-resizer-react'
import React, { useContext, useState } from 'react'
import { constants } from '../../../../lib'
import Loader from '../../../PageLoader'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

// Build the component.
const EditGroup = ({ gid, crumbs }) => {
  // Set formLoading.
  const [formLoading, setFormLoading] = useState(true)
  // Process the url.
  let url = constants.misc.webFormUrls.groupEditUrl
  url = url.replace('{GID}', gid)
  url = `${process.env.REACT_APP_TARGET_URL}${url}`
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  // Determine form loaded.
  const formLoaded = () => {
    // Sending page data for tracking.
    setPageData({
      type: 'manage-group',
      breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: 'Group Details' }],
      page_id: gid
    })
    setFormLoading(false)
  }
  // Return the output.
  return (
    <div className='dialog-modal'>
      <DialogContent>
        {formLoading ? <Loader /> : null}
        <DialogContentText>
          <Typography className='field'>
            <IframeResizer
              src={url}
              style={{ width: '1px', minWidth: '100%' }}
              className="webform-share-iframe"
              frameBorder="0"
              allow="geolocation; microphone; camera"
              allowtransparency="true"
              onLoad={formLoaded}
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </div>
  )
}
// Export the component for use.
export default EditGroup
