import React, { useState, useEffect } from 'react'
// Making necessary imports.
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import '../sass/component/page-about-details.scss'
import { constants } from '../../../../lib'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

// Defining the PageAboutDetails for the nextGen.
const PageAboutDetails = ({ vision, date, lifeCycle, entity, heading, lastChanged, owner, gid, nid }) => {
  // Set expandedView value.
  const [expandedView, setExpandedView] = useState(false)
  // Set show read more value.
  const [showReadMore, setShowReadMore] = useState(false)
  // Conditional checking for read more.
  useEffect(() => {
    const element = document.getElementById('vision_text_wrapper')
    const firstChild = element.childNodes[0]
    const childCount = element.childElementCount
    setShowReadMore((firstChild.scrollHeight > firstChild.clientHeight) || childCount > 1)
  }, [vision])

  // Return the output.
  return (
    <div className="about_detailspage_wrapper">
      <div className="about_heading">{heading}</div>
      <div className="vision_wrapper">
        {lifeCycle && <div className="inception_section life_cycle">
          <span className="inception_text">Lifecycle State</span>
          <span className="inception_date bottom_padding">{lifeCycle}</span>
        </div>}
        <div><div className="vision">Vision</div>
          <div id="vision_text_wrapper" className={expandedView ? 'vision_text' : 'vision_text limited_text'} dangerouslySetInnerHTML={{ __html: vision || constants.filter.none }}></div>
          {showReadMore && <div className="vision_readmore" onClick={() => setExpandedView(!expandedView)}>{expandedView ? 'Read less' : 'Read more'} {expandedView ? <ExpandLess /> : <ExpandMore />} </div>}
        </div>
        {entity !== 'Application' && <div className="inception_section">
          <span className="inception_text">Inception Date</span>
          <span className="inception_date">{date ? moment(new Date(date)).format(constants.misc.dateFormat.date) : constants.filter.none}</span>
        </div>}
        {entity === 'Application' && <div className="last_updated">
          <span className="inception_text">Last update in Voyager</span>
          <span className="inception_date">{lastChanged ? moment(lastChanged).tz(constants.misc.dateFormat.timeZone).format(constants.misc.dateFormat.dateLong) : constants.filter.none}</span>
        </div>}
        {entity === 'Application' && <div className="application_owner">
          <span className="inception_text">Application Owner</span>
          <span className="owner_name"><a href={`mailto:${owner?.userEmail || constants.contactEmail}`}>{owner?.displayName ? `${owner?.displayName} (${owner?.username})` : owner?.username || 'Anonymous'}</a></span>
        </div>}
        {entity === 'Application' && <div className="application_history">
          <span className="inception_text">Application History</span>
          <span className="revision_history"><Link to={`/revision-history/${gid}/application/${nid}/view/all`}>View Revision History</Link></span>
        </div>}
      </div>
    </div>
  )
}

// Return the component for use.
export default PageAboutDetails
