import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import './externalServiceProvider.scss'
import { Tab, Tabs, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import queryString from 'query-string'
import StarRating from '../../../StarRating'
import itemImage from '../../../../images/esp_icon.png'
import logoDefault from '../../../../images/office.jpg'
import { Audits } from '../Audits'
import { Findings } from '../Findings'
import { QualityInvestigations } from '../QualityInvestigations'
import { CAPAs } from '../CAPAs'
import { printPrimaryLocation } from '../CTHelpers/CSLHelper'
import { constants } from '../../../../lib'
import { UserContext } from '../../../context/UserContext'
import { CTErrorPage, makeNoAccessRows } from '../CTHelpers/CTErrorPage'
import { checkAccess, queryContent } from '../CTHelpers/CheckAccess'
import Loader from '../../../PageLoader'
import EmbedExternalUrl from '../../../EmbedExternalUrl'
import ContentManageButton from '../../contentManageButton'
import EmbedShoppingCart from '../../../ShoppingCart/embedShoppingCart'
import EntityUsageTabs from '../../entityUsageTabs'

// Add css for StyledTab.
const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    borderRight: '1px solid grey',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)
// Add css for LastTab.
const LastTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)
// Add css for contentTopStyle.
const contentTopStyle = {
  paddingTop: '20px'
}
// Export the ExternalServiceProvider data.
export const ExternalServiceProvider = ({ id }) => {
  // Set CTName value.
  const CTName = 'External Service Provider'
  // Set pageTabs data.
  const pageTabs = { home: 0, audits: 1, findings: 2, qis: 3, capas: 4, associations: 5 }
  // Set data.
  const [data, setData] = useState()
  // Set reference entity usage/association data.
  const [usageData, setUsageData] = useState()
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set selectedTab.
  const [selectedTab, setSelectedTab] = useState(0)
  // Set if has access or not.
  const [onlyNoAccess, setOnlyNoAccess] = useState(false)
  // Set if have null value error.
  const [hasNullValue, setHasNullValue] = useState(false)
  // Set if have availability error.
  const [notAvailable, setNotAvailable] = useState()
  // Fetch userRoles from UserContext.
  const { userRoles, userName, otherDetails } = useContext(UserContext)
  // Set location.
  const location = useLocation()
  // Set history.
  const history = useNavigate()
  // Set defaultAlt value.
  const defaultAlt = 'company-logo'
  // Get the hash for tab.
  const parsedHash = Object.keys(queryString.parse(location.hash))
  // Set initialTab value.
  let initialTab = 0
  if (parsedHash.length && typeof (pageTabs[parsedHash[0]]) !== 'undefined') {
    // Update initialTab value.
    initialTab = pageTabs[parsedHash[0]]
  }
  // Function for change operation.
  const handleChange = (event, newValue) => {
    if (initialTab !== newValue) {
      initialTab = newValue
      location.hash = Object.keys(pageTabs).find(key => pageTabs[key] === newValue)
      history(location)
    }
  }
  // Set selected tab based on hash.
  useEffect(() => {
    setSelectedTab(initialTab)
  }, [initialTab])
  // Check node access when ids are available.
  useEffect(() => {
    if (!id) {
      setHasNullValue(true)
      setIsLoading(false)
    } else {
      const promise = checkAccess(id)
      const show = []
      const hide = []
      promise.then(obj => {
        // Show data if have access.
        if (obj.access) {
          show.push(obj.item)
        } else {
          // Hide data or generate error base on code received.
          if (obj.error === 403) {
            hide.push(obj.item)
          }
          if (obj.error === 404) {
            setHasLoadingError(true)
            setIsLoading(false)
          }
        }
      }).then(() => {
        // If user have access.
        if (show && show.length > 0) {
          // Build the payload for api.
          const ESPVariable = JSON.stringify({
            id: show[0]
          })
          // Call graphql for ESPQuery.
          const ESPQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.ESPQuery}&variables=${ESPVariable}`
          // Query Call
          queryContent(ESPQuery)
            .then(response => {
              if (!response?.data) {
                setHasLoadingError(true)
              } else {
                // Set data based on nodeQuery.
                setData(response.data)
                // Set usage/association data based on response.
                setUsageData(response.data.entityUsageContent.results[0])
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
        } else {
          if (!hide || hide.length === 0) {
            setHasLoadingError(true)
            setIsLoading(false)
          } else {
            // Generate proper user information.
            const userInfo = userName || otherDetails.name
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setOnlyNoAccess(true)
            setNotAvailable(noAccessRows)
            setIsLoading(false)
          }
        }
      })
    }
  }, [id, userName, otherDetails.name])
  // Display error message if null value received.
  if (hasNullValue && !isLoading) {
    // Set message.
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.nullValue }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }
  // Display error message if don't have access.
  if (onlyNoAccess && !isLoading && notAvailable) {
    return (
      <CTErrorPage ct={`${CTName}`} message={notAvailable} />
    )
  }
  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    // Set message.
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.invalidContent }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }
  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Return entire data for display purpose.
  // Tabs used:
  // - Home.
  // - Audits.
  // - Findings.
  // - QualityInvestigations.
  // - CAPAs.
  // - Associations.
  // Display manage button based on user role.
  return !isLoading && data && (
    <div className='content-home tab-content-wrapper'>
      <div className="header--esp-wrapper">
        <img className='header--esp' src={data.nodeById?.companyLogo?.url || logoDefault} alt={data.nodeById?.companyLogo?.alt || defaultAlt} style={data.nodeById?.companyLogo?.width ? { width: '383px' } : null} />
      </div>
      <div className='content-company-name'>
        <img className='content-image' src={itemImage} alt={data?.nodeById?.entityLabel || 'ESP'} />
        <div display='flex' flex-direction='column'>
          <div>
            <div className='esp-title'>
              {data?.nodeById?.entityLabel}
              {data.nodeById.companyWebsite?.title
                ? <a className='esp-website' href={`${data.nodeById.companyWebsite?.title}`}>
                  <Link />
                </a>
                : null
              }
            </div>
          </div>
          {data?.nodeById.fieldStarRating !== null && (<div className='esp-star-rating' display='flex' flex-direction='row'>
            <StarRating rating={data?.nodeById?.fieldStarRating / 2} />
            <div className='star-rating-text'>
              {data?.nodeById?.fieldStarRating / 2}/5
            </div>
          </div>)}
        </div>
        {
          userRoles.administrator ||
            userRoles.contentAuthor ||
            userRoles.feedEngineer ||
            userRoles.groupAdmin
            ? <ContentManageButton nid={id} />
            : <div />
        }
        <EmbedShoppingCart item={data.nodeById} image={itemImage} bundle="external_service_provider" />
      </div>
      <>
        <Tabs variant='fullWidth' value={selectedTab} onChange={handleChange} indicatorColor='primary'>
          <StyledTab label='Home' />
          <StyledTab label='Audits' />
          <StyledTab label='Findings' />
          <StyledTab label='Quality Investigations' />
          <StyledTab label='CAPAs' />
          <LastTab label='Associations' />
        </Tabs>
        {selectedTab === 0 &&
          <div className='content-home-wrapper content-data'>
            <div className="content-home-info--left-column">
              <div className='content-home-info--space-apart' style={contentTopStyle}>
                <Typography className="body-embed-url" color='primary'>
                  {data.nodeById.embedExternalUrl?.uri != null ? <EmbedExternalUrl url={data.nodeById.embedExternalUrl.uri} /> : ''}
                  {data.nodeById.body?.value != null ? <div dangerouslySetInnerHTML={{ __html: data.nodeById.body?.value }} /> : ''}
                </Typography>
                <Typography className='fieldCategory'>
                  Contacts
                </Typography>
                <Typography className='fieldTitle'>
                  Business Contact:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.businessContact?.entity.title}
                </Typography>
                <Typography className='fieldTitle'>
                  Quality Contact:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.qualityContact?.entity.title}
                </Typography>
              </div>
              <div className='content-home-info--space-apart' style={contentTopStyle}>
                <Typography className='fieldCategory'>
                  Location Information
                </Typography>
                {printPrimaryLocation(
                  {
                    title: 'External Service Provider Location',
                    address1: data.nodeById.address1,
                    address2: data.nodeById.address2,
                    city: data.nodeById.city?.entity.title,
                    state: data.nodeById.state?.entity.title,
                    country: data.nodeById.country?.entity.title,
                    lat: data.nodeById.lat,
                    lon: data.nodeById.lon
                  })}
                <Typography className='fieldTitle'>
                  Region:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.region?.map(({ entity }) => entity?.title).join(', ') || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  Audit Locations:
                </Typography>
                <div>
                  {data.nodeById.auditLocations?.map((i, key) => {
                    return (
                      <div>
                        <Typography className='field' key={key}>
                          <Typography><strong>{i?.organization}</strong></Typography>
                        </Typography>
                        <Typography className='field' key={key}>
                          {i?.addressLine1 || constants.filter.none}
                        </Typography>
                        <Typography className='field' key={key}>
                          {i?.addressLine2 || constants.filter.none}
                        </Typography>
                        <Typography className='field' key={key}>
                          {i?.town || constants.filter.none},
                          {i?.state || constants.filter.none},
                          {i?.postalCode || constants.filter.none}
                        </Typography>
                        <Typography className='field' key={key}>
                          {i?.country || constants.filter.none}
                        </Typography>
                        <br />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='content-home-info--space-apart' style={contentTopStyle}>
                <Typography className='fieldCategory'>
                  Additional Information
                </Typography>
                <Typography className='fieldTitle'>
                  Star Rating Model:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.starRatingModel?.entity?.title}
                </Typography>
                <Typography className='fieldTitle'>
                  ESP Type:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.espType?.map(item => item.entity.title).join(', ') || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  ESP Category:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.espCategory?.map(item => item.entity.title).join(', ') || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  ESP Commodity:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.espCommodity?.entity?.title || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  CL Assignment:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.clAssignment?.entity?.title || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  CL Rationale:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.clRationale?.value != null
                    ? <div dangerouslySetInnerHTML={{ __html: data.nodeById.clRationale?.value }} />
                    : constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  Supplier Ids:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.supplierId?.map(item => item).join(', ') || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  AIM Ids:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.aimId?.map(item => item).join(', ') || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  Natural Key:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.naturalKey || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  Parent ESP:
                </Typography>
                <Typography className='field'>
                  {data.nodeById.parentEsp?.entity?.title ? <Link to={data.nodeById.parentEsp?.entity?.url?.path}>{data.nodeById.parentEsp?.entity?.title}</Link> : constants.filter.none}
                </Typography>
              </div>
            </div>
            <div className='content-home-info--right-column' style={contentTopStyle}>
              <Typography className='fieldCategory'>
                Audit Location Names:
              </Typography>
              {data.nodeById.fieldAuditLocationName?.map(i => <ul className='alternative-names__remove-style-type'> {i?.entity.title} </ul>)}
            </div>
          </div>}
        {selectedTab === 1 &&
          <Audits ids={data.Audits.entities?.filter(n => n).map(({ nid }) => nid).join(',')} />}
        {selectedTab === 2 &&
          <Findings ids={data.Audits?.entities?.filter(n => n).map(props => props.Findings?.entities?.filter(n => n).map(({ nid }) => nid).join(',')).join(',')} />}
        {selectedTab === 3 &&
          <QualityInvestigations ids={data.QualityInvestigations.entities?.filter(n => n).map(({ nid }) => nid).join(',')} />}
        {selectedTab === 4 &&
          <CAPAs ids={data.CAPAs.entities?.filter(n => n).map(({ nid }) => nid).join(',')} />}
        {selectedTab === 5 &&
          <EntityUsageTabs groupUsage={data.nodeById.groups} contentUsage={usageData} />}
      </>
    </div>
  )
}
