// Configure and export entity type.
// Entity type label and class.
export const entityTypes = Object.seal({
  all: { label: 'All' },
  content: { label: 'Content', hideDisplay: true },
  article: { label: 'Application', class: 'ml-15' },
  audits: { label: 'Audits', class: 'ml-15', hideDisplay: true },
  capas: { label: 'CAPAs', class: 'ml-15', hideDisplay: true },
  clinical_research_site_activity: { label: 'Clinical Research Site Activity', class: 'ml-15', hideDisplay: true },
  discovery_location: { label: 'Discovery Location', class: 'ml-15', hideDisplay: true },
  external_service_provider: { label: 'External Service Provider', class: 'ml-15', hideDisplay: true },
  findings: { label: 'Findings', class: 'ml-15', hideDisplay: true },
  local_operating_company: { label: 'Local Operating Company', class: 'ml-15', hideDisplay: true },
  quality_investigations: { label: 'Quality Investigations', class: 'ml-15', hideDisplay: true },
  process: { label: 'Process', class: 'ml-15', hideDisplay: true },
  group: { label: 'Group' }
})

// Export the facet configuration.
// Placed all content types and there fields used for facet.
// Each fields may have below elements:
// - label
// - type
//  - checkboxes
//  - dateField (include start date and end date)
// - dataType (mention "number" where required, to sort it numerically)
// - searchLabel (whether search execute on label not in value)
// - hideDisplay (if true, then this field will not display as facet field)
// - combine (It a array of multiple fields, if user search for all mentioned fields, this filter will execute.)
// - generateValue (if true, then this field value will be generated based on constructValue)
export const facetNames = Object.seal({
  // Facet fields for all i.e. any group or content.
  all: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__field_root_sector: { label: 'Sectors', type: 'checkboxes' },
    group__field_root_sector: { label: 'Sectors', type: 'checkboxes', hideDisplay: true }
  },
  // Facet fields for all content.
  content: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' }
  },
  // Facet fields for article.
  article: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__field_root_sector: { label: 'Sectors', type: 'checkboxes' },
    content__group_users: { label: 'Content in my groups', type: 'checkboxes', displayType: 'switch' },
    content__field_is_gxp: { label: 'GxP', type: 'checkboxes', displayType: 'switch', defaultVal: 'yes' },
    content__field_tags__entity__name: { label: 'Tags', type: 'checkboxes' },
    content__field_life_cycle__entity__name: { label: 'Life Cycles', type: 'checkboxes' }
  },
  // Facet fields for audits.
  audits: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_audit_actual_start_date: { label: 'Audit Actual Start Date', type: 'dateField' },
    content__field_audit_actual_end_date: { label: 'Audit Actual End Date', type: 'dateField' },
    content__field_audit_type__entity__name: { label: 'Audit Type', type: 'checkboxes' },
    content__field_location_country__entity__name: { label: 'Audit Location Country', type: 'checkboxes' },
    content__field_region__entity__name: { label: 'Audit Location Region', type: 'checkboxes' },
    content__field_audit_published_date: { label: 'Audit Published Date', type: 'dateField' },
    content__field_audit_target_sector__entity__name: { label: 'Target Sector', type: 'checkboxes' },
    content__field_principal_investigator__entity__name: { label: 'Principal Investigator', type: 'checkboxes' },
    content__field_therapeutic_area_ta___entity__name: { label: 'Therapeutic Area', type: 'checkboxes' }
  },
  // Facet fields for capas.
  capas: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_capa_ext_due_date_status__entity__name: { label: 'CAPA Ext Due Date Status', type: 'checkboxes' },
    content__field_capa_original_due_date: { label: 'CAPA Original Due Date', type: 'dateField' },
    content__field_capa_root_cause_s__entity__name: { label: 'CAPA Root Cause(s)', type: 'checkboxes' },
    content__field_capa_owning_business_unit__entity__name: { label: 'CAPA Owning Business Unit', type: 'checkboxes' },
    content__field_capa_owning_segment__entity__name: { label: 'CAPA Owning Segment', type: 'checkboxes' },
    content__field_capa_owning_site_region__entity__name: { label: 'CAPA Owning Site Region', type: 'checkboxes' }
  },
  // Facet fields for clinical research site activity.
  clinical_research_site_activity: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_country__entity__name: { label: 'Country', type: 'checkboxes' },
    content__field_principal_investigator__entity__name: { label: 'Principal Investigator', type: 'checkboxes' },
    content__field_region__entity__name: { label: 'Region', type: 'checkboxes' },
    content__field_site_name__entity__name: { label: 'Site Name', type: 'checkboxes' },
    content__field_activity_status__entity__name: { label: 'Site Status', type: 'checkboxes' },
    content__field_star_rating: { label: 'Star Rating', type: 'checkboxes', dataType: 'number', searchLabel: true },
    content__field_therapeutic_area_ta___entity__name: { label: 'Therapeutic Area', type: 'checkboxes' },
    content__field_business_lead__entity__name: { label: 'Business Lead', type: 'checkboxes' },
    content__field_quality_lead__entity__name: { label: 'Quality Lead', type: 'checkboxes' }
  },
  // Facet fields for findings.
  findings: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_finding_classification__entity__name: { label: 'Finding Classification', type: 'checkboxes' },
    content__field_finding_compliance_topic__entity__name: { label: 'Finding Compliance Topic', type: 'checkboxes' },
    content__field_finding_compliance_subtopi__entity__name: { label: 'Finding Compliance Subtopic', type: 'checkboxes' },
    content__field_finding_quality_process__entity__name: { label: 'Finding Quality Process', type: 'checkboxes' },
    content__field_finding_type__entity__name: { label: 'Finding Type', type: 'checkboxes' }
  },
  // Facet fields for discovery location.
  discovery_location: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_region__entity__name: { label: 'Region', type: 'checkboxes' },
    content__field_country__entity__name: { label: 'Country', type: 'checkboxes' },
    content__field_star_rating: { label: 'Star Rating', type: 'checkboxes', dataType: 'number', searchLabel: true },
    content__field_sector__entity__name: { label: 'Sector', type: 'checkboxes' },
    content__field_therapeutic_area__entity__name: { label: 'Therapeutic Area', type: 'checkboxes' },
    content__field_functional_area__entity__name: { label: 'Functional Area', type: 'checkboxes' },
    content__field_business_lead__entity__name: { label: 'Business Lead', type: 'checkboxes' },
    content__field_quality_lead__entity__name: { label: 'Quality Lead', type: 'checkboxes' }
  },
  // Facet fields for external service provider.
  external_service_provider: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_star_rating: { label: 'Star Rating', type: 'checkboxes', dataType: 'number', searchLabel: true },
    content__field_region__entity__name: { label: 'Region', type: 'checkboxes' },
    content__field_location_country__entity__name: { label: 'Country', type: 'checkboxes' },
    content__field_esp_commodity__entity__name: { label: 'ESP Commodity', type: 'checkboxes' },
    content__field_esp_category__entity__name: { label: 'ESP Category', type: 'checkboxes' },
    content__field_business_contact__entity__name: { label: 'Business Contact', type: 'checkboxes' },
    content__field_quality_contact__entity__name: { label: 'Quality Contact', type: 'checkboxes' }
  },
  // Facet fields for local operating company.
  local_operating_company: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_country__entity__name: { label: 'Country Office', type: 'checkboxes' },
    content__field_region__entity__name: { label: 'Region', type: 'checkboxes' },
    content__field_location_country__entity__name: { label: 'Country', type: 'checkboxes' },
    content__field_star_rating: { label: 'Star Rating', type: 'checkboxes', dataType: 'number', searchLabel: true },
    content__field_sector__entity__name: { label: 'Sector', type: 'checkboxes' }
  },
  // Facet fields for process.
  process: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_life_cycle__entity__name: { label: 'Life Cycle', type: 'checkboxes' },
    content__field_process_input_title: { label: 'Process Input', type: 'checkboxes' },
    content__field_process_owner__entity__label: { label: 'Process Owner (Group Owner)', type: 'checkboxes' },
    content__field_system__entity__title: { label: 'System', type: 'checkboxes' },
    content__field_type_of_analytics_applied__entity__name: { label: 'Type of Analytics', type: 'checkboxes' }
  },
  // Facet fields for quality investigations.
  quality_investigations: {
    content__access_by_users: { label: '', type: 'checkboxes', hideDisplay: true, generateValue: true },
    content__groups: { label: 'Groups', type: 'checkboxes' },
    content__field_qi_business_unit__entity__name: { label: 'QI Business Unit', type: 'checkboxes' },
    content__field_qi_creation_date: { label: 'QI Creation Date', type: 'dateField' },
    content__field_qi_due_date: { label: 'QI Due Date', type: 'dateField' },
    content__field_qi_gxp__entity__name: { label: 'QI GXP', type: 'checkboxes' },
    content__field_qi_impact_category__entity__name: { label: 'QI Impact Category', type: 'checkboxes' },
    content__field_qi_owner__entity__name: { label: 'QI Owner', type: 'checkboxes' },
    content__field_qi_record_state__entity__name: { label: 'QI Record State', type: 'checkboxes' },
    content__field_qi_therapeutic_area__entity__name: { label: 'QI Therapeutic Area', type: 'checkboxes' }
  },
  // Facet fields for group.
  group: {
    group__field_root_sector: { label: 'Sectors', type: 'checkboxes' },
    group__members: { label: 'My Groups', type: 'checkboxes', displayType: 'switch' }
  }
})

// Export various miscellaneous configurations.
// In sorting default is used for primary sort criteria
// and default2 is used for secondary sort criteria
// Actual field name will be generated during execution.
export const miscConfig = Object.seal({
  facetSearchEnabled: true,
  minSearchEnableFacetLimit: 6,
  multipleEntitiesRecordLimit: 3,
  noFacetSearchOptionText: 'No option available!',
  multiValueSeparator: '||',
  facetRowMinHeight: 42,
  facetBoxMaxHeight: 252,
  facetPersistentParams: ['entityType', 'limit', 'offset', 'keyword', 'sort', 'content__field_root_sector', 'group__field_root_sector'],
  facetSemiPersistentParams: ['content__groups'],
  facetSemiPersistentParamsNotApplicable: ['all', 'group'],
  facetClearElement: 'Clear Selections',
  facetClearAll: 'Clear All Filters',
  sorting: [
    {
      label: 'Sort By',
      title: 'sortBy',
      default: 'changed',
      default2: 'relevance',
      options: {
        relevance: 'Relevance',
        title: 'Title',
        created: 'Publication Date',
        changed: 'Updated Date'
      }
    },
    {
      label: 'Order',
      title: 'sortOrder',
      default: 'desc',
      options: {
        asc: 'Ascending',
        desc: 'Descending'
      }
    }
  ]
})

// Corresponding custom labels for fields if required.
export const customLabel = Object.seal({
  content__field_star_rating: { 1: 0.5, 2: 1, 3: 1.5, 4: 2, 5: 2.5, 6: 3, 7: 3.5, 8: 4, 9: 4.5, 10: 5 }
})

// Exclude options if any.
// As per business logic Retired excluded here.
// Because Retired group/content will not display in search result.
// And not having any details page.
export const excludeItems = Object.seal({
  group__field_life_cycle__entity__title: ['Retired'],
  content__field_life_cycle__entity__name: ['Retired']
})

// This is used for process the label.
// Currently for content group eliminate the group id in label.
export const labelSplitter = Object.seal({
  content__groups: { splitWith: '__', index: 0 }
})

// Construct the value for certain keys, not likely present in url param.
// Key relateWith specify the relation with other fields.
// User key under it will take the value from user context
// Other key should be some other facet with process value rule like splitWith & index.
export const constructValue = Object.seal({
  content__access_by_users: {
    defaultAccessVal: 'access_not_restricted',
    relateWith: { user: 'name', content__groups: { splitWith: '__', index: 1 } }
  }
})

// This is used for select the facet along with.
export const sendAlong = Object.seal({
  all: ['content__field_root_sector', 'group__field_root_sector']
})
