import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import ArchiveIcon from '@mui/icons-material/Archive'
import { useNavigate, useParams } from 'react-router-dom'
import checkIconGreen from '../../../../images/icon-checkmark.svg'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// Build the component.
const ArchiveButtonAndModal = ({ subject, postedDate, scheduleDate, status, setFeedbackMessage }) => {
  // Set modalOpen.
  const [modalOpen, setIsModalOpen] = useState(false)
  // Set isLoading.
  const [isInProgress, setIsInProgress] = useState(false)
  // Get nid from path parameter.
  const { nid } = useParams()
  // Get navigate from hook.
  const navigate = useNavigate()
  // Note for schedule announcement archive.
  const scheduleArchiveNote = 'Archiving this scheduled announcement will remove it from your upcoming posts.'
  // Note for active announcement archive.
  const activeArchiveNote = 'Archiving this announcement will remove it from all groups it was posted in.'

  // Function to handle modal close.
  const handleClose = () => {
    setIsModalOpen(false)
    setIsInProgress(false)
  }

  // Function to handle archive click.
  const handleArchive = () => {
    setIsInProgress(true)
    // To track if logged out.
    let haveResponse = false
    // Firing the query.
    fetch(`${process.env.REACT_APP_TARGET_URL}/api/archive-announcement`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ nid: parseInt(nid) })
    })
      .then(res => {
        haveResponse = true
        return res.json()
      })
      .then(res => {
        // Incase error in query.
        if (res.error) {
          throw new Error(res.errorMsg)
        }
        // Incase not error set the success feedbackMessage.
        setFeedbackMessage({ type: 'success', message: res.response.msg, icon: <img src={checkIconGreen} alt='Check Icon' /> })
        // Navigate to the individual archive page.
        navigate(`/inbox/announcements/archived/${nid}`)
      })
      .catch(error => {
        if (!haveResponse) {
          window.location.reload()
        }
        const errorMessage = error?.name === 'Error' ? error.message : 'Some Error Occurred'
        setFeedbackMessage({ type: 'error', message: errorMessage, icon: <ErrorOutlineIcon /> })
      })
      .finally(() => {
        setIsInProgress(false)
        handleClose()
      })
  }

  // Return the output.
  return (
    <>
      <Button variant='outlined' onClick={() => setIsModalOpen(true)} startIcon={<ArchiveIcon />}>
        Archive
      </Button>
      {modalOpen && <Dialog open={modalOpen} maxWidth={'xs'} onClose={handleClose}
        aria-labelledby="archival-modal-title"
        aria-describedby="archival-modal-description">
        <div className='archive_modal'>
          <DialogTitle id="archive-dialog-title">
            <Typography id="archive-modal-title">
              Are you sure you want to archive this {status === 'inactive' ? 'scheduled ' : ''}announcement?
              <CloseIcon className="close-icon" onClick={handleClose} />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography className="modal-description top">
              {subject}
            </Typography>
            <Typography className="modal-description top second">
              {status === 'inactive' ? `Scheduled for ${scheduleDate}` : `Posted ${postedDate}`}
            </Typography>
            <Typography className="modal-description">
              Note: {status === 'inactive' ? scheduleArchiveNote : activeArchiveNote} You can view archived announcements in the 'Archived' tab.
            </Typography>
            <div className="btn_archive">
              <Button variant="outlined" disabled={isInProgress} onClick={handleClose}>Cancel</Button>
              <Button variant="contained" data-tracking="Archive" disabled={isInProgress} onClick={handleArchive}>{isInProgress ? 'Archiving...' : 'Archive'}</Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
      }
    </>
  )
}

export default ArchiveButtonAndModal
