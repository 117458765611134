import React from 'react'
import { Link } from 'react-router-dom'
// Import the required assets and images.
import '../sass/component/application-card.scss'
import personMultiIcon from '../../../../images/icon-people.svg'
import queryString from 'query-string'
import { constants } from '../../../../lib'
import { FavoriteButton } from './FavoriteButton'

// Defining the ApplicationCard for the nextGen.
const ApplicationCard = ({ appType, wasJustAdded, color, appName, lifeCycle, description, memberCount, image, gxp, url, gid, nid, userFavorites, addOrRemoveFavorite, assetType }) => {
  // Set the color style.
  const colorStyle = color ? `bg-${color} cat-section` : 'cat-section'
  // Return the output.
  return (
    <div className="col-4">
      <div className="application-card-section">
        <Link to={`${url}?${queryString.stringify({ gid })}`}>
          <div className="image">
            {appType && <span className={colorStyle}>{appType}</span>}
            {image && <img src={image.derivative?.url} alt={image.alt} title={image.title} width={image.derivative?.width} height={image.derivative?.height} />}
          </div>
          <div className="app-section-bottom">
            <div className="name">{appName}</div>
            {wasJustAdded && <div className='just-added'>Just Added</div>}
            <div className="owner">{lifeCycle}</div>
            <div className="details" dangerouslySetInnerHTML={{ __html: description || '' }}></div>
            <div className="count">
              <div className="count-left"><span className="left-icon"><img src={personMultiIcon} alt="people icon" />{memberCount || 0}</span></div>
              <div className="count-right">
                <span className="gxp-label">{(Array.isArray(gxp) && gxp.length > 0) ? constants.misc.gxpLabel : constants.misc.nonGxpLabel}</span>
                <span><FavoriteButton assetType={assetType} gid={gid} id={nid} userFavorites={userFavorites} addOrRemoveFavorite={addOrRemoveFavorite} title={appName} /></span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

// Return the component for use.
export default ApplicationCard
