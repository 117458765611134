import React from 'react'
import { Paper, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './EmailSentMessage.scss'

/**
 * This function creates a UI element for when a user sends a site to the next user in the SIPOC chain
 *
 * @param {string} popupRecipient - The email of the recipient of the sent item
 * @param {Object} popupItem - The site being sent to the recipient
 * @param {function} setPopupRecipient - The callback function for resetting the recipient after an email is sent
 * @param {function} setPopoutItem - Function callback for resetting shopping cart item once email is sent
 * @param {function} setVisibility - Function callback for disabling visibility once message is acknowledged
 * @param {bool} isVisible - Bool determining visibility of MessageBody component
 * @param {string} emailStatusText - Status text whether email sent or not
 *
 * @return {Object} The React wrapper for the email sent popup
 */
export default function EmailSentMessage ({ popupRecipient, popupItem, setPopupRecipient, setPopupItem, setVisibility, isVisible, emailStatusText }) {
  // when close button is clicked hide popup and remove popup item and recipient
  const handleClick = () => {
    setVisibility(false)
    setPopupItem(null)
    setPopupRecipient(null)
  }

  return (
    isVisible ? <MessageBody emailRecipient={popupRecipient} item={popupItem} handleClick={handleClick} emailStatusText={emailStatusText} /> : null
  )
}

/**
 * This function creates a UI element for when a user sends a site to the next user in the SIPOC chain
 *
 * @param {string} emailRecipient - The email of the recipient of the sent item
 * @param {Object} item - The site being sent to the recipient
 * @param {function} handleClick - The callback function for after the user has acknowledged the popup
 * @param {string} emailStatusText - Status text whether email sent or not
 *
 * @return {Object} The React elemnt for the email sent popup
 */
function MessageBody ({ emailRecipient, item, handleClick, emailStatusText }) {
  return (
    <Paper
      className='popup'
      elevation={5}
    >
      <IconButton
        onClick={handleClick}
        className='popup-close-btn'
      >
        <CloseIcon />
      </IconButton>
      <Typography variant='h5' className='popup-title'>Message Sent</Typography>
      <Typography variant='body1' className='popup-body text__color-janssen-blue'>
        You have sent {item.title} to {emailRecipient}.
      </Typography>
      <Typography variant='body1' className='popup-body'>
        The accountable and responsible individuals will have access to the pending items in their Group's Inbox for review.
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: emailStatusText }} style={{ paddingBottom: '10px' }}></div>
    </Paper>
  )
}
