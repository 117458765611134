import { Client, BatchRequestContent } from '@microsoft/microsoft-graph-client'
import _ from 'lodash'
const Buffer = require('buffer').Buffer
let cacheAccessToken

// Delete cached access token.
function delCacheToken () {
  cacheAccessToken = ''
}

// Generate user abbreviation.
export const userAbbreviation = username => (
  username.substring(0, 2).toUpperCase()
)

// Initiated the ms graph client.
const client = Client.initWithMiddleware({
  debugLogging: false,
  authProvider: {
    getAccessToken: function () {
      if (!cacheAccessToken) {
        // Generate and return the access token.
        return fetch('/api/token/get-token').then(res => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw new Error()
          }
        }).then(res => {
          if (parseInt(res.statusCode) === 200) {
            cacheAccessToken = res.accessToken
            // Remove cache access token after 4 minutes.
            _.debounce(delCacheToken, 240000)()
            return cacheAccessToken
          } else {
            throw new Error(res.errorMessage)
          }
        }).catch(err => console.error(err))
      } else {
        // Return the access token from cache.
        return Promise.resolve(cacheAccessToken)
      }
    }
  }
})

// Convert base64 to blob data.
const b64toBlob = async (b64Data, contentType, sliceSize) => {
  contentType = contentType || 'image/png' || 'image/jpeg' || 'image/jpg'
  sliceSize = sliceSize || 512

  const byteCharacters = Buffer.from(b64Data, 'base64').toString('binary')
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: contentType })
}

// Convert blob to base64 data.
const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

// Prepare the batch response..
const batchResponseContent = ({ responses }) => {
  const batchResponse = new Map()
  responses.forEach(res => {
    const body = res.body
    const contentType = res.headers['Content-Type']
    const options = {}

    options.headers = res.headers
    options.status = res.status

    const response = new Response(body, options)
    batchResponse.set(res.id, { contentType, response })
  })
  return batchResponse
}

// Build, process and export the batch data.
export const downloadPhotosBatching = async personsData => {
  try {
    // Create batch request steps for the users specified above.
    const batchRequestSteps = personsData.map(({ username }) => {
      const email = `${username}@its.jnj.com`
      return {
        id: email,
        request: new Request(`/users/${email}/photos/48x48/$value`, {
          method: 'GET'
        })
      }
    })
    // Initiate the batch request and execute the operation.
    const batchRequestContent = new BatchRequestContent(batchRequestSteps)
    // Generate the content for batch.
    const content = await batchRequestContent.getContent()
    // Call the batch api.
    const responseData = await client.api('/$batch').post(content)
    // Processing the batch response data.
    const batchResponse = batchResponseContent(responseData)
    // Initialize the profile image object.
    const profileImageData = {}
    await Promise.all(
      personsData.map(async person => {
        const { username } = person
        const email = `${username}@its.jnj.com`
        const { contentType, response } = batchResponse.get(email)
        if (response.ok) {
          const data = await response.text()
          const binToBlob = await b64toBlob(data, contentType)
          // Associate the base64 output to an src attribute of an <img> HTML tag.
          const base64Result = await blobToBase64(binToBlob)
          profileImageData[username] = base64Result
        } else {
          // Profile image not exist.
          profileImageData[username] = ''
        }
        return profileImageData
      })
    )
    // Return all profile Image data for this batch.
    return profileImageData
  } catch (error) {
    console.error(error?.code || 'Error while loading profile image')
  }
}

// Build, process and export the individual profile image.
export const downloadPhotoIndividual = async (username, size) => {
  try {
    // Create batch request steps for the users specified above.
    const response = await client.api(`/users/${username}@its.jnj.com/photos/${size}/$value`).get()
    // Associate the base64 output to an src attribute of an <img> HTML tag.
    const base64Result = await blobToBase64(response)
    return { profileImage: base64Result }
  } catch (error) {
    console.error(error?.code)
    // Have some error hence returning blank profile image.
    return { profileImage: '' }
  }
}
