import { useCallback, useState, useEffect, useContext } from 'react'
// Import necessary components.
import update from 'immutability-helper'
import './set-group-view.scss'
import { Button } from '@mui/material'
import Alert from '@mui/material/Alert'
import checkMarkIcon from '../../../../../images/icon-checkmark.svg'
import { Card } from './Card.js'
import { constants } from '../../../../../lib'
import { EventTrackingContext } from '../../../../../contexts/EventTrackingContext'

export const DefaultGroup = ({ isApplicationPage, gid, groupContent, setFeedbackMessage, crumbs }) => {
  // Set initial card data.
  const [initialCard, setInitialCard] = useState()
  // Set card row data.
  const [cards, setCards] = useState()
  // Set is loading.
  const [isLoading, setIsLoading] = useState(false)
  // Set is disable buttons
  const [disabled, setDisabled] = useState(true)
  // Set error message if API failed.
  const [hasError, setHasError] = useState(false)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  // Re-initiate data based on url change
  useEffect(() => {
    setCards(groupContent)
    setInitialCard(groupContent)
    setDisabled(true)
    // Sending page data for tracking.
    setPageData({
      type: isApplicationPage ? 'set-default-application-view' : 'set-default-group-view',
      breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: isApplicationPage ? 'Set Default Application View' : 'Set Default Group View' }],
      page_id: gid
    })
  }, [groupContent, isApplicationPage])
  // Set save group/application order.
  const onSave = () => {
    setDisabled(true)
    // Set loading before submit.
    setIsLoading(true)
    //  Set the api url.
    const pathPart = isApplicationPage ? 'application' : 'group'
    const url = `${process.env.REACT_APP_TARGET_URL}/api/set-${pathPart}-order`
    //  Set the payload data.
    const data = {
      gid: parseInt(gid),
      data: cards.map((card, index) => ({
        id: card.nid ? card.nid : card.id,
        weight: index
      }))
    }
    // To track if logged out.
    let haveResponse = false
    // Call the api.
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(res => {
        haveResponse = true
        return res.json()
      })
      .then(res => {
        // Set stop loading.
        setIsLoading(false)
        if (!res.error) {
          // Set success message in page top.
          setFeedbackMessage({ message: res.response.msg, type: 'success', icon: <img src={checkMarkIcon} alt='check mark icon' /> })
          // Load the group silently.
          setInitialCard(cards)
        } else {
          // Set disable the buttons.
          setDisabled(false)
          // Set error message in page top.
          setFeedbackMessage({ icon: <img src={checkMarkIcon} alt='check mark icon' />, type: 'error', message: res.errorMsg })
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err.message)
        // Set error message if API failed.
        setHasError(true)
      })
  }
  // Set reset the rows.
  const onReset = () => {
    setCards(initialCard)
    // Set disable the buttons.
    setDisabled(true)
  }
  // Set move card up and down.
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards(prevCards =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
    setDisabled(false)
  }, [])
  // Call the row card.
  const renderCard = useCallback((card, index, application) => {
    return (
      <div key={index}>
        <Card
          key={index}
          index={index}
          id={!application ? card.id : card.nid}
          text={!application ? card.title : card.entityLabel}
          moveCard={moveCard}
        />
      </div>
    )
  }, [])
  // Return the component for use.
  return (
    <>
      {hasError && <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: constants.errors.server }} /></Alert>}
      {!hasError && cards && cards.length <= 1 && <div className="group_items_wrapper error">Custom ordering requires at least two {isApplicationPage ? 'applications' : 'subgroups'}.</div>}
      {!hasError && cards && cards.length > 0 && <>
        <div className="group_items_wrapper">{cards.map((card, i) => renderCard(card, i, isApplicationPage))}</div>
        {cards.length > 1 &&
          <div className='btn-wrapper-setgroup'>
            <Button variant='outlined' className='reset-setgroup' disabled={disabled} onClick={onReset}>
              Reset
            </Button>
            <Button id='save-setgroup' variant='contained' className='save-setgroup' disabled={disabled} onClick={onSave}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        }
      </>
      }
    </>
  )
}
// Export the component for use.
export default DefaultGroup
