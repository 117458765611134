// Import various components.
import React, { useContext, useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import moment from 'moment'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ImageIcon from '@mui/icons-material/Image'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import './announcement.scss'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import Loader from '../../../PageLoader'
import generateRootSectorOrGlobal from '../../Assets/component/GenerateRootSectorOrGlobal'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import Pagination from '../../../Pagination'
import GroupSelectDropdown from './GroupSelectDropdown'
import AnnouncementDetails from './AnnouncementDetails'
import ArchiveIcon from '../../../../images/archive_icon.svg'
import ScheduledIcon from '../../../../images/scheduled_icon.svg'
import AddAnnouncement from './AddAnnouncement'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

// Build the component as place holder.
const Announcements = ({ groupUserGroupsByUidRA, setFeedbackMessage, userId, pageSize, isLoading, setErrorMessage, setIsLoading, parameters, handleChange, setPageSize }) => {
  // Set announcements.
  const [announcements, setAnnouncements] = useState([])
  // Set totalCount.
  const [totalCount, setTotalCount] = useState(0)
  // Get id, nid from path parameter.
  const { id, nid } = useParams()
  // Get location object.
  const location = useLocation()
  // Get navigate from hook.
  const navigate = useNavigate()
  // Set selectedGroups.
  const [selectedGroups, setSelectedGroups] = useState([])
  // Set groupOptions.
  const [groupOptions, setGroupOptions] = useState([])
  // Set groupsLoaded.
  const [groupsLoaded, setGroupsLoaded] = useState(true)
  // Set backUrl.
  const [backUrl, setBackUrl] = useState('')
  // Get query params.
  const priority = parameters.priority || ''
  const search = parameters.search || ''
  const page = parseInt(parameters.page) || 0
  const gids = parameters.gids || ''
  // Set profileImageData.
  const [profileImageData, setProfileImageData] = useState({})
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    // Not do anything if request for create or groups not loaded.
    if (!groupsLoaded || id === 'create') {
      return
    }
    // Set group loaded false if my-groups page and no groups there.
    if (id === 'my-groups') {
      if (!groupOptions.length) {
        setGroupsLoaded(false)
        return
      }
    } else {
      setGroupsLoaded(true)
    }
    setIsLoading(true)
    // Variables object.
    let announcementVariables = {
      status: 'active',
      pageSize,
      page
    }
    let breadCrumbText
    // Set params based on the id field.
    switch (id) {
      case 'global':
        announcementVariables.groupOrGlobal = 'global'
        breadCrumbText = 'Announcement-Global'
        break
      case 'my-groups':
        announcementVariables.groupIds = selectedGroups.length ? selectedGroups.map(group => group.gid).join(',') : 'all'
        breadCrumbText = 'Announcement-My Groups'
        break
      case 'archived':
        announcementVariables.groupIds = 'all'
        announcementVariables.groupOrGlobal = 'global'
        announcementVariables.status = 'archive'
        breadCrumbText = 'Announcement-Archived'
        break
      case 'scheduled':
        announcementVariables.groupIds = 'inactive'
        announcementVariables.groupOrGlobal = 'global'
        announcementVariables.status = 'inactive'
        breadCrumbText = 'Announcement-Scheduled'
        break
      default:
        announcementVariables.groupIds = 'all'
        announcementVariables.groupOrGlobal = 'global'
        breadCrumbText = 'Announcement-All'
    }
    // Set keyword property if it exists.
    if (search.trim().length) {
      announcementVariables.keyword = encodeURIComponent(search)
    }
    // Set priorityFilter if it exists.
    if (priority === 'high') {
      announcementVariables.priority = 'high'
    }
    // Stringify the variable object.
    announcementVariables = JSON.stringify(announcementVariables)
    // Prepare announcement query.
    const announcementQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.announcementListQuery}&variables=${announcementVariables}`
    // Fire the query.
    queryContent(announcementQuery)
      .then(response => {
        setAnnouncements(response.data.announcementsList.results)
        setTotalCount(response.data.announcementsList.count)
        if (!nid) {
          // Sending page data for tracking.
          setPageData({
            type: `inbox-announcements-${id === 'my-groups' ? 'groups' : id}`,
            breadCrumbs: [{ text: 'Home', url: { path: '/' } }, { text: breadCrumbText }]
          })
        }
      })
      .catch(() => setErrorMessage(constants.errors.server))
      .finally(() => setIsLoading(false))
  }, [priority, search, pageSize, page, id, selectedGroups, groupsLoaded, groupOptions])

  useEffect(() => {
    if (id === 'my-groups' && !groupsLoaded && id !== 'create') {
      setIsLoading(true)
      const queryVariable = { uid: userId }
      // Query to get all the group options that can be selected.
      const groupsQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.allGroupsByUserQuery}&variables=${JSON.stringify(queryVariable)}`
      // Fire the query.
      queryContent(groupsQuery)
        .then(response => {
          const eligibleGroups = response.data.groupUserGroupsByUidList.results
          const gidsFromUrl = gids.split(',')
          const checkedGroups = eligibleGroups.filter(group => gidsFromUrl.some(gid => parseInt(gid) === parseInt(group.gid)))
          setSelectedGroups(checkedGroups)
          const groupOptionsArr = eligibleGroups.length ? eligibleGroups : [null]
          setGroupOptions(groupOptionsArr)
        })
        .catch(() => setErrorMessage(constants.errors.server))
        .finally(() => setGroupsLoaded(true))
    }
  }, [gids, id, userId, groupsLoaded])

  useEffect(() => {
    // Setting gids in params.
    if (id === 'my-groups' && !isLoading) {
      handleChange('gids', selectedGroups.map(group => group.gid).join(','))
    }
  }, [selectedGroups, isLoading, id])

  // Function to handle change in pagination values.
  const handleChangeFor = category => value => {
    if (category === 'offset') {
      handleChange('page', value / pageSize)
    }
    if (category === 'limit') {
      handleChange('pageSize', value === 50 ? 0 : value)
      setPageSize(value)
    }
  }

  // Function to handle click of back button.
  const backClick = () => {
    if (backUrl.trim().length) {
      navigate(backUrl)
    } else {
      navigate(`/inbox/announcements/${id}`)
    }
  }

  // Function to open announcement details.
  const handleOpenAnnouncement = () => {
    setBackUrl(`${location.pathname}${location.search}`)
  }

  // Return the add announcement form if id is equal to create.
  if (id === 'create') {
    return <div className='messages_right_section'><div className='announcementlist_wrapper no_top_gap'><AddAnnouncement setMessage={setFeedbackMessage} /></div></div>
  }

  // Return JSX.
  return (
    <div className='messages_right_section'>
      {isLoading
        ? <div className='announcementlist_wrapper'><Loader /></div>
        : <>
          {
            (id === 'my-groups' && !nid) && <GroupSelectDropdown groupOptions={groupOptions.filter(x => !!x)} selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />
          }
          {(announcements.length && !nid)
            ? <>
              <div className='announcementlist_wrapper'>
                {
                  announcements.map(item => (
                    <Link to={`${location.pathname}/${item.nid}`} key={item.nid} onClick={() => handleOpenAnnouncement()}>
                      <div className='announcementlist_item'>
                        <div className='details_name'>{item.owner.displayName || item.owner.username}</div>
                        <div className='details_text_wrapper'>
                          <div className='details_text'>
                            {item.fieldPriority.length
                              ? <div className='priority'>
                                <ErrorOutlineIcon />
                                High Priority
                              </div>
                              : null
                            }
                            <div className='status'>{item.title}</div>
                          </div>
                          <div className='message'>{item.body.value.replace(/(<([^>]+)>)/ig, '')}</div>
                          {((item.fieldExistingContent && item.fieldExistingContent?.count > 0) || item.fieldUploadImages.length > 0) && <div className='chip_wrapper'>
                            {item.fieldExistingContent && item.fieldExistingContent?.count > 0
                              ? <Chip
                                icon={<InsertLinkIcon />}
                                label='Application'
                                variant='outlined'
                                size='small'
                              />
                              : null
                            }
                            {item.fieldUploadImages.length
                              ? <Chip
                                icon={<ImageIcon />}
                                label='Image'
                                variant='outlined'
                                size='small'
                              />
                              : null
                            }
                          </div>}
                          <div className='tag_wrapper'>{generateRootSectorOrGlobal(item.fieldGroups?.entities, item.fieldIsGroupOrGlobal === 'global')}</div>
                        </div>
                        <div className='details_date'>
                          {id === 'archived' && <img src={ArchiveIcon} alt='archived' />}
                          {id === 'scheduled' && <img src={ScheduledIcon} alt='scheduled' />}
                          {id === 'scheduled' ? moment(new Date(item.fieldPlannedStartDate?.value)).format(constants.misc.dateFormat.dateLong) : item.entityChanged}
                        </div>
                      </div>
                    </Link>
                  ))
                }
              </div>
              <Pagination handleChangeFor={handleChangeFor} params={{ offset: page * pageSize, limit: pageSize }} totalCount={totalCount} pageNumber={page} />
            </>
            : !nid ? <div className='announcementlist_wrapper no_results'>{search.length ? 'No Results Found.' : 'You have no announcements.'}</div> : null
          }
          {
            nid && <AnnouncementDetails groupUserGroupsByUidRA={groupUserGroupsByUidRA} profileImageData={profileImageData} setErrorMessage={setErrorMessage} setProfileImageData={setProfileImageData} backClick={backClick} setFeedbackMessage={setFeedbackMessage} />
          }
        </>
      }
    </div>
  )
}
// Export the component for use.
export default Announcements
