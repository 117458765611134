import React from 'react'
import { Link } from 'react-router-dom'
import ArticleResults from './ArticleResults'
import GroupResults from './GroupResults'
import ResultBreadcrumbAndLink from './ResultBreadcrumbAndLink'

// Generate and export the loader component for use.
export default function SearchResults ({ item }) {
  // Return the output.
  // Entire card will be linked to actual content/group url.
  let rowType
  if (item.resultType === 'node' && item.bundle === 'article') {
    rowType = 'Application'
  }
  if (item.resultType === 'group') {
    rowType = 'Group'
  }
  return (
    <div className="search-result-row">
      <Link to={item.url}>
        {
          rowType === 'Application' && <ArticleResults item={item} />
        }
        {
          rowType === 'Group' && <GroupResults item={item} />
        }
      </Link>
      <ResultBreadcrumbAndLink breadcrumb={item.breadcrumb} url={item.url} rowType={rowType} />
    </div>
  )
}
