import React, { useState, useEffect, useRef } from 'react'
import { VariableSizeList as List } from 'react-window'
import { miscConfig, customLabel, labelSplitter } from '../../Search/SearchConfig'
import calculateSize from '../CalculateSize'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Checkbox, InputBase, LinearProgress, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import { makeStyles } from '@mui/styles'

// Overwrite and add some css.
const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    backgroundColor: '#fafafa',
    border: '1px solid #333',
    width: '100%'
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(0.5, 0.5, 0.5, `calc(1em + ${theme.spacing(2)})`),
    transition: theme.transitions.create('width')
  },
  clearSelection: {
    width: '100%'
  }
}))

let searchKeyword = {}
let scrollToItem = {}

// Build and export the component.
export default function CheckboxFacetField ({ fieldName, fieldLabel, options, onChange, defaultChecked, labelValues, entityType }) {
  const classes = useStyles()
  const defaultKeyword = typeof (searchKeyword[entityType]) !== 'undefined' && typeof (searchKeyword[entityType][fieldName]) !== 'undefined' ? searchKeyword[entityType][fieldName] : ''
  // Set search key.
  const [searchKey, setSearchKey] = useState(defaultKeyword)
  // Set option items.
  const [optionItems, setOptionItems] = useState(options)
  // Set option item count.
  const [optionItemCount, setOptionItemCount] = useState(options.length)
  // Set checked or not.
  const [checked, setChecked] = useState(defaultChecked)
  // Set option element default height.
  const [elementHeight, setElementHeight] = useState(miscConfig.facetBoxMaxHeight)
  // Set no result text.
  const [noResultText, setNoResultText] = useState(<LinearProgress />)
  // Set the reference.
  const listRef = useRef()

  // Set necessary states and default keyword, scroll.
  useEffect(() => {
    setOptionItems(options)
    setOptionItemCount(options.length)
    setElementHeight(getElementHeight(options.length))
    setNoResultText(<LinearProgress />)
    if (searchKey) {
      filterOptions(searchKey)
    }
    let delayScroll
    if (listRef?.current && typeof (scrollToItem[entityType]) !== 'undefined' && typeof (scrollToItem[entityType][fieldName]) !== 'undefined') {
      const currentRef = listRef.current
      delayScroll = setTimeout(() => { currentRef.scrollToItem(scrollToItem[entityType][fieldName]) }, 500)
    }
    const delayCall = setTimeout(() => { setNoResultText(miscConfig.noFacetSearchOptionText) }, 10000)
    return () => {
      if (delayScroll) {
        clearTimeout(delayScroll)
      }
      clearTimeout(delayCall)
    }
  }, [options, entityType, fieldName, searchKey])

  // Get element height for different count of options.
  const getElementHeight = count => {
    let height
    switch (count) {
      case 1:
        height = miscConfig.facetRowMinHeight + 8
        break
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        height = (count * miscConfig.facetRowMinHeight)
        break
      default:
        height = miscConfig.facetBoxMaxHeight
        break
    }
    return height
  }

  // Handle checkbox change to trigger search.
  const handleCheckboxChange = e => {
    let checkedItems
    if (e.target.checked) {
      checkedItems = [e.target.value, ...checked]
    } else {
      checkedItems = checked.filter(prev => prev !== e.target.value)
    }
    const checkedItemsParam = checkedItems.join(miscConfig.multiValueSeparator)
    setChecked(checkedItems)
    if (typeof (scrollToItem[entityType]) === 'undefined') {
      // Initiate object again after changing content type.
      scrollToItem = {}
      scrollToItem[entityType] = {}
    }
    scrollToItem[entityType][fieldName] = e.target.id.replace(fieldName + '_', '')
    onChange(fieldName)(checkedItemsParam)
  }

  // Filter the options.
  const filterOptions = e => {
    const keyword = (typeof (e?.target?.value) !== 'undefined') ? e.target.value : e
    setNoResultText(miscConfig.noFacetSearchOptionText)
    let results = options
    // Set the default search automatically.
    if (keyword !== '') {
      results = options.filter(option => {
        let optionVal = option.toString()
        if (Object.keys(labelValues).length && typeof (labelValues[option]) !== 'undefined') {
          optionVal = labelValues[option].toString()
        }
        // Use the toLowerCase() method to make it case-insensitive
        return optionVal.toLowerCase().includes(keyword.toLowerCase())
      })
      setOptionItems(results)
      setElementHeight(getElementHeight(results.length))
    } else {
      // If the text field is empty, show all
      setOptionItems(options)
    }
    setSearchKey(keyword)
    // Persist search keyword
    if (typeof (searchKeyword[entityType]) === 'undefined') {
      // Initiate object again after changing content type.
      searchKeyword = {}
      searchKeyword[entityType] = {}
    }
    // Delete scroll position if no option selected for this facet field.
    searchKeyword[entityType][fieldName] = keyword
    if (typeof (e?.target?.value) !== 'undefined' && typeof (scrollToItem[entityType]) !== 'undefined' && typeof (scrollToItem[entityType][fieldName]) !== 'undefined') {
      delete scrollToItem[entityType][fieldName]
    }
    // Reset metadata to set height again.
    if (typeof (listRef?.current) !== 'undefined' && listRef?.current) {
      listRef.current.resetAfterIndex(0, false)
    }
  }

  // Process the label to display in checkbox.
  // First check for alteration configured for custom label
  // Then check for label splitter.
  const processLabel = label => {
    let processedLabel = typeof (customLabel[fieldName]) !== 'undefined' && typeof (customLabel[fieldName][label]) !== 'undefined'
      ? customLabel[fieldName][label].toString()
      : label.toString()
    if (typeof (labelSplitter[fieldName]) !== 'undefined' && typeof (labelSplitter[fieldName].splitWith) !== 'undefined') {
      const labelArr = processedLabel.split(labelSplitter[fieldName].splitWith)
      if (labelArr[labelSplitter[fieldName].index] !== undefined) {
        processedLabel = labelArr[labelSplitter[fieldName].index]
      }
    }
    return processedLabel
  }

  // Configure each row for react window.
  // As number of options are huge for most of the facet fields.
  const Row = ({ index, style, data }) => {
    const dataLabel = processLabel(data[index])
    return (
      <div key={`${fieldName}_${index}`} style={style} className="facetRow">
        <FormControlLabel className="formcontrollbl"
          control={
            <Checkbox
              name={fieldName}
              color="primary"
              id={`${fieldName}_${index}`}
              value={data[index]}
              onChange={handleCheckboxChange}
              checked={checked.includes(data[index])}
              size="small"
            />
          }
          label={dataLabel}
          title={dataLabel}
        />
      </div>
    )
  }

  // Get each item size.
  const getItemSize = index => {
    const rowSize = calculateSize(optionItems[index], { key: fieldName, className: 'dummy' })
    return rowSize.height > miscConfig.facetRowMinHeight ? rowSize.height : miscConfig.facetRowMinHeight
  }

  // Clear filter selection.
  const ClearSelection = () => {
    return checked.length > 0
      ? (<Button
        variant="outlined"
        color="secondary"
        size="small"
        className={`${classes.clearSelection} hidden`}
        onClick={() => onChange(fieldName)('')}
        startIcon={<DeleteSweepIcon />}
      >
        {miscConfig.facetClearElement}
      </Button>)
      : null
  }

  // Return the component Outputs.
  // Search box will only appear if option count more that 6.
  return (
    <div className={`facet-box ${fieldName}`}>
      <div className="facet-box-header">
        <label className="facetid-heading">{fieldLabel}</label>
      </div>
      {optionItemCount > miscConfig.minSearchEnableFacetLimit
        ? (<div className="searchfacet-wrapper">
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon fontSize='small' />
            </div>
            <InputBase
              placeholder="Search"
              type="search"
              defaultValue={searchKey}
              onChange={filterOptions}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': `Search ${fieldLabel}` }}
            />
          </div>
        </div>)
        : null}
      <div className="checkbox-wrapper">
        {optionItems && optionItems.length > 0
          ? (<>
            <List
              className="List"
              itemData={optionItems}
              height={elementHeight}
              itemCount={optionItems.length}
              itemSize={getItemSize}
              ref={listRef}
            >
              {Row}
            </List>
            <ClearSelection />
          </>)
          : <div className="loader-wrapper"><h5>{noResultText}</h5></div>
        }
      </div>
    </div>
  )
}
