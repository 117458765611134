import React, { useEffect, useState } from 'react'
import { Button, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'
import '../sass/component/generic-modal.scss'
import CloseIcon from '@mui/icons-material/Close'

// Create style for dialog.
const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    left: '50%',
    top: 30,
    width: '800px',
    marginLeft: '-400px',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginLeft: '-45%'
    }
  }
}))

// Build the component.
const TagsFilterPage = ({ tagFilterOpen, handleFilterClose, tags, setTags }) => {
  // Get classes.
  const classes = useStyles()
  // Set the  tagButtons.
  const [tagButtons, setTagButtons] = useState(tags)

  // Set tags if changed.
  useEffect(() => {
    setTagButtons(tags)
  }, [tags])

  // Function to toggle button between selected and not selected.
  const handleToggle = index => {
    const items = [...tagButtons]
    const item = items[index]
    items[index] = {
      ...item,
      selected: !item.selected
    }
    // Set the tag button.
    setTagButtons(items)
  }

  // Function to save the selected State of buttons.
  const handleSave = () => {
    setTags(tagButtons)
    handleFilterClose()
  }

  // Define the body.
  const body = (
    <div className="generic-modal-wrapper">
      <div className="header">
        <div className="generic-modal-title">Filter By Tags</div>
        <CloseIcon className="close_popup" onClick={handleFilterClose} />
      </div>
      <div>
        <p className="text-description">Select one or more tags to include in your filtered view</p>
      </div>
      <div className="selected_tags_wrapper">
        {
          tagButtons.map((tag, index) => {
            return <span key={index} variant="contained" className={tag.selected ? 'selected' : 'default'} onClick={() => handleToggle(index)}>
              {tag.label}
            </span>
          })
        }
      </div>
      <div className="btn_wrapper">
        <Button variant="outlined" className="btn" color="primary" onClick={e => handleFilterClose(e)}>
          Cancel
        </Button>
        <Button variant="contained" className="btn" color="primary" onClick={handleSave}>
          Apply
        </Button>
      </div>
    </div>
  )

  // Return the modal.
  return (
    <Dialog open={tagFilterOpen} onClose={handleFilterClose}
      aria-labelledby="tag-modal-title" aria-describedby="tag-modal-description"
      maxWidth="lg" scroll="paper"
      classes={{ paper: classes.dialog }}
    >
      {body}
    </Dialog>
  )
}

// Exporting the component.
export default TagsFilterPage
