import { constants } from '../../../../lib'

const groupsVar = new Map()
let idleTimeGroup = 0
let groupIntervalFunc
let groupCounterStarted = false

// Check access for current user against node group ids
export function obtainGroups (ids) {
  // Cache the group data for idleTimeLimit
  if (!groupCounterStarted) {
    groupIntervalFunc = setInterval(groupTimerIncrement, 60000)
    groupCounterStarted = true
  }
  const groups = []
  idleTimeGroup = 0
  ids.forEach(id => {
    if (groupsVar.has(id)) {
      // If the data exist in cache push from there.
      groups.push({ id, group: groupsVar.get(id) })
    } else {
      // Call the api to collect group data.
      // Build api payload.
      const groupVariable = JSON.stringify({
        id: id.toString()
      })
      // Generate api url.
      const groupQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupQueryById}&variables=${groupVariable}`
      fetch(groupQuery, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(json => {
          const results = json?.data?.groupGroupsByNidList?.results
          if (results && results.length > 0) {
            results.forEach(result => {
              const group = result.title
              groups.push({ id, group })
              groupsVar.set(id, group)
            })
          } else {
            groupsVar.set(id, [])
          }
        })
    }
  })
  return groups
}

// Clear access cache if idleTimeLimit exceed.
const groupTimerIncrement = () => {
  idleTimeGroup += 1
  if (idleTimeGroup > constants.misc.idleTimeLimit) {
    groupsVar.clear()
    clearInterval(groupIntervalFunc)
    groupCounterStarted = false
  }
}
