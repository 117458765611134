import { useState, useEffect, useContext } from 'react'
import { queryContent } from '../components/ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { UserContext } from '../components/context/UserContext'
import { downloadPhotosBatching } from '../components/ProfileImage/utility'
import { constants } from '../lib'

export const useMemberList = (members, ownedGroupId, gid) => {
  // Set business team members data.
  const [businessTeam, setBusinessTeam] = useState({})
  // Track if user is already a member in group.
  const [isMember, setIsMember] = useState(false)
  // Set membersProfileImageData.
  const [membersProfileImageData, setMembersProfileImageData] = useState({})
  // Fetch the user context.
  const { userId, otherDetails } = useContext(UserContext)
  // Check if current user is Responsible or accountable.
  const [isRespOrAccount, setRespOrAccount] = useState(false)
  // Check if user in certain RACI.
  const isRaciRole = type => entity => entity?.groupRoles?.toLowerCase().includes(type)
  useEffect(() => {
    if (gid !== 0 && typeof gid !== 'undefined' && parseInt(gid) !== parseInt(ownedGroupId) && (ownedGroupId !== null || ownedGroupId === '0')) {
      // Prepare eligible group query variable.
      const uid = JSON.stringify({ uid: userId })
      const gidQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupUserGroupsByUidRA}&variables=${uid}`
      // Call the query.
      queryContent(gidQuery)
        .then(res => {
          const eids = res.data.groupUserGroupsByUidRA.results.map(x => x.gid)
          if (eids.some(eid => eid === gid)) {
            setRespOrAccount(true)
          }
        })
        .catch(err => console.log(err))
    }
  }, [gid, userId, ownedGroupId])

  // Function For sorting names alphabetically considering username if there is no display name.
  const sortAlphabetically = (a, b) => {
    const name1 = (a.displayName === 'false' ? a.username.toLowerCase() : a.displayName.toLowerCase())
    const name2 = (b.displayName === 'false' ? b.username.toLowerCase() : b.displayName.toLowerCase())
    if (name1 > name2) { return 1 }
    if (name1 < name2) { return -1 }
    return 0
  }
  // Set role names.
  const businessTeamRoles = {
    label: constants.misc.groupTeamMemberData.businessTeamRoles
  }

  // Prepare the members.
  useEffect(() => {
    if (members) {
      // Preparing Group Role Matrix
      const responsible = members?.filter(isRaciRole('responsible'))?.sort(sortAlphabetically)
      const accountable = members?.filter(isRaciRole('accountable'))?.sort(sortAlphabetically)
      const consulted = members?.filter(isRaciRole('consulted'))?.sort(sortAlphabetically)
      const informed = members?.filter(isRaciRole('informed'))?.sort(sortAlphabetically)

      // Check each RACI role against userId.
      const isResponsible = responsible?.some(member => member.userId === userId)
      const isAccountable = accountable?.some(member => member.userId === userId)
      const isConsulted = consulted?.some(member => member.userId === userId)
      const isInformed = informed?.some(member => member.userId === userId)

      // Set isMember as True if user has any RACI Role.
      setIsMember(isResponsible || isAccountable || isConsulted || isInformed)

      // Set isResponsibleOrAccountable.
      if (!isRespOrAccount && (!gid || parseInt(gid) === parseInt(ownedGroupId))) {
        setRespOrAccount(isResponsible || isAccountable)
      }

      // Push all RACI members.
      const btmList = [responsible, accountable, consulted, informed]

      // To avoid repetition of members having multiple roles.
      const businessList = []
      btmList.forEach((btm, index) => {
        btm.forEach(person => {
          // Create unique object to avoid overwrite.
          const businessPerson = { ...person, role: businessTeamRoles.label[index] }
          businessList.push(businessPerson)
        })
      })

      // Set the count.
      const btmCount = [...new Set(businessList.map(person => person.username))].length

      // Set individual team.
      setBusinessTeam({ list: businessList, count: btmCount })
    }
  }, [members, userId])

  // Set the member's profile picture.
  useEffect(() => {
    // Check for member existence.
    (async () => {
      if (members) {
        // Initialize the members data who don't have picture uploaded in application.
        const reducedMembersData = members.filter(member => (member.userPicture === '' && member.userId !== userId))
        // Initialize the profile picture object.
        let profilePicObj = {}
        // Set logged in user profile picture if he/she is member of the group.
        if (members.some(member => member.userId === userId)) {
          profilePicObj = { [otherDetails.name]: otherDetails.profilePicture }
          setMembersProfileImageData(profilePicObj)
        }
        if (members.length) {
          // Create batch of 20 items, as ms graph can max accommodate 20 per batch.
          const batchArray = reducedMembersData.reduce((previous, _, __, next) => [...previous, next.splice(0, 20)], [])
          for (const userArray of batchArray) {
            // Download and accumulate profile image with other data.
            const response = await downloadPhotosBatching(userArray)
            profilePicObj = { ...profilePicObj, ...response }
            // Load images for members.
            setMembersProfileImageData(profilePicObj)
          }
        }
      }
    })()
  }, [members, otherDetails, userId])

  // Return the hook output.
  return [
    businessTeam,
    membersProfileImageData,
    isMember,
    isRespOrAccount
  ]
}
