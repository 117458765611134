import React, { useState, useEffect } from 'react'
import './styles/App.css'
import './styles/navbarComponents.css'
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom'
import Routes from './components/routes'
import { ShoppingCartProvider } from './contexts'
import { createTheme, ThemeProvider } from '@mui/material'
import { UserProvider } from './components/context/UserContext'
import TagManager from 'react-gtm-module'
import LogoutPage from './components/LogoutPage'
import NavBar from './components/NextGen/Assets/component/NavBar'
import Footer from './components/NextGen/Assets/component/Footer'
import { NavBarProvider } from './contexts/NavBarContext'
import { NotificationProvider } from './contexts/NotificationContext'
import { AnnouncementProvider } from './contexts/AnnouncementContext'
import ViewApplication from './components/NextGen/Application/ViewApplication'
import { EventTrackingProvider } from './contexts/EventTrackingContext'
import EventTracker from './components/EventTracker'

// Create theme.
const defaultColor = '#1C75BC'
// Set primary and secondary color.
const theme = createTheme({
  palette: {
    primary: {
      main: defaultColor,
      dark: defaultColor
    },
    secondary: {
      main: '#C11717'
    },
    default: {
      main: defaultColor,
      dark: defaultColor
    }
  },
  transitions: {
    create: () => 'none'
  }
})
// Add google tag manager.
if (process.env.REACT_APP_GTM_ID) {
  // Set params for google tag manager.
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayerName: 'dataLayer'
  }
  // Initialization of GTM.
  TagManager.initialize(tagManagerArgs)
}

export default function App () {
  // Set logout state value.
  const [logoutValue, setLogoutValue] = useState(false)
  // Set viewingApplication.
  const [viewingApplication, setViewApplication] = useState(false)

  useEffect(() => {
    if (window.location.pathname === '/logout' && (localStorage.getItem('isAuthenticated') !== '1')) {
      // Set true, if navigate to logout page & already authenticated.
      setLogoutValue(true)
    } else {
      // Set false for all other condition.
      setLogoutValue(false)

      if (window.location.pathname === '/view-application') {
        setViewApplication(true)
      } else {
        setViewApplication(false)
      }
    }
  }, [])

  // Return the output
  // If logout value true then only have logout route
  // Else will wrap with user data and theme provider.
  return (
    <>
      {(logoutValue)
        ? <BrowserRouter>
          <Switch>
            <Route path='/logout' element={<LogoutPage />} />
          </Switch>
        </BrowserRouter>
        : <UserProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ShoppingCartProvider>
                <NotificationProvider>
                  <AnnouncementProvider>
                    <NavBarProvider>
                      <EventTrackingProvider>
                        {viewingApplication
                          ? <Switch>
                            <Route path='/view-application' element={<ViewApplication />} />
                          </Switch>
                          : <>
                            <NavBar />
                            <Routes />
                            <Footer />
                          </>
                        }
                        <EventTracker />
                      </EventTrackingProvider>
                    </NavBarProvider>
                  </AnnouncementProvider>
                </NotificationProvider>
              </ShoppingCartProvider>
            </BrowserRouter>
          </ThemeProvider>
        </UserProvider>
      }
    </>
  )
}
