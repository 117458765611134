import React, { useContext, useEffect, useState } from 'react'
// Making necessary imports.
import './application.scss'
import { Typography } from '@mui/material'
import { UserContext } from '../../context/UserContext'
import { constants } from '../../../lib'
import { checkAccess, queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { CTErrorPage, makeNoAccessRows } from '../../ContentDetails/ContentPages/CTHelpers/CTErrorPage'
import Loader from '../../PageLoader'
import PageHeader from '../Assets/component/PageHeader'
import PageAboutDetails from '../Assets/component/PageAboutDetails'
import TeamCard from '../Assets/component/TeamCard'
import OwnedBy from '../Assets/component/OwnedBy'
import EmbedExternalUrl from '../../EmbedExternalUrl'
import { useMemberList, useFavorites } from '../../../hooks'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import TagsCard from '../Assets/component/TagsCard'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

// Defining the Application for the nextGen.
const Application = ({ id }) => {
  // Set location.
  const location = useLocation()
  // Set CTName value.
  const CTName = 'Application'
  // Set aboutHeading.
  const aboutHeading = 'About Application'
  // Set all data.
  const [data, setData] = useState()
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set if has access or not.
  const [onlyNoAccess, setOnlyNoAccess] = useState(false)
  // Set if have availability error.
  const [notAvailable, setNotAvailable] = useState()
  // Set if have null value error.
  const [hasNullValue, setHasNullValue] = useState(false)
  // Set error state.
  const [hasError, setHasError] = useState(false)
  // Set ownedGroup.
  const [ownedGroup, setOwnedGroup] = useState()
  // Set ownedGroup.
  const [ownedGroupId, setOwnedGroupId] = useState(null)
  // Set sharedWith groups.
  const [sharedWithGroups, setSharedWithGroups] = useState([])
  // Set ownerGroup data.
  const [ownerGroupData, setOwnerGroupData] = useState({})
  // Set tags.
  const [tags, setTags] = useState([])
  // Get required user context data.
  const { userName, otherDetails } = useContext(UserContext)
  // Get the team data object from config.
  const groupTeamData = constants.misc.groupTeamMemberData
  // Set gId.
  const gid = queryString.parse(location.search.replace('?', ''))?.gid || 0
  // Get member details from member list hook.
  const [businessTeam, membersProfileImageData, isMember, isRespOrAccount] = useMemberList(ownerGroupData?.groupMembersById?.results, ownedGroupId, gid)
  // Fetch the favorites and favorite operations from hook.
  const [userFavorites, addOrRemoveFavorite, feedbackMessage] = useFavorites()
  // Check node access when ids are available.
  useEffect(() => {
    if (!id) {
      setHasNullValue(true)
      setIsLoading(false)
    } else {
      const promise = checkAccess(id)
      const show = []
      const hide = []
      promise.then(obj => {
        // Show data if have access.
        if (obj.access) {
          show.push(obj.item)
        } else {
          // Hide data or generate error base on code received.
          if (obj.error === 403) {
            hide.push(obj.item)
          }
          if (obj.error === 404) {
            setHasLoadingError(true)
            setIsLoading(false)
          }
        }
      }).then(() => {
        // If user have access.
        if (show && show.length > 0) {
          // Build the payload for api.
          const articleObj = {
            id: show[0]
          }
          if (gid) {
            articleObj.gid = gid
          }
          const articleVariable = JSON.stringify(articleObj)

          // Call graphql for articleQuery.
          const articleQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.articleQuery}&variables=${articleVariable}`
          // Query Call.
          queryContent(articleQuery)
            .then(response => {
              if (!response?.data?.nodeQuery?.count) {
                setHasLoadingError(true)
              } else {
                // Set data based on nodeQuery.
                const entity = response.data.nodeQuery?.entities[0]
                setData(entity)
                let sector
                if (response?.data?.groupById) {
                  sector = response?.data?.groupById?.owningSector?.entity
                } else {
                  sector = entity?.fieldOwningGroup?.entity?.owningSector?.entity
                }
                // Sending page data for tracking.
                setPageData({
                  type: 'application',
                  breadCrumbs: entity?.entityBreadCrumb,
                  siteSector: [
                    {
                      id: sector?.id,
                      location: `${window.location.origin}${sector?.url?.path}`,
                      title: sector?.title
                    }
                  ],
                  page_id: id,
                  group_id: entity?.fieldOwningGroup?.entity?.nid,
                  group_title: entity?.fieldOwningGroup?.entity?.title,
                  group_location: `${window.location.origin}${entity?.fieldOwningGroup?.entity?.url?.path}`,
                  userRole: response.data?.groupUserGroupsByUidRoleList?.results[0]?.userRoles
                })
                const relationalTags = entity.relationalTags?.entities[0]
                if (relationalTags?.useTags.includes('yes')) {
                  setTags(relationalTags.fieldTags.map((tag, index) => {
                    if (tag?.entity) {
                      return ({
                        entity: {
                          title: tag.entity.entityLabel,
                          tid: index
                        }
                      })
                    } else {
                      return {}
                    }
                  }))
                } else {
                  setTags(entity.fieldTags)
                }
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
        } else {
          if (!hide || hide.length === 0) {
            setHasLoadingError(true)
            setIsLoading(false)
          } else {
            // Generate proper user information.
            const userInfo = userName || otherDetails.name
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setOnlyNoAccess(true)
            setNotAvailable(noAccessRows)
            setIsLoading(false)
          }
        }
      })
    }
  }, [id, userName, otherDetails.name, gid])

  // Fetch the overall details for the owner group.
  const fetchOwnedGroupData = ownerGroupId => {
    // Start loading.
    setIsLoading(true)
    // Generate the payload.
    const groupVariable = JSON.stringify({
      id: ownerGroupId.toString()
    })
    // Generate the query url.
    const groupQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupOverviewQuery}&variables=${groupVariable}`
    // Query Call
    queryContent(groupQuery)
      .then(response => {
        if (!response?.data) {
          setHasError(true)
        } else {
          // Set GroupData.
          setOwnerGroupData(response.data)
        }
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false))
  }

  // Finding OwnedBy SharedWith group details.
  const findOwnedBySharedWithGroup = () => {
    if (typeof data === 'undefined') {
      return
    }
    let ownedGroupValue = ''
    if (data?.fieldOwningGroup?.entity?.lifeCycle?.entity?.title !== constants.misc.retiredLifeCycle) {
      ownedGroupValue = data?.fieldOwningGroup?.entity?.nid
      setOwnedGroup(data?.fieldOwningGroup?.entity)
    }
    const sharedWithGroupValue = data?.groups?.results?.filter(group => (group?.nid !== ownedGroupValue))
    setSharedWithGroups(sharedWithGroupValue)
    // Calling fetchOwnedGroupData.
    if (ownedGroupValue) {
      setOwnedGroupId(ownedGroupValue)
      fetchOwnedGroupData(ownedGroupValue)
    } else {
      setOwnedGroupId('0')
    }
  }
  // Find ownedBy sharedWith groupName
  useEffect(() => {
    findOwnedBySharedWithGroup()
  }, [data])

  // Display error message if null value received.
  if (hasNullValue && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.nullValue }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }
  // Display error message if don't have access.
  if (onlyNoAccess && !isLoading && notAvailable) {
    return (
      <CTErrorPage ct={`${CTName}`} message={notAvailable} />
    )
  }
  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.invalidContent }} align='center' />
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }
  // Return error page if have some error.
  if (hasError) {
    return (
      <div className="group_page_wrapper min_content_height bg-f7">
        <div className="group_wrapper_section">
          <div className="group_page_section">
            <div className="errorLoading" dangerouslySetInnerHTML={{ __html: constants.errors.server }} />
          </div>
        </div>
      </div>
    )
  }
  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  return !isLoading && data && (
    <div className="min_content_height bg-f7">
      <PageHeader title={data.title} memberCount={data.userHavingAccess} entity="Application"
        isRespOrAccount={isRespOrAccount} id={data?.nid} breadCrumb={data.entityBreadCrumb}
        userFavorites={userFavorites} addOrRemoveFavorite={addOrRemoveFavorite} feedback={feedbackMessage}
        gid={gid || ownedGroupId} ownerGroupId={ownedGroupId} />
      <div className="application_details_wrapper">
        <div className="group_page_wrapper">
          <div className="group_wrapper_section">
            <div className="group_page_section">
              <div className="group_pageleft col-9">
                {data?.embedExternalUrl?.uri &&
                  <>
                    <div className="group_heading_wrapper">
                      <div className="group_heading">Application View</div>
                    </div>
                    <div className='full_screen_wrapper'>
                      <div className='full_screen'>
                        <Typography className="body-embed-url">
                          <EmbedExternalUrl url={data?.embedExternalUrl?.uri} buttonTitle="View full screen" />
                        </Typography>
                      </div>
                    </div>
                  </>
                }
                <div className='group_heading details_heading'>Details</div>
                <div className='section_wrapper'>
                  {data?.articleDescription?.value ? <div dangerouslySetInnerHTML={{ __html: data?.articleDescription?.value }} /> : constants.filter.none}
                </div>
                <div className='group_heading section_heading'>Access Instructions</div>
                <div className='section_wrapper'>
                  <div className='section_details'>
                    {data?.accessInstructions?.value ? <div dangerouslySetInnerHTML={{ __html: data?.accessInstructions?.value }} /> : constants.filter.none}
                  </div>
                </div>
              </div>
              <div className="group_pageright col-3">
                <PageAboutDetails vision={data?.vision?.value} lifeCycle={data?.fieldLifeCycle?.entity?.title} entity="Application" heading={aboutHeading} lastChanged={data?.entityChanged} owner={data?.owner?.entity || data?.creator} gid={gid || ownedGroupId} nid={id} />
                <TeamCard teamData={groupTeamData.businessTeam}
                  businessTeam={businessTeam} id={ownedGroupId} isMember={isMember}
                  profileImageData={membersProfileImageData} />
                <OwnedBy ownedBy={ownedGroup} sharedWith={sharedWithGroups} />
                {
                  (tags.length) ? <TagsCard tags={tags} /> : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Return the component for use.
export default Application
