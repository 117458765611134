import React, { useContext, useEffect, useState } from 'react'
import RevisionHeader from './RevisionHeader'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { constants } from '../../../lib'
import RevisionList from './RevisionList'
import ViewRevision from './ViewRevision'
import './revision-history.scss'
import CompareVersions from './CompareVersions'
import { NavBarContext } from '../../../contexts/NavBarContext'
import NotFound from '../../NotFound'

const RevisionHistory = () => {
  // Set breadCrumb array.
  const [breadCrumb, setBreadCrumb] = useState([])
  // Set isHeaderLoading.
  const [isHeaderLoading, setIsHeaderLoading] = useState(true)
  // Set feedback message.
  const [feedbackMessage, setFeedbackMessage] = useState()
  // Get gid, nid, task from path parameter.
  const { gid, nid, task, vid } = useParams()
  // Set the main page url.
  const mainPageUrl = `/revision-history/${gid}/application/${nid}`
  // Get navigate function hook.
  const navigate = useNavigate()
  // NavBarContext method.
  const { setUrlPath } = useContext(NavBarContext)

  // Function to return page based on the task.
  const generatePage = () => {
    let routeError = false
    if (!parseInt(nid)) {
      return <NotFound />
    }
    if (task === 'view') {
      if (typeof vid === 'undefined') {
        navigate(`${mainPageUrl}/view/all`)
      } else if (vid === 'all') {
        return <RevisionList setFeedbackMessage={setFeedbackMessage} setBreadCrumb={setBreadCrumb} setIsHeaderLoading={setIsHeaderLoading} />
      } else if (parseInt(vid)) {
        return <ViewRevision setBreadCrumb={setBreadCrumb} setFeedbackMessage={setFeedbackMessage} setIsHeaderLoading={setIsHeaderLoading} />
      } else {
        routeError = true
      }
    } else if (task === 'compare') {
      return <CompareVersions setBreadCrumb={setBreadCrumb} setFeedbackMessage={setFeedbackMessage} setIsHeaderLoading={setIsHeaderLoading} />
    } else {
      routeError = true
    }
    if (routeError || !parseInt(nid)) {
      return <NotFound />
    }
  }
  // Clear the feedbackMessage after given time has passed.
  useEffect(() => {
    const timer = setTimeout(() => setFeedbackMessage({}), constants.misc.favoritesLimit.feedbackMessageTimeout * 1000)
    return () => clearTimeout(timer)
  }, [feedbackMessage])

  // Set url path for navbar.
  useEffect(() => {
    if (breadCrumb && breadCrumb?.length > 1) {
      setUrlPath(breadCrumb[1]?.url?.path)
    }
  }, [breadCrumb, setUrlPath, task])

  // return the output.
  return (
    <div className='min_content_height bg-f7'>
      {!isHeaderLoading && <RevisionHeader breadCrumb={breadCrumb} gid={gid} feedbackMessage={feedbackMessage} />}
      <div className='revision_history_page_wrapper row'>
        <div className='revision_history_page_wrapper_section'>
          <div className='revision_history_wrapper'>
            {!isHeaderLoading && <div className='revision_history_header'>
              <div className='sub_heading'>
                Revisions allow you to track differences between multiple versions of your content, and revert to older versions.
              </div>
              {vid !== 'all' && <div className='previous_list' data-tracking="Back to all versions" onClick={() => navigate(`${mainPageUrl}/view/all`)}>
                <ArrowBackIcon />
                Back to all versions
              </div>
              }
            </div>
            }
            {generatePage()}
          </div>
        </div>
      </div>
    </div>
  )
}

// Export the component for use.
export default RevisionHistory
