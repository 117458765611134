import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import IframeResizer from 'iframe-resizer-react'
import React, { useState } from 'react'
import Loader from '../../../PageLoader'
import CloseIcon from '@mui/icons-material/Close'
import '../sass/component/modal-form.scss'
import { usePostMessage } from '../../../../hooks'

const ModalForms = ({ open, setOpen, url, setShouldReload, setMessage }) => {
  // Set formLoading flag.
  const [formLoading, setFormLoading] = useState(true)

  // Close popup.
  const handleClose = () => {
    setOpen(false)
  }
  // Call the post message hook.
  usePostMessage(setShouldReload, handleClose, setMessage)

  // Identify the form loaded.
  const formLoaded = () => {
    setFormLoading(false)
  }

  // Generate the body.
  const body = (
    <div className='dialog-modal'>
      <DialogTitle id="scroll-dialog-title">
        <CloseIcon className="close-icon" onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        {formLoading ? <Loader /> : null}
        <DialogContentText>
          <Typography className='field'>
            <IframeResizer
              src={url}
              className="webform-share-iframe"
              frameBorder="0"
              allow="geolocation; microphone; camera"
              allowtransparency="true"
              onLoad={formLoaded}
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </div>
  )

  // Return the output.
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth='md'
        scroll="paper"
      >
        {body}
      </Dialog>
    </div>
  )
}

// Export component for use.
export default ModalForms
