import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Insights from './insights'
import NotFound from '../NotFound'
import { insightsUtilityObject } from './utility'

// Main component for asset insight.
const AssetInsight = () => {
  // Set params.
  const params = useParams()
  // Set insight component.
  const [insightComponent, setInsightComponent] = useState(null)

  useEffect(() => {
    // Load corresponding component from name param.
    // Otherwise show 404 page.
    const insightObject = insightsUtilityObject(params.name)
    if (typeof (insightObject) === 'object') {
      setInsightComponent(<Insights details={insightObject} />)
    } else {
      setInsightComponent(<NotFound />)
    }
  }, [params])

  // Return the output.
  return (
    <div className='content-home content-esp content-insight'>
      {insightComponent}
    </div>
  )
}

export default AssetInsight
