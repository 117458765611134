import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import iconExpand from '../../../images/white-icon-expand.svg'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { Link } from 'react-router-dom'
import { deleteEntities } from './helpers'
import { constants } from '../../../lib'
import FavoriteMenuOptions from './FavoriteMenuOptions'
import { UserContext } from '../../context/UserContext'
import FavoriteEntityHeader from './FavoriteEntityHeader'

export default function FavoriteApplications ({ nodeData, setNodeData, updateFavorite, setFeedbackMessage }) {
  // Set cardData.
  const [cardData, setCardData] = useState(nodeData)
  // Set sortCriteria from localStorage.
  const [sortCriteria, setSortCriteria] = useState(localStorage.getItem('applicationSortCriteria') || 1)
  // Set display limit from constants.
  const [limit, setLimit] = useState(constants.misc.favoritesLimit.nodeDisplayLimit)
  // Set app opened or not.
  const [appOpened, setAppOpened] = useState([])
  // Get userId from UserContext.
  const { userId } = useContext(UserContext)
  // Handle function for react-fullscreen.
  const handle = useFullScreenHandle()
  // Set iframeUrl to be displayed in fullscreen.
  const [iframeUrl, setIframeUrl] = useState('')
  // Set applicationSearchRef.
  const applicationSearchRef = useRef()

  // Function to remove the application favorites.
  const handleRemove = async assetId => {
    const nodeItem = cardData.filter(node => node.nid === assetId)[0]
    // First removing from localState, then firing api.
    setCardData(() => cardData.filter(node => node.nid !== assetId))
    setNodeData(() => nodeData.filter(node => node.nid !== assetId))
    // Checking api status.
    const apiStatus = await deleteEntities(userId, null, null, 'node', parseInt(assetId))
    // If api failed, then put the group back into localState.
    if (!apiStatus) {
      setFeedbackMessage({
        message: 'Error Occurred while removing.',
        type: 'error'
      })
      setCardData(() => [...cardData, nodeItem])
      setNodeData(() => [...nodeData, nodeItem])
    } else {
      setFeedbackMessage({
        message: `Removed ${nodeItem.title} from `,
        type: 'success'
      })
    }
  }

  // To focus on the input field as soon as it gets rendered.
  useEffect(() => {
    if (applicationSearchRef.current) {
      applicationSearchRef.current.focus()
    }
  }, [applicationSearchRef.current])

  // Handles change in the sortCriteria.
  const handleSortCriteria = value => {
    localStorage.setItem('applicationSortCriteria', value)
    setSortCriteria(value)
  }

  // Handle fullscreen state change.
  const handleChange = useCallback(isFullScreen => {
    if (!isFullScreen) {
      setIframeUrl('')
    }
  }, [handle])

  // Show iframe in fullscreen.
  useEffect(() => {
    if (iframeUrl) {
      handle.enter()
    }
  }, [iframeUrl])

  // Get url of node add gid if exist.
  const getNodeUrl = (url, gid) => {
    let pageUrl = url
    if (gid) {
      pageUrl += `?gid=${gid}`
    }
    return pageUrl
  }

  // Handle click when navigate to details page
  const pageNavigationClick = id => {
    updateFavorite(id, 'node', 1)
    setAppOpened(current => [...current, id])
  }

  // Return the output of favorite applications.
  return (
    <div className="fav_page_wrapper">
      <FullScreen handle={handle} onChange={handleChange}>
        <div className="fav_application_wrapper">
          <iframe
            title="View Application"
            id="embed-iframe"
            src={iframeUrl}
            height={window.innerHeight}
            width="100%"
          ></iframe>
        </div>
      </FullScreen>
      <FavoriteEntityHeader title="Favorite Applications" data={nodeData} sortCriteria={sortCriteria}
        handleSortCriteria={handleSortCriteria} setCardData={setCardData} />
      <div className="group_wrapper_section">
        <div className="group_page_section">
          <div className="row">
            {(!cardData?.length && !nodeData?.length)
              ? <div className="no_item_group">
                <p className="no_fav">You do not have any favorite applications</p>
                <Link to="/search?entityType=article&keyword=&offset=0" target="_blank" className="search_grp">Search Applications</Link>
              </div>
              : null}
            {
              (nodeData?.length && !cardData?.length) ? <div className="no_item_group">No application match with that search keyword.</div> : null
            }
            {cardData && cardData.map((node, index) => (index < limit)
              ? (<div className="col-3" key={index}>
                <div className="application_card_section">
                  <div className="image">
                    {node?.opened === 0 && !appOpened.includes(node.nid) && <div className="just-added">Just added</div>}
                    {node?.embededExternalUrl?.url?.path && <><img src={node?.contentImage?.entity?.thumbnail?.derivative?.url} alt={node?.contentImage?.entity?.thumbnail?.alt || 'application image'} />
                      <div className="image-cover" onClick={() => {
                        setIframeUrl(node.embededExternalUrl.url.path)
                        pageNavigationClick(node.nid)
                      }}>
                        <div className="icon_expand"><img src={iconExpand} alt="Go to Full Screen" />View Application</div>
                      </div>
                    </>}
                  </div>
                  <div className="app_section_bottom">
                    <div className="name"><Link to={getNodeUrl(node?.url?.path, node?.gid)} onClick={() => pageNavigationClick(node.nid)}>{node.title}</Link></div>
                    <div className="count">
                      <div className="count_left">{node?.lifeCycleState?.entity?.entityLabel || ''}</div>
                      <FavoriteMenuOptions url={node?.goToGroupUrl} assetId={node.nid}
                        assetName={node.title} assetType="node" handleRemove={handleRemove} />
                    </div>
                  </div>
                </div>
              </div>)
              : null)
            }
          </div>
          {(cardData && (cardData.length > limit)) && <div className="See_more" onClick={() => setLimit(constants.misc.favoritesLimit.node)}>See More Favorite Applications ({cardData.length - limit})</div>}
        </div>
      </div>
    </div >
  )
}
