import React, { useState } from 'react'
import { Select, MenuItem, FormControl, Typography } from '@mui/material'
import '../searchFacets.scss'

/**
 *
 * @param {function} onChange - Function callback for changing dropdown selected value
 * @param {array} selectOptions - Array of strings holding possible values for dropdown menu items
 * @param {string} title - String containing the name of this select facet - displayed in <Typography> over Select element
 * @param {string} defaultOverride - default value for activeItem, stored as current value of filter in Search page
 *
 * @return {Object} React component representing a Select element with menu items retreived from drupal
 */
export default function SelectFacet ({ onChange, selectOptions, title, defaultOverride }) {
  if (!selectOptions.includes(defaultOverride)) {
    defaultOverride = selectOptions[0]
  }
  const [activeItem, setActiveItem] = useState(defaultOverride)

  const handleChange = event => {
    onChange(event.target.value)
    setActiveItem(event.target.value)
  }

  return (
    <FormControl
      className='search-facet-wrapper'
    >
      <Typography>
        {title}
      </Typography>
      <Select
        labelId='select-label'
        id='select'
        value={activeItem}
        onChange={handleChange}
        label=''
      >
        {selectOptions.map((type, idx) => (
          <MenuItem
            key={`${type}-${idx}`}
            value={type}
          >
            {capitalizeFirstLetter(type)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
