import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, FormControl, FormControlLabel, Button, TextField, Checkbox } from '@mui/material'
import { Autocomplete } from '@mui/lab'
import AuditItem from '../AuditItem'
import { Delete, Send } from '@mui/icons-material'
import { ShoppingCartContext } from '../../contexts/ShoppingCartContext'
import './CartItem.scss'
import { constants } from '../../lib'

export default function CartItem ({ processes, mapKey, item, setPopupRecipient, setPopupItem, setPopupVisibility, setErrorText, setEmailStatusText, changeHandler, defaultSelectedProcess, emailNotifyHandler, setEventInProgress }) {
  // Fetch ShoppingCartContext.
  const { removeItem, addEmailRecipient, emailRecipients } = useContext(ShoppingCartContext)
  // set recipient data.
  const [recipient, setRecipient] = useState(defaultSelectedProcess)
  // set recipientId.
  const [recipientId, setRecipientId] = useState()
  // set isChecked flag.
  const [isChecked, setIsChecked] = useState(false)
  // set isCheckedNotify flag.
  const [isCheckedNotify, setIsCheckedNotify] = useState(true)
  // set checkedElement data.
  const [checkedElement, setCheckedElement] = useState('')
  // set disableElement flag.
  const [disableElement, setDisableElement] = useState(true)
  // Prepare processList.
  const processList = processes?.entities?.length ? processes.entities.filter(entity => entity !== null) : ['']
  // Function to handle change operation.
  const handleChange = (_, value) => {
    setRecipient(value)
    setIsChecked(false)
    setDisableElement(false)
  }
  // Function to handle checkbox change operation.
  const handleCheckboxChange = (event, value) => {
    if (value && typeof (recipient) !== 'undefined') {
      setIsChecked(true)
      changeHandler(recipient)
      setCheckedElement(event.target.name)
    } else {
      setIsChecked(false)
    }
  }
  // Function to handle delete operation.
  const handleDelete = () => {
    // Remove Item.
    removeItem(mapKey)
  }
  // Function to handle send operation.
  const handleSend = () => {
    // Set error message blank.
    setErrorText('')
    if (item.id && recipientId) {
      setEventInProgress(true)
      fetch('/api/assetProcesses', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify([{
          assetId: item.id,
          processId: recipientId,
          assetType: item.resultType
        }])
      })
        .then(res => {
          if (res.status < 400) {
            setPopupItem(item)
            setPopupRecipient(recipient)
            setPopupVisibility(true)
            removeItem(mapKey)
            // Function to send email notification.
            // Based on isCheckedNotify flag, notification will be sent.
            emailNotifyHandler([`${item.id}_${recipientId}`], [item.resultType], setEmailStatusText, [isCheckedNotify])
          } else {
            return res.json()
          }
        })
        .then(res => {
          if (res) {
            // Display error message.
            setErrorText(res.error)
          }
        })
        .catch(() => {
          setErrorText(constants.errors.server)
        })
        .finally(() => setEventInProgress(false))
    } else {
      setErrorText('Please select a process for your asset.')
    }
  }
  // Set recipient.
  useEffect(() => {
    if (recipient) {
      const [title, lifeCycle] = recipient.split(':')
      const currentRecipientId = processList
        .find(process =>
          process.title.trim() === title.trim() && (!process.fieldLifeCycle || process.fieldLifeCycle?.entity?.title?.trim() === lifeCycle.trim()))
      if (currentRecipientId) {
        addEmailRecipient({ processId: currentRecipientId?.nid, assetId: item.id, id: mapKey, name: recipient, sendNotify: isCheckedNotify })
        setRecipientId(currentRecipientId?.nid)
      }
    }
  }, [mapKey, recipient, isCheckedNotify, item])
  // Set initial parameter.
  useEffect(() => {
    if (defaultSelectedProcess) {
      if (checkedElement !== `copy_${mapKey}`) {
        setIsChecked(false)
        setDisableElement(true)
      }
      setCheckedElement('')
      setRecipient(defaultSelectedProcess)
    } else {
      if (typeof (emailRecipients.get(mapKey)) !== 'undefined') {
        setRecipient(emailRecipients.get(mapKey).name)
      }
    }
  }, [defaultSelectedProcess])
  // Prepare option data.
  const options = processList.reduce((acc, { title, fieldLifeCycle }) => [...acc, `${title}${fieldLifeCycle ? `: ${fieldLifeCycle.entity?.title}` : ''}`], [])
  // Return the output.
  return (
    <Box className='outline-box' border={1} margin={1} borderRadius={5}>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <Box className='auditItem-card'>
            <AuditItem className='auditItem-override' item={item} />
          </Box>
        </Grid>
        <Grid className='recipient-select-wrapper' item md={4} xs={12}>
          <FormControl className='formControl-wrapper'>
            <Autocomplete
              options={options}
              getOptionLabel={option => option}
              getOptionSelected={selection => selection}
              renderInput={params => <TextField {...params} label="Select Process" variant="outlined" />}
              onChange={handleChange}
              value={recipient}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCheckboxChange}
                name={`copy_${mapKey}`}
                color="primary"
                value={mapKey}
                checked={isChecked}
                disabled={disableElement}
              />
            }
            label="Copy selected process to others"
          />
        </Grid>
        <Grid className='button-group' item md={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setIsCheckedNotify(!isCheckedNotify)}
                name={`notification_${mapKey}`}
                color="primary"
                value={mapKey}
                checked={isCheckedNotify}
              />
            }
            label="Send with email notification"
          />
          <Box className='button-group'>
            <Button
              endIcon={<Send />}
              color='primary'
              variant='contained'
              className='actionButton'
              onClick={handleSend}
            >
              Send
            </Button>
            <Button
              endIcon={<Delete />}
              color='secondary'
              variant='contained'
              className='actionButton'
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
