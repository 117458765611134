import React from 'react'
import '../sass/component/owned-by.scss'
import { Link } from 'react-router-dom'

// Defining the OwnedBy group & shared with.
// Owned by coming from owning group.
// Shared with will be those groups where this content reside.
const OwnedBy = ({ ownedBy, sharedWith }) => {
  // Determine the className if have data.
  const wrapperClassName = ownedBy || sharedWith.length ? 'owned_wrapper' : ''
  const sharedClassName = ownedBy ? 'shared' : ''
  return (
    <div className={`${wrapperClassName}`}>
      {ownedBy && <div className="owned_inner">
        <div className="owned_heading">Owned by</div>
        <Link to={ownedBy?.url?.path}><div className='owned_name'>{ownedBy?.title}</div></Link>
      </div>}
      {sharedWith.length > 0 && <div className={`owned_inner ${sharedClassName}`}>
        <div className="owned_heading">Shared with</div>
        {
          sharedWith.map((groupName, index) => <Link to={groupName?.url?.path} key={index}><div className='owned_name'>{groupName?.title}</div></Link>)
        }
      </div>}
    </div>
  )
}

// Return the component for use.
export default OwnedBy
