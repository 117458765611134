import React from 'react'
import { Star, StarBorder, StarHalf } from '@mui/icons-material'
// Export StarRating component.
export default function StarRating ({ rating }) {
  // configurable param for max number of stars possible in a rating
  const MAX_STARS = 5

  const hasHalfStep = `${rating}`.includes('.')
  const fullStars = Math.floor(rating)
  const halfStars = fullStars + (hasHalfStep ? 1 : 0)

  const starsArray = Array(MAX_STARS)
    .fill(Star, 0, rating)
    .fill(StarHalf, rating, halfStars)
    .fill(StarBorder, halfStars)
  // Return StarRating data.
  return (
    <div>
      {starsArray.map((StarItem, idx) => <StarItem key={`star-rating-${idx}`} />)}
    </div>
  )
}
