import React, { createContext, useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { constants } from '../lib'
import { queryContent } from '../components/ContentDetails/ContentPages/CTHelpers/CheckAccess'
// Export AnnouncementsContext component.
export const AnnouncementContext = createContext()
// Export AnnouncementsProvider component.
export const AnnouncementProvider = ({ children }) => {
  // Set location.
  const location = useLocation()
  // Set infinite scrolling stop.
  const [hasMoreAnnouncements, setHasMoreAnnouncements] = useState(true)
  // Set announcements.
  const [announcements, setAnnouncements] = useState([])
  // Set nextAnnouncements for profile.
  const [nextAnnouncements, setNextAnnouncements] = useState([])
  // Set isLoading.
  const [isAnnouncementsLoading, setAnnouncementsIsLoading] = useState(true)
  // Set pageNumber.
  const [pageNumber, setPageNumber] = useState(0)
  // set data count from fetch data.
  const [totalAnnouncements, setTotalAnnouncements] = useState()
  // Set initial loading state.
  const initialPath = useRef()
  // Set shouldReload.
  const [announcementReload, setAnnouncementReload] = useState(false)

  // Set fetch announcements data.
  const fetchAnnouncementsData = useCallback(() => {
    // Set offset initial value.
    if (announcements.length >= totalAnnouncements) {
      // Set infinite scrolling stop.
      setHasMoreAnnouncements(false)
    } else {
      // Set offset value updating.
      setPageNumber(prevPageNumber => prevPageNumber + 1)
    }
  }, [announcements, totalAnnouncements])

  useEffect(() => {
    if (hasMoreAnnouncements) {
      // Variables object.
      let announcementVariables = {
        groupIds: 'all',
        groupOrGlobal: 'global',
        status: 'active',
        pageSize: constants.misc.defaultInboxRecordCount,
        page: pageNumber
      }
      // Stringify the variable object.
      announcementVariables = JSON.stringify(announcementVariables)
      // Prepare announcement query.
      const announcementQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.announcementListQuery}&variables=${announcementVariables}`
      // Call the query.
      queryContent(announcementQuery)
        .then(response => {
          const announcementsData = response.data.announcementsList.results
          setTotalAnnouncements(parseInt(response.data.announcementsList.count))
          setNextAnnouncements(announcementsData)
          if (pageNumber > 0) {
            setAnnouncements(prevAnnouncements => ([...prevAnnouncements, ...announcementsData]))
          } else {
            setAnnouncements(announcementsData)
          }
        })
        .catch(err => console.log(err))
        .finally(() => setAnnouncementsIsLoading(false))
    }
  }, [pageNumber, hasMoreAnnouncements])

  // Function to reload announcements.
  const reloadAnnouncements = () => {
    setHasMoreAnnouncements(false)
    setPageNumber(0)
    setTimeout(() => {
      setHasMoreAnnouncements(true)
    }, 500)
  }

  useEffect(() => {
    if (announcementReload) {
      reloadAnnouncements()
      setAnnouncementReload(false)
    }
  }, [announcementReload])

  // Set calling while path name changed.
  useEffect(() => {
    if (initialPath.current) {
      reloadAnnouncements()
    }
    initialPath.current = location.pathname
  }, [location.pathname])

  const contextValue = useMemo(() => ({ isAnnouncementsLoading, announcements, totalAnnouncements, hasMoreAnnouncements, fetchAnnouncementsData, nextAnnouncements, setAnnouncementReload }), [isAnnouncementsLoading, announcements, totalAnnouncements, hasMoreAnnouncements, fetchAnnouncementsData, nextAnnouncements, setAnnouncementReload])

  // Return AnnouncementsContext.
  return (
    <AnnouncementContext.Provider value={contextValue}>
      {children}
    </AnnouncementContext.Provider>
  )
}
