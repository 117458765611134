import { FormControl, FormControlLabel, Radio, RadioGroup, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Button } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React, { useContext, useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Loader from '../../../PageLoader'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../../../../lib'
import memberIconRed from '../../../../images/red-icon-people.svg'
import checkMarkIcon from '../../../../images/icon-checkmark.svg'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

const MoveGroup = ({ id, userId, eligibleGIDs, setShouldReload, setFeedbackMessage, setRedirectPath, crumbs }) => {
  // Set form loading.
  const [formLoading, setFormLoading] = useState(true)
  // Set entities.
  const [entities, setEntities] = useState([])
  // Set groupName.
  const [groupName, setGroupName] = useState('')
  // Set targetGroupId.
  const [targetGroupId, setTargetGroupId] = useState('')
  // Set breadCrumbs.
  const [breadCrumbs, setBreadCrumbs] = useState([])
  // Set rootSectorError.
  const [rootSectorError, setRootSectorError] = useState(false)
  // Set inProgress.
  const [inProgress, setInProgress] = useState(false)
  // Set hasError.
  const [hasError, setHasError] = useState(false)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    // Prepare move group query variable.
    const gid = JSON.stringify({ id })

    // Prepare eligible group query.
    const moveGroupDetailQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.moveGroupDetailQuery}&variables=${gid}`

    // Query Call.
    queryContent(moveGroupDetailQuery)
      .then(response => {
        if (response.data.groupById.entityBreadCrumb.length === 2) {
          // Root sector group cannot be moved.
          setRootSectorError(true)
        } else {
          // Set all group related properties.
          setGroupName(response.data.groupById.title)
          setTargetGroupId(response.data.groupById?.parentGroup.entity.id)
          if (!response.data.groupById.parentGroup.entity.otherGroupsOnSameLevel) {
            setEntities(response.data.rootSegments?.entities)
          } else {
            setEntities(response.data.groupById.parentGroup.entity.otherGroupsOnSameLevel.entity.groups?.entities)
          }

          // Setting breadCrumbs.
          setBreadCrumbs(response.data.groupById.entityBreadCrumb.slice(0, -2).map(x => {
            const segments = x.text.split('||')
            const text = segments[0]
            let idVal = '0'
            if (segments.length === 2) {
              idVal = segments[1]
            }
            return ({ text, id: idVal })
          }))
        }
        // Sending page data for tracking.
        setPageData({
          type: 'manage-move-group',
          breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: 'Move Group' }],
          page_id: id
        })
      })
      .catch(err => {
        console.log(err)
        setHasError(true)
      })
      .finally(() => setFormLoading(false))
  }, [id, userId])

  // Function to check if given group Id is eligible to be selected or not.
  const checkEligible = gid => {
    if (id === gid) {
      return true
    } else {
      return !eligibleGIDs.some(x => x === gid)
    }
  }

  // Function to get deeper into the group.
  const getData = id => {
    setFormLoading(true)
    const gid = JSON.stringify({ id })
    // Prepare the endpoint to get the group list for move group.
    const moveGroupDetailQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.moveGroupDetailQuery}&variables=${gid}`
    // Query Call.
    queryContent(moveGroupDetailQuery)
      .then(response => {
        if (response.data.groupById?.childGroups?.entities) {
          setEntities(response.data.groupById.childGroups?.entities)
        } else {
          setEntities(response.data.rootSegments?.entities)
        }
        if (parseInt(id)) {
          setBreadCrumbs(response.data.groupById.entityBreadCrumb.map(x => {
            const segments = x.text.split('||')
            const text = segments[0]
            let idVal = 0
            if (segments.length === 2) {
              idVal = segments[1]
            }
            return ({ text, id: idVal })
          }))
        } else {
          setBreadCrumbs([{ text: 'Home', id: '0' }])
        }
      })
      .catch(err => {
        console.log(err)
        setHasError(true)
      })
      .finally(() => setFormLoading(false))
  }

  // Function to handle target selection change.
  const handleChange = e => {
    setTargetGroupId(e.target.value)
  }

  const moveGroup = () => {
    setInProgress(true)
    // To track if logged out.
    let haveResponse = false
    // Call the api.
    fetch(`${process.env.REACT_APP_TARGET_URL}/api/move-group`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        gid: parseInt(id),
        parent_gid: parseInt(targetGroupId)
      })
    })
      .then(response => {
        haveResponse = true
        return response.json()
      })
      .then(res => {
        if (!res.error) {
          setFeedbackMessage({ icon: <img src={checkMarkIcon} alt='Member Icon' />, type: 'success', message: 'Moved to ', label: res.response.redirectPath.label })
          setRedirectPath(res.response.redirectPath.path)
          setShouldReload(true)
        } else {
          setFeedbackMessage({ icon: <img src={memberIconRed} alt='Member Icon' />, type: 'error', message: res.errorMsg })
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err)
        setHasError(true)
      })
      .finally(() => setInProgress(false))
  }

  // Return the output.
  return (
    <>
      {!rootSectorError
        ? <> {groupName.length
          ? <>
            <div className="managegroup_section_heading">
              Move Group <span>{groupName}</span>
            </div>
            <div className="managegroup_section_sub">
              <p>
                Select a group to move <span>{groupName}</span> into
              </p>
            </div>
          </>
          : null
        }
          {formLoading
            ? <Loader />
            : (<>{hasError && <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: constants.errors.server }} /></Alert>}
              <div className="move-group-bc">{breadCrumbs.map((bc, i) => (i === breadCrumbs.length - 1) ? <span key={i}>{bc.text}<ChevronRightIcon /></span> : <span className="clickable" onClick={() => getData(bc.id)} key={i}>{bc.text}<ChevronRightIcon /></span>)}</div>
              <div className="group_list_wrapper">
                <List>
                  <FormControl className="move-group-list">
                    <RadioGroup aria-labelledby="move-group-list" name="move-group" value={targetGroupId} onChange={handleChange}>
                      {entities?.map((item, index) => {
                        const disabled = checkEligible(item.id)
                        return (
                          <ListItem key={index} disablePadding>
                            <ListItemIcon className={item.childGroups.count ? 'expandable' : 'non-expandable'}>
                              {item.childGroups.count ? <ChevronRightIcon fontSize="inherit" onClick={() => getData(item.id)} /> : <ChevronRightIcon fontSize="inherit" />}
                            </ListItemIcon>
                            {item.childGroups.count ? <ListItemText primary={item.title} className="expandable" onClick={() => getData(item.id)} /> : <ListItemText primary={item.title} />}
                            <ListItemSecondaryAction sx={{ right: 0 }}>
                              <FormControlLabel value={item.id} control={<Radio size="small" disabled={disabled} />} />
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </List>
              </div>
              <div className="btn-wrapper-movegroup">
                {!inProgress
                  ? <Button id="move-group" data-tracking="Move Group" variant="contained" color="primary" className="movegrp-btn" onClick={moveGroup}>
                    Move Group
                  </Button>
                  : <Button variant="contained" disabled>
                    Moving...
                  </Button>}
              </div>
            </>)}
        </>
        : <p>You are not allowed to move root sector group.</p>}
    </>
  )
}

// Export the component for use.
export default MoveGroup
