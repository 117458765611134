import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RemoveFavoriteModal from '../Assets/component/RemoveFavoriteModal'
import { ClickAwayListener } from '@mui/material'

const FavoriteMenuOptions = ({ url, handleRemove, assetId, assetName, assetType, pageNavigationClick }) => {
  // Set menuOpen( tracks if dropdown is extended or collapsed).
  const [menuOpen, setMenuOpen] = useState(false)
  // Set confirmModal( tracks if confirmation modal is displayed).
  const [confirmModal, setConfirmModal] = useState(false)

  // Function to toggle dropdown menu state.
  const handleClick = () => {
    setMenuOpen(prevState => !prevState)
  }

  // Function to close modal.
  const handleClose = e => {
    e.preventDefault()
    setConfirmModal(false)
    setMenuOpen(false)
  }

  // Function to open confirmation modal.
  const openConfirmModal = () => {
    setConfirmModal(true)
    setMenuOpen(false)
  }

  // Function to handle confirm click of the modal.
  const handleConfirm = e => {
    e.preventDefault()
    setMenuOpen(false)
    handleRemove(assetId)
    handleClose(e)
  }

  // Handle blur click for menu.
  const handleClickAway = () => {
    setMenuOpen(false)
  }

  // Handle blur click for menu.
  const goToGroupClick = () => {
    handleClickAway()
    if (assetType === 'group') {
      pageNavigationClick(assetId)
    }
  }

  // Return the output.
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="count_right">
        <span className="t_dots" onClick={handleClick}>
          <MoreVertRoundedIcon />
        </span>
        <div className="t_option">
          {menuOpen && <ul className="t_inner_options">
            <li><Link to={url} onClick={() => goToGroupClick()}>Go to Group</Link></li>
            <li onClick={() => openConfirmModal()}>Remove Favorite</li>
          </ul>}
          <RemoveFavoriteModal confirmModal={confirmModal} handleClose={handleClose} entityName={assetName} handleConfirm={handleConfirm} />
        </div>
      </div>
    </ClickAwayListener>
  )
}

// Exporting the component.
export default FavoriteMenuOptions
