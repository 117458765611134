import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { Alert, Stack, List, ListItem, ListItemText } from '@mui/material'
import { UserContext } from '../../../context/UserContext'
import Loader from '../../../PageLoader'
import { usePostMessage } from '../../../../hooks'
import './manage-application.scss'
import DeleteApplication from './DeleteApplication'
import ApplicationManageForms from './ApplicationManageForms'
import { NavBarContext } from '../../../../contexts/NavBarContext'

const ManageApplication = () => {
  // Set breadCrumb array.
  const [breadCrumb, setBreadCrumb] = useState([])
  // Set isLoading.
  const [isLoading, setIsLoading] = useState(true)
  // Set groupData.
  const [articleData, setArticleData] = useState()
  // Extract id and task from path parameters.
  const { id, task, gid } = useParams()
  // Set hasManagePrive.
  const [hasManagePriv, setHasManagePriv] = useState(false)
  // Get userId from UserContext.
  const { userId } = useContext(UserContext)
  // Set shouldReload.
  const [shouldReload, setShouldReload] = useState(true)
  // Get feedbackMessage from hook.
  const [feedbackMessage, setFeedbackMessage] = usePostMessage(setShouldReload)
  // Set hasLoadingError.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Get ownerGroupId from params.
  const [ownerGroupId, setOwnerGroupId] = useState()
  // Set manageThroughGroup.
  const [manageThroughGroup, setManageThroughGroup] = useState(false)
  // Set manageThroughOwningGroup.
  const [manageThroughOwningGroup, setManageThroughOwningGroup] = useState(false)
  // NavBarContext method.
  const { setUrlPath } = useContext(NavBarContext)

  useEffect(() => {
    if (shouldReload) {
      // Build the payload for api.
      const articleObj = { id }
      if (gid) {
        articleObj.gid = gid
      }
      const articleVariable = JSON.stringify(articleObj)

      // Call graphql for articleQuery.
      const articleQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.articleQuery}&variables=${articleVariable}`
      // Prepare eligible group query variable.
      const uid = JSON.stringify({ uid: userId })
      // Prepare eligible group query.
      const gidQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupUserGroupsByUidRA}&variables=${uid}`

      // Query Call.
      queryContent(articleQuery)
        .then(response => {
          let owningGroupId
          if (!response?.data?.nodeQuery?.count) {
            setHasLoadingError(true)
          } else {
            // Set data based on nodeQuery.
            setArticleData(response.data.nodeQuery?.entities[0])
            setBreadCrumb([...response.data.nodeQuery?.entities[0].entityBreadCrumb, {
              text: 'Manage Application'
            }])
            owningGroupId = response.data.nodeQuery?.entities[0].fieldOwningGroup?.entity?.nid
            setOwnerGroupId(owningGroupId)
          }

          // Query Call.
          queryContent(gidQuery)
            .then(res => {
              const groupId = parseInt(gid) ? gid : owningGroupId
              const eids = res.data.groupUserGroupsByUidRA.results.map(x => x.gid)
              if (eids.some(eid => eid === owningGroupId) && groupId === owningGroupId) {
                setManageThroughOwningGroup(true)
                setHasManagePriv(true)
              } else if (eids.some(eid => eid === gid)) {
                setManageThroughGroup(true)
                setHasManagePriv(true)
              } else {
                setManageThroughOwningGroup(false)
                setManageThroughGroup(false)
                setHasManagePriv(false)
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
        })
        .catch(() => setHasLoadingError(true))
        .finally(() => setShouldReload(false))
    }
  }, [gid, id, userId, shouldReload])

  // Set url path for navbar.
  useEffect(() => {
    if (breadCrumb && breadCrumb?.length > 1) {
      setUrlPath(breadCrumb[1]?.url?.path)
    }
  }, [breadCrumb, setUrlPath, task])

  // Define all left menu items.
  const commonUrl = `/manage-application/${id}`
  const menuItems = [
    { label: 'Application Details', url: `${commonUrl}/edit/${gid}`, associatedTask: 'edit' },
    { label: 'Application Tags', url: `${commonUrl}/tags/${gid}`, associatedTask: 'tags' },
    { label: 'Cover Image', url: `${commonUrl}/cover-image/${gid}`, associatedTask: 'cover-image' },
    { label: 'Delete Application', url: `${commonUrl}/delete/${gid}`, associatedTask: 'delete' }
  ]

  // Function to generate body depending on the task.
  const generateBody = () => {
    switch (task) {
      case 'edit': {
        return <ApplicationManageForms id={id} gid={gid || ownerGroupId} formUrlId="ManageApplication" crumbs={breadCrumb} />
      }
      case 'cover-image': {
        return <ApplicationManageForms id={id} gid={gid || ownerGroupId} formUrlId="ManageApplicationCoverImage" crumbs={breadCrumb} />
      }
      case 'tags': {
        return <ApplicationManageForms id={id} gid={gid || ownerGroupId} formUrlId="ManageApplicationTags" crumbs={breadCrumb} />
      }
      case 'delete': {
        return <DeleteApplication id={id} gid={gid || ownerGroupId} manageThroughGroup={manageThroughGroup} manageThroughOwningGroup={manageThroughOwningGroup} applicationName={articleData?.title} setFeedbackMessage={setFeedbackMessage} crumbs={breadCrumb} />
      }
      default: {
        return <div />
      }
    }
  }
  // Display error if there.
  if (hasLoadingError) {
    return (<div className="min_content_height bg-f7">
      <div className="manageapp_page_wrapper row">
        <div className="manageapp_page_wrapper_section" dangerouslySetInnerHTML={{ __html: constants.errors.server }} />
      </div>
    </div>)
  }
  // Return the output.
  return (
    <div className="min_content_height bg-f7">
      {isLoading
        ? <Loader />
        : (<>
          <div className="page_header_wrapper">
            {feedbackMessage?.message && <div className="fab_details_alert">
              <Alert severity={feedbackMessage.type} icon={feedbackMessage?.icon} >
                {feedbackMessage?.message}
              </Alert>
            </div>}
            <div className="breadcrumb_section_wrapper">
              <ul className="breadcrumb_section">
                {breadCrumb &&
                  breadCrumb?.map((item, index) => {
                    const gidString = gid ? `?gid=${gid}` : ''
                    return <li key={index} className="breadcrumb_item">
                      {index < breadCrumb.length - 1
                        ? (<>
                          <Link to={index === breadCrumb.length - 2 ? `${item?.url?.path}${gidString}` : item?.url?.path}>{item?.text}</Link>
                          <span className="breadcrumb_icon">
                            <ChevronRightIcon />
                          </span>
                        </>)
                        : item?.text
                      }
                    </li>
                  })}
              </ul>
            </div>
            <div className="page_header_inner">
              <div className="page_header_heading">Manage Application</div>
            </div>
          </div>
          <div className="manageapp_page_wrapper row">
            <div className="manageapp_page_wrapper_section">
              {
                hasManagePriv
                  ? (<Stack direction={{ xs: 'column', sm: 'row' }}>
                    <div className="manageapp_left_section col-3">
                      <div className="manageapp_menu_wrapper">
                        <List className="manageapp_menu">
                          {
                            menuItems.map((item, index) => (
                              <ListItem key={index} disablePadding className={task === item.associatedTask ? 'active-menu' : ''}>
                                <ListItemText children={<Link to={item?.url}>{item.label}</Link>} />
                              </ListItem>
                            ))
                          }
                        </List>
                      </div>
                    </div>
                    <div className="manageapp_right_section">
                      <div className="manageapp_section_wrapper">
                        {generateBody()}
                      </div>
                    </div>
                  </Stack>)
                  : (<div className="manageapp_access_wrapper">
                    <div className="message">
                      You do not have access to manage this Application
                    </div>
                    <Link className="message_link" to={articleData?.url?.path}>Back to {articleData?.title}</Link>
                  </div>)}
            </div>
          </div>
        </>)}
    </div>
  )
}

// Export the component for use.
export default ManageApplication
