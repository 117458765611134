import React, { useState, useContext } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import { Menu, MenuItem, ListItemText, Avatar } from '@mui/material'
import ProfileImage from '../../../ProfileImage'
import { userAbbreviation } from '../../../ProfileImage/utility'
import { blue } from '@mui/material/colors'
import { UserContext } from '../../../context/UserContext'
import '../sass/component/my-profile.scss'

// Set dropDownFocused.
const dropDownFocused = blue[900]
// Define StyledMenu.
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))
// Define StyledMenuItem.
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: dropDownFocused,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    },
    '& div.MuiListItemText-root': {
      margin: '0px'
    }
  }
}))(MenuItem)

// Create style for avatar.
const useStyles = makeStyles(() => ({
  avatarStyle: {
    backgroundColor: '#1C75BC',
    width: '34px',
    height: '34px',
    fontSize: '0.85rem',
    '& img': {
      width: '34px',
      height: '34px'
    }
  }
}))

// Export MyProfile component.
export default function MyProfile () {
  // Fetch data from UserContext.
  const { performLogout, otherDetails } = useContext(UserContext)
  // Set anchorEl.
  const [anchorEl, setAnchorEl] = useState(null)
  // Set classes.
  const classes = useStyles()
  // Function to handle click event.
  const handleClick = event => {
    // Set AnchorEl.
    setAnchorEl(event.currentTarget)
  }
  // Function to handle close operation.
  const handleClose = () => {
    setAnchorEl(null)
  }
  let myAppProfilePhoto
  if (otherDetails.userPicture) {
    // Set the profile photo url if exist in application.
    myAppProfilePhoto = `${process.env.REACT_APP_TARGET_URL}/${otherDetails.userPicture}`
  }

  // Return MyProfile data.
  // Menus
  // - Inbox
  // - Log out
  return (
    <div>
      <Avatar onClick={handleClick} className={classes.avatarStyle}>
        <ProfileImage
          alt={userAbbreviation(otherDetails.name)}
          userPicture={myAppProfilePhoto}
          profileImage={otherDetails.profilePicture}
        />
      </Avatar>
      <StyledMenu id="my-profile" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem onClick={performLogout}>
          <ListItemText primaryTypographyProps={{ 'data-tracking': 'Log Out' }} primary="Log Out" />
        </StyledMenuItem>
      </StyledMenu>
    </div >
  )
}
