import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Typography, Grid, Button } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { useSearch } from '../../hooks'
import queryString from 'query-string'
import Pagination from '../Pagination'
import Loader from '../PageLoader'
import './search.scss'
import { entityTypes, facetNames, miscConfig, sendAlong } from './SearchConfig'
import FacetFields from '../SearchFacets/FacetFields'
import RadioFacetField from '../SearchFacets/FacetFields/RadioFacetField'
import SearchError from './SearchError'
import SearchSorting from './SearchSorting'
import { constants } from '../../lib'
import { ShoppingCartContext } from '../../contexts'
import { UserContext } from '../context/UserContext'
import ShoppingCartError from '../ShoppingCart/ShoppingCartError'
import SearchResults from './SearchResults'
import GenerateSearchPayloadVariable from './GenerateSearchPayloadVariable'
let locationChange = true

// Export the apache solr search component.
export default function Search ({ sectionIndex, currentEntityType, renderEntityType, setResultsCounts, resultCounts, onDataLoad }) {
  const maxMembers = 100
  const minMembers = 0
  // Set filter.
  const [filter, setFilter] = useSearch({
    entityType: 'process',
    sector: 'all',
    raci: 'all',
    keyword: '',
    member: '',
    size: [minMembers, maxMembers]
  })
  const history = useNavigate()
  const location = useLocation()
  const url = location.search
  let queryStringObj = queryString.parse(url.replace('?', ''))
  // Set the entity to render in this section.
  const entityTypeToRender = renderEntityType || queryStringObj?.entityType || 'all'
  // Include the entity type in query string.
  queryStringObj = { ...queryStringObj, entityType: entityTypeToRender }
  // Set original entity type as current.
  const originalEntityType = currentEntityType || queryStringObj?.entityType || 'all'
  // Set url params.
  const [params, setParams] = useState(queryStringObj)
  // Set search results.
  const [results, setResults] = useState([])
  // Set facet filters.
  const [facets, setFacets] = useState([])
  // Set facet hide state.
  const [hideFacets, setHideFacets] = useState([])
  // Set loading error state.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set loading state.
  const [isLoading, setIsLoading] = useState(true)
  // Set empty result state.
  const [noResults, setNoResults] = useState(false)
  // Set result count.
  const [totalCount, setTotalCount] = useState(0)
  // Set result change state.
  const [changeSearchData, setChangeSearchData] = useState(true)
  // Fetching the shopping cart item.
  const { addItemToCart, shoppingCartItems } = useContext(ShoppingCartContext)
  // Set the cart error state if has any.
  const [hasCartError, setHasCartError] = useState(false)
  // Set count of items remaining to be added to cart
  const [remainingItemsCount, setRemainingItemsCount] = useState(0)
  // Set count of items remaining to be added to cart
  const [bulkButtonEnableDisable, setBulkButtonEnableDisable] = useState(false)
  // Fetch data from UserContext.
  const { otherDetails } = useContext(UserContext)

  // Enable or disable bulk add to cart button.
  useEffect(() => {
    if (shoppingCartItems.size >= constants.misc.maxCartItem) {
      setBulkButtonEnableDisable(true)
    } else {
      setBulkButtonEnableDisable(false)
    }
  }, [shoppingCartItems.size])

  // Prepare filter conditions based on search criteria.
  useEffect(() => {
    const payloadVariables = GenerateSearchPayloadVariable(queryStringObj, otherDetails, currentEntityType, renderEntityType, setIsLoading)
    // Prepare the payload.
    const variables = JSON.stringify(payloadVariables)
    // Generate the query url.
    const searchQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.generalSearchQuery}&variables=${variables}`
    // Query call.
    fetch(searchQuery, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    })
      .then(response => response.json())
      .then(json => {
        // Set all necessary states.
        const rawFacets = json.data.searchAPISearch.facets
        const documents = json.data.searchAPISearch.documents
        setNoResults(json?.data?.searchAPISearch?.count === 0)
        setTotalCount(json?.data?.searchAPISearch?.count)
        if (setResultsCounts && renderEntityType) {
          setResultsCounts(renderEntityType, json?.data?.searchAPISearch?.count)
        }
        setChangeSearchData(true)
        // Prepare differ properties of search data for display.
        const searchData = documents.map(document => {
          let resultItem = ''
          if (typeof document.content__nid !== 'undefined' && document.content__nid !== null && document.content__nid !== '') {
            resultItem = {
              id: document.content__nid,
              bundle: document.content__content_type,
              resultType: 'node',
              title: document.content__content_title,
              description: document.content__body,
              cardImage: document.content__field_content_image_search_thumbnail_url,
              sectorColor: document.content__field_root_sector_color,
              sectorLabel: document.content__field_root_sector,
              vision: document.content__field_vision,
              gxp: document.content__field_is_gxp
            }
          } else if (typeof document.group__id !== 'undefined' && document.group__id !== null && document.group__id !== '') {
            resultItem = {
              id: document.group__id,
              bundle: document.group__type,
              resultType: 'group',
              title: document.group__title,
              vision: document.group__field_vision,
              sectorColor: document.group__field_root_sector_color,
              sectorLabel: document.group__field_root_sector,
              businessTeamCount: document.group__business_team_count,
              contentTeamCount: document.group__content_team_count,
              applicationsCount: document.group__applications_count
            }
          } else {
            resultItem = {}
          }
          resultItem = { ...resultItem, url: document.url, breadcrumb: document.entity__breadcrumb }
          return resultItem
        })
        const hideFacetKey = []
        const facetsData = rawFacets.filter(facet => {
          if (facetNames[originalEntityType] && typeof facetNames[originalEntityType][facet.name] !== 'undefined' && facetNames[originalEntityType][facet.name] !== null && facetNames[originalEntityType][facet.name] !== '') {
            if (typeof facetNames[originalEntityType][facet.name].hideDisplay !== 'undefined' && facetNames[originalEntityType][facet.name].hideDisplay === true) {
              // Hide the facet field display based on search config.
              hideFacetKey.push(facet.name)
              setHideFacets(hideFacetKey)
              return false
            } else {
              // Remove the facet from hidden list based on search config.
              // This is useful if same facet is used in multiple entityType and their hideDisplay is different.
              setHideFacets(hideFacetKey.filter(hiddenItem => hiddenItem !== facet.name))
              return true
            }
          }
          return false
        }).map(facet => {
          // Filter out all values returned from solr server.
          return {
            name: facet.name,
            label: facetNames[originalEntityType][facet.name],
            options: facet.values.filter(value => (value.filter !== '!')).map(value => value.filter)
          }
        }).reduce((facet, currentVal) => {
          // Prepare the facet label and options.
          facet[currentVal.name] = { label: currentVal.label, options: currentVal.options }
          return facet
        }, {})
        setResults(searchData)
        setFacets(facetsData)
        setHasLoadingError(false)
      })
      .catch(error => {
        console.log(error)
        setHasLoadingError(true)
      })
      .finally(() => {
        setIsLoading(false)
        onDataLoad(true)
        setChangeSearchData(false)
      })
  }, [params, originalEntityType, entityTypeToRender])

  // Detect the location change.
  useEffect(() => {
    if (!locationChange && JSON.stringify(params) !== JSON.stringify(queryStringObj)) {
      setParams(queryStringObj)
    } else {
      locationChange = false
    }
  }, [location])

  // only accept a query string value if it is a valid Select option
  if (Object.prototype.hasOwnProperty.call(entityTypes, params.entityType) && filter.entityType !== params.entityType) {
    filter.entityType = params.entityType
  }

  // Add plural indicator 's' if count is not 1.
  const setPlural = count => parseInt(count) === 1 ? '' : 's'

  // Handle any search filter change.
  const handleChangeFor = category => value => {
    locationChange = true
    let resetOffset = false
    let newParams
    const clonedFilter = JSON.parse(JSON.stringify(filter))
    clonedFilter[category] = value
    setFilter(clonedFilter)
    // Opt for reset offset if value changed
    if (category !== 'offset' && typeof (params[category]) !== 'undefined' && params[category] !== value) {
      resetOffset = true
    }
    // Set or delete other field specified in sendAlong for this entity type.
    if (sendAlong.all && sendAlong.all.includes(category)) {
      sendAlong.all.filter(item => item !== category).forEach(item => {
        params[item] = value
        if (value.trim() === '') {
          delete params[item]
        }
      })
    }
    // Remove parameter if blank else add/update.
    if (typeof (params[category]) !== 'undefined' && typeof value === 'string' && value.trim() === '') {
      newParams = params
      delete newParams[category]
    } else {
      newParams = { ...params, [category]: value }
    }
    // Remove all search filters for ney entity type or requested for clear.
    if (params?.entityType !== newParams?.entityType || category === 'clearAllFilters') {
      for (const param of Object.keys(newParams)) {
        let facetPersistentParams = miscConfig.facetPersistentParams
        if (category !== 'clearAllFilters' && !miscConfig.facetSemiPersistentParamsNotApplicable.includes(newParams?.entityType)) {
          facetPersistentParams = facetPersistentParams.concat(miscConfig.facetSemiPersistentParams)
        }
        // Remove extra parameter except default, when entityType changed or requested for clear.
        if (!facetPersistentParams.includes(param)) {
          delete newParams[param]
        }
      }
    }
    // Reset of set if it is opt for or limit changed.
    if (category === 'limit' || (category !== 'offset' && (typeof (params[category]) === 'undefined' || resetOffset))) {
      newParams = { ...newParams, offset: 0 }
    }
    // To append keyword if exists.
    if (typeof (params?.keyword) !== 'undefined') {
      newParams = { ...newParams, keyword: params.keyword }
    }
    let redirectParam = newParams
    if (category !== 'entityType') {
      // Redirect with current entity type only, not with rendered one.
      redirectParam = { ...redirectParam, entityType: originalEntityType }
    } else {
      // Set param with rendered entity type only, not with current one.
      newParams = { ...newParams, entityType: entityTypeToRender }
    }
    // Prepare the new url and redirect.
    const returnURL = `/search?${queryString.stringify(redirectParam)}`
    setParams(newParams)
    history(returnURL)
  }

  // Handle error including no result or loading error.
  const handleError = () => {
    if (isLoading) {
      return null
    } else {
      if (noResults) {
        return (
          <div className='search-results'>
            {parseInt(sectionIndex) === 0 && <div className='bulkButtonDiv'><Typography variant='h6' component='h3'>
              {resultCounts || 0} Search result{setPlural(resultCounts)} for "{searchKeyword}"
            </Typography></div>
            }
            <div className="search_section_wrapper">
              <div className="search_heading">{entityTypes[entityTypeToRender]?.label}</div>
              <div className="no-results search_inner_section">
                <SearchError params={params.keyword} />
              </div>
            </div>
          </div>)
      } else if (hasLoadingError) {
        return (<div className='search-results'>
          {parseInt(sectionIndex) === 0 && <div className='bulkButtonDiv'><Typography variant='h6' component='h3'>
            0 Search results for "{searchKeyword}"
          </Typography></div>
          }
          <div className="search_section_wrapper">
            <div className="search_heading">{entityTypes[entityTypeToRender]?.label}</div>
            <div className="no-results search_inner_section">
              <div className="error-message unexpected-error" dangerouslySetInnerHTML={{ __html: constants.errors.server }} />
            </div>
          </div>
        </div>
        )
      }
    }
  }

  // Add bulk cart items.
  const bulkAddItemInCart = allItems => {
    let selectedItemsNumber = shoppingCartItems.size
    allItems.forEach(item => {
      if (!(shoppingCartItems && shoppingCartItems.has(`${item.resultType}_${item.id}`))) {
        if (shoppingCartItems.size < constants.misc.maxCartItem) {
          addItemToCart(item)
        }
        selectedItemsNumber += 1
      }
    })
    if (selectedItemsNumber > constants.misc.maxCartItem) {
      setRemainingItemsCount(selectedItemsNumber - constants.misc.maxCartItem)
      setHasCartError(true)
    }
  }

  // Prepare search keyword from url parameter.
  const searchKeyword = typeof (params?.keyword) !== 'undefined' ? params.keyword : ''
  // The overlay call restrict to do anything during search in progress.
  const overlayClass = (isLoading || changeSearchData) ? 'loading-overlay' : ''

  // Return the output.
  return (
    <>
      <div className={overlayClass}></div>
      {parseInt(sectionIndex) === 0
        ? <aside className='search-filters search_new_filter'>
          <Typography variant='h6' component='h3' className='search-top-filter'>
            Filters
          </Typography>
          <div className='aside-content__padding-top'>
            <div className='search-filter search_inner_filter'>
              <RadioFacetField
                onChange={value => {
                  handleChangeFor('entityType')(value)
                }}
                radioOptions={entityTypes}
                title='Types'
                defaultOverride={originalEntityType}
              />
            </div>
            {miscConfig.facetSearchEnabled && !changeSearchData && facets && Object.keys(facets).length > 0 && (
              <FacetFields
                entityType={originalEntityType}
                facetsData={facets}
                hideFacets={hideFacets}
                onChange={handleChangeFor}
                params={params}
              />)
            }
          </div>
        </aside>
        : null
      }
      {
        results && !hasLoadingError && !isLoading && !changeSearchData && !noResults
          ? <aside className='search-results'>
            <div className='bulkButtonDiv'>
              {parseInt(sectionIndex) === 0 && <Typography variant='h6' component='h3'>
                {resultCounts || results.length || ''} Search result{setPlural(resultCounts || results.length || '')} for "{searchKeyword}"
              </Typography>
              }
              <div className='bulkButtonDiv hidden' style={{ gap: 20 }}>
                {params.entityType && params.entityType !== 'all' && <SearchSorting onChange={handleChangeFor} params={params} />}
                <Button
                  endIcon={<AddShoppingCartIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() => bulkAddItemInCart(results)}
                  disabled={bulkButtonEnableDisable}
                >
                  Add below items to shopping cart
                </Button>
              </div>
            </div>
            {hasCartError && <ShoppingCartError setValue={setHasCartError} remainingItemsCount={remainingItemsCount} setRemainingItemsCount={setRemainingItemsCount} />}
            <div className='aside-content__padding-top search_section_wrapper'>
              <div className="search_heading">{entityTypes[entityTypeToRender]?.label}</div>
              <Grid container>
                {results && results.map(item => (
                  <SearchResults item={item} key={`${item.resultType}_${item.id}`} />
                ))}
              </Grid>
            </div>
            {originalEntityType === entityTypeToRender && <Pagination handleChangeFor={handleChangeFor} params={params} totalCount={totalCount} />}
            {originalEntityType !== entityTypeToRender && <div data-tracking='See All' className="see_all_results" onClick={() => handleChangeFor('entityType')(entityTypeToRender)}><span>See All {entityTypes[entityTypeToRender]?.label} Results ({totalCount})</span></div>}
          </aside>
          : handleError()
      }
      {
        isLoading && <aside className={`search-results tab-content-wrapper loader-section-${parseInt(sectionIndex)}`}>
          <Loader />
        </aside>
      }
    </>
  )
}
