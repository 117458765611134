import React, { useContext } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import Home from './Home'
import ShoppingCart from './ShoppingCart'
import { UserContext } from './context/UserContext'
import ScrollToTop from './ScrollToTop'
import ContentDetails from './ContentDetails'
import ContentOrGroupDetails from './ContentOrGroupDetails'
import Dashboard from './Dashboard'
import NotFound from './NotFound'
import AssetInsights from './AssetInsights'
import NextGenHome from './NextGen/Home'
import NextGenSearch from './NextGen/SearchHOC'
import Group from './NextGen/Group'
import MyFavorites from './NextGen/MyFavorites'
import ManageGroup from './NextGen/Group/ManageGroup'
import Inbox from './NextGen/Inbox'
import ManageApplication from './NextGen/Application/ManageApplication'
import RevisionHistory from './NextGen/RevisionHistory'

// Define and export routes.
export default function Routes () {
  // Set user authenticate state from user context.
  const { isAuthenticated, performLogout } = useContext(UserContext)

  /**
   * Routes are
   * - /shopping-cart
   * - /home/:tab (tab will change for each tab click)
   * - /search (Any solr search)
   * - /group/:id/:tab (Rarely use now, if tab specified then directly that page open)
   * - /content/:type (Rarely use now, used when multiple ids passed in url)
   * - /my-dashboard (To view current user dashboard)
   * - /my-favorite (To view current user favorites)
   * - /inbox/:name (Use for user's inbox)
   * - /inbox/:name/:id (For specific submission)
   * - /inbox/:name/:id/:nid (For announcement details)
   * - /revision-history/:gid/application/:nid/:task (For all revisions of a application)
   * - /revision-history/:gid/application/:nid/:task/:vid (For all revision listing or specific revision history)
   * - /revision-history/:gid/application/:nid/:task/:vid1/:vid2 (For compare 2 revisions)
   * - /insight/:name (Use for various asset dashboard)
   * - /manage-group/:gid/:task (Use for manage group purpose)
   * - /manage-application/:id/:task/:gid (Use for manage application purpose)
   * - /logout (To logout the current user)
   * - / (Any other url will be route through this.
   *     Determine node or group details page based on backend url.)
   * Render if user already authenticated.
   */
  return (
    <>
      {isAuthenticated
        ? (<>
          <ScrollToTop />
          <Switch>
            <Route path='/index' element={<NextGenHome />} />
            <Route path='/shopping-cart' element={<ShoppingCart />} />
            <Route exact path='/' element={<NextGenHome />} />
            <Route path='/home' element={<Home />} />
            <Route path='/home/:tab' element={<Home />} />
            <Route path='/search' element={<NextGenSearch />} />
            <Route path='/group/:id' element={<Group />} />
            <Route path='/content/:type' element={<ContentDetails />} />
            <Route path='/my-dashboard' element={<Dashboard />} />
            <Route path='/my-favorites' element={<MyFavorites />} />
            <Route path='/inbox/:name' element={<Inbox />}>
              <Route path=':id' element={<Inbox />}>
                <Route path=':nid' element={<Inbox />} />
              </Route>
            </Route>
            <Route path='/revision-history/:gid/application/:nid/:task' element={<RevisionHistory />}>
              <Route path=':vid1/:vid2' element={<RevisionHistory />} />
              <Route path=':vid' element={<RevisionHistory />} />
            </Route>
            <Route path='/insights/:name' element={<AssetInsights />} />
            <Route path='/manage-group/:gid/:task' element={<ManageGroup />} />
            <Route path='/manage-application/:id/:task/:gid' element={<ManageApplication />} />
            <Route path='/logout' action={performLogout} />
            <Route path='/404' element={<NotFound />} />
            <Route path='/*' element={<ContentOrGroupDetails />} />
          </Switch>
        </>)
        : null
      }
    </>
  )
}
