import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../PageLoader'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../../../lib'
import { diffLines, formatLines } from 'unidiff'
import { parseDiff, Diff, Hunk, markEdits, tokenize } from 'react-diff-view'
import GenericErrorMessage from '../Assets/component/GenericErrorMessage'
import { generateFieldData, revisionLabels, getUsername, getFormattedDate } from './RevisionHelpers'
import RestoreLinkAndModal from './RestoreLinkAndModal'
import 'react-diff-view/style/index.css'
import NotFound from '../../NotFound'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

const CompareVersions = ({ setFeedbackMessage, setBreadCrumb, setIsHeaderLoading }) => {
  // Set allRevisions.
  const [allVersions, setAllVersions] = useState([])
  // Set isHeaderLoading.
  const [isLoading, setIsLoading] = useState(true)
  // Set firstRevision.
  const [firstRevision, setFirstRevision] = useState({})
  // Set secondRevision.
  const [secondRevision, setSecondRevision] = useState({})
  // Set currentRevision.
  const [currentRevision, setCurrentRevision] = useState({})
  // Set firstRevisionFieldData.
  const [firstRevisionFieldData, setFirstRevisionFieldData] = useState({})
  // Set secondRevisionFieldData.
  const [secondRevisionFieldData, setSecondRevisionFieldData] = useState({})
  // Set diffItems.
  const [diffItems, setDiffItems] = useState([])
  // Get navigate function hook.
  const navigate = useNavigate()
  // Get gid, nid, vid1, vid2 from path parameter.
  const { gid, nid, vid1, vid2 } = useParams()
  // Set isRespOrAccountable.
  const [isRespOrAccountable, setIsRespOrAccountable] = useState(false)
  // Set errorMessage.
  const [errorMessage, setErrorMessage] = useState()
  // Set route error.
  const [routeError, setRouteError] = useState(false)
  // Set the main page url.
  const mainPageUrl = `/revision-history/${gid}/application/${nid}`
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    if (!parseInt(vid1) || !parseInt(vid2) || vid1 === vid2) {
      setRouteError(true)
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    setRouteError(false)
    setErrorMessage()
    // Variables object.
    let comparisonQueryVariables = {
      id: parseInt(nid).toString(),
      gid: parseInt(gid).toString(),
      vid: [parseInt(vid1).toString(), parseInt(vid2).toString()]
    }
    // Build the payload for api.
    comparisonQueryVariables = JSON.stringify(comparisonQueryVariables)
    // Build the api end point for revision.
    const comparisonQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.revisionQuery}&variables=${comparisonQueryVariables}`
    // Query Call.
    queryContent(comparisonQuery)
      .then(response => {
        setAllVersions(response.data.allRevisions.entities.map(entity => parseInt(entity?.vid)).filter(rid => !!rid).sort((a, b) => (b - a)))
        // Setting the breadCrumbs.
        setBreadCrumb(response.data.nodeQuery.entities[0].entityBreadCrumb || [])
        if (!response.data.revisions.entities[0] || !response.data.revisions.entities[1]) {
          setErrorMessage(constants.errors.nullValue)
          return
        }
        // Setting first and second revisions.
        setFirstRevision(response.data.revisions.entities[0])
        setSecondRevision(response.data.revisions.entities[1])
        // Setting the current version.
        setCurrentRevision(response.data.nodeQuery.entities[0])
        // Generate field data using firstRevision.
        const firstFieldData = generateFieldData(response.data.revisions.entities[0], false)
        setFirstRevisionFieldData(firstFieldData)
        // Generate field data using second revision.
        const secondFieldData = generateFieldData(response.data.revisions.entities[1], false)
        setSecondRevisionFieldData(secondFieldData)

        // Generate diff data between first and second revision.
        const difference = []
        Object.keys(firstFieldData).forEach(key => {
          if (firstFieldData[key] !== secondFieldData[key]) {
            difference.push(key)
          }
        })
        setDiffItems(difference)

        // Getting groups where user is responsible or accountable.
        const eligibleGroups = response.data.groupUserGroupsByUidRA.results.map(result => result.gid)
        // Setting isResponsibleOrAccountable if owning group found in eligibleGroups.
        setIsRespOrAccountable(eligibleGroups.includes(response.data.nodeQuery.entities[0]?.fieldOwningGroup?.entity?.entityId || 0))
        // Sending page data for tracking.
        setPageData({
          type: 'revision-history-compare',
          breadCrumbs: [...response?.data?.nodeQuery?.entities[0]?.entityBreadCrumb, { text: 'Revision-History-Compare' }],
          page_id: nid
        })
      })
      .catch(() => setErrorMessage(constants.errors.server))
      .finally(() => {
        setIsLoading(false)
        setIsHeaderLoading(false)
      })
  }, [gid, nid, vid1, vid2])

  // Function to handle previous change click.
  const handlePreviousChange = () => {
    const secondVersion = parseInt(vid1) < parseInt(vid2) ? vid1 : vid2
    let firstVersion
    for (const version of allVersions) {
      if (version < parseInt(secondVersion)) {
        firstVersion = version
        break
      }
    }
    navigate(`/revision-history/${gid}/application/${nid}/compare/${firstVersion}/${secondVersion}`)
  }

  // Function to handle next change click.
  const handleNextChange = () => {
    const firstVersion = parseInt(vid1) > parseInt(vid2) ? vid1 : vid2
    let secondVersion
    for (const version of allVersions) {
      if (version === parseInt(firstVersion)) {
        break
      }
      secondVersion = version
    }
    navigate(`/revision-history/${gid}/application/${nid}/compare/${firstVersion}/${secondVersion}`)
  }

  // Function to navigate to listing page after restore modal submission.
  const navigateToMainPage = () => {
    navigate(`${mainPageUrl}/view/all`)
  }

  // Render token for difference.
  const renderToken = (token, defaultRender, i) => {
    if (token.type === 'space') {
      return (
        <span key={i} className="space">
          {token.children && token.children.map((tkn, j) => renderToken(tkn, defaultRender, j))}
        </span>
      )
    } else {
      return defaultRender(token, i)
    }
  }

  // Get tokenizer hunks.
  const tokenizer = hunks => {
    if (!hunks) {
      return undefined
    }
    const options = {
      highlight: false,
      enhancers: [markEdits(hunks, { type: 'block' })]
    }
    try {
      return tokenize(hunks, options)
    } catch (ex) {
      return undefined
    }
  }

  // Show loader when is loading is true.
  if (isLoading) {
    return <Loader />
  }

  // Show not found if have route error.
  if (routeError) {
    return <NotFound />
  }

  // Image of first version.
  const firstRevisionImage = firstRevision?.fieldContentImage?.entity?.thumbnail?.derivative?.url
  // Image of Second version.
  const secondRevisionImage = secondRevision?.fieldContentImage?.entity?.thumbnail?.derivative?.url

  // Return the output.
  return (
    <>
      {errorMessage
        ? <GenericErrorMessage errorMessage={errorMessage} />
        : <div className='revision_history_view_wrapper'>
          <div className='revision_subject_wrapper'>
            <div className='subject_title'>{currentRevision.title}</div>
            <div className='subject_subtitle'>
              Submitted by {getUsername(currentRevision)} on {getFormattedDate(currentRevision?.entityChanged, true)}
              <div className='revision_prev_next'>
                {secondRevision?.vid !== allVersions[allVersions.length - 1] && <span data-tracking='Previous Change' onClick={handlePreviousChange} className='prev'>
                  Previous Change
                </span>}
                {firstRevision?.vid !== allVersions[0] && <span data-tracking='Next Change' onClick={handleNextChange} className='next'>
                  Next Change
                </span>}
              </div>
            </div>
            <div className='revision_comparison'>
              <div className='title'>Comparing</div>
              <div className='comparing_column_heading'>
                <div className='comparing_rev'>
                  <Link to={`${mainPageUrl}/view/${secondRevision?.vid}`}>{getFormattedDate(secondRevision?.entityChanged)}</Link>
                  <span className="user_info">by {getUsername(secondRevision)}</span>
                  {isRespOrAccountable && <RestoreLinkAndModal nid={nid} setFeedbackMessage={setFeedbackMessage}
                    selectedVersion={secondRevision} currentVersion={currentRevision} postSuccessAction={navigateToMainPage} />}
                </div>
                <div className='comparing_rev'>
                  <Link to={`${mainPageUrl}/view/${firstRevision?.vid}`}>{getFormattedDate(firstRevision?.entityChanged)}</Link>
                  <span className="user_info">by {getUsername(firstRevision)}</span>
                  {firstRevision?.vid === currentRevision?.vid
                    ? <div><span className='current_ver restore_ver'>Current Version</span></div>
                    : isRespOrAccountable
                      ? <RestoreLinkAndModal nid={nid} setFeedbackMessage={setFeedbackMessage}
                        selectedVersion={firstRevision} currentVersion={currentRevision} postSuccessAction={navigateToMainPage} />
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='revision_changes_wrapper'>
            {diffItems.length > 0 && diffItems.map(item => {
              const diffText = formatLines(diffLines(secondRevisionFieldData[item], firstRevisionFieldData[item]), { context: 4 })
              const [diff] = parseDiff(diffText)
              const tokens = tokenizer(diff.hunks)
              return (
                <div key={item} className='revision_changes'>
                  <div className='change_title'>{revisionLabels[item]}</div>
                  <Diff viewType="split" diffType={diff.type} hunks={diff.hunks}
                    tokens={tokens}
                    renderToken={renderToken}>
                    {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
                  </Diff>
                </div>
              )
            })}
            {(firstRevisionImage !== secondRevisionImage) && <div className='revision_changes'>
              <div className='change_title'>Uploaded Image</div>
              <table className="diff diff-split">
                <colgroup><col className="diff-gutter-col" /><col /><col className="diff-gutter-col" /><col /></colgroup>
                <tbody className="diff-hunk">
                  <tr className="diff-line diff-line-compare">
                    <td className="diff-gutter diff-gutter-delete">1</td>
                    <td className="diff-code diff-code-delete">
                      {secondRevisionImage
                        ? <img style={{ height: 160 }} src={secondRevisionImage} alt='SecondRevision' />
                        : ''
                      }
                    </td>
                    <td className="diff-gutter diff-gutter-insert">1</td>
                    <td className="diff-code diff-code-insert">
                      {firstRevisionImage
                        ? <img style={{ height: 160 }} src={firstRevisionImage} alt='firstRevision' />
                        : ''
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>}
            {!diffItems.length && firstRevisionImage === secondRevisionImage && <div className='no_revision_changes'>There is no difference to display in the selected revisions. This may be due to two possible reasons. Firstly, both selected revisions may be identical. Alternatively, there may have been some system field changes that are not currently displaying.</div>}
          </div>
        </div>
      }
    </>
  )
}

// Export component for further use.
export default CompareVersions
