import React, { useContext, useState } from 'react'
import { ShoppingCart, CheckOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ShoppingCartContext } from '../../contexts'
import ShoppingCartError from './ShoppingCartError'

const EmbedShoppingCart = ({ item, image, bundle, type = 'node' }) => {
  // Get data from ShoppingCartContext.
  const { addItemToCart, removeItem, shoppingCartItems } = useContext(ShoppingCartContext)
  // Set the cart error state if has any.
  const [hasCartError, setHasCartError] = useState(false)
  if (item?.id) {
    item.nid = item.id
  }
  const toggleItemInCart = () => {
    if (shoppingCartItems && shoppingCartItems.has(`${type}_${parseInt(item.nid)}`)) {
      // Remove item from cart.
      removeItem(`${type}_${parseInt(item.nid)}`)
    } else {
      if (shoppingCartItems.size < 100) {
        let itemClicked
        if (type === 'group') {
          // Build group item for cart.
          itemClicked = {
            bundle,
            cardImage: image,
            id: parseInt(item.id),
            rating: null,
            resultType: type,
            title: item?.name,
            url: item.url
          }
        } else {
          // Build node item for cart.
          itemClicked = {
            bundle,
            cardImage: image,
            id: parseInt(item.nid),
            rating: item?.starRating ? item.starRating : null,
            resultType: type,
            title: item?.title ? item.title : item.entityLabel,
            url: item.url.path
          }
        }
        // Add item to cart.
        addItemToCart(itemClicked)
      } else {
        // Set cart error.
        setHasCartError(true)
      }
    }
  }
  // Return EmbedShoppingCart data.
  return (
    <>
      <IconButton aria-label='add to cart' component='span' onClick={() => toggleItemInCart()} className="cart-icon">
        {shoppingCartItems && shoppingCartItems.has(`${type}_${parseInt(item.nid)}`) ? <CheckOutlined /> : <ShoppingCart />}
      </IconButton>
      {hasCartError ? <ShoppingCartError setValue={setHasCartError} /> : null}
    </>
  )
}
// Export the component.
export default EmbedShoppingCart
