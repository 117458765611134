import React from 'react'
import PropTypes from 'prop-types'
import ProgressiveImage from 'react-progressive-graceful-image'

// Build the profile image.
const ProfileImage = ({ userPicture, profileImage, alt }) => {
  const formatImage = key => {
    const placeholder = (
      <div>{alt}</div>
    )
    return (
      <ProgressiveImage src={key} placeholder="">
        {(src, loading) => (
          loading ? placeholder : <img src={src} alt={alt} />
        )}
      </ProgressiveImage>
    )
  }
  if (userPicture) {
    // 1st priority is uploaded image in application.
    return formatImage(userPicture)
  } else if (profileImage) {
    // Then the images available in jnj account.
    return formatImage(profileImage)
  } else {
    // Then only return initial.
    return <div>{alt}</div>
  }
}
// Default property of profileImage function.
ProfileImage.defaultProps = {
  userPicture: '',
  profileImage: '',
  alt: ''
}
// Prototype to follow.
ProfileImage.propTypes = {
  userPicture: PropTypes.string,
  profileImage: PropTypes.string,
  alt: PropTypes.string
}
// Export the component to use.
export default ProfileImage
