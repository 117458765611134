import React, { useState, useEffect, useContext } from 'react'
import Search from '../../Search'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useNextGenSearch } from '../../../hooks'
import './search-results.scss'
import SearchInput from '../Assets/component/SearchInput'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

let entityObj = {}
// Generate and export the loader component for use.
export default function SearchHOC () {
  const location = useLocation()
  const url = location.search
  const queryStringObj = queryString.parse(url.replace('?', ''))
  // Get the search keyword.
  const searchKeyword = queryStringObj?.keyword || ''
  // Set all sections for each entities.
  const entities = {
    all: ['article', 'group'],
    article: ['article'],
    group: ['group']
  }
  // Set current entity type.
  let currentEntityType = queryStringObj?.entityType || 'all'
  if (!entities[currentEntityType]) {
    currentEntityType = 'all'
  }
  // Set all result count.
  const [allResultsCounts, setAllResultsCounts] = useState(0)
  // Set entity result count.
  const [entityCounts, setEntityCounts] = useState({})
  // Set entity result count.
  const [allCountString, setAllCountString] = useState('')
  // Use useNextGenSearch hook.
  const [searchValue, setSearchValue, urlForRedirect, loading, searchOptions, searchOptionCount] = useNextGenSearch('search_text_box')
  // Set page data for tracking.
  const { setPageData, setSearchData } = useContext(EventTrackingContext)
  // Set groupSearchLoaded.
  const [groupSearchLoaded, setGroupSearchLoaded] = useState(currentEntityType !== 'group' && currentEntityType !== 'all')
  // Set appsSearchLoaded.
  const [appsSearchLoaded, setAppsSearchLoaded] = useState(currentEntityType !== 'article' && currentEntityType !== 'all')
  // Function to add-up all counts.
  const addCount = (entityTypeName, count) => {
    entityObj[entityTypeName] = count
    setEntityCounts(entityObj)
    setAllCountString(JSON.stringify(entityObj))
  }
  // Set search value.
  useEffect(() => {
    if (searchKeyword) {
      setSearchValue(searchKeyword)
    } else {
      setSearchValue('')
    }
  }, [searchKeyword])
  // Set count related things 0 on entity type change.
  useEffect(() => {
    setAllResultsCounts(0)
    setEntityCounts({})
    entityObj = {}
    // Sending page data for tracking.
    setPageData({
      type: 'search'
    })
    setGroupSearchLoaded(currentEntityType !== 'group' && currentEntityType !== 'all')
    setAppsSearchLoaded(currentEntityType !== 'article' && currentEntityType !== 'all')
  }, [url])

  useEffect(() => {
    const { keyword, offset, ...filters } = queryStringObj
    if (groupSearchLoaded && appsSearchLoaded) {
      setSearchData({
        searchTerm: keyword,
        entityType: currentEntityType,
        filters
      })
    }
  }, [groupSearchLoaded, appsSearchLoaded])
  // Set count on entity count change.
  useEffect(() => {
    let count = 0
    Object.keys(entityCounts).forEach(item => {
      count += entityCounts[item]
    })
    if (count) {
      setAllResultsCounts(count)
    }
  }, [allCountString, entityCounts])

  // Return the output.
  // Output include search box and results depending on entity type.
  return (
    <div className="min_content_height search_gray_bg_wrapper">
      <div className="search_gray_bg">
        <div className="search-asides__flex-row">
          <div className="search_box_section_wrapper">
            <div className="intelligence_search">
              <SearchInput id="search_text_box" searchValue={searchValue} setSearchValue={setSearchValue}
                searchOptions={searchOptions} urlForRedirect={urlForRedirect} loading={loading}
                placeholder="Search" className="input-box" showButton={true} count={searchOptionCount} />
            </div>
          </div>
          {currentEntityType && entities[currentEntityType] && entities[currentEntityType].map((entity, index) => (
            <Search key={entity} sectionIndex={index} currentEntityType={currentEntityType} renderEntityType={entity} setResultsCounts={addCount} resultCounts={allResultsCounts}
              onDataLoad={entity === 'article' ? setAppsSearchLoaded : setGroupSearchLoaded}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
