import React, { useState, useEffect, useContext } from 'react'
import { constants } from '../../lib'
import { UserContext } from '../context/UserContext'
import Loader from '../PageLoader'
import MyItemCard from './MyItemCard'
import { cardImageFor, cardRedirectFor } from './CardFunc'
import Pagination from '../Pagination'
// MyItems component.
const MyItems = ({ activityType }) => {
  // Fetch userId from UserContext.
  const { userId } = useContext(UserContext)
  // Set itemData.
  const [itemData, setItemData] = useState({})
  // Set hasError flag.
  const [hasError, setHasError] = useState(false)
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(true)
  // Set isLoadingDetails flag.
  const [isLoadingDetails, setIsLoadingDetails] = useState(true)
  // Set requireAssetProcess.
  const [requireAssetProcess, setRequireAssetProcess] = useState([])
  // Set pageParam.
  const [pageParam, setPageParam] = useState({ limit: constants.misc.defaultHomeRecordCount, offset: 0 })
  // Set validData.
  const [validData, setValidData] = useState({})
  // Function for change operation.
  const handleChangeFor = category => value => {
    const currentParams = pageParam
    if (category === 'limit') {
      currentParams.limit = value
      currentParams.offset = 0
    }
    if (category === 'offset') {
      currentParams.offset = value
    }
    setPageParam(currentParams)
  }

  // Fetch any initiated by me items that belong to the group.
  useEffect(() => {
    if (userId != null) {
      fetch('/api/assetProcesses/user-activity', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: userId,
          activityType
        })
      })
        .then(res => {
          if (res.status < 400) {
            return res.json()
          } else {
            throw new Error()
          }
        })
        .then(res => {
          setItemData(res)
          if (res.length === 0) {
            setIsLoadingDetails(false)
          }
        })
        .catch(() => setHasError(true))
        .finally(() => setIsLoading(false))
    }
  }, [userId, activityType])

  // Fetch the contents of the inbox cards.
  useEffect(() => {
    if (itemData && typeof (itemData.data) !== 'undefined' && itemData.data.length > 0) {
      const nodeAssetIds = itemData.data.filter(item => item.Asset.type === 'node')
        .reduce((acc, item) => [...acc, item?.Asset?.drupalId], [])
      const groupAssetIds = itemData.data.filter(item => item.Asset.type === 'group')
        .reduce((acc, item) => [...acc, item?.Asset?.drupalId], [])
      const processIds = itemData.data.reduce((acc, item) => [...acc, item?.Process?.drupalId], [])
      const allIds = nodeAssetIds.concat(processIds)
      const ids = [...new Set(allIds)]
      // Prepare payload data.
      const variables = JSON.stringify({
        ids: ids.length ? ids : ['0'],
        gids: groupAssetIds.length ? groupAssetIds : ['0']
      })
      // Call graphql for cartQuery.
      const cartQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.shoppingCartQuery}&variables=${variables}`
      // Query Call
      fetch(cartQuery, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(response => {
          const nodeQueryData = response?.data?.nodeQuery?.assets
          const groupQueryData = response?.data?.groupQuery?.assets
          const assetProcessMasterData = nodeQueryData.concat(groupQueryData)
          setRequireAssetProcess(assetProcessMasterData)
          const validAssetIds = assetProcessMasterData.reduce((acc, item) => [...acc, item?.assetId], [])
          const filteredData = itemData?.data.filter(item => validAssetIds.includes(item?.Asset?.drupalId) && validAssetIds.includes(item?.Process?.drupalId))
          setValidData({ data: filteredData, totalCount: filteredData.length })
        })
        .catch(() => setHasError(true))
        .finally(() => setIsLoadingDetails(false))
    } else {
      if (typeof (itemData.data) !== 'undefined') {
        setValidData({ data: [], totalCount: 0 })
        setIsLoadingDetails(false)
      }
    }
  }, [itemData])

  if (hasError) {
    // Display error message.
    return <div className='details-wrapper'>
      <div align="center" style={{ width: '100%' }}>
        <strong>An error occurred while trying to load the items. Please try again later.</strong>
      </div>
    </div>
  }
  // Return the loader or MyItems data.
  if (isLoading || isLoadingDetails) {
    return <Loader />
  } else {
    return (
      validData && validData?.data.length > 0
        ? (<div>
          {
            validData.data.slice(pageParam.offset, (pageParam.offset + pageParam.limit)).map((item, index) => {
              const assetData = requireAssetProcess.find(asset => (asset?.assetId === item?.Asset.drupalId) && (asset?.assetType === item?.Asset.type))
              const processData = requireAssetProcess.find(process => process?.assetId === item?.Process.drupalId)
              const currentContentType = assetData.contentType?.entity?.entityLabel ? assetData.contentType?.entity?.entityLabel : assetData.contentType
              return assetData && processData && (
                <MyItemCard
                  CardData={{
                    assetData,
                    processData,
                    id: assetData.assetId,
                    cardImage: cardImageFor(currentContentType),
                    cardRedirect: cardRedirectFor(currentContentType, assetData.assetId, assetData.assetUrl.path),
                    title: assetData.assetName,
                    rating: assetData.fieldStarRating,
                    createdAt: item.createdAt,
                    processTiedToLabel: processData.assetName,
                    processTiedToUrl: processData.assetUrl.path,
                    groupTiedToLabel: processData?.processOwner?.entity?.entityLabel,
                    groupTiedToUrl: processData?.processOwner?.entity?.url.path
                  }}
                  key={`${pageParam.offset}_${index}`}
                />
              )
            }
            )}
          <Pagination handleChangeFor={handleChangeFor} params={pageParam} totalCount={validData.totalCount} />
        </div>)
        : <div align="center" style={{ display: 'block' }}><strong>There is no item to display.</strong></div>
    )
  }
}
// Export MyItems component.
export default MyItems
