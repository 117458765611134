// Function to sort the data items alphabetically based on title.
export const sortAlphabetically = (data, order, contentType) => {
  // Here we need to know content Type as title exposed in different name.
  const result = data
  result.sort((a, b) => {
    const firstItemName = contentType === 'applications' ? a.entityLabel : a.title
    const secondItemName = contentType === 'applications' ? b.entityLabel : b.title
    // ASC : A to Z.
    // Else or DESC : Z to A.
    if (order === 'asc') {
      if (firstItemName.toLowerCase() > secondItemName.toLowerCase()) {
        return 1
      } else {
        return -1
      }
    } else {
      if (firstItemName.toLowerCase() < secondItemName.toLowerCase()) {
        return 1
      } else {
        return -1
      }
    }
  })
  return result
}

// Function to sort the data items based on the createdAt field.
export const sortByDateAdded = (data, order, page) => {
  const result = data
  result.sort((a, b) => {
    // ASC : Older one come first.
    // Else or DESC : Newer one come first.
    const firstItemDate = page === 'favorite' ? a.createdAt : a.entityCreated
    const secondItemDate = page === 'favorite' ? b.createdAt : b.entityCreated
    if (order === 'asc') {
      if (firstItemDate > secondItemDate) {
        return -1
      } else {
        return 1
      }
    } else {
      if (firstItemDate < secondItemDate) {
        return -1
      } else {
        return 1
      }
    }
  })
  return result
}
