import { Grid } from '@mui/material'
import { constants } from '../../../../lib'
import { Link } from 'react-router-dom'
const MyRecordDetails = ({ requestedBy, requestedOn, groupData, isReqPage }) => {
  // Get the necessary data.
  const { groupName, parentGroup, inceptionDate, expiryDate, vision, parentGroupURL, status } = groupData
  // Return the output.
  // - New Group Name
  // - Parent Group
  // - Requested By
  // - Requested On
  // - Inception Date
  // - Expiration Date
  // - Vision
  // - Status
  return (
    <>
      <Grid item xs={6} sm={4} md={3}>
        <div className='details-text-label'>New Group Name</div>
        <div className='details-text'>{groupName || constants.filter.none}</div>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <div className='details-text-label'>Parent Group</div>
        <div className='details-text'>
          <Link to={parentGroupURL} target='_blank'>{parentGroup || constants.filter.none}</Link>
        </div>
      </Grid>
      {!isReqPage &&
        <Grid item xs={6} sm={4} md={3}>
          <div className='details-text-label'>Requested By</div>
          <div className='details-text'>
            {requestedBy || constants.filter.none}
          </div>
        </Grid>}
      <Grid item xs={6} sm={4} md={3}>
        <div className='details-text-label'>Requested On</div>
        <div className='details-text'>
          {requestedOn || constants.filter.none}
        </div>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <div className='details-text-label'>Status</div>
        <div className='details-text'>
          {status || constants.filter.none}
        </div>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <div className='details-text-label'>Inception Date</div>
        <div className='details-text'>
          {inceptionDate || constants.filter.none}
        </div>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <div className='details-text-label'>Expiration Date</div>
        <div className='details-text'>
          {expiryDate || constants.filter.none}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <div className='details-text-label'>Vision</div>
        <div className='details-text'>{vision ? <span dangerouslySetInnerHTML={{ __html: vision }} /> : constants.filter.none}</div>
      </Grid>
    </>
  )
}

// Export the component for use.
export default MyRecordDetails
