import React, { useContext, useEffect, useState } from 'react'
import './team-list.scss'
import { useMemberList } from '../../../../../hooks'
import GroupMemberAddModal from '../../GroupMemberAddModal'
import Member from './Member'
import EditMember from './EditMember'
import RemoveMember from './RemoveMember'
import { EventTrackingContext } from '../../../../../contexts/EventTrackingContext'

const BusinessTeam = ({ gid, groupMembers, setFeedbackMessage, setShouldReload, groupName, crumbs }) => {
  // Get member details from member list hook.
  const [businessTeam, membersProfileImageData] = useMemberList(groupMembers.results)
  // Set open flag for Add member modal.
  const [isOpen, setIsOpen] = useState(false)
  // Set edit member modal.
  const [isEditMember, setIsEditMember] = useState(false)
  // Set remove member modal.
  const [isRemoveMember, setIsRemoveMember] = useState(false)
  // Set open flag for Add member modal.
  const [isActionOpen, setIsActionOpen] = useState(false)
  // Set open flag for Add member modal.
  const [memberData, setMemberData] = useState(false)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  // Handle to close and open add member modal.
  const handleAddClose = addMember => {
    setIsOpen(false)
    if (addMember === 'addMember') {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    // Sending page data for tracking.
    setPageData({
      type: 'manage-business-team',
      breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: 'Business Team' }],
      page_id: gid
    })
  }, [])
  // Handle to open Edit /delete member modal.
  const handleAction = (option, user) => {
    setIsActionOpen(false)
    if (option === 'Edit') {
      setMemberData(user)
      setIsRemoveMember(false)
      setIsEditMember(true)
      setIsActionOpen(true)
    } else if (option === 'Remove') {
      setMemberData(user)
      setIsEditMember(false)
      setIsRemoveMember(true)
      setIsActionOpen(true)
    } else {
      setMemberData(false)
      setIsEditMember(false)
      setIsRemoveMember(false)
      setIsActionOpen(false)
    }
  }

  // Return the component for use.
  return (
    <div className='business-team'>
      <div className="content_team_wrapper">
        <div className="team_wrapper-left">
          <div className="content_heading">Business Team</div>
          <div className="content_subheading">Manage users responsible for executing the vision.</div>
        </div>
        <div className="team_wrapper_right join" onClick={() => handleAddClose('addMember')}>
          + Add Member
        </div>
      </div>
      <div className="tabs_wrapper">
        <ul className="tabs_section tabs_header">
          <li className="tabs_item">Name</li>
          <li className="tabs_item">Role</li>
          <li className="tabs_item">Description</li>
        </ul>
        {businessTeam.count === 0 && <div className="content_subheading">No member present</div>}
        {businessTeam.count !== 0 && businessTeam?.list?.map(person => {
          const appUserPicture = person?.userPicture ? process.env.REACT_APP_TARGET_URL + '/' + person.userPicture : null
          return (
            person && (
              <div key={person.userId} >
                <Member gid={gid} person={person} appUserPicture={appUserPicture} membersProfileImageData={membersProfileImageData} handleAction={handleAction} />
              </div>
            ))
        })}
      </div>
      {isOpen && <GroupMemberAddModal visible={isOpen} onCancel={handleAddClose} gid={gid} setGroupShouldReload={setShouldReload} setFeedbackMessage={setFeedbackMessage} />}
      {isEditMember && memberData && <EditMember gid={gid} visible={isActionOpen} person={memberData} onCancel={handleAction} setFeedbackMessage={setFeedbackMessage} setShouldReload={setShouldReload} />}
      {isRemoveMember && memberData && <RemoveMember gid={gid} visible={isActionOpen} person={memberData} onCancel={handleAction} setFeedbackMessage={setFeedbackMessage} setShouldReload={setShouldReload} groupName={groupName} />}
    </div>
  )
}
// Export the component for use.
export default BusinessTeam
