import React, { createContext, useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { queryContent } from '../components/ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../lib'

// Export NotificationContext component.
export const NotificationContext = createContext()
// Export NotificationProvider component.
export const NotificationProvider = ({ children }) => {
  // Set initial limit.
  const limit = constants.misc.defaultInboxRecordCount
  // Set location.
  const location = useLocation()
  // set notifications from fetch data.
  const [notifications, setNotifications] = useState([])
  // set data count from fetch data.
  const [notificationsCount, setNotificationsCount] = useState()
  // set offSet.
  const [offSet, setOffSet] = useState(0)
  // Set infinite scrolling stop.
  const [hasMoreNotifications, setHasMoreNotifications] = useState(true)
  // Set loading state.
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(true)
  // Set initial loading state.
  const initialPath = useRef()
  // Update the infinite scroll value.
  const fetchNotificationsData = useCallback(() => {
    // Set offset initial value.
    if (notifications.length >= notificationsCount) {
      // Set infinite scrolling stop.
      setHasMoreNotifications(false)
    } else {
      // Set offset value updating.
      setOffSet(prevOffSet => prevOffSet + limit)
    }
  }, [notifications, notificationsCount, limit])

  useEffect(() => {
    if (hasMoreNotifications) {
      // Generate the approval api endpoint.
      const notificationQuery = `${process.env.REACT_APP_TARGET_URL}/api/group-notifications?offset=${offSet}&limit=${limit}`
      // Call the query.
      queryContent(notificationQuery)
        .then(res => {
          setNotificationsCount(parseInt(res?.response?.count))
          // Assigning fetching data.
          const documents = res.response.data
          // Mapping the fetching data.
          const notificationsData = documents.map(document => {
            return { sid: document.sid, owner: document.owner, changedAt: document.changedAt, groupData: document.groupData }
          })
          // Assigning fetched data.
          if (offSet > 0) {
            setNotifications(prevNotifications => ([...prevNotifications, ...notificationsData]))
          } else {
            setNotifications(notificationsData)
          }
        })
        .catch(err => console.log(err))
        .finally(() => setIsNotificationsLoading(false))
    }
  }, [offSet, hasMoreNotifications, limit])

  // Set calling while path name changed.
  useEffect(() => {
    if (initialPath.current) {
      setHasMoreNotifications(false)
      setOffSet(0)
      setTimeout(() => {
        setHasMoreNotifications(true)
      }, 500)
    }
    initialPath.current = location.pathname
  }, [location.pathname])

  const contextValue = useMemo(() => ({ notifications, notificationsCount, hasMoreNotifications, isNotificationsLoading, fetchNotificationsData }), [notifications, notificationsCount, hasMoreNotifications, isNotificationsLoading, fetchNotificationsData])

  // Return NotificationContext.
  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}
