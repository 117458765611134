import React, { useState, useCallback } from 'react'
import { Button } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const ContentManageButton = ({ nid }) => {
  // Manage Button states
  const [showManageOptions, setShowManageOptions] = useState(false)
  const [anchor, setAnchor] = useState(null)

  // Manage button function
  const ShowManageButtonOptions = useCallback(anchorEl => {
    // The base url to redirect.
    const viewUrl = `${process.env.REACT_APP_TARGET_URL}/node/${nid}`
    // Set quickLinkObj data.
    const manageOptionList = [
      { label: 'View', link: `${viewUrl}` },
      { label: 'Edit', link: `${viewUrl}/edit` }
    ]

    // The close handler.
    const handleClose = () => {
      setAnchor(null)
      setShowManageOptions(false)
    }

    // The menu navigation.
    const handleNavigate = url => {
      window.open(
        url,
        '_blank'
      )
      handleClose()
    }

    // Return the output.
    return (
      <Menu id="long-menu" anchorEl={anchorEl} open={true} onClose={handleClose}
        getContentAnchorEl={setAnchor}
        className="manage-menu"
      >
        {manageOptionList.map(option => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleNavigate(option.link)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    )
  }, [nid])

  // Return the main output of component.
  return <>
    <Button
      endIcon={<MoreVertIcon />}
      onClick={e => {
        setShowManageOptions(true)
        setAnchor(e.currentTarget)
      }}
      className='manage-button'
    >
      Manage
    </Button>
    {showManageOptions && ShowManageButtonOptions(anchor)}
  </>
}
// Export ContentManageButton component.
export default ContentManageButton
