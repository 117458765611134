import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import LoopIcon from '@mui/icons-material/Loop'
import ErrorIcon from '@mui/icons-material/Error'

// The group join button component.
const JoinGroupButton = ({ gid }) => {
  // Initial state data for button.
  const initialData = {
    label: 'Request to Join Group', className: 'join-button', startIcon: null, color: 'primary', disabled: false
  }
  // Set the button data.
  const [buttonData, setButtonData] = useState(initialData)

  // the effect
  useEffect(() => {
    setButtonData(buttonData)
  }, [buttonData])

  // Handle the group join request.
  const joinGroup = () => {
    let changeButton = { label: 'Requesting...', className: 'requesting', startIcon: <LoopIcon />, color: 'default', disabled: true }
    // Indicate the processing request.
    setButtonData(changeButton)
    // Generate the query url.
    const url = `${process.env.REACT_APP_TARGET_URL}/api/join-group`
    // To track if logged out.
    let haveResponse = false
    // Query call.
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ gid })
    })
      .then(result => {
        haveResponse = true
        if (result.ok) {
          return result.json()
        } else {
          throw new Error(`${result.status}`)
        }
      })
      .then(result => {
        if (!result.error) {
          // Once request sent successfully.
          changeButton = { label: result.response.msg, className: 'request-sent', startIcon: <DoneIcon />, color: 'default', disabled: true }
          setButtonData(changeButton)
        } else {
          throw new Error('Unexpected Error')
        }
      })
      .catch(e => {
        if (!haveResponse) {
          window.location.reload()
        }
        changeButton.label = 'Request Failed'
        changeButton.className = 'request-failed'
        changeButton.startIcon = <ErrorIcon />
        setButtonData(changeButton)
        console.error(e.message)
        setTimeout(function () {
          // Re initiate the button data.
          setButtonData(initialData)
        }, 5000)
      })
  }

  // Return the button html.
  return (
    <Button variant="outlined" data-tracking='Request To Join Group' color={buttonData.color} className={buttonData.className}
      startIcon={buttonData.startIcon} onClick={joinGroup} disabled={buttonData.disabled}>
      {buttonData.label}
    </Button >
  )
}

// Export the component for use.
export default JoinGroupButton
