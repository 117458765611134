import React, { useState, useContext } from 'react'
// Import necessary components.
import personMultiIcon from '../../../images/icon-people.svg'
import userStatistics from '../../../images/icon-application.svg'
import FavoriteMenuOptions from './FavoriteMenuOptions'
import { UserContext } from '../../context/UserContext'
import { deleteEntities } from './helpers'
import { constants } from '../../../lib'
import { Link } from 'react-router-dom'
import FavoriteEntityHeader from './FavoriteEntityHeader'

// Build the component.
const FavoriteGroups = ({ allUserRoles, groupData, setGroupData, updateFavorite, setFeedbackMessage }) => {
  // Set cardData.
  const [cardData, setCardData] = useState()
  // Get the userId, userRoles from UserContext.
  const { userId, userRoles } = useContext(UserContext)
  // Set sortCriteria from local storage.
  const [sortCriteria, setSortCriteria] = useState(localStorage.getItem('groupSortCriteria') || 1)
  // Set display limit from constants.
  const [limit, setLimit] = useState(constants.misc.favoritesLimit.groupDisplayLimit)
  // Set group opened or not.
  const [groupOpened, setGroupOpened] = useState([])

  // Function to remove the group favorites.
  const handleRemove = async assetId => {
    const groupItem = cardData.filter(group => group.gid === assetId)[0]
    // First removing from localState, then firing api.
    setCardData(() => cardData.filter(group => group.gid !== assetId))
    setGroupData(() => groupData.filter(group => group.gid !== assetId))
    // Checking api status.
    const apiStatus = await deleteEntities(userId, null, null, 'group', parseInt(assetId))
    // If api failed, then put the group back into localState.
    if (!apiStatus) {
      // Set data.
      setFeedbackMessage({
        message: 'Error Occurred while removing.',
        type: 'error'
      })
      setCardData(() => [...cardData, groupItem])
      setGroupData(() => [...groupData, groupItem])
    } else {
      setFeedbackMessage({
        message: `Removed ${groupItem.title} from `,
        type: 'success'
      })
    }
  }

  // Function to generate the string of roles.
  const findRoles = gid => {
    if (!allUserRoles) {
      return ''
    }
    // Business Team Roles.
    let roles = []
    let allRoles = ''
    for (const group of allUserRoles) {
      if (group.gid === gid) {
        roles = group.groupRoles.split(',')
        break
      }
    }

    // Content team roles only if business team exists.
    if (roles.length > 0 && (roles.includes('Accountable') || roles.includes('Responsible'))) {
      // Loop through all roles.
      Object.keys(userRoles).forEach(role => {
        if (userRoles[role]) {
          switch (role) {
            case 'contentAuthor':
              roles.push('Content Author')
              break
            case 'feedEngineer':
              roles.push('Feed Engineer')
              break
            case 'groupAdmin':
              roles.push('Group Admin')
              break
            case 'Webform Designer':
              roles.push('Webform Designer')
              break
          }
        }
      })
    }
    // Accumulate all roles.
    if (roles.length > 0) {
      allRoles = roles.join(' • ')
    }
    return allRoles
  }

  // Handles change in the sortCriteria.
  const handleSortCriteria = value => {
    localStorage.setItem('groupSortCriteria', value)
    setSortCriteria(value)
  }

  // Handle click when navigate to details page
  const pageNavigationClick = id => {
    updateFavorite(id, 'group', 1)
    setGroupOpened(current => [...current, id])
  }

  // Return the output.
  return (
    <div className="fav_page_wrapper">
      <FavoriteEntityHeader title="Favorite Groups" data={groupData} sortCriteria={sortCriteria}
        handleSortCriteria={handleSortCriteria} setCardData={setCardData} />
      <div className="group_page_wrapper">
        <div className="group_wrapper_section">
          <div className="page-group-sec">
            <div className="grp_inner_wpr">
              <div className="row">
                {(!cardData?.length && !groupData?.length)
                  ? <div className="no_item_group">
                    <p className="no_fav">You do not have any favorite groups</p>
                    <Link to="/search?entityType=group&keyword=&offset=0" target="_blank" className="search_grp">Search Groups</Link>
                  </div>
                  : null}
                {
                  (groupData?.length && !cardData?.length) ? <div className="no_item_group">No Group match with that search keyword.</div> : null
                }
                {cardData && cardData.map((group, index) => (index < limit)
                  ? (<div className="col-3" key={index}>
                    <div className="group_innersection">
                      <div className={(group?.opened === 0 && !groupOpened.includes(group.gid)) ? 'group_innerheading with_just_added' : 'group_innerheading'}>
                        <Link to={group.url.path} onClick={() => pageNavigationClick(group.gid)}>{group.title}</Link>
                        {group?.opened === 0 && !groupOpened.includes(group.gid) && <div className="just-added">Just added</div>}
                      </div>
                      <div className="group_subheading">{findRoles(group.gid)}</div>
                      <div className="data_count-wrapper">
                        <div className="data_count_innerleft">
                          <div className="data_count"><img src={personMultiIcon} alt={constants.misc.uniqueUserAssociatedAlt} title={constants.misc.uniqueUserAssociatedAlt} />{group.userAssociated}</div>
                          <div className="data_statics"><img src={userStatistics} alt={constants.misc.uniqueAppAssociatedAlt} title={constants.misc.uniqueAppAssociatedAlt} />{group.appAssociated || 0}</div>
                        </div>
                        <FavoriteMenuOptions url={group.url.path} assetId={group.gid}
                          assetName={group.title} assetType="group" pageNavigationClick={pageNavigationClick} handleRemove={handleRemove} />
                      </div>
                    </div>
                  </div>)
                  : null)
                }
              </div>
              {(cardData && (cardData.length > limit)) && <div className="See_more" onClick={() => setLimit(constants.misc.favoritesLimit.group)}>See More Favorite Groups ({cardData.length - limit})</div>}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

// Export the component for use.
export default FavoriteGroups
