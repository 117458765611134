import { useState } from 'react'

// Build and export use search custom hook.
export const useSearch = initialValues => {
  // Set the filter state.
  const [filter, setFilter] = useState(initialValues)

  return [
    filter,
    setFilter
  ]
}
