import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { constants } from '../../lib'
import TablePagination from '@mui/material/TablePagination'
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import './index.scss'

function TablePaginationActions (props) {
  const { count, page, rowsPerPage, onPageChange } = props

  // Handle first page button click.
  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }

  // Handle previous button click.
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  // Handle next button click.
  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  // Handle last page button click.
  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  // Return the output.
  return (
    <div className="pager-arrow-wrapper">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

// Prototype to follow for this component.
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

// Export the component for use.
export default function Pagination ({ handleChangeFor, params, totalCount }) {
  // Initialize the page number.
  let pageNo = 0
  if (params?.offset) {
    // Set the page number from url or default.
    pageNo = parseInt(Math.floor(params?.offset / (params?.limit || parseInt(constants.misc.defaultRecordCount))))
  }

  // Set the current page.
  const [, setPage] = useState(pageNo)
  // Set row per page.
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(params?.limit) || parseInt(constants.misc.defaultRecordCount))

  // Handle page change request.
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    const offset = parseInt(newPage) * parseInt(rowsPerPage)
    handleChangeFor('offset')(offset)
  }

  // Handle record per page request.
  const handleChangeRowsPerPage = event => {
    const limit = parseInt(event.target.value, 10)
    setRowsPerPage(limit)
    handleChangeFor('limit')(limit)
    setPage(0)
  }

  // Return the output.
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[10, 20, 30, 40, 50]}
      count={totalCount}
      page={pageNo}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage="Records per page:"
    />
  )
}
