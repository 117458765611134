import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LaunchIcon from '@mui/icons-material/Launch'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { Stack, List, ListItem, ListItemText, Alert, Button, Grid, Menu, MenuItem } from '@mui/material'
import Common from './Common'
import NotFound from '../../NotFound'
import Announcements from './Announcements'
import { constants } from '../../../lib'
import './inbox.scss'
import { UserContext } from '../../context/UserContext'
import { withStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
import SortIcon from '@mui/icons-material/Sort'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import queryString from 'query-string'
import InboxError from './InboxError'

// Add CSS for dropdown menu.
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))

// Add CSS for each menu item.
const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '0',
    '& div.MuiListItemText-root': {
      margin: '0px'
    },
    '& span.MuiTypography-root': {
      fontFamily: 'Commissioner-Regular',
      fontSize: '15px',
      color: '#333',
      padding: '10px 50px 10px 25px',
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: 'inherit'
        }
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#1C75BC'
      }
    }
  }
}))(MenuItem)

const Inbox = () => {
  // To set the anchor element for dropdown menu.
  const [anchorEl, setAnchorEl] = useState(null)
  // Set params.
  const params = useParams()
  // Get nid from params.
  const nid = params?.nid
  // Get id from params.
  const id = params?.id
  // Set initial page.
  const [rightNavigation, setRightNavigation] = useState(params?.name)
  // Set feedback message.
  const [feedbackMessage, setFeedbackMessage] = useState()
  // Set requestNotifications.
  const [requestNotifications, setRequestNotifications] = useState(0)
  // Set approvalNotifications.
  const [approvalNotifications, setApprovalNotifications] = useState(0)
  // Set shouldNotificationReload.
  const [shouldNotificationReload, setShouldNotificationReload] = useState(false)
  // Get location object from useLocation hook.
  const location = useLocation()
  // Set url param.
  const url = location.search
  // Set query param.
  const parameters = queryString.parse(url.replace('?', ''))
  // Set pageSize.
  const [pageSize, setPageSize] = useState(parameters?.pageSize || 50)
  // Get userId.
  const { userId } = useContext(UserContext)
  // Set groupUserGroupsByUidRA.
  const [groupUserGroupsByUidRA, setGroupUserGroupsByUidRA] = useState([])
  // Set searchValue.
  const [searchValue, setSearchValue] = useState(parameters?.search || '')
  // Get navigate.
  const navigate = useNavigate()
  // Set isLoading.
  const [isLoading, setIsLoading] = useState(true)
  // Set error message.
  const [errorMessage, setErrorMessage] = useState('')

  // Handle to open dropdown.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  // Handle to close dropdown.
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Function to handle change of any field.
  const handleChange = (field, value) => {
    handleClose()
    const currentParameters = { ...parameters, [field]: value }
    if (field !== 'page') {
      currentParameters.page = 0
    }
    Object.keys(currentParameters).forEach(key => {
      if (!currentParameters[key]) {
        delete currentParameters[key]
      }
    })
    if (!nid) {
      const redirectUrl = `${location.pathname}?${queryString.stringify(currentParameters)}`
      navigate(redirectUrl)
    }
  }

  // for left navigation data
  const listItems = [
    { label: 'Announcements', type: 'heading' },
    { label: 'All', alternativeLabel: 'Announcements', token: 'all', url: 'announcements/all' },
    { label: 'Global', token: 'global', url: 'announcements/global', clsName: 'indent' },
    { label: 'My Groups', token: 'my-groups', url: 'announcements/my-groups', clsName: 'indent' },
    { label: 'Scheduled', token: 'scheduled', url: 'announcements/scheduled' },
    { label: 'Archived', token: 'archived', url: 'announcements/archived' },
    { label: 'Notifications', type: 'heading', clsName: 'top-border' },
    { label: 'Requests', token: 'requests', description: 'View all requests that you have initiated', url: 'requests', countIndex: requestNotifications },
    { label: 'Approvals', token: 'approvals', description: 'View all approvals that you can either approve or deny', url: 'approvals', countIndex: approvalNotifications }
  ]
  // Set params for dynamic routing.
  useEffect(() => {
    // Set the dynamic url path.
    if (params?.name) {
      setRightNavigation(params?.name === 'announcements' ? params?.id : params?.name)
    }
  }, [params])
  // Set success/error message in header.
  useEffect(() => {
    if (feedbackMessage?.message) {
      const timer = setTimeout(() => setFeedbackMessage({}), constants.misc.favoritesLimit.feedbackMessageTimeout * 1000)
      return () => clearTimeout(timer)
    }
  }, [feedbackMessage])

  useEffect(() => {
    // Set API URL for notificationCount query.
    const query = `${process.env.REACT_APP_TARGET_URL}/api/group-data-pending-count`
    queryContent(query)
      .then(res => {
        setApprovalNotifications(parseInt(res.response.count.pendingApprovals))
        setRequestNotifications(parseInt(res.response.count.pendingRequests))
      })
      .catch(err => console.log(err))
      .finally(() => setShouldNotificationReload(false))
  }, [shouldNotificationReload, location.pathname])

  useEffect(() => {
    // Set API URL for groupUidRA query.
    const query = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupUserGroupsByUidRA}&variables=${JSON.stringify({ uid: userId })}`
    queryContent(query)
      .then(res => {
        setGroupUserGroupsByUidRA(res.data.groupUserGroupsByUidRA?.results.map(result => parseInt(result.gid)))
      })
      .catch(err => console.log(err))
  }, [userId])

  // Render the right side component based on dynamic uel path
  const getComponent = urlParam => {
    const dynamicParam = urlParam.toLowerCase()
    if (dynamicParam === 'requests') {
      return <Common urlPart="group-request-list" setErrorMessage={setErrorMessage} paramId={params?.id || 'all'} setShouldNotificationReload={setShouldNotificationReload} isLoading={isLoading} setIsLoading={setIsLoading} />
    } else if (dynamicParam === 'approvals') {
      return <Common setFeedbackMessage={setFeedbackMessage} setErrorMessage={setErrorMessage} urlPart="group-approval-list" paramId={params?.id || 'all'} setShouldNotificationReload={setShouldNotificationReload} isLoading={isLoading} setIsLoading={setIsLoading} />
    } else if (['all', 'global', 'my-groups', 'archived', 'scheduled', 'create'].includes(dynamicParam)) {
      return <Announcements groupUserGroupsByUidRA={groupUserGroupsByUidRA} setFeedbackMessage={setFeedbackMessage} userId={userId} pageSize={pageSize} isLoading={isLoading} setErrorMessage={setErrorMessage} setIsLoading={setIsLoading} parameters={parameters} handleChange={handleChange} setPageSize={setPageSize} />
    } else {
      return <NotFound />
    }
  }

  // Function to handle navigation menu click.
  const handleNavigationClick = (e, token) => {
    if (!isLoading) {
      setRightNavigation(token)
      setErrorMessage('')
      setSearchValue('')
    } else {
      e.preventDefault()
    }
  }

  const createAnnouncement = () => {
    navigate('/inbox/announcements/create')
  }

  // Navigate to clicked page.
  const getLeftNavigation = () => {
    // Loop through all items to generate the left menu.
    return listItems.map((value, key) => {
      // Return each menu.
      return (
        <div key={`main_${key}`} className={value?.clsName}>
          {value?.type === 'heading' && <p className={`subheader ${value?.clsName}`}>{value.label}</p>}
          {!value?.type && <ListItem className={value.token === rightNavigation ? 'active-menu' : ''} disablePadding >
            <ListItemText children={<Link to={`/inbox/${value.url}`} onClick={e => handleNavigationClick(e, value.token)} style={{ cursor: isLoading ? 'none' : 'pointer' }}>{value.label}</Link>} />
            {value?.countIndex ? <div className='inbox_menucounter'><span className='menucounter_text'>{value?.countIndex}</span></div> : null}
          </ListItem >}
        </div>
      )
    })
  }
  // Get the heading item.
  const pageHeadingItem = listItems.find(item => item.token === rightNavigation)

  // Return the output.
  return (
    <div className="min_content_height bg-f7">
      <div className="page_header_wrapper">
        {feedbackMessage?.message && <div className="fab_details_alert">
          <Alert severity={feedbackMessage.type} icon={feedbackMessage?.icon} >
            {feedbackMessage?.message}
          </Alert>
        </div>}
        <div className="breadcrumb_section_wrapper">
          <ul className="breadcrumb_section">
            <li className="breadcrumb_item">
              <Link to="/">Home</Link>
              <span className="breadcrumb_icon">
                <ChevronRightIcon />
              </span>
            </li>
            <li className="breadcrumb_item">Inbox</li>
          </ul>
        </div>
        <div className="page_header_inner">
          <div className="page_header_heading_wrapper">
            <div className="page_header_heading">Inbox</div>
            <div className="page_header_subheading">View all announcements and notifications related to you.</div>
          </div>
          <div className='create_announcement'>
            {groupUserGroupsByUidRA.length !== 0 && <Button aria-haspopup='true' variant='contained' startIcon={<LaunchIcon />} onClick={createAnnouncement}>
              Create new announcement
            </Button>
            }
          </div>
        </div>
      </div>
      <div className="inbox_page_wrapper row">
        <div className="inbox_page_wrapper_section">
          <Stack direction={{ xs: 'column', sm: 'row' }}>
            <div className="inbox_left_section col-3">
              <div className="inbox_menu_wrapper">
                <List className="inbox_menu">
                  {getLeftNavigation()}
                </List>
              </div>
            </div>
            <div className="inbox_right_section">
              {id !== 'create'
                ? <div className="heading_section_wrapper">
                  <Grid container>
                    <div className='section_heading'>{pageHeadingItem?.alternativeLabel || pageHeadingItem?.label}</div>
                    {(!isLoading && params?.name === 'announcements' && !nid) && <>
                      <div className='search_filter_wrapper'>
                        <div className='search_wrapper'>
                          <SearchIcon />
                          <input className="search_box" value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyDown={e => (e.key === 'Enter') ? handleChange('search', e.target.value) : ''} placeholder="Search" />
                        </div>
                        <IconButton onClick={handleClick}><SortIcon fontSize="large" /></IconButton>
                      </div>
                      <StyledMenu id="action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className="action_menu_item">
                        <StyledMenuItem onClick={() => handleChange('priority', '')}>
                          <ListItemText>Any</ListItemText>
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => handleChange('priority', 'high')}>
                          <ListItemText>High Priority</ListItemText>
                        </StyledMenuItem>
                      </StyledMenu></>
                    }
                  </Grid>
                  <div className="section_sub">
                    {params.name !== 'announcements'
                      ? params?.id
                        ? <p><Link to={`/inbox/${rightNavigation}`}><ChevronLeftIcon />{`Back to all ${rightNavigation}`}</Link></p>
                        : <p>{listItems.find(item => item.url === rightNavigation)?.description}</p>
                      : null
                    }
                  </div>
                </div>
                : null}
              {errorMessage.length ? <InboxError errorMessage={errorMessage} /> : getComponent(rightNavigation)}
            </div>
          </Stack>
        </div>
      </div>
    </div>
  )
}
// Export the component for use.
export default Inbox
