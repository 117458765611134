import React, { useState, useEffect } from 'react'
// Import necessary components.
import { Button, Modal, Backdrop, Fade, TextareaAutosize, RadioGroup, Radio, FormControlLabel, FormLabel, FormControl } from '@mui/material'
import './group-member-add-modal.scss'
import { Close } from '@mui/icons-material'
import FieldSwitch from '../Assets/component/FieldSwitch'
import { constants } from '../../../lib'

// Export GroupMemberAddModal component.
export default function AddMemberModalStep1 ({ visible, onCancel, raciRolesArr, continues, data, classes }) {
  // set same RACI role.
  const [sameRaciRole, setSameRaciRole] = useState(data.sameRaciRole)
  // Set initialFormData.
  const initialFormData = { emails: data.email, role: data.role, description: data.description }
  // set form data.
  const [formData, setFormData] = useState(initialFormData)
  // Set initialValidationStatus.
  const initialValidationStatus = { errorMemberEmailIds: '', errorMemberRoles: '', errorDescription: '' }
  // Set validation status.
  const [validationStatus, setValidationStatus] = useState(initialValidationStatus)
  // Set error message.
  const [errorMsgs, setErrorMsgs] = useState(initialValidationStatus)
  // Set step1FormSubmitted.
  const [step1FormSubmitted, setStep1FormSubmitted] = useState(false)

  // validate the form.
  const validateForm = () => {
    // Initiate values.
    let statusClassName = initialValidationStatus
    let validationErrors = initialValidationStatus
    let hasError = false
    if (sameRaciRole) {
      // Description error.
      if (!formData.description.trim()) {
        hasError = true
        validationErrors = { ...validationErrors, errorDescription: 'Please enter description.' }
        statusClassName = { ...statusClassName, errorDescription: 'fieldError' }
      }
      // Role error.
      if (!formData.role.trim()) {
        hasError = true
        validationErrors = { ...validationErrors, errorMemberRoles: 'Please choose a role.' }
        statusClassName = { ...statusClassName, errorMemberRoles: 'fieldHasError' }
      }
    }
    // Accumulate all emails.
    let allEmails = formData.emails.trim() ? formData.emails.trim().split(';').map(item => item.trim()) : []
    // Make it unique.
    allEmails = [...new Set(allEmails)]
    // Check if number of email exceeded.
    if (allEmails?.length > constants.misc.maxAddMemberCount) {
      hasError = true
      validationErrors = { ...validationErrors, errorMemberEmailIds: `Only ${constants.misc.maxAddMemberCount} emails can be added at one time. Please remove additional emails.` }
      statusClassName = { ...statusClassName, errorMemberEmailIds: 'fieldError' }
    }
    // No email placed.
    if (!allEmails?.length) {
      hasError = true
      validationErrors = { ...validationErrors, errorMemberEmailIds: 'Please enter single or multiple emails.' }
      statusClassName = { ...statusClassName, errorMemberEmailIds: 'fieldError' }
    }
    // Return based on there are any error or not.
    if (hasError) {
      setValidationStatus(statusClassName)
      setErrorMsgs(validationErrors)
      return false
    } else {
      setValidationStatus(initialValidationStatus)
      setErrorMsgs(initialValidationStatus)
      return true
    }
  }

  // Validate form data when step 1 form submitted.
  useEffect(() => {
    if (step1FormSubmitted) {
      validateForm()
    }
  }, [step1FormSubmitted, formData])

  // Validate and set form data.
  useEffect(() => {
    if (!sameRaciRole) {
      if (step1FormSubmitted) {
        validateForm()
      }
      setFormData({
        ...formData,
        role: '',
        description: ''
      })
    }
  }, [step1FormSubmitted, sameRaciRole])

  // Submit handler to continue in next step.
  const submitHandler = () => {
    setStep1FormSubmitted(true)
    if (validateForm()) {
      continues(formData.emails, formData.role, formData.description, sameRaciRole)
    }
  }

  // The close handler to reset everything.
  const onCancelHandler = () => {
    onCancel()
    setValidationStatus(initialValidationStatus)
    setErrorMsgs(initialValidationStatus)
    setStep1FormSubmitted(false)
    setFormData({ emails: '', role: '', description: '' })
    setSameRaciRole(false)
  }

  // Return AddMemberModal step 1 data.
  // In first step have
  //  - Email textarea
  //  - Common role selection
  //  - Common description
  return (
    <div className="add-member">
      <Modal
        aria-labelledby="Add member modal"
        aria-describedby="Add group members"
        className={classes.modal}
        open={visible}
        onClose={onCancelHandler}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={visible}>
          <div className={`${classes.paper} modal-wrapper`}>
            <span className="close-modal">
              <Close fontSize="medium" onClick={onCancelHandler} />
            </span>
            <div className="add-member-modal-content">
              <div className="heading">Add Members <span className="border-right">|</span> <span className="steps"> Step 1 of 2</span></div>
              <div className='sub-heading'>Add one or multiple emails separated with a semicolon(;)</div>
              <form noValidate autoComplete="off" id="group-add-member-form" className="member-form-wrapper">
                <div className={validationStatus.errorMemberEmailIds ? 'description-wrapper fieldHasError' : 'description-wrapper'}>
                  <FormLabel component="legend" className="fieldHeading form-email">Add Emails *</FormLabel>
                  <TextareaAutosize
                    id="member-email-ids"
                    className={'email-textarea ' + validationStatus.errorMemberEmailIds}
                    aria-label="minimum height"
                    minRows={5}
                    value={formData.emails}
                    onChange={event => setFormData({ ...formData, emails: event.target.value })}
                    placeholder="Enter single or multiple emails"
                    required
                    maxRows={5}
                  />
                  {
                    errorMsgs.errorMemberEmailIds && <span className="error-desc">{errorMsgs.errorMemberEmailIds}</span>
                  }
                </div>
                <div className="member-wrapper">
                  <div className="member-inner-section">
                    <div className="switch-heading">Assign all members the same RACI roles and description</div>
                    <FieldSwitch
                      checked={sameRaciRole}
                      onChange={() => setSameRaciRole(prev => !prev)}
                      color="primary"
                      name="enDisFields"
                      inputProps={{ 'aria-label': 'enable or disable fields' }}
                    />
                  </div>
                </div>
                <div className="role-wrapper">
                  <FormControl component="fieldset" required className={validationStatus.errorMemberRoles}>
                    <FormLabel component="legend" className={!sameRaciRole ? 'fieldHeading Mui-disabled' : 'fieldHeading'}>Role</FormLabel>
                    <RadioGroup aria-label="roles" name="roles1" value={formData.role} onChange={event => setFormData({ ...formData, role: event.target.value })}>
                      {raciRolesArr.map(raciRole => (
                        <FormControlLabel value={raciRole.toLocaleLowerCase()} key={raciRole} disabled={!sameRaciRole} control={<Radio color='primary' />} label={raciRole} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  {errorMsgs.errorMemberRoles && <span className="role-error">{errorMsgs.errorMemberRoles}</span>}
                </div>
                <div className={validationStatus.errorDescription ? 'description-wrapper fieldHasError' : 'description-wrapper'}>
                  <FormLabel component="legend" className={!sameRaciRole ? 'fieldHeading Mui-disabled' : 'fieldHeading'}>Description *</FormLabel>
                  <TextareaAutosize
                    id="member-description"
                    className={!sameRaciRole ? 'email-textarea Mui-disabled' : 'email-textarea ' + validationStatus.errorDescription}
                    aria-label="minimum height"
                    minRows={1}
                    value={formData.description}
                    onChange={event => setFormData({ ...formData, description: event.target.value })}
                    placeholder="Description"
                    required
                    disabled={!sameRaciRole}
                    maxRows={2}
                  />
                  {errorMsgs.errorDescription && <span className="error-desc">{errorMsgs.errorDescription}</span>}
                </div>
                <div className="btn-wrapper-email">
                  <Button color="primary" variant="outlined" className="cancel-email" onClick={onCancelHandler}>
                    Cancel
                  </Button>
                  <Button
                    id="send-email"
                    variant="contained"
                    color="primary"
                    className="send-email"
                    onClick={submitHandler}
                  >
                    Next
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div >
  )
}
