import React from 'react'
import { constants } from '../../../lib'

// Add plural indicator 's' if count is not 1.
const setPlural = count => parseInt(count) === 1 ? '' : 's'

// Export the component for use.
// Each row contain title, vision, various counts, sector.
// Sector color coming from backend and which is configurable.
export default function GroupResults ({ item }) {
  // Return the output.
  return (
    <div className={`search_inner_section ${item.resultType}`}>
      <div className="outer_section_right g_section">
        <div className="section_left">
          <div className="sections">
            <label className="heading">{item.title}</label>
            <span className="section_lbl application_lbl" style={{ backgroundColor: item.sectorColor }}>{item.sectorLabel}</span>
          </div>
          <div className="section_count section_vision">
            <span className='item_list no'><span>{item.businessTeamCount} Business Team Member{setPlural(item.businessTeamCount)}</span></span>
            <span className='item_list'><span>{item.applicationsCount} Application{setPlural(item.applicationsCount)}</span></span>
          </div>
          <div className="section_details">
            <span className="bld">Vision:</span>
            {item.vision ? <span className="long_text" dangerouslySetInnerHTML={{ __html: item.vision }} /> : constants.filter.none}
          </div>
        </div>
      </div>
    </div>
  )
}
