// Custom Util functions used across search section
// Convert timestamp to date.
export function convertTimeToDate (value) {
  let formattedDate = ''
  if (value && !isNaN(value)) {
    const f = new Date()
    f.setTime(value)
    // Convert to date from ISO
    formattedDate = f.toISOString().split('T')[0]
  }
  return formattedDate
}
// Convert date to timestamp.
export function getTimestamp (value) {
  let dateVal = ''
  if (value && !isNaN(Date.parse(value))) {
    // Convert to time.
    dateVal = new Date(value).getTime()
  }
  return dateVal
}
// Generate search url.
export function generateSearchUrl (entity, field, value) {
  return `/search?entityType=${entity.toLowerCase()}&offset=0&${field}=${encodeURIComponent(value).replace('(', '%28').replace(')', '%29')}`
}
