import React, { useState, useEffect } from 'react'
// Import necessary components.
import { Button, Modal, Backdrop, Fade } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import './group-member-add-modal.scss'
import { Close } from '@mui/icons-material'
import memberIcon from '../../../images/green-icon-people.svg'
import { constants } from '../../../lib'

// Function used to extract email.
const emailExtract = emailText => {
  const emailStr = emailText.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})/gi)
  if (emailStr) {
    return emailStr[0]
  } else {
    return false
  }
}

// Export GroupMemberAddModal component.
export default function AddMemberModalStep2 ({ gid, visible, raciRolesArr, onCancel, stepBack, emailArray, setFeedbackMessage, setGroupShouldReload, classes }) {
  // Set rows.
  const [rows, setRows] = useState([])
  // Set row errors.
  const [rowErrors, setRowErrors] = useState()
  // Set validation status.
  const [validationStatus, setValidationStatus] = useState(true)
  // Set is loading.
  const [isLoading, setIsLoading] = useState(false)
  // Set step2FormSubmitted.
  const [step2FormSubmitted, setStep2FormSubmitted] = useState(false)

  // Set row data when role change.
  const changeData = (value, index, field) => {
    setRows(() => {
      const copy = [...rows]
      copy[index] = {
        ...copy[index],
        [field]: value
      }
      return copy
    })
  }

  // Validate form data when form step 2 form submitted.
  useEffect(() => {
    if (step2FormSubmitted) {
      validateForm()
    }
  }, [rows, step2FormSubmitted])

  // Set rows, row errors based on email array.
  useEffect(() => {
    setRows(emailArray)
    setRowErrors([...Array(emailArray.length)].fill({
      type: '',
      message: ''
    }))
  }, [emailArray])

  // Validate the form.
  const validateForm = () => {
    // Initiate the value.
    const errors = []
    let hasError = false
    const emailArr = []
    // Validate each row.
    rows.forEach(row => {
      const error = {
        type: [],
        message: ''
      }
      // Generic error.
      const genericErrorMessage = 'Please fill in missing information.'
      // Check if any one of them is empty.
      if (!(row.role || row.description.trim() || row.email.trim())) {
        hasError = true
        error.type.push('rowError')
        error.message = genericErrorMessage
      } else if (!emailExtract(row.email)) {
        // Validate email
        hasError = true
        error.type.push('emailError')
        error.message = 'Please enter a valid email address.'
      } else {
        // No role selected.
        if (!row.role) {
          hasError = true
          error.type.push('roleError')
          error.message = genericErrorMessage
        }
        // No description placed.
        if (!row.description.trim()) {
          hasError = true
          error.type.push('descriptionError')
          error.message = genericErrorMessage
        }
      }
      if (emailArr.includes(row.email.trim())) {
        hasError = true
        if (!(error.type.includes('emailError'))) {
          error.type.push('emailError')
          error.message = 'This is a duplicate email address, already specified above. ' + error.message
        }
      } else {
        emailArr.push(row.email.trim())
      }
      // Set validation status base on error.
      if (hasError) {
        setValidationStatus(false)
      } else {
        setValidationStatus(true)
      }
      errors.push(error)
    })
    setRowErrors(errors)
    return !hasError
  }

  // Handle step back.
  const handleBack = () => {
    let emailString = ''
    rows.forEach(row => {
      emailString += (row.email + ';')
    })
    stepBack(emailString.slice(0, -1))
  }

  // Handle submit.
  const handleSubmit = () => {
    setStep2FormSubmitted(true)
    if (!validateForm()) {
      return
    }
    // Set loading before submit.
    setIsLoading(true)
    // Set the api url.
    const url = `${process.env.REACT_APP_TARGET_URL}/api/add-member-to-group`
    // Set the payload data.
    const data = {
      gid,
      memberData: rows.map(row => ({
        emailId: emailExtract(row.email),
        role: row.role,
        description: row.description
      }))
    }
    // To track if logged out.
    let haveResponse = false
    // Call the api.
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(res => {
        haveResponse = true
        return res.json()
      })
      .then(res => {
        setIsLoading(false)
        if (res.error) {
          const errors = []
          let hasError = false
          res.response.errorMsg.forEach(msg => {
            if (msg) {
              hasError = true
            }
            const error = {
              message: msg,
              type: msg ? 'rowError' : ''
            }
            errors.push(error)
          })
          if (hasError) {
            setValidationStatus(false)
          }
          // Set row error from errors.
          setRowErrors(errors)
        } else {
          // Set message in page top.
          setFeedbackMessage({ message: `Added ${rows.length} Member${rows.length === 1 ? '' : 's'} to group`, type: 'success', icon: <img src={memberIcon} alt='Member Icon' />, section: 'addMember' })
          // Load the group silently.
          setGroupShouldReload(true)
          // Close the modal.
          onCancel()
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err.message)
      })
  }

  // Delete a row.
  const deleteRow = index => {
    // Not allow delete if submitting or only one row left.
    if (isLoading || rows.length === 1) {
      return false
    }
    setRows(rows.filter((row, position) => position !== index))
  }

  // Add another row.
  const addAnotherMember = e => {
    e.preventDefault()
    // Not allow add row if submitting or reached max limit.
    if (isLoading || rows.length === constants.misc.maxAddMemberCount) {
      return false
    }
    setRows([...rows, {
      email: '',
      role: '',
      description: ''
    }])
    setRowErrors([...rowErrors, {
      type: '',
      message: ''
    }])
  }

  // Return memberAddModal step 2 data.
  return (
    <div className="add-member">
      <Modal
        aria-labelledby="Add member modal"
        aria-describedby="Add group members"
        className={classes.modal}
        open={visible}
        onClose={onCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={visible}>
          <div className={`${classes.paper} modal-wrapper`}>
            <span className="close-modal">
              <Close fontSize="medium" onClick={onCancel} />
            </span>
            <div className="add-member-modal-content">
              <div className="heading">Add Members <span className="border-right">|</span> <span className="steps"> Step 2 of 2</span></div>
              <div className='sub-heading'>Edit and review members before adding them to group</div>
              <form autoComplete="off" id="group-add-member-form">
                <div className="add-member-step-2">
                  <div className="table-wrapper">
                    <ul className="table-header step-table">
                      <li>Member</li>
                      <li>Role</li>
                      <li>Description</li>
                      <li></li>
                    </ul>
                    <div className="table-details-wrapper">
                      {
                        rows.map((item, index) => {
                          const errorType = rowErrors[index]?.type
                          return (
                            <div className="table-inner-details" key={index} >
                              <ul className={(errorType.includes('rowError')) ? 'table-details step-table error-input' : 'table-details step-table'}>
                                <li className={(errorType.includes('emailError')) ? 'error-input' : ''}>
                                  <input type="text" placeholder="Enter email" name="email" value={item.email} onChange={e => changeData(e.target.value, index, 'email')} />
                                </li>
                                <li className={(errorType.includes('roleError')) ? 'error-input' : ''}>
                                  <select value={item.role} name="role" onChange={e => changeData(e.target.value, index, 'role')}>
                                    <option value="">Select Role</option>
                                    {raciRolesArr.map(raciRole => (
                                      <option value={raciRole.toLocaleLowerCase()} key={raciRole}>{raciRole}</option>
                                    ))}
                                  </select>
                                </li>
                                <li className={(errorType.includes('descriptionError')) ? 'error-input' : ''}>
                                  <input type="text" placeholder="Enter description" value={item.description} name="description" onChange={e => changeData(e.target.value, index, 'description')} />
                                </li>
                                <li className="delete_row">{rows.length > 1 && <DeleteIcon color="disabled" onClick={() => deleteRow(index)} />}</li>
                              </ul>
                              <p className="error-msg">{rowErrors[index]?.message}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className="btn-wrapper-email step-2">
                  {!validationStatus && <span className="all-error">Please fix errors to add members.</span>}
                  <div className='add_member_wrapper'>
                    <div className="add_member">
                      {!isLoading && (rows.length < constants.misc.maxAddMemberCount) && <p data-tracking='+ Add Another Member' onClick={addAnotherMember}>+ Add Another Member</p>}
                    </div>
                    <div className="btn-section-bottom">
                      <Button color="primary" disabled={isLoading} variant="outlined" className="cancel-email" onClick={handleBack}>
                        Back
                      </Button>
                      <Button
                        id="send-email"
                        variant="contained"
                        color="primary"
                        className="send-email"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        data-tracking='Add to Group'
                      >
                        {isLoading ? 'Saving...' : 'Add to Group'}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
