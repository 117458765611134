import React from 'react'
// Import necessary components.
import CloseIcon from '@mui/icons-material/Close'
import { Button, Modal } from '@mui/material'
import '../sass/component/generic-modal.scss'

// Build the component.
const RemoveFavoriteModal = ({ confirmModal, handleClose, entityName, handleConfirm }) => {
  // Configure the body.
  const body = (
    <div className="generic-modal-wrapper remove-modal">
      <div className="header">
        <h2 className="generic-modal-title">Remove Favorite</h2>
        <CloseIcon className="close_popup" onClick={e => handleClose(e)} />
      </div>
      <div>
        <p className="text-description">Are you sure you want to remove {entityName} from your favorites? This will remove it from your favorites.</p>
      </div>
      <div className="btn_wrapper">
        <Button variant="outlined" className="btn" color="primary" onClick={e => handleClose(e)}>
          Cancel
        </Button>
        <Button variant="contained" className="btn" color="primary" onClick={e => handleConfirm(e)}>
          Remove
        </Button>
      </div>
    </div>
  )

  // Return the output.
  return (
    <Modal open={confirmModal} onClose={handleClose}
      aria-labelledby="fav-remove-modal-title"
      aria-describedby="fav-remove-modal-description"
    >
      {body}
    </Modal>
  )
}

// Export the component for use.
export default RemoveFavoriteModal
