import React, { useEffect } from 'react'
import {
  Audits,
  CAPAs, ClinicalResearchSiteActivity,
  DiscoveryLocation,
  ExternalServiceProvider,
  Findings,
  ProcessCT,
  QualityInvestigations
} from './ContentPages'
import { LOCHome } from './LOCHome'
import { useLocation, useParams } from 'react-router-dom'
import { constants } from '../../lib'
import './contentDetails.scss'
import Application from '../NextGen/Application'
import { useFavorites } from '../../hooks'
// Function for array flip operation.
// Return reverseArray.
const arrayFlip = arr => {
  let key
  const reverseArray = []
  for (key in arr) {
    if (Object.prototype.hasOwnProperty.call(arr, key)) {
      reverseArray[arr[key]] = key
    }
  }
  return reverseArray
}

const ContentDetails = ({ match }) => {
  const [userFavorites, , , updateFavorite] = useFavorites()
  let id = 0
  let type = ''
  const params = useParams()
  // Set searchParams.
  const searchParams = new URLSearchParams(useLocation().search)
  if (!match) {
    type = params?.type
    id = searchParams.get('id')
  } else {
    // Call arrayFlip function.
    const contentType = arrayFlip(constants.drupalDataType.contentType)
    type = contentType[match.type]
    id = match.id
  }

  // Update latest used date.
  useEffect(() => {
    if (userFavorites && userFavorites.node.includes(parseInt(id))) {
      updateFavorite(id, 'node', 2)
    }
  }, [id])

  // Set tabContents for all brqc content types.
  const tabContents = {
    audits: () => <Audits ids={id} />,
    findings: () => <Findings ids={id} />,
    qis: () => <QualityInvestigations ids={id} />,
    capas: () => <CAPAs ids={id} />,
    esp: () => <ExternalServiceProvider id={id} />,
    loc: () => <LOCHome id={id} />,
    crsac: () => <ClinicalResearchSiteActivity id={id} />,
    dl: () => <DiscoveryLocation id={id} />,
    process: () => <ProcessCT id={id} />,
    article: () => <Application id={id} />
  }
  // Return content details.
  return (
    <>
      {tabContents[type] ? tabContents[type]() : <div className='content-wrapper' style={{ textAlign: 'center' }}>Content not found for this type.</div>}
    </>
  )
}
// Export ContentDetails data.
export default ContentDetails
