import React, { useContext, useEffect, useState } from 'react'
// Import the required assets and images.
import ConnectIntelligenceCard from '../Assets/component/ConnectIntelligenceCard'
import { useNextGenSearch } from '../../../hooks'
import { constants } from '../../../lib'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import Loader from '../../PageLoader'
import SearchInput from '../Assets/component/SearchInput'
import GlobalGroupAnnouncement from '../Assets/component/GlobalGroupAnnouncement'
import HomeBanner from './HomeBanner'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'
// Generate the NextGenHome page.
// Currently it is using dummy data.
// But it will be removed once functionality implemented.
const NextGenHome = () => {
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)
  // Use useNextGenSearch hook.
  const [searchValue, setSearchValue, urlForRedirect, loading, searchOptions, searchOptionCount] = useNextGenSearch('search_text_box')
  // Set rootSectorData.
  const [rootSectorData, setRootSectorData] = useState()
  // Set isLoading flag.
  const [isLoading, setIsLoading] = useState(false)
  // Set hasLoadingError flag.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // set data count from globalGroupAnnouncement js.
  const [announcementsCount, setAnnouncementsCount] = useState(0)

  // Root sector Data fetching.
  useEffect(() => {
    // Sending page data for tracking.
    setPageData({
      type: 'index',
      breadCrumbs: [{ text: 'Home' }]
    })
    // Set loading state.
    setIsLoading(true)
    // Call graphql for Root Sector Data.
    const RootSectorQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.rootSectorQuery}`
    // Query Call
    queryContent(RootSectorQuery)
      .then(response => {
        if (!response?.data) {
          setHasLoadingError(true)
        } else {
          // Set rootSectorData.
          setRootSectorData(response.data)
        }
      })
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))
  }, [])

  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Return the output.
  return (
    <div className="min_content_height bg-f7">
      <HomeBanner />
      <div className="home_intelligence-wrapper">
        <div className="home_intelligence_inner_wrapper">
          <div className="home-wrapper">
            <div className="intelligence_heading">Next Gen Data & Analytics</div>
            <div className="intelligence_subheading">Where people, process, data, and technology converge for our customers</div>
            <div className="intelligence_search">
              <SearchInput id="search_text_box" searchValue={searchValue} setSearchValue={setSearchValue}
                searchOptions={searchOptions} urlForRedirect={urlForRedirect} loading={loading}
                placeholder="Search by keyword" className="input-box" showIcon={true} showButton={true} count={searchOptionCount} />
            </div>
            <div className={`view_data_wrapper component ${announcementsCount ? 'global_announcement_view' : ''}`}>
              <div className='left_section'>
                <div className="view_data_heading">View Data Applications by Sector</div>
                <div className="row">
                  {
                    rootSectorData && rootSectorData.groupQuery?.entities?.map(entity => (
                      <ConnectIntelligenceCard key={entity.id} data={entity} />
                    ))
                  }
                  {hasLoadingError && <div className='errorLoading' dangerouslySetInnerHTML={{ __html: constants.errors.server }} />}
                </div>
              </div>
              {!hasLoadingError && <div className='right_section'>
                <GlobalGroupAnnouncement globalOrGroupId="global" setAnnouncementsCount={setAnnouncementsCount} announcementsCount={announcementsCount} />
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Return the component for use.
export default NextGenHome
