import React, { useState } from 'react'
import { Button } from '@mui/material'
import { constants } from '../../../../../lib'
import SideNavGroups from '../SideNavGroups/SideNavGroups'
import SideNavContentTypes from '../SideNavContentTypes/SideNavContentTypes'
import SideNavModal from '../SideNavModal/SideNavModal'
import { checkDupes } from './SideNavHelpers'

// Export side nav button for audits.
export default function SideNavButtonAudits ({ auditData, setSidePreviews }) {
  // Set state to show modal.
  const [shouldShowModal, setShouldShowModal] = useState(false)
  // Set the content type.
  const [contentType, setContentType] = useState()
  // Set the content type title.
  const [contentTypeTitle, setContentTypeTitle] = useState()
  // Set the data to render.
  const [data, setData] = useState()
  // Check audit data validity.
  if (auditData === null) {
    return setSidePreviews(
      <div>
        {constants.filter.none}
      </div>
    )
  } else {
    const findings = auditData?.findings
    let capaCount = 0
    let qiCount = 0
    let findingString = ''
    let capaString = ''
    let qiString = ''
    const ESPs = []
    const LOCs = []
    const CRSACs = []
    const DLs = []
    // Check non-duplicate node and push it.
    const pushElement = (dataItem, type) => {
      dataItem.forEach(element => {
        if (element && checkDupes(element, type)) {
          type.push(element)
        }
      })
    }
    // Push data for auditData CRSAC.
    if (auditData?.CRSAC) {
      pushElement(auditData?.CRSAC, CRSACs)
    }
    // Push data for auditData ESP.
    if (auditData?.ESP) {
      pushElement(auditData?.ESP, ESPs)
    }
    // Push data for auditData LOC.
    if (auditData?.LOC) {
      pushElement(auditData?.LOC, LOCs)
    }
    // Push data for auditData DL.
    if (auditData?.discoveryLocation) {
      pushElement(auditData?.discoveryLocation, DLs)
    }
    // If has finding.
    if (findings.count > 0) {
      const findingIDs = []
      const capaIDs = []
      const qiIDs = []
      findings.findingEntities.filter(n => n).forEach(finding => {
        findingIDs.push(finding?.nid)
        const currentCAPAs = finding?.AuditFindingCAPAs
        if (currentCAPAs.count > 0) {
          capaCount += currentCAPAs.count
          // Loop through all capa entries.
          currentCAPAs.capaEntities.filter(n => n).forEach(capa => {
            // Push capa id.
            capaIDs.push(capa?.nid)
            // Push data for capa CRSAC.
            if (capa?.CRSAC) {
              pushElement(capa?.CRSAC, CRSACs)
            }
            // Push data for capa ESP.
            if (capa?.ESP) {
              pushElement(capa?.ESP, ESPs)
            }
            // Push data for capa LOC.
            if (capa?.LOC) {
              pushElement(capa?.LOC, LOCs)
            }
            // Push data for capa DL.
            if (capa?.DL) {
              pushElement(capa?.DL, DLs)
            }
          })
        }
        const currentQIs = finding?.AuditFindingQIs
        if (currentQIs.count > 0) {
          qiCount += currentQIs.count
          // Loop through all qis entries.
          currentQIs.qiEntities.filter(n => n).forEach(qi => {
            qiIDs.push(qi?.nid)
            // Push data for qi CRSAC.
            if (qi?.CRSAC) {
              pushElement(qi?.CRSAC, CRSACs)
            }
            // Push data for qi ESP.
            if (qi?.ESP) {
              pushElement(qi?.ESP, ESPs)
            }
            // Push data for qi LOC.
            if (qi?.LOC) {
              pushElement(qi?.LOC, LOCs)
            }
            // Push data for qi DL.
            if (qi?.DL) {
              pushElement(qi?.DL, DLs)
            }
          })
        }
      })
      findingString = `/content/findings?id=${findingIDs.filter(n => n).join(',')}`
      qiString = `/content/qis?id=${qiIDs.filter(n => n).join(',')}`
      capaString = `/content/capas?id=${capaIDs.filter(n => n).join(',')}`
    }
    // Return the side nav button for audits.
    return (
      <div>
        <Button
          onClick={() => {
            // Finding Query Call
            setSidePreviews(
              <div>
                <SideNavContentTypes
                  contentType='Findings'
                  number={findings.count}
                  link={findingString}
                />
                <SideNavContentTypes
                  contentType='CAPAs'
                  number={capaCount}
                  link={capaString}
                />
                <SideNavContentTypes
                  contentType='Quality Investigations'
                  number={qiCount}
                  link={qiString}
                />
                <SideNavGroups
                  contentType='CRSAC'
                  data={CRSACs}
                  openModalCallback={() => {
                    setContentType('CRSAC')
                    setData(CRSACs)
                    setShouldShowModal(true)
                    setContentTypeTitle('CRSAC')
                  }}
                />
                <SideNavGroups
                  contentType='ESP'
                  data={ESPs}
                  openModalCallback={() => {
                    setContentType('ESP')
                    setData(ESPs)
                    setShouldShowModal(true)
                    setContentTypeTitle('ESP')
                  }}
                />
                <SideNavGroups
                  contentType='LOC(s)'
                  data={LOCs}
                  openModalCallback={() => {
                    setContentType('LOC')
                    setData(LOCs)
                    setShouldShowModal(true)
                    setContentTypeTitle('LOC(s)')
                  }}
                />
                <SideNavGroups
                  contentType='DL'
                  data={DLs}
                  openModalCallback={() => {
                    setContentType('DL')
                    setData(DLs)
                    setShouldShowModal(true)
                    setContentTypeTitle('DL')
                  }}
                />
              </div>
            )
          }}
        >
          Side Nav
        </Button>
        <SideNavModal
          isOpen={shouldShowModal}
          onClose={() => setShouldShowModal(false)}
          data={data}
          contentType={contentType}
          contentTypeTitle={contentTypeTitle}
        />
      </div>
    )
  }
}
