import React, { useContext, useEffect, useState } from 'react'
import { DialogContent, DialogContentText, Typography } from '@mui/material'
import IframeResizer from 'iframe-resizer-react'
import { constants } from '../../../../lib'
import Loader from '../../../PageLoader'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

// Build the component.
const ApplicationManageForms = ({ formUrlId, gid, id, crumbs }) => {
  // Set formLoading.
  const [formLoading, setFormLoading] = useState(true)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)
  // Process the url.
  let url = constants.misc.webFormUrls[formUrlId]
  url = url.replace('{GID}', gid).replace('{NID}', id)
  url = `${process.env.REACT_APP_TARGET_URL}${url}`

  useEffect(() => {
    setFormLoading(true)
    let pageType = {}
    switch (formUrlId) {
      case 'ManageApplication': {
        pageType = { type: 'manage-application', title: 'Application Details' }
        break
      }
      case 'ManageApplicationCoverImage': {
        pageType = { type: 'manage-application-cover-image', title: 'Cover Image' }
        break
      }
      case 'ManageApplicationTags': {
        pageType = { type: 'manage-application-tag', title: 'Application Tags' }
        break
      }
      default: {
        pageType = {}
      }
    }
    // Sending page data for tracking.
    setPageData({
      type: pageType?.type,
      breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: pageType?.title }],
      page_id: id
    })
  }, [formUrlId])

  // Determine form loaded.
  const formLoaded = () => {
    setFormLoading(false)
  }
  // Return the output.
  return (
    <div className='dialog-modal app-forms'>
      <DialogContent>
        {formLoading ? <Loader /> : null}
        <DialogContentText>
          <Typography className='field'>
            <IframeResizer
              src={url}
              style={{ width: '1px', minWidth: '100%' }}
              className="webform-share-iframe"
              frameBorder="0"
              allow="geolocation; microphone; camera"
              allowtransparency="true"
              onLoad={formLoaded}
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </div>
  )
}
// Export the component for use.
export default ApplicationManageForms
