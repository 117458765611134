import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import siteLogo from './../../images/logo-voyager.svg'
import { constants } from '../../lib'
import IframeResizer from 'iframe-resizer-react'
import Loader from '../PageLoader'
import './logoutPage.scss'

// The logout page component.
const LogoutPage = () => {
  // Logout Page Header component.
  const LogoutPageHeader = () => {
    // Return the output.
    return (
      <>
        <div className="navbar-wrapper header-border-color">
          <div className="navbar">
            <div className="navbar-left">
              <div className="site-logo"><img src={siteLogo} alt="logo" className='cursor-pointer' onClick={() => { window.location.pathname = '/' }} /></div>
            </div>
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    // Setting environment.
    let environment = window.location.hostname.split('.')[0]
    if (environment === 'jnjbrqc') {
      environment = 'local'
    }
    if (environment === 'iq') {
      environment = 'prod'
    }
    // Setting page data for logout page.
    const data = {
      event: 'page_view',
      page_data: {
        system: 'frontend',
        env: environment,
        page_referrer: null,
        page_id: null,
        page_type: 'static',
        page_location: `${window.location.origin}/logout`,
        page_title: document.title,
        site_sectors: null,
        breadcrumbs: null
      },
      user_data: {
        user_id: null,
        user_login_state: constants.misc.anonymousUser,
        user_language: null,
        user_group_id: null,
        user_group_title: null,
        user_group_location: null,
        user_group_roles: null
      }
    }

    const dataLayer = window.dataLayer || []
    // Clearing the previous event.
    dataLayer.push({ event_data: null })
    // Pushing the new event data.
    dataLayer.push(data)
  }, [])

  // Create some style.
  const showElement = {
    display: 'block'
  }

  const hideElement = {
    display: 'none'
  }

  // Set formLoading flag.
  const [formLoading, setFormLoading] = useState(true)
  // Set formStyle.
  const [formStyle, setFormStyle] = useState(hideElement)

  // Execute on form load.
  const formLoaded = () => {
    setFormLoading(false)
    setFormStyle(showElement)
  }

  // Return the output with logout survey embedded.
  return (
    <div className='logout-page-bg'>
      <LogoutPageHeader />
      <div className='logout-page-body'>
        <>
          {formLoading ? <Loader /> : null}
          <Typography style={formStyle}>
            <IframeResizer
              src={`${process.env.REACT_APP_TARGET_URL}${constants.misc.logoutUrl}`}
              className="webform-share-iframe"
              allow="geolocation; microphone; camera"
              allowtransparency="true"
              onLoad={formLoaded}
            />
          </Typography>
        </>
      </div>
    </div>
  )
}

// Return the component for use.
export default LogoutPage
