import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Tabs, Tab, Typography, Box, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { constants } from '../../lib'

// The Tab panel component.
function TabPanel (props) {
  // Receive all the props.
  const { children, value, index, ...other } = props
  // Return the vertical panel.
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
// Prototype to follow.
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

// Get the tab props.
function tabProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

// Generate the search bar.
function SearchBar ({ handleSearch }) {
  const classes = useStyles()
  return (<div className="usage-search-wrapper">
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search"
        type="search"
        onChange={handleSearch}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'Search entity' }}
      />
    </div>
  </div>)
}

// Overwrite and add some styles.
const useStyles = makeStyles(theme => ({
  // Overwrite some root property.
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: '250px'
  },
  // Overwrite some tabs property.
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    fontSize: '.65rem'
  },
  // Overwrite some tab property.
  tab: {
    fontSize: '.65rem',
    textTransform: 'none'
  },
  tabContent: {
    width: '100%'
  },
  noData: {
    fontSize: '.85rem'
  },
  // Configure search bar style.
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    backgroundColor: '#fafafa',
    border: '1px solid #333',
    width: '300px',
    [theme.breakpoints.up('sm')]: {
      width: '300px'
    }
  },
  // Place the search icon inside search box.
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '240px',
    [theme.breakpoints.up('sm')]: {
      width: '240px',
      '&:focus': {
        width: '240px'
      }
    }
  },
  hideElement: {
    display: 'none'
  },
  // Configure show element and its width.
  showElement: {
    display: 'block',
    border: '1px solid #ddd',
    width: '100%',
    padding: '5px',
    textAlign: 'center',
    fontSize: '.85rem'
  }
}))

// Build and export the component.
export default function EntityUsageTabs ({ groupUsage, contentUsage }) {
  // Get the styles.
  const classes = useStyles()
  // Set the value state.
  const [value, setValue] = useState(0)
  // Set the group filter data.
  const [groupFilteredData, setGroupFilteredData] = useState(groupUsage.results)
  // Set the group footer row which contains no result text.
  const [groupFootRow, setGroupFootRow] = useState(classes.hideElement)
  // Set the content footer row which contains no result text.
  const [contentFootRow, setContentFootRow] = useState(classes.hideElement)
  // Set the no result text.
  const noMatchText = 'No matching row found'

  // Handle the tab change.
  const handleTabChange = (event, newValue) => {
    setGroupFilteredData(groupUsage.results)
    setGroupFootRow(classes.hideElement)
    setContentFootRow(classes.hideElement)
    setValue(newValue)
  }

  // Handle the group search operation.
  function handleGroupSearch (e) {
    const contentFilter = e.target.value.toLowerCase()
    let result = []
    result = groupUsage.results.filter(data => (
      JSON.stringify(data).toLowerCase().includes(contentFilter.toLowerCase())
    ))
    setGroupFilteredData(result)
    // Show or hide the no result.
    if (!result.length) {
      setGroupFootRow(classes.showElement)
    } else {
      setGroupFootRow(classes.hideElement)
    }
  }

  // Handle the content search operation.
  // As Drupal send html for content usage hence did the search differently.
  function handleContentSearch (e) {
    let td
    let i
    let j
    let txtValue
    let hiddenRow = 0
    // Get the filter text in lower case.
    const filter = e.target.value.toLowerCase()
    // Fetch all tr.
    const tr = document.querySelectorAll('div.table-responsive table tbody tr')
    // Loop through all tr.
    for (i = 0; i < tr.length; i++) {
      // Fetch all td for each tr.
      td = tr[i].getElementsByTagName('td')
      txtValue = ''
      for (j = 0; j < td.length; j++) {
        // Concatenate all td content.
        txtValue += (td[j].textContent || td[j].innerText) + '||'
      }
      // Hide all tr if content not matching.
      if (txtValue.toLowerCase().indexOf(filter) > -1) {
        tr[i].style.display = ''
      } else {
        tr[i].style.display = 'none'
        hiddenRow++
      }
    }
    // Show or hide the no result.
    if (hiddenRow === tr.length) {
      setContentFootRow(classes.showElement)
    } else {
      setContentFootRow(classes.hideElement)
    }
  }

  // Return the output.
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleTabChange}
        aria-label="Usage vertical tabs"
        className={classes.tabs}
      >
        <Tab label="Groups" {...tabProps(0)} className={classes.tab} />
        <Tab label="Content" {...tabProps(1)} className={classes.tab} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabContent}>
        {groupUsage.results.length
          ? <div>
            <SearchBar handleSearch={handleGroupSearch} />
            <div class="table-responsive-group">
              <table data-striping="1" class="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>Entity</th>
                    <th>Lifecycle State</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {groupFilteredData.map(item => (
                    <tr>
                      <td><a href={item.url.path}>{item.title}</a></td>
                      <td>{item.lifeCycle?.entity?.title || constants.filter.none}</td>
                      <td>{item.type?.entity?.title}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={groupFootRow}>{noMatchText}</div>
          </div>
          : <div className={classes.noData}>This content is not attached with any group.</div>
        }
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabContent}>
        {contentUsage.count > 0
          ? <div>
            <SearchBar handleSearch={handleContentSearch} />
            <div dangerouslySetInnerHTML={{ __html: contentUsage.usageData }} className={classes.tabContent} />
            <div className={contentFootRow}>{noMatchText}</div>
          </div>
          : <div className={classes.noData}>There is no reference entity.</div>
        }
      </TabPanel>
    </div>
  )
}
