import React, { useContext, useEffect } from 'react'
// Import necessary components.
import { Alert } from '@mui/material'
import './application.scss'
import EmbedExternalUrl from '../../EmbedExternalUrl'
import previewIcon from '../../../images/preview_application_icon.svg'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

// Build the component.
const ViewApplication = () => {
  // Get query params from url.
  const location = window.location.search
  const url = new URLSearchParams(location).get('url')
  // Set the offset height.
  const heightOffset = 250
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    // Sending page data for tracking.
    setPageData({
      type: 'view-application'
    })
  }, [])

  // Return the output.
  return (
    <div className="min_content_height bg-f7 full">
      <div className="view_application_wrapper">
        <Alert className="alert_info" severity="info" icon={<img src={previewIcon} alt="preview_icon" />}>Previewing Application</Alert>
        <div className="group_heading_wrapper">
          <div className="group_heading uppercase">Application View</div>
          <div className="group_subheading">
            <p>If you cannot view the application, <a href="/files/faq-preview-application-in-iframe.pdf" target="_blank">view this link</a> for more information to troubleshoot the issue.</p>
          </div>
        </div>
        <div className="full_screen_wrapper">
          <div className="full_screen">
            <div className="body-embed-url">
              <EmbedExternalUrl url={url} buttonTitle="View full screen" frameHeight={`${window.innerHeight - heightOffset}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Export the component.
export default ViewApplication
