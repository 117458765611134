import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from '@mui/styles'
import { Menu, MenuItem, IconButton, Tab, Tabs, Avatar } from '@mui/material'
import ProfileImage from '../../../ProfileImage'
import { downloadPhotosBatching, userAbbreviation } from '../../../ProfileImage/utility'
import InboxRoundedIcon from '@mui/icons-material/InboxRounded'
import { ReactComponent as CheckIcon } from '../../../../images/icon-checkmark.svg'
import { ReactComponent as CloseIcon } from '../../../../images/icon-close.svg'
import { ReactComponent as PeopleIcon } from '../../../../images/icon-people.svg'
import { UserContext } from '../../../context/UserContext'
import { NotificationContext } from '../../../../contexts/NotificationContext'
import { AnnouncementContext } from '../../../../contexts/AnnouncementContext'
import Loader from '../../../PageLoader'
import generateRootSectorOrGlobal from './GenerateRootSectorOrGlobal'
import '../sass/component/inbox.scss'

// Set style.
const useStyles = makeStyles(theme => ({
  menu: {
    width: '100%'
  },
  viewAll: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  avatarStyle: {
    backgroundColor: '#D8E5F0',
    color: '#333333',
    width: '34px',
    height: '34px',
    fontSize: '0.85rem',
    '& img': {
      width: '34px',
      height: '34px'
    }
  }
}))

// Build the component.
export default function Inbox () {
  // Set css.
  const classes = useStyles()
  // Set notification section closing.
  const [anchorEl, setAnchorEl] = useState(null)
  // Set tabs initial value.
  const [tabValue, setTabValue] = useState('announcements')
  // Set url history.
  const history = useNavigate()
  // Set members profile image data.
  const [profileImageData, setProfileImageData] = useState({})
  // Fetch the user context.
  const { userId, otherDetails } = useContext(UserContext)
  // Fetch data from notificationContext.
  const { notifications, notificationsCount, hasMoreNotifications, isNotificationsLoading, fetchNotificationsData } = useContext(NotificationContext)
  // Fetch data from announcementContext.
  const { isAnnouncementsLoading, announcements, totalAnnouncements, hasMoreAnnouncements, fetchAnnouncementsData, nextAnnouncements } = useContext(AnnouncementContext)
  // Set reference of scrollable div.
  const notificationRef = useRef(null)
  const announcementRef = useRef(null)
  // Go to inbox page
  const goToInboxPage = () => {
    handleClose()
    setTabValue('announcements')
    history('/inbox/announcements/all')
  }
  // Set the scroller on the top while changing tab.
  const scrollToTop = tabItem => {
    const currentRef = tabItem === 'announcements' ? announcementRef : notificationRef
    setTimeout(() => {
      currentRef.current?.scroll({
        top: 0
      })
    }, 100)
  }
  // Open the notification section.
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  // Set the notification section close.
  const handleClose = () => {
    setAnchorEl(null)
  }
  // Set the tab value.
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    if (newValue) {
      scrollToTop(newValue)
    }
  }

  // Add profile image from Graph API
  useEffect(() => {
    (async () => {
      if (nextAnnouncements) {
        // Initialize the announcements owner profile data who don't have picture uploaded in application.
        const announcementOwnerData = nextAnnouncements.filter(announcement => (announcement.owner.userPicture === null && announcement.owner.userId !== parseInt(userId) && !profileImageData[announcement.owner.userId]))
        // Initialize the profile picture object.
        let profilePicObj = {}
        // Set logged in user profile picture if he/she is member of the group.
        if (nextAnnouncements.some(announcement => announcement.owner.userId === parseInt(userId))) {
          profilePicObj = { [otherDetails.name]: otherDetails.profilePicture }
        }
        if (announcementOwnerData.length) {
          // Set user name for user profile.
          const userArray = announcementOwnerData.map(user => ({ username: user.owner.username }))
          const uniqueUserArray = [
            ...new Map(userArray.map(item => [item.username, item])).values()
          ]
          // Download and accumulate profile image.
          const response = await downloadPhotosBatching(uniqueUserArray)
          profilePicObj = { ...profilePicObj, ...response }
        }
        // Load images for owners.
        setProfileImageData(prevProfileImageData => ({ ...prevProfileImageData, ...profilePicObj }))
      }
    })()
  }, [nextAnnouncements, otherDetails.profilePicture, otherDetails.name, userId])

  // Set initial tab section.
  const filterNotificationsOrAnnouncements = tabValue === 'notifications' ? notifications : announcements
  // Set data for notification.
  const notificationMenuData =
  {
    pending: { name: 'Pending', clsName: 'people', icon: <PeopleIcon />, title: 'New Request', pathPart: 'approvals' },
    approved: { name: 'Approved', clsName: 'check', icon: <CheckIcon />, title: 'Request Approved', pathPart: 'requests' },
    denied: { name: 'Denied', clsName: 'close', icon: <CloseIcon />, title: 'Request Denied', pathPart: 'requests' }
  }
  // Set infinite scroll for announcements.
  const announcementsSection = () => {
    if (isAnnouncementsLoading) {
      return <Loader wrapperClass="inbox-loading" />
    }
    if (!totalAnnouncements) {
      return <div className='empty_list'><p>You have no announcements.</p></div>
    }
    return (
      <div className='announcements_item_wrapper' id="scrollableAnnouncements" ref={announcementRef}>
        <InfiniteScroll dataLength={announcements.length} next={fetchAnnouncementsData} hasMore={hasMoreAnnouncements} loader={<Loader type="Linear" />} scrollableTarget='scrollableAnnouncements'>
          {filterNotificationsOrAnnouncements && filterNotificationsOrAnnouncements.map(announcement => {
            const name = announcement.owner.displayName || announcement.owner.username
            const appUserPicture = announcement.owner.userPicture?.url || ''
            return <MenuItem key={announcement.nid} onClick={handleClose} className={`${classes.menu} announcements_items`}>
              <Link to={`/inbox/announcements/all/${announcement.nid}`}>
                <div className='person_icon'>
                  <Avatar className={classes.avatarStyle}>
                    <ProfileImage
                      alt={userAbbreviation(announcement.owner.username)}
                      userPicture={appUserPicture}
                      profileImage={profileImageData[announcement.owner.username] || ''}
                    />
                  </Avatar>
                </div>
                <div className='announcements_message_wrapper'>
                  <div className='name_info_wrapper'>
                    <div className='name_info'>
                      <div className='name'>
                        {name}
                        <span className='separator'>&bull;</span>
                        <span className='email'>{announcement.owner.userEmail.toLowerCase()}</span>
                      </div>
                      <div className="date">{announcement.entityChanged}</div>
                      <div className='status'>{announcement.title}</div>
                    </div>
                  </div>
                  <div className="announcement_message">{announcement.body.value.replace(/(<([^>]+)>)/ig, '')}</div>
                  <div className="tag_wrapper">
                    {generateRootSectorOrGlobal(announcement.fieldGroups?.entities, announcement.fieldIsGroupOrGlobal === 'global')}
                  </div>
                </div>
              </Link>
            </MenuItem>
          })}
        </InfiniteScroll>
      </div>
    )
  }
  // Set infinite scroll for notifications.
  const notificationSection = () => {
    if (isNotificationsLoading) {
      return <Loader wrapperClass="inbox-loading" />
    }
    if (!notificationsCount) {
      return <div className='empty_list'><p>You have no notifications to review.</p></div>
    }
    return (
      <div className='notification_item_wrapper' id="scrollableNotifications" ref={notificationRef}>
        <InfiniteScroll dataLength={notifications.length} next={fetchNotificationsData} hasMore={hasMoreNotifications} loader={<Loader type="Linear" />} scrollableTarget='scrollableNotifications'>
          {filterNotificationsOrAnnouncements && filterNotificationsOrAnnouncements.map(notification => {
            const rowStatus = notification.groupData.actionStatus.toLowerCase()
            return <MenuItem key={notification.sid} onClick={handleClose} className={`${classes.menu} notification_items`}>
              <Link to={`/inbox/${notificationMenuData[rowStatus]?.pathPart}/${notification.sid}`}>
                <div className={`request_icon ${notificationMenuData[rowStatus]?.clsName}`}>
                  {notificationMenuData[rowStatus]?.icon}
                </div>
                <div className='notification_message_wrapper'>
                  <div className='title'>
                    {notificationMenuData[rowStatus]?.title}
                  </div>
                  <div className='date'>{notification.changedAt}</div>
                  <div className='message'>
                    {rowStatus !== 'pending'
                      ? `Your request to add ${notification.groupData.groupName} has been ${rowStatus}`
                      : `${notification.owner} is requesting to add a group: ${notification.groupData.groupName}`}</div>
                </div>
              </Link>
            </MenuItem>
          })}
        </InfiniteScroll>
      </div>
    )
  }
  // Return the output.
  return (
    <>
      <IconButton onClick={handleClick}>
        <InboxRoundedIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className='inbox_menu'
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Inbox' className="box-title" onClick={goToInboxPage} />
          <Tab label='Announcements' value='announcements' />
          <Tab label='Notifications' value='notifications' />
        </Tabs>
        <div>
          {filterNotificationsOrAnnouncements &&
            <div className='list_loader'>
              {tabValue === 'notifications' ? notificationSection() : announcementsSection()}
            </div>
          }
          <div className={`viewall_wrapper ${classes.viewAll}`}>
            <div onClick={handleClose}>  <Link to={`/inbox/${tabValue === 'notifications' ? 'requests' : 'announcements/all'}`}> View All </Link></div>
          </div>
        </div>
      </Menu>
    </>
  )
}
