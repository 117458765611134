import { constants } from '../../lib'

// Handle change main method.
export const handleChangeFor = (category, params, location, queryString) => value => {
  let newParams
  // Check if category is object.
  if (typeof (category) === 'object') {
    // Calling handle change for object values
    newParams = handleChangeForObject(category, params)
  } else {
    // Calling handle change for other values.
    newParams = handleChangeForOtherValues(category, params, value)
  }
  // Prepare the new url and redirect.
  const returnURL = `${location.pathname}?${queryString.stringify(newParams)}`
  return (
    {
      newParams,
      returnURL
    }
  )
}
// Handle change for object values.
const handleChangeForObject = (category, params) => {
  let newParams = { ...params }
  for (const [categoryKey, categoryValue] of Object.entries(category)) {
    // Remove parameter if blank else add/update.
    if (!categoryValue) {
      delete newParams[categoryKey]
    } else {
      newParams = { ...newParams, [categoryKey]: categoryValue }
    }
  }
  return { ...newParams, offset: 0 }
}
// Handle change for other values.
const handleChangeForOtherValues = (category, params, value) => {
  let resetOffset = false
  let newParams
  // Opt for reset offset if value changed.
  if (category !== 'offset' && typeof (params[category]) !== 'undefined' && params[category] !== value) {
    resetOffset = true
  }
  // Remove parameter if blank else add/update.
  if (typeof (params[category]) !== 'undefined' && typeof value === 'string' && value.trim() === '') {
    newParams = params
    delete newParams[category]
  } else {
    newParams = { ...params, [category]: value }
  }
  // Reset offset if it is opt for or limit changed.
  if (category === 'limit' || (category !== 'offset' && (typeof (params[category]) === 'undefined' || resetOffset))) {
    newParams = { ...newParams, offset: 0 }
  }
  // To append keyword if exists.
  if (typeof (params?.keyword) !== 'undefined') {
    newParams = { ...newParams, keyword: params.keyword }
  }
  return newParams
}

// Convert date format.
export const formatDate = (dateValue, dateFormat) => {
  const year = dateValue.getFullYear()
  const month = dateValue.getMonth() + 1
  const date = dateValue.getDate()
  // Padded with leading 0 for single digit month and date.
  const formattedMonth = month <= 9 ? '0' + month : month
  const formattedDate = date <= 9 ? '0' + date : date
  // Default date format.
  let formattedDateValue = `${formattedMonth}-${formattedDate}-${year}`
  if (dateFormat === 'yyyy-mm-dd') {
    formattedDateValue = `${year}-${formattedMonth}-${formattedDate}`
  }
  return formattedDateValue
}

// Various date calculations to use in date filter.
// Yesterday's timestamp.
const latestTimeStamp = new Date().setDate(new Date().getDate() - 1)
// Yesterday's date.
export const latestDate = new Date(latestTimeStamp)
// 3 years ago from yesterday's timestamp.
const earliestTimeStamp = new Date(latestTimeStamp).setFullYear(latestDate.getFullYear() - 3)
// 3 years ago from yesterday's date.
export const earliestDate = new Date(earliestTimeStamp)
// 3 years ago from yesterday's year.
export const earliestDateYear = earliestDate.getFullYear()

// Function to determine Insights utility object.
export const insightsUtilityObject = insightValue => {
  switch (insightValue) {
    case 'esp':
      return ({
        contentType: 'ESP',
        insightQuery: constants.drupalQueryIds.ESPInsightQuery,
        logo: true
      })
    case 'loc':
      return ({
        contentType: 'LOC',
        insightQuery: constants.drupalQueryIds.LOCInsightQuery,
        logo: false
      })
    case 'crsa':
      return ({
        contentType: 'CRSA',
        insightQuery: constants.drupalQueryIds.CRSACInsightQuery,
        logo: false
      })
    default:
      return null
  }
}
