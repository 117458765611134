import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import './manage-group.scss'
import MoveGroup from './MoveGroup'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { Alert, Stack, List, ListItem, ListItemText } from '@mui/material'
import { UserContext } from '../../../context/UserContext'
import Loader from '../../../PageLoader'
import EditGroup from './EditGroup'
import { usePostMessage } from '../../../../hooks'
import DeleteGroup from './DeleteGroup'
import BusinessTeam from './BusinessTeam'
import SetGroupItemsOrder from './SetGroupItemsOrder'
import { NavBarContext } from '../../../../contexts/NavBarContext'

const ManageGroup = () => {
  // Set breadCrumb array.
  const [breadCrumb, setBreadCrumb] = useState([])
  // Set isLoading.
  const [isLoading, setIsLoading] = useState(true)
  // Set groupData.
  const [groupData, setGroupData] = useState()
  // Extract gid and task from path parameters.
  const { gid, task } = useParams()
  // Set eligible groupIds.
  const [eligibleGIDs, setEligibleGIDs] = useState([])
  // Fetch the user context.
  const { userId } = useContext(UserContext)
  // Set manage privilege.
  const [hasManagePriv, setHasManagePriv] = useState(false)
  // Set shouldReload.
  const [shouldReload, setShouldReload] = useState(true)
  // Get feedbackMessage from hook.
  const [feedbackMessage, setFeedbackMessage, handleEvent] = usePostMessage(setShouldReload)
  // Set redirectPath.
  const [redirectPath, setRedirectPath] = useState('')
  // Set hasError.
  const [hasError, setHasError] = useState(false)
  // Set groupMembers.
  const [groupMembers, setGroupMembers] = useState()
  // Set group temp data.
  const [groupTempData, setGroupTempData] = useState({})
  // Set the page count.
  const [pageCount, setPageCount] = useState(0)
  // Set the current page.
  const [currentPage, setCurrentPage] = useState(1)
  // Set content loading state.
  const [isContentLoading, setIsContentLoading] = useState(false)
  // NavBarContext method.
  const { setUrlPath } = useContext(NavBarContext)

  // Set url path for navbar.
  useEffect(() => {
    if (breadCrumb && breadCrumb?.length > 1) {
      setUrlPath(breadCrumb[1]?.url?.path)
    }
  }, [breadCrumb, setUrlPath, task])

  // Fetch first page contents.
  useEffect(() => {
    if (shouldReload) {
      setCurrentPage(1)
      // Generate the payload.
      const groupVariable = JSON.stringify({
        id: gid.toString()
      })
      // Prepare eligible group query variable.
      const uid = JSON.stringify({ uid: userId })
      // Prepare eligible group query.
      const gidQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupUserGroupsByUidRA}&variables=${uid}`
      // Generate the query url.
      const groupQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupOverviewQuery}&variables=${groupVariable}`
      // Query Call.
      queryContent(gidQuery)
        .then(res => {
          const eids = res.data.groupUserGroupsByUidRA.results.map(x => x.gid)
          setEligibleGIDs(eids)
          if (eids.some(id => id === gid)) {
            setHasManagePriv(true)
          } else {
            setHasManagePriv(false)
          }
          // Query Call.
          queryContent(groupQuery)
            .then(response => {
              setBreadCrumb([
                ...response?.data?.groupById?.entityBreadCrumb,
                { text: 'Manage Group' }
              ])
              let totalPages = 0
              setGroupMembers(response?.data?.groupMembersById)
              setGroupData(response.data)
              // Retrieve the number of pages & set it.
              if (response?.data?.groupContentByGidList.count > 0) {
                totalPages = Math.floor(response?.data?.groupContentByGidList.count / constants.misc.defaultGroupContentCount)
                if (totalPages > 0) {
                  setGroupTempData(response.data)
                }
                setPageCount(totalPages)
              }
            })
            .catch(err => {
              console.log(err)
              setHasError(true)
            })
            .finally(() => setIsLoading(false))
        })
        .catch(err => {
          console.log(err)
          setHasError(true)
        })
        .finally(() => setShouldReload(false))
    }
  }, [gid, userId, shouldReload])

  // Fetch group content list from 2nd page onwards.
  useEffect(() => {
    if (pageCount > 0 && currentPage <= pageCount) {
      const groupVariable = JSON.stringify({
        id: gid.toString(),
        page: currentPage
      })
      setIsContentLoading(true)
      // Generate the query url.
      const groupQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.groupContentListQuery}&variables=${groupVariable}`
      // Query Call
      queryContent(groupQuery)
        .then(response => {
          // Combine all temp data.
          const clonedGroupData = groupTempData
          if (!groupTempData.groupContentByGidList?.results) {
            clonedGroupData.groupContentByGidList.results = response?.data.groupContentByGidList.results
          } else {
            clonedGroupData.groupContentByGidList.results = groupTempData.groupContentByGidList.results.concat(response?.data.groupContentByGidList.results)
          }
          if (currentPage === pageCount) {
            // All content loaded.
            setIsContentLoading(false)
          } else {
            // Incrementing current page.
            setCurrentPage(currentPage + 1)
          }
          setGroupData({ ...clonedGroupData })
        })
        .catch(err => {
          console.log(err)
          setHasError(true)
        })
    }
  }, [pageCount, currentPage, gid, groupTempData])

  // Define all left menu items.
  const commonUrl = `/manage-group/${gid}`
  const menuItems = [
    { label: 'Group Details', url: `${commonUrl}/edit`, associatedTask: 'edit' },
    { label: 'Business Team', url: `${commonUrl}/business-team`, associatedTask: 'business-team' },
    { label: 'Move Group', url: `${commonUrl}/move`, associatedTask: 'move' },
    { label: 'Delete Group', url: `${commonUrl}/delete`, associatedTask: 'delete' },
    { label: 'Set Default Group View', url: `${commonUrl}/set-group-order`, associatedTask: 'set-group-order' },
    { label: 'Set Default Application View', url: `${commonUrl}/set-application-order`, associatedTask: 'set-application-order' }
  ]

  // Function to generate body depending on the task.
  const generateBody = () => {
    switch (task) {
      case 'move': {
        return <MoveGroup id={gid} userId={userId} handleEvent={handleEvent} eligibleGIDs={eligibleGIDs} setShouldReload={setShouldReload} setRedirectPath={setRedirectPath} setFeedbackMessage={setFeedbackMessage} crumbs={breadCrumb} />
      }
      case 'business-team': {
        return <BusinessTeam gid={gid} groupMembers={groupMembers} setShouldReload={setShouldReload} setFeedbackMessage={setFeedbackMessage} groupName={groupData?.groupById?.entityLabel} crumbs={breadCrumb} />
      }
      case 'edit': {
        return <EditGroup gid={gid} crumbs={breadCrumb} />
      }
      case 'delete': {
        return <DeleteGroup id={gid} setFeedbackMessage={setFeedbackMessage} crumbs={breadCrumb} />
      }
      case 'set-group-order': {
        return <SetGroupItemsOrder gid={gid} groupContent={groupData.groupById?.childGroups?.entities} setFeedbackMessage={setFeedbackMessage} crumbs={breadCrumb} />
      }
      case 'set-application-order': {
        return <SetGroupItemsOrder isApplication='application' gid={gid} groupContent={groupData?.groupContentByGidList?.results} crumbs={breadCrumb} setFeedbackMessage={setFeedbackMessage} />
      }
      default: {
        return <div />
      }
    }
  }
  // Display error if there.
  if (hasError) {
    return (<div className="min_content_height bg-f7">
      <div className="managegroup_page_wrapper row">
        <div className="managegroup_page_wrapper_section" dangerouslySetInnerHTML={{ __html: constants.errors.server }} />
      </div>
    </div>)
  }
  // Return the output.
  return (
    <div className="min_content_height bg-f7">
      {isLoading
        ? <Loader />
        : (<>
          <div className="page_header_wrapper">
            {feedbackMessage?.message && <div className="fab_details_alert">
              <Alert severity={feedbackMessage.type} icon={feedbackMessage?.icon} >
                {feedbackMessage?.message} &nbsp;
                {feedbackMessage?.label && <Link to={redirectPath}>{feedbackMessage?.label}</Link>}
              </Alert>
            </div>}
            <div className="breadcrumb_section_wrapper">
              <ul className="breadcrumb_section">
                {breadCrumb &&
                  breadCrumb?.map((item, index) => (
                    <li key={index} className="breadcrumb_item">
                      {index < breadCrumb.length - 1
                        ? (<>
                          <Link to={item?.url?.path}>{item?.text}</Link>
                          <span className="breadcrumb_icon">
                            <ChevronRightIcon />
                          </span>
                        </>)
                        : item?.text
                      }
                    </li>
                  ))}
              </ul>
            </div>
            <div className="page_header_inner">
              <div className="page_header_heading">Manage Group</div>
            </div>
          </div>
          <div className="managegroup_page_wrapper row">
            <div className="managegroup_page_wrapper_section">
              {
                hasManagePriv
                  ? (<Stack direction={{ xs: 'column', sm: 'row' }}>
                    <div className="managegroup_left_section col-3">
                      <div className="managegroup_menu_wrapper">
                        <List className="managegroup_menu">
                          {
                            menuItems.map((item, index) => (
                              <ListItem key={index} disablePadding className={task === item.associatedTask ? 'active-menu' : ''}>
                                <ListItemText children={<Link to={item.url}>{item.label}</Link>} />
                              </ListItem>
                            ))
                          }
                        </List>
                      </div>
                    </div>
                    <div className="managegroup_right_section">
                      {task === 'set-application-order' && isContentLoading ? <><Loader type="Linear" /><Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={isContentLoading} invisible={true}></Backdrop></> : null}
                      <div className="managegroup_section_wrapper">
                        {generateBody()}
                      </div>
                    </div>
                  </Stack>)
                  : (<div className="managegroup_access_wrapper">
                    <div className="message">
                      You do not have access to manage this group
                    </div>
                    <Link className="message_link" to={groupData?.groupById?.url?.path}>Back to {groupData?.groupById?.entityLabel}</Link>
                  </div>)}
            </div>
          </div>
        </>)}
    </div>
  )
}

// Export the component for use.
export default ManageGroup
