import React, { useState, useEffect, useContext } from 'react'
import { Button, TextareaAutosize, FormLabel } from '@mui/material'
import greenIconPeople from '../../../../images/green-icon-people.svg'
import redIconPeople from '../../../../images/red-icon-people.svg'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import Loader from '../../../PageLoader'
import RecordCard from '../Common/RecordCard'
import { constants } from '../../../../lib'
import { NotificationContext } from '../../../../contexts/NotificationContext'

const MyComments = ({ sid, setFeedbackMessage, setShouldNotificationReload }) => {
  // Set initialValidationStatus.
  const initialValidationStatus = { comments: '' }
  // Set initialFormData.
  const initialFormData = { comment: '' }
  // Set form data.
  const [formData, setFormData] = useState(initialFormData)
  // Set error message.
  const [errorMsgs, setErrorMsgs] = useState(initialValidationStatus)
  // Set step1FormSubmitted.
  const [isSubmitted, setIsSubmitted] = useState(false)
  // Set is loading.
  const [isLoading, setIsLoading] = useState(false)
  // Store previous value from my account.
  const [previousValue, setPreviousValue] = useState(sid)
  // Store current value
  const [currentValue, setCurrentValue] = useState()
  // Store current value true or false
  const [defaultValue, setDefaultValue] = useState(true)
  // If API failed show error message below off the comment
  const [commentErrorMessage, setCommentErrorMessage] = useState('')
  // Send approval or denied data to RecordCard.
  const [approvalData, setApprovalData] = useState({})
  // Fetch the Notification context data.
  const { setOffSet, setHasMoreNotifications } = useContext(NotificationContext)
  // Initiate some constant value.
  const approved = 'Approved'
  const denied = 'Denied'

  const validateForm = () => {
    // Initiate values.
    let validationErrors = initialValidationStatus
    let hasError = false
    const allComments = formData.comment.trim()

    if (!allComments?.length) {
      hasError = true
      validationErrors = {
        ...validationErrors,
        comments: 'Please enter comments.'
      }
    }

    // Return based on there are any error or not.
    if (hasError) {
      setErrorMsgs(validationErrors)
      return false
    } else {
      setErrorMsgs(initialValidationStatus)
      return true
    }
  }
  // Submit handler to continue in next step.
  const acceptHandler = acceptOrDeny => {
    setIsSubmitted(true)
    if (validateForm()) {
      // Set loading before submit.
      setIsLoading(true)
      const url = `${process.env.REACT_APP_TARGET_URL}/api/group-request-action`
      // Set the payload data.
      const data = {
        sid,
        action_comment: formData.comment,
        action_status: acceptOrDeny
      }
      // To track if logged out.
      let haveResponse = false
      // Call the api.
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(data)
      })
        .then(res => {
          haveResponse = true
          return res.json()
        })
        .then(res => {
          // Set default value
          setDefaultValue(true)
          if (res.error) {
            // Set api failed
            setCommentErrorMessage(res.errorMsg)
          } else {
            // Assign fetching data
            const getData = res.response.data
            // Set group name
            const { groupName } = getData
            // Set previous value
            setPreviousValue(sid)
            // Set current value
            setCurrentValue(sid)
            // Update to fetching data
            setApprovalData(getData)
            // Set notification context reload.
            setHasMoreNotifications(false)
            setOffSet(0)
            setTimeout(() => {
              setHasMoreNotifications(true)
            }, 500)
            // Set index notification Reload.
            setShouldNotificationReload(true)
            // Show header message in my account header.
            if (acceptOrDeny === approved) {
              setFeedbackMessage({ message: `${approved} ${groupName} to be added`, type: 'success', icon: <img src={greenIconPeople} alt='memberIcon' /> })
            } else {
              setFeedbackMessage({ message: `${denied} ${groupName}`, type: 'error', icon: <img src={redIconPeople} alt='memberIcon' /> })
            }
          }
        })
        .catch(err => {
          if (!haveResponse) {
            window.location.reload()
          }
          console.log(err.message)
          setCommentErrorMessage(constants.errors.server)
        })
        .finally(() => setIsLoading(false))
    }
  }
  // Validate comment form data if comments empty submitted.
  useEffect(() => {
    if (isSubmitted && formData) {
      validateForm()
    }
  }, [formData, isSubmitted])

  if (isLoading) {
    // Return the loader.
    return <Loader wrapperClass='no-class' />
  }
  return (
    <div>
      {previousValue !== currentValue && defaultValue && (
        <div>
          <div className='mycomment-wrapper'>
            <FormLabel component='legend' className='comment-label'>
              Enter a comment before accepting or denying *
            </FormLabel>
            <TextareaAutosize
              id='user-comment'
              className={errorMsgs?.comments ? 'fieldError comment-textarea' : 'comment-textarea'}
              aria-label='minimum height'
              minRows={1}
              value={formData.comment}
              onChange={event =>
                setFormData({ ...formData, comment: event.target.value })
              }
              placeholder='Enter a brief description of why you are denying this group'
              required
              disabled={false}
              maxRows={2}
            />
            {errorMsgs.comments && (
              <span className='error-desc'>{errorMsgs.comments}</span>
            )}
          </div>
          <div className='btn-wrapper-comment'>
            <Button id='send-email' variant='contained' color='primary' onClick={() => acceptHandler(approved)} startIcon={<CheckCircleSharpIcon />}>
              Accept
            </Button>
            <Button color='primary' variant='outlined' onClick={() => acceptHandler(denied)} startIcon={<CancelSharpIcon />}>
              Deny
            </Button>
          </div>
          <div className='comment_error_msg' dangerouslySetInnerHTML={{ __html: commentErrorMessage }} />
        </div>
      )}
      {previousValue === currentValue && defaultValue && (
        <RecordCard approvalData={approvalData} />
      )}
    </div>
  )
}

// Export the component for use.
export default MyComments
