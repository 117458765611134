import { constants } from '../../../../lib'

const nidAccess = new Map()
let idleTimeCheckAccess = 0
let intervalFunc
let counterStarted = false

// Check access for current user against node id
export function checkAccess (id) {
  // Cache the access for idleTimeLimit
  if (!counterStarted) {
    intervalFunc = setInterval(checkAccessTimerIncrement, 60000)
    counterStarted = true
  }
  let accessObj = {}
  if (!id) {
    return Promise.resolve({ access: true, item: 0 })
  }
  idleTimeCheckAccess = 0
  if (nidAccess.has(id)) {
    // If the data exist in cache return from there.
    return Promise.resolve(nidAccess.get(id))
  } else {
    // Call the api to check access.
    return fetch(`${process.env.REACT_APP_TARGET_URL}/api/access/node/${id}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    }).then(res => {
      const status = res.status
      // If page is unaccessible or not exist.
      if (status > 200) {
        accessObj = { access: false, error: 404 }
        if (status === 403) {
          accessObj = { access: false, error: 403, item: id }
        }
        nidAccess.set(id, accessObj)
        return accessObj
      } else {
        return res.json()
      }
    }).then(res => {
      accessObj = { access: res.access, item: id }
      // If user don't have access or the node not exist.
      if (res.code > 200) {
        accessObj.error = res.code
      }
      if (res.code > 403) {
        delete accessObj.item
      }
      nidAccess.set(id, accessObj)
      // Return the access data.
      return accessObj
    }).catch(() => {
      accessObj = { access: false, error: 404 }
      nidAccess.set(id, accessObj)
      return accessObj
    })
  }
}
// Execute the api call.
export function queryContent (query) {
  // Audit Query Call
  return fetch(query, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
  })
    .then(response => response.json())
    .catch(err => err)
}

// Clear access cache if idleTimeLimit exceed.
const checkAccessTimerIncrement = () => {
  idleTimeCheckAccess += 1
  if (idleTimeCheckAccess > constants.misc.idleTimeLimit) {
    nidAccess.clear()
    clearInterval(intervalFunc)
    counterStarted = false
  }
}
