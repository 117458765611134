import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Tab, Tabs, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import queryString from 'query-string'
import itemImage from '../../../../images/processDef.png'
import IframeResizer from 'iframe-resizer-react'
import { constants } from '../../../../lib'
import './ProcessCT.scss'
import { UserContext } from '../../../context/UserContext'
import { checkAccess, queryContent } from '../CTHelpers/CheckAccess'
import { CTErrorPage, makeNoAccessRows } from '../CTHelpers/CTErrorPage'
import Loader from '../../../PageLoader'
import EmbedExternalUrl from '../../../EmbedExternalUrl'
import ContentManageButton from '../../contentManageButton'
import EmbedShoppingCart from '../../../ShoppingCart/embedShoppingCart'
import EntityUsageTabs from '../../entityUsageTabs'
import { generateSearchUrl } from '../../../Search/SearchUtil'

// Use withStyles to build the tab other than last tab.
const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    borderRight: '1px solid grey',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)

// Use withStyles to build the last tab.
const LastTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: '.65rem'
  }
}))(props => <Tab disableRipple {...props} />)

// Create some style.
const hideElement = {
  display: 'none'
}

const showElement = {
  display: 'block'
}

// Export entire process data.
export const ProcessCT = ({ id }) => {
  const CTName = 'Process'
  // Define the tabs.
  const pageTabs = { process: 0, webform: 1, metadata: 2, associations: 3 }
  // Set data to be used.
  const [data, setData] = useState()
  // Set reference entity usage/association data.
  const [usageData, setUsageData] = useState()
  // Set loading state.
  const [isLoading, setIsLoading] = useState(true)
  // Set webform loading state.
  const [formLoading, setFormLoading] = useState(true)
  // Set the form style state.
  const [formStyle, setFormStyle] = useState(hideElement)
  // Set if have loading error.
  const [hasLoadingError, setHasLoadingError] = useState(false)
  // Set if has access or not.
  const [onlyNoAccess, setOnlyNoAccess] = useState(false)
  // Set if have availability error.
  const [notAvailable, setNotAvailable] = useState()
  // Set if have null value error.
  const [hasNullValue, setHasNullValue] = useState(false)
  // Set the selected tab.
  const [selectedTab, setSelectedTab] = useState(0)
  // Set the webform url.
  const [webformUrl, setWebformUrl] = useState('')
  // Fetch the user context.
  const { userRoles, userName, otherDetails } = useContext(UserContext)
  const location = useLocation()
  const history = useNavigate()
  // Get the hash for tab.
  const parsedHash = Object.keys(queryString.parse(location.hash))
  let initialTab = 0
  if (parsedHash.length && typeof (pageTabs[parsedHash[0]]) !== 'undefined') {
    initialTab = pageTabs[parsedHash[0]]
  }
  // Handle tab change.
  const handleChange = (event, newValue) => {
    if (initialTab !== newValue) {
      setFormLoading(true)
      setFormStyle(hideElement)
      initialTab = newValue
      location.hash = Object.keys(pageTabs).find(key => pageTabs[key] === newValue)
      history(location)
    }
  }

  // Set selected tab based on hash.
  useEffect(() => {
    setSelectedTab(initialTab)
  }, [initialTab])

  // Check node access when ids are available.
  useEffect(() => {
    if (!id) {
      setHasNullValue(true)
      setIsLoading(false)
    } else {
      const promise = checkAccess(id)
      const show = []
      const hide = []
      promise.then(obj => {
        // Show data if have access
        if (obj.access) {
          show.push(obj.item)
        } else {
          // Hide data or generate error base on code received
          if (obj.error === 403) {
            hide.push(obj.item)
          }
          if (obj.error === 404) {
            setHasLoadingError(true)
            setIsLoading(false)
          }
        }
      }).then(() => {
        // If user have access.
        if (show && show.length > 0) {
          // Build the payload for api.
          const processCTVariable = JSON.stringify({
            id: show[0]
          })
          // Build the query URL string & call to fetch data.
          const processCTQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.processQueryCT}&variables=${processCTVariable}`
          // Query Call
          queryContent(processCTQuery)
            .then(response => {
              if (!response?.data?.nodeQuery?.count) {
                setHasLoadingError(true)
              } else {
                // Set data based on nodeQuery.
                setData(response.data.nodeQuery.entities[0])
                // Set usage/association data based on response.
                setUsageData(response.data.entityUsageContent.results[0])
              }
            })
            .catch(() => setHasLoadingError(true))
            .finally(() => setIsLoading(false))
        } else {
          if (!hide || hide.length === 0) {
            setHasLoadingError(true)
            setIsLoading(false)
          } else {
            // Generate proper user information.
            const userInfo = userName || otherDetails.name
            // Fetch no access html.
            const noAccessRows = makeNoAccessRows(CTName, hide, userInfo)
            setOnlyNoAccess(true)
            setNotAvailable(noAccessRows)
            setIsLoading(false)
          }
        }
      })
    }
  }, [id, userName, otherDetails.name])

  // Generate the webform url with parent query string if exist.
  useEffect(() => {
    if (data && data?.fieldEmbedWebform?.uri) {
      const paramString = queryString.parse(location.search.replace('?', ''))
      if (Object.keys(paramString).length) {
        const url = new URL(data.fieldEmbedWebform?.uri)
        const queryParams = url.searchParams
        Object.keys(paramString).forEach(key => {
          queryParams.set(key, paramString[key])
        })
        url.search = queryParams.toString()
        setWebformUrl(url.toString())
      } else {
        setWebformUrl(data.fieldEmbedWebform?.uri)
      }
    }
  }, [data, location.search])

  // webform load completed.
  const formLoaded = () => {
    setFormLoading(false)
    setFormStyle(showElement)
  }

  // Display error message if null value received.
  if (hasNullValue && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.nullValue }} align='center' />
    // Return the error page.
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }

  // Display error message if don't have access.
  if (onlyNoAccess && !isLoading && notAvailable) {
    // Return the error page.
    return (
      <CTErrorPage ct={`${CTName}`} message={notAvailable} />
    )
  }

  // Display error message if there is some loading error.
  if (hasLoadingError && !isLoading) {
    const message = <Typography dangerouslySetInnerHTML={{ __html: constants.errors.invalidContent }} align='center' />
    // Return the error page.
    return (
      <CTErrorPage ct={`${CTName}`} message={message} />
    )
  }

  // Show loader if data fetching in progress.
  if (isLoading) {
    // Return the loader.
    return <Loader />
  }

  // Return entire data for display purpose.
  // Tabs used:
  // - Process
  // - Webform (optional)
  // - Metadata
  // - Associations
  // Display manage button based on user role.
  return !isLoading && data && (
    <div className='content-home'>
      <div className='content-company-name'>
        <img className='content-image' src={itemImage} alt={data.title} />
        <div display='flex' flex-direction='column'>
          <div className='process-title'>
            {data.title}
          </div>
        </div>
        {
          userRoles.administrator ||
            userRoles.contentAuthor ||
            userRoles.feedEngineer ||
            userRoles.groupAdmin
            ? <ContentManageButton nid={id} />
            : <div />
        }
        <EmbedShoppingCart item={data} image={itemImage} bundle="process" />
      </div>
      <>
        <Tabs variant='fullWidth' value={selectedTab} onChange={handleChange} indicatorColor='primary' className='processTabs'>
          <StyledTab label='Process' />
          {data?.fieldEmbedWebform != null ? <StyledTab label='Webform' /> : <StyledTab label='Webform' style={hideElement} />}
          <StyledTab label='Metadata' />
          <LastTab label='Associations' />
        </Tabs>
        {selectedTab === 0 &&
          <div className='content-home-wrapper content-data'>
            <div className="content-home-info--full-column">
              <Typography className="body-embed-url" color='primary'>
                {data.embedExternalUrl?.uri != null ? <EmbedExternalUrl url={data.embedExternalUrl?.uri} /> : ''}
                {data.processDescription?.value != null ? <div dangerouslySetInnerHTML={{ __html: data.processDescription?.value }} /> : ''}
              </Typography>
            </div>
          </div>
        }
        {selectedTab === 1 &&
          <div className='content-home-info--full-column'>
            {formLoading ? <Loader /> : null}
            <Typography className='field' style={formStyle}>
              <IframeResizer
                src={webformUrl}
                style={{ width: '1px', minWidth: '100%' }}
                className="webform-share-iframe"
                frameBorder="0"
                allow="geolocation microphone camera"
                allowtransparency="true"
                onLoad={formLoaded}
              />
            </Typography>
          </div>
        }
        {selectedTab === 2 &&
          <div className='content-home-wrapper content-data'>
            <div className="content-home-info--full-column">
              <div className='content-home-info--space-apart'>
                <Typography className='fieldTitle'>
                  Life Cycle
                </Typography>
                <Typography className='field'>
                  {
                    data?.fieldLifeCycle?.entity?.title
                      ? <Link to={generateSearchUrl(CTName, 'content__field_life_cycle__entity__name', data?.fieldLifeCycle?.entity?.title)}>{data?.fieldLifeCycle?.entity?.title}</Link>
                      : constants.filter.none
                  }
                </Typography>
                <Typography className='fieldTitle'>
                  System
                </Typography>
                <Typography className='field'>
                  {
                    data?.fieldSystem?.length > 0
                      ? data.fieldSystem.map(item => (<Link to={generateSearchUrl(CTName, 'content__field_system__entity__title', item.entity.title)}>{item.entity.title}</Link>)).reduce((prev, curr) => [prev, ', ', curr])
                      : constants.filter.none
                  }
                </Typography>
                <Typography className='fieldTitle'>
                  Groups
                </Typography>
                <Typography className='field'>
                  {
                    data?.groups?.results.length > 0
                      ? data.groups.results.map(item => <Link to={item.url.path}>{item.title}</Link>).reduce((prev, curr) => [prev, ', ', curr])
                      : constants.filter.none
                  }
                </Typography>
                <Typography className='fieldTitle'>
                  Process Input
                </Typography>
                <Typography className='field'>
                  {
                    data?.fieldProcessInput?.length > 0
                      ? <ul>{data.fieldProcessInput.map((item, key) => <li key={key}><Link to={item.entity.url.path}>{item.entity.title}</Link></li>)}</ul>
                      : constants.filter.none
                  }
                </Typography>
                <Typography className='fieldTitle'>
                  Process Owners
                </Typography>
                <Typography className='field'>
                  {
                    data?.fieldProcessOwner?.entity?.title
                      ? <Link to={data?.fieldProcessOwner?.entity?.url.path}>{data?.fieldProcessOwner?.entity?.title}</Link>
                      : constants.filter.none
                  }
                </Typography>
                <Typography className='fieldTitle'>
                  Natural Key
                </Typography>
                <Typography className='field'>
                  {data.naturalKey || constants.filter.none}
                </Typography>
                <Typography className='fieldTitle'>
                  Type of Analytics Applied
                </Typography>
                <Typography className='field'>
                  {
                    data?.fieldTypeOfAnalyticsApplied?.length > 0
                      ? data.fieldTypeOfAnalyticsApplied.map(item => (<Link to={generateSearchUrl(CTName, 'content__field_type_of_analytics_applied__entity__name', item.entity.title)}>{item.entity.title}</Link>)).reduce((prev, curr) => [prev, ', ', curr])
                      : constants.filter.none
                  }
                </Typography>
              </div>
            </div>
          </div>
        }
        {selectedTab === 3 &&
          <EntityUsageTabs groupUsage={data?.groups} contentUsage={usageData} />
        }
      </>
    </div>
  )
}
