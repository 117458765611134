import React, { useState } from 'react'
import { Radio, RadioGroup, FormControl, FormControlLabel, Typography } from '@mui/material'
import './FacetFields.scss'

/**
 *
 * @param {function} onChange - Function callback for changing dropdown selected value
 * @param {array} radioOptions - Array of strings holding possible values for dropdown menu items
 * @param {string} title - String containing the name of this radio facet - displayed in <Typography> over Radio element
 * @param {string} defaultOverride - default value for activeItem, stored as current value of filter in Search page
 *
 * @return {Object} React component representing a Radio element
 */
export default function RadioFacetField ({ onChange, radioOptions, title, defaultOverride }) {
  // Set default active value.
  if (!Object.prototype.hasOwnProperty.call(radioOptions, defaultOverride)) {
    defaultOverride = Object.keys(radioOptions)[0]
  }
  // Set the active item.
  const [activeItem, setActiveItem] = useState(defaultOverride)
  // Handle the change.
  const handleChange = event => {
    onChange(event.target.value)
    setActiveItem(event.target.value)
  }
  // Return the output.
  return (
    <FormControl className="search-facet-wrapper">
      <Typography className="heading-radio">
        {title}
      </Typography>
      <RadioGroup aria-label="radio-label" name={title} value={defaultOverride || activeItem} onChange={handleChange}>
        {Object.keys(radioOptions).filter(item => !radioOptions[item]?.hideDisplay).map((type, idx) => (
          <FormControlLabel key={`${type}-${idx}`} value={type} control={<Radio color="primary" size="small" />} label={radioOptions[type].label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
