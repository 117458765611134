import React from 'react'
import './SearchError.scss'

// Build and export search error for result not found.
export default function SearchError ({ params }) {
  // Return the output.
  return (
    <div>
      <div className='error-message'>Your search {params} did not match any content.</div>
      <div className='suggestion-header'>Suggestions:</div>
      <li className='suggestion'>Make sure all words are spelled correctly.</li>
      <li className='suggestion'>Try different keywords.</li>
      <li className='suggestion'>Try more general keywords.</li>
      <li className='suggestion'>Verify search criteria is correct.</li>
    </div>
  )
}
