import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import { constants } from '../../../../lib'
import { queryContent } from '../../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import Loader from '../../../PageLoader'
import { useNavigate } from 'react-router-dom'
import memberIconRed from '../../../../images/red-icon-people.svg'
import { EventTrackingContext } from '../../../../contexts/EventTrackingContext'

const DeleteGroup = ({ id, setFeedbackMessage, crumbs }) => {
  // Set inProgress.
  const [inProgress, setInProgress] = useState(false)
  // Set formLoading.
  const [formLoading, setFormLoading] = useState(true)
  // Set groupName.
  const [groupName, setGroupName] = useState('')
  // Set eligibleForDelete.
  const [eligibleForDelete, setEligibleForDelete] = useState(true)
  // Set object from useNavigate hook.
  const navigate = useNavigate()
  // Set shouldReload.
  const [hasError, setHasError] = useState(false)
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    // Prepare move group query variable.
    const gid = JSON.stringify({ id })

    // Prepare eligible group query.
    const moveGroupDetailQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.moveGroupDetailQuery}&variables=${gid}`

    // Query Call.
    queryContent(moveGroupDetailQuery)
      .then(response => {
        // Set all group related properties.
        setGroupName(response.data.groupById.title)
        // Check if group is eligible to be deleted.
        if (response.data.groupById.childGroups.count) {
          setEligibleForDelete(false)
        }
        setFormLoading(false)
        // Sending page data for tracking.
        setPageData({
          type: 'delete-group',
          breadCrumbs: [...crumbs.slice(0, crumbs.length - 1), { text: 'Delete Group' }],
          page_id: id
        })
      })
      .catch(err => {
        console.log(err)
        setHasError(true)
      })
      .finally(() => {
        setFormLoading(false)
      })
  }, [id])

  // Function to fire query for deleting selected group.
  const deleteGroup = () => {
    // Setting in progress as true.
    setInProgress(true)
    // Set API URL for notificationCount query.
    const query = `${process.env.REACT_APP_TARGET_URL}/api/delete-group`
    // To track if logged out.
    let haveResponse = false
    // Firing the query.
    fetch(query, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        gid: id
      })
    })
      .then(response => {
        haveResponse = true
        return response.json()
      })
      .then(res => {
        if (!res.error) {
          // Set the success message in localStorage.
          localStorage.setItem('feedbackMessage', JSON.stringify({ type: 'error', section: 'manage-group', message: res.msg }))
          // Redirect to page given in response.
          navigate(res.response.redirectPath)
        } else {
          setFeedbackMessage({ icon: <img src={memberIconRed} alt='Member Icon' />, type: 'error', message: res.errorMsg })
        }
      })
      .catch(err => {
        if (!haveResponse) {
          window.location.reload()
        }
        console.log(err)
        setHasError(true)
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  return (
    <div>
      {formLoading
        ? <div><Loader /></div>
        : <>
          <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={inProgress} invisible={true}></Backdrop>
          <div className="managegroup_section_heading">Delete Group</div>
          {hasError && <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: constants.errors.server }} /></Alert>}
          <div>
            {!eligibleForDelete ? <p>This group cannot be deleted until the groups within it are moved or deleted.</p> : <p>Are you sure you want to permanently delete {groupName}. Deleting the group will remove all relationships to the applications, people, and information associated to the group.</p>}
          </div>
          {eligibleForDelete && !hasError && <div className="btn-wrapper-movegroup">
            {!inProgress
              ? <Button id="move-group" variant="contained" color="primary" className="movegrp-btn" onClick={deleteGroup} >
                Delete Group
              </Button>
              : <Button variant="contained" disabled>
                Deleting...
              </Button>}
          </div>}
        </>}
    </div>
  )
}

// Exporting the component.
export default DeleteGroup
