import React, { useState } from 'react'
// Import necessary components.
import starFilled from '../../../../images/star-filled.svg'
import starEmpty from '../../../../images/star-empty.svg'
import IconButton from '@mui/material/IconButton'
import RemoveFavoriteModal from './RemoveFavoriteModal'
import '../sass/component/fav-btn.scss'

// Build and export the component for use.
export const FavoriteButton = ({ userFavorites, addOrRemoveFavorite, assetType, id, gid, title }) => {
  // Set inprogress.
  const [inProgress, setInProgress] = useState(false)
  // Set confirm modal.
  const [confirmModal, setConfirmModal] = useState(false)

  // Method for click handler.
  const handleClick = e => {
    e.preventDefault()
    if (!inProgress) {
      if (userFavorites[assetType].includes(+id)) {
        setConfirmModal(true)
      } else {
        setInProgress(true)
        addOrRemoveFavorite(e, parseInt(id), assetType, title, gid, setInProgress)
      }
    }
  }

  // Handle close.
  const handleClose = e => {
    e.preventDefault()
    setConfirmModal(false)
  }

  // Handle confirm.
  const handleConfirm = e => {
    e.preventDefault()
    setConfirmModal(false)
    addOrRemoveFavorite(e, parseInt(id), assetType, title, gid, setInProgress)
  }

  // Return output.
  return (
    <div className="fav-btn">
      <IconButton onClick={e => handleClick(e)} >
        {userFavorites && <img src={userFavorites[assetType].includes(parseInt(id)) ? starFilled : starEmpty} alt="Favorite icon" />}
      </IconButton>
      <RemoveFavoriteModal confirmModal={confirmModal} handleClose={handleClose} entityName={title} handleConfirm={handleConfirm} />
    </div>
  )
}
