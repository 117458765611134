import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { queryContent } from '../../ContentDetails/ContentPages/CTHelpers/CheckAccess'
import { constants } from '../../../lib'
import Loader from '../../PageLoader'
import './revision-history.scss'
import { generateFieldData, revisionLabels, getUsername, getFormattedDate } from './RevisionHelpers'
import GenericErrorMessage from '../Assets/component/GenericErrorMessage'
import RestoreLinkAndModal from './RestoreLinkAndModal'
import { EventTrackingContext } from '../../../contexts/EventTrackingContext'

// Build the component.
const ViewRevision = ({ setBreadCrumb, setFeedbackMessage, setIsHeaderLoading }) => {
  // Set is loading.
  const [isLoading, setIsLoading] = useState(true)
  // Set group user result.
  const [revisions, setRevisions] = useState({})
  // Set all revision data.
  const [revisionData, setRevisionData] = useState({})
  // Set currentRevision.
  const [currentRevision, setCurrentRevision] = useState({})
  // Get gid, nid from path parameter.
  const { gid, nid, vid } = useParams()
  // Set isRespOrAccountable.
  const [isRespOrAccountable, setIsRespOrAccountable] = useState(false)
  // Set errorMessage.
  const [errorMessage, setErrorMessage] = useState()
  // Set the main page url.
  const mainPageUrl = `/revision-history/${gid}/application/${nid}`
  // Get navigate function hook.
  const navigate = useNavigate()
  // Set page data for tracking.
  const { setPageData } = useContext(EventTrackingContext)

  useEffect(() => {
    // Variables object.
    let IndividualRevisionHistoryVariables = {
      id: parseInt(nid).toString(),
      gid: parseInt(gid).toString(),
      vid: [parseInt(vid).toString()]
    }
    // Stringify the variable object
    IndividualRevisionHistoryVariables = JSON.stringify(IndividualRevisionHistoryVariables)
    // Prepare announcement query.
    const revisionHistoryQuery = `${process.env.REACT_APP_TARGET_URL}/graphql?queryId=${constants.drupalQueryIds.revisionQuery}&variables=${IndividualRevisionHistoryVariables}`
    queryContent(revisionHistoryQuery)
      .then(response => {
        const revisionEntities = response?.data?.revisions?.entities[0]
        // set entities data for revision helper.
        setRevisions(generateFieldData(revisionEntities))
        // set entities data.
        setRevisionData(revisionEntities)
        // Setting the current version.
        setCurrentRevision(response.data.nodeQuery.entities[0])
        // Setting the breadCrumbs.
        setBreadCrumb(response?.data?.nodeQuery?.entities[0]?.entityBreadCrumb)
        if (!response.data.revisions.count) {
          setErrorMessage(constants.errors.nullValue)
        }
        // Getting groups where user is responsible or accountable.
        const eligibleGroups = response.data.groupUserGroupsByUidRA.results.map(result => result.gid)
        // Setting isResponsibleOrAccountable if owning group found in eligibleGroups.
        setIsRespOrAccountable(eligibleGroups.includes(response.data.nodeQuery.entities[0]?.fieldOwningGroup?.entity?.entityId || 0))
        // Sending page data for tracking.
        setPageData({
          type: 'revision-history-individual',
          breadCrumbs: [...response?.data?.nodeQuery?.entities[0]?.entityBreadCrumb, { text: 'Revision-History-Individual' }],
          page_id: nid
        })
      })
      .catch(() => setErrorMessage(constants.errors.server))
      .finally(() => {
        setIsLoading(false)
        setIsHeaderLoading(false)
      })
  }, [nid, gid, vid])

  // Function to navigate to listing page after restore modal submission.
  const navigateToMainPage = () => {
    navigate(`${mainPageUrl}/view/all`)
  }

  // Show loader when is loading is true.
  if (isLoading) {
    return <Loader />
  }

  // Return the output.
  return (
    <>
      {errorMessage
        ? <GenericErrorMessage errorMessage={errorMessage} />
        : <div className='revision_history_view_wrapper'>
          <div className='revision_subject_wrapper'>
            <div className='subject_title'>{revisionData?.title}</div>
            <div className='subject_subtitle'>
              Submitted by {getUsername(revisionData)}&nbsp;
              on {revisionData?.entityChanged ? getFormattedDate(revisionData.entityChanged, true) : constants.filter.none}
            </div>
          </div>
          <div className='revision_body_wrapper'>
            <div className='content'>
              <div className='title'>{revisionLabels.vision}</div>
              <div className='info' dangerouslySetInnerHTML={{ __html: revisions.vision }} />
            </div>
            <div className='content'>
              <div className='title'>{revisionLabels.details}</div>
              <div className='info' dangerouslySetInnerHTML={{ __html: revisions.details }} />
            </div>
            <div className='content'>
              <div className='title'>{revisionLabels.accessInstructions}</div>
              <div className='info' dangerouslySetInnerHTML={{ __html: revisions.accessInstructions }} />
            </div>
          </div>
          <div className='revision_footer_wrapper'>
            <Grid container>
              <Grid item md={4} sm={6} xs={12}>
                <div className='title'>Public or Private?</div>
                <div className='info'>{revisions?.status}</div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className='title'>{revisionLabels.lifecycleState}</div>
                <div className='info'>{revisions?.lifecycleState}</div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className='title'>{revisionLabels.applicationUrl}</div>
                {revisions?.applicationUrl !== constants.filter.none ? <a className='info' href={revisions?.applicationUrl} target="_blank" rel="noreferrer">{revisions.applicationUrl}</a> : revisions?.applicationUrl}
              </Grid >
              <Grid item md={4} sm={6} xs={12}>
                <div className='title'>{revisionLabels.owningGroup}</div>
                <Link className='info' to={revisionData?.fieldOwningGroup?.entity?.url?.path}>{revisions?.owningGroup}</Link>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className='title'>{revisionLabels.owner}</div>
                <a href={`mailto:${revisionData?.creator?.userEmail}`} className='info'>
                  {revisions.owner}
                </a>
              </Grid>
            </Grid>
          </div>
          {currentRevision?.vid !== revisionData?.vid && isRespOrAccountable && <div className='revision_restore_button'>
            <RestoreLinkAndModal selectedVersion={revisionData} nid={nid} setFeedbackMessage={setFeedbackMessage} currentVersion={currentRevision} postSuccessAction={navigateToMainPage} />
          </div>}
        </div>
      }
    </>
  )
}

// Return the component for use.
export default ViewRevision
